html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  line-height: 1;
  font: inherit;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  font-size: 14px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
button,
a,
span,
div,
i {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  text-decoration: none;
  color: inherit;
}
button {
  position: relative;
  box-sizing: border-box;
  border: none;
  overflow: hidden;
}
input,
select {
  outline: none;
}
p {
  text-align: justify;
}
[class^="partner-favicon-"],
[class*=" partner-favicon-"] {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-image: url("https://r.100r.systems/image/upload/v1497885853/sprite-favicon_wfmdhn.jpg");
  background-position: center 0;
  background-color: #fff;
  display: block;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  [class^="partner-favicon-"],
  [class*=" partner-favicon-"] {
    background-image: url("https://r.100r.systems/image/upload/v1497885854/sprite-favicon_x2_ypwchl.jpg");
  }
}
[class^="partner-favicon-x1-"],
[class*=" partner-favicon-x1-"] {
  background-image: url("https://r.100r.systems/image/upload/v1497885853/sprite-favicon_wfmdhn.jpg");
}
[class^="partner-favicon-x2-"],
[class*=" partner-favicon-x2-"] {
  background-image: url("https://r.100r.systems/image/upload/v1497885854/sprite-favicon_x2_ypwchl.jpg");
}
.partner-favicon-1,
.partner-favico-x2-1 {
  background-position: center 0;
}
.partner-favicon-2,
.partner-favico-x2-2 {
  background-position: center 3.571428571428572%;
}
.partner-favicon-3,
.partner-favico-x2-3 {
  background-position: center 7.142857142857143%;
}
.partner-favicon-4,
.partner-favico-x2-4 {
  background-position: center 10.714285714285715%;
}
.partner-favicon-5,
.partner-favico-x2-5 {
  background-position: center 14.285714285714286%;
}
.partner-favicon-6,
.partner-favico-x2-6 {
  background-position: center 17.857142857142858%;
}
.partner-favicon-7,
.partner-favico-x2-7 {
  background-position: center 21.42857142857143%;
}
.partner-favicon-8,
.partner-favico-x2-8 {
  background-position: center 25%;
}
.partner-favicon-9,
.partner-favico-x2-9 {
  background-position: center 28.571428571428573%;
}
.partner-favicon-10,
.partner-favicon-x2-10 {
  background-position: center 32.142857142857146%;
}
.partner-favicon-11,
.partner-favicon-x2-11 {
  background-position: center 35.714285714285715%;
}
.partner-favicon-12,
.partner-favicon-x2-12 {
  background-position: center 39.285714285714285%;
}
.partner-favicon-13,
.partner-favicon-x2-13 {
  background-position: center 42.85714285714286%;
}
.partner-favicon-14,
.partner-favicon-x2-14 {
  background-position: center 46.42857142857143%;
}
.partner-favicon-15,
.partner-favicon-x2-15 {
  background-position: center 50%;
}
.partner-favicon-16,
.partner-favicon-x2-16 {
  background-position: center 53.57142857142858%;
}
.partner-favicon-17,
.partner-favicon-x2-17 {
  background-position: center 57.142857142857146%;
}
.partner-favicon-18,
.partner-favicon-x2-18 {
  background-position: center 60.714285714285715%;
}
.partner-favicon-19,
.partner-favicon-x2-19 {
  background-position: center 64.28571428571429%;
}
.partner-favicon-20,
.partner-favicon-x2-20 {
  background-position: center 67.85714285714286%;
}
.partner-favicon-21,
.partner-favicon-x2-21 {
  background-position: center 71.42857142857143%;
}
.partner-favicon-22,
.partner-favicon-x2-22 {
  background-position: center 75%;
}
.partner-favicon-23,
.partner-favicon-x2-23 {
  background-position: center 78.57142857142857%;
}
.partner-favicon-24,
.partner-favicon-x2-24 {
  background-position: center 82.14285714285715%;
}
.partner-favicon-25,
.partner-favicon-x2-25 {
  background-position: center 85.71428571428572%;
}
.partner-favicon-26,
.partner-favicon-x2-26 {
  background-position: center 89.28571428571429%;
}
.partner-favicon-27,
.partner-favicon-x2-27 {
  background-position: center 92.85714285714286%;
}
.partner-favicon-28,
.partner-favicon-x2-28 {
  background-position: center 96.42857142857143%;
}
.partner-favicon-29,
.partner-favicon-x2-29 {
  background-position: center 100%;
}
[class^="flags-countries-"],
[class*=" flags-countries-"] {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-image: url("https://r.100r.systems/image/fetch/c_limit,f_auto/https://static.100r.systems/img/flags/sprite-flags.jpg");
  background-color: #fff;
  display: block;
}
.flags-countries-spain {
  background-position: center 0;
}
.flags-countries-italy {
  background-position: center 18.51851851851852%;
}
.flags-countries-mexico {
  background-position: center 37.03703703703704%;
}
.flags-countries-france {
  background-position: center 55.55555555555556%;
}
.flags-countries-germany {
  background-position: center 74.07407407407408%;
}
.flags-countries-united-kingdom {
  background-position: center 92.5925925925926%;
}
@charset "UTF-8";
@font-face {
  font-family: "hundredrooms";
  src: url("https://static.100r.systems/font/hundredicons/hundredrooms-26102018.eot");
  src: url("https://static.100r.systems/font/hundredicons/hundredrooms-26102018.eot?#iefix") format("embedded-opentype"), url("https://static.100r.systems/font/hundredicons/hundredrooms-26102018.woff") format("woff"), url("https://static.100r.systems/font/hundredicons/hundredrooms-26102018.ttf") format("truetype"), url("https://static.100r.systems/font/hundredicons/hundredrooms-g.svg#hundredrooms") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "hundredrooms";
  content: attr(data-icon);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="hundredicons-"]:before,
[class*=" hundredicons-"]:before {
  font-family: "hundredrooms";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.hundredicons-spinner:before {
  display: inline-block;
  content: '';
  color: #fff;
  border: 2px solid #fff;
  border-color: #b3b3b3 #b3b3b3 rgba(255,255,255,0.3) rgba(255,255,255,0.3);
  width: 16px;
  height: 16px;
  background: transparent;
  border-radius: 50%;
  will-change: transform;
  contain: strict;
  animation: loading 0.6s infinite linear;
}
.hundredicons-cross:before {
  content: "h";
}
.hundredicons-heart-filled:before {
  content: "2";
}
.hundredicons-email-filled:before {
  content: "/";
}
.hundredicons-logo-facebook-filled:before {
  content: ":";
}
.hundredicons-logo-google-plus-filled:before {
  content: ";";
}
.hundredicons-logo-twitter-bird-filled:before {
  content: "<";
}
.hundredicons-logo-whatsapp-filled:before {
  content: "=";
}
.hundredicons-drop:before {
  content: "@";
}
.hundredicons-sun:before {
  content: "]";
}
.hundredicons-star:before {
  content: "`";
}
.hundredicons-star-half:before {
  content: "|";
}
.hundredicons-hotdeal:before {
  content: "}";
}
.hundredicons-star-filled:before {
  content: "~";
}
.hundredicons-save:before {
  content: "\\";
}
.hundredicons-save-filled:before {
  content: "\E000";
}
.hundredicons-share-simple:before {
  content: "{";
}
.hundredicons-share-reverse:before {
  content: "\E001";
}
.hundredicons-save-star:before {
  content: "\E002";
}
.hundredicons-establishments-4:before {
  content: "\E005";
}
.hundredicons-calendar:before {
  content: "\E007";
}
.hundredicons-link:before {
  content: "\E00B";
}
.hundredicons-favorites-filled:before {
  content: "\E00E";
}
.hundredicons-request:before {
  content: "\E00F";
}
.hundredicons-establishments-1:before {
  content: "\E010";
}
.hundredicons-establishments-3:before {
  content: "\E011";
}
.hundredicons-establishments-2:before {
  content: "\E013";
}
.hundredicons-cross:before {
  content: "\E015";
}
.hundredicons-minus-filled:before {
  content: "\E012";
}
.hundredicons-plus-filled:before {
  content: "\E016";
}
.hundredicons-bolt-filled:before {
  content: "_";
}
.hundredicons-bolt:before {
  content: "\E01D";
}
.hundredicons-facebook:before {
  content: "\E01F";
}
.hundredicons-google:before {
  content: "\E020";
}
.hundredicons-linkedin:before {
  content: "\E021";
}
.hundredicons-twitter:before {
  content: "\E026";
}
.hundredicons-youtube:before {
  content: "\E027";
}
.hundredicons-instagram:before {
  content: "\E028";
}
.hundredicons-search:before {
  content: "b";
}
.hundredicons-percent:before {
  content: "l";
}
.hundredicons-share:before {
  content: "r";
}
.hundredicons-facebook-messenger:before {
  content: "[";
}
.hundredicons-favorites:before {
  content: "\E00C";
}
.hundredicons-recomended-icon:before {
  content: "\E018";
}
.hundredicons-check:before {
  content: "\E019";
}
.hundredicons-services-3:before {
  content: "\E01A";
}
.hundredicons-services-8:before {
  content: "\E022";
}
.hundredicons-services-20:before {
  content: "\E023";
}
.hundredicons-services-19:before {
  content: "\E024";
}
.hundredicons-services-7:before {
  content: "\E025";
}
.hundredicons-services-1:before {
  content: "\E029";
}
.hundredicons-services-17:before {
  content: "\E02A";
}
.hundredicons-services-11:before {
  content: "\E02B";
}
.hundredicons-services-16:before {
  content: "\E02C";
}
.hundredicons-services-13:before {
  content: "\E02D";
}
.hundredicons-services-15:before {
  content: "\E02E";
}
.hundredicons-services-18:before {
  content: "\E02F";
}
.hundredicons-services-4:before {
  content: "\E030";
}
.hundredicons-services-2:before {
  content: "\E031";
}
.hundredicons-services-21:before {
  content: "\E032";
}
.hundredicons-services-12:before {
  content: "\E033";
}
.hundredicons-services-14:before {
  content: "\E034";
}
.hundredicons-services-6:before {
  content: "\E035";
}
.hundredicons-services-5:before {
  content: "\E036";
}
.hundredicons-services-10:before {
  content: "\E037";
}
.hundredicons-services-9:before {
  content: "\E038";
}
.hundredicons-favorites-outline:before {
  content: "\E039";
}
.hundredicons-view:before {
  content: "\E03A";
}
.hundredicons-account:before {
  content: "\E03B";
}
.hundredicons-bell:before {
  content: "\E03C";
}
.hundredicons-trash:before {
  content: "\E03E";
}
.hundredicons-done:before {
  content: "\E03F";
}
.hundredicons-map:before {
  content: "\E041";
}
.hundredicons-refresh:before {
  content: "\E042";
}
.hundredicons-log-out:before {
  content: "a";
}
.hundredicons-back:before {
  content: "A";
}
.hundredicons-arrow-right-filled:before {
  content: "\E01E";
}
.hundredicons-arrow-down-filled:before {
  content: "\E03D";
}
.hundredicons-arrow-left-filled:before {
  content: "\E044";
}
.hundredicons-arrow-up-filled:before {
  content: "\E045";
}
.hundredicons-filter:before {
  content: "f";
}
.hundredicons-bed:before {
  content: "e";
}
.hundredicons-guest:before {
  content: "c";
}
.hundredicons-sort:before {
  content: "g";
}
.hundredicons-results:before {
  content: "h";
}
.hundredicons-door-open:before {
  content: "\E02C";
}
.hundredicons-hr-copylinkl-share-icon:before {
  content: "j";
}
.hundredicons-whatsapp-icon-share:before {
  content: "m";
}
.hundredicons-hr-delete:before {
  content: "q";
}
.hundredicons-hr-pencil:before {
  content: "o";
}
.hundredicons-hr-viewed:before {
  content: "p";
}
.hundredicons-hr-info:before {
  content: "y";
}
.hundredicons-hr-icon-list:before {
  content: "s";
}
.hundredicons-hr-icon-map:before {
  content: "z";
}
.hundredicons-hr-icon-favorito:before {
  content: "d";
}
.hundredicons-hr-icon-area-personal:before {
  content: "c";
}
.hundredicons-comment:before {
  content: "v";
}
.hundredicons-hr-icon-facebook:before {
  content: "n";
}
.hundredicons-hr-icon-favorito-filled:before {
  content: "u";
}
.hundredicons-hr-icon-area-personal-filled:before {
  content: "t";
}
.hundredicons-cancellation {
  vertical-align: middle;
  display: inline-block;
  line-height: 0;
}
.hundredicons-cancellation:before {
  content: '';
  background-image: url("/img/cancellation/generic_cancellation_icon.svg");
  background-position: center center;
  background-repeat: no-repeat;
  vertical-align: middle;
  background-size: contain;
  width: 0;
  height: 0;
  padding: 0.6em;
  display: inline-block;
  margin-right: 0.5em;
}
.hundredicons-cancellation.full-cancellation:before {
  background-image: url("/img/cancellation/full_cancellation_icon.svg");
}
.hundredicons-cancellation.partial-cancellation:before {
  background-image: url("/img/cancellation/partial_cancellation_icon.svg");
}
.hundredicons-cancellation.minified.full:before {
  background-image: url("/img/cancellation/full_cancellation_simple.svg");
}
.hundredicons-cancellation.minified.partial:before {
  background-image: url("/img/cancellation/partial_cancellation_simple.svg");
}
.hundredicons-iconcama:before {
  content: "x";
}
.hundredicons-icon-plane:before {
  content: "w";
}
.hundredicons-icon-loading:before {
  content: "B";
}
.hundredicons-instantbook {
  vertical-align: middle;
  display: inline-block;
  line-height: 0;
}
.hundredicons-instantbook:before {
  content: '';
  background-image: url("/img/instantbook/instantbook.svg");
  background-position: left;
  background-repeat: no-repeat;
  vertical-align: middle;
  background-size: contain;
  width: 15px;
  height: 15px;
  padding: 0.6em;
  display: inline-block;
  margin-right: 0;
}
.hundredicons-cancellation-simple:before {
  content: "\E046";
}
.hundredicons-icon-heart:before {
  content: "\E047";
}
.hundredicons-icon-star:before {
  content: "\E048";
}
.hundredicons-icon-fire:before {
  content: "\E049";
}
.hundredicons-palmtree:before {
  content: "\E050";
}
.-animation-slide-in-left {
  animation-name: slideInLeft;
  animation-duration: 0.3s;
  animation-delay: 0s;
}
.-animation-slide-out-left {
  animation-name: slideOutLeft;
  animation-duration: 0.3s;
  animation-delay: 0s;
}
.-animation-slide-in-right {
  animation-name: slideInRight;
  animation-duration: 0.3s;
  animation-delay: 0s;
}
.-animation-slide-out-right {
  animation-name: slideOutRight;
  animation-duration: 0.3s;
  animation-delay: 0s;
}
.-animation-slide-in-up {
  animation-name: slideInUp;
  animation-duration: 0.3s;
  animation-delay: 0s;
}
.-animation-slide-out-up {
  animation-name: slideOutUp;
  animation-duration: 0.3s;
  animation-delay: 0s;
}
.-animation-bounce {
  animation-name: bounce;
  animation-duration: 0.7s;
  animation-delay: 0s;
}
.-animation-shake {
  animation-name: shake;
  animation-duration: 0.5s;
  animation-delay: 0s;
}
.-animation-pulse {
  animation-name: pulse;
  animation-duration: 0.5s;
  animation-delay: 0s;
}
.-animation-rotate {
  animation-name: rotate;
  animation-duration: 0.5s;
  animation-delay: 0s;
}
.-animation-slide-in-down {
  animation-name: slideInDown;
  animation-duration: 0.5s;
  animation-delay: 0s;
}
.-animation-slide-in-up {
  animation-name: slideInUp;
  animation-duration: 0.5s;
  animation-delay: 0s;
}
.-animation-slide-out-down {
  animation-name: slideOutDown;
  animation-duration: 0.5s;
  animation-delay: 0s;
}
.-animation-ringing {
  animation-name: ringing;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-delay: 0s;
}
.-animation-slide-in-up-windows {
  animation-name: slideInUpWindows;
  animation-duration: 0.5s;
  animation-delay: 0s;
}
.-animation-fade-in {
  animation-name: fade-in;
  animation-duration: 0.5s;
  animation-delay: 0s;
}
.-animation-loading {
  animation-name: loading;
  animation-delay: 0s;
  animation: loading 0.5s infinite linear;
}
.-animation-zoom-in {
  animation-name: zoomIn;
  animation-duration: 0.5s;
  animation-delay: 0s;
}
.-animation-zoom-in {
  animation-name: opacity;
  animation-duration: 0.5s;
  animation-delay: 0s;
}
@-moz-keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slideOutLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@-webkit-keyframes slideOutLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@-o-keyframes slideOutLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slideOutLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@-moz-keyframes slideInRight {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInRight {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes slideInRight {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInRight {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slideOutRight {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
@-webkit-keyframes slideOutRight {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
@-o-keyframes slideOutRight {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes slideOutRight {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
@-moz-keyframes slideInUp {
  0% {
    transform: translate3d(0, 100vh, 0);
  }
  100% {
    transform: translate3d(0, 0vh, 0);
  }
}
@-webkit-keyframes slideInUp {
  0% {
    transform: translate3d(0, 100vh, 0);
  }
  100% {
    transform: translate3d(0, 0vh, 0);
  }
}
@-o-keyframes slideInUp {
  0% {
    transform: translate3d(0, 100vh, 0);
  }
  100% {
    transform: translate3d(0, 0vh, 0);
  }
}
@keyframes slideInUp {
  0% {
    transform: translate3d(0, 100vh, 0);
  }
  100% {
    transform: translate3d(0, 0vh, 0);
  }
}
@-moz-keyframes slideOutUp {
  0% {
    transform: translate3d(0, 0vh, 0);
  }
  100% {
    transform: translate3d(0, 100vh, 0);
  }
}
@-webkit-keyframes slideOutUp {
  0% {
    transform: translate3d(0, 0vh, 0);
  }
  100% {
    transform: translate3d(0, 100vh, 0);
  }
}
@-o-keyframes slideOutUp {
  0% {
    transform: translate3d(0, 0vh, 0);
  }
  100% {
    transform: translate3d(0, 100vh, 0);
  }
}
@keyframes slideOutUp {
  0% {
    transform: translate3d(0, 0vh, 0);
  }
  100% {
    transform: translate3d(0, 100vh, 0);
  }
}
@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translate3d(0, 0, 0);
  }
  40% {
    transform: translate3d(0, -30px, 0);
  }
  60% {
    transform: translate3d(0, -15px, 0);
  }
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translate3d(0, 0, 0);
  }
  40% {
    transform: translate3d(0, -30px, 0);
  }
  60% {
    transform: translate3d(0, -15px, 0);
  }
}
@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translate3d(0, 0, 0);
  }
  40% {
    transform: translate3d(0, -30px, 0);
  }
  60% {
    transform: translate3d(0, -15px, 0);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translate3d(0, 0, 0);
  }
  40% {
    transform: translate3d(0, -30px, 0);
  }
  60% {
    transform: translate3d(0, -15px, 0);
  }
}
@-moz-keyframes shake {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}
@-webkit-keyframes shake {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}
@-o-keyframes shake {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes shake {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}
@-moz-keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@-moz-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: scale(0deg);
  }
}
@-webkit-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: scale(0deg);
  }
}
@-o-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: scale(0deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: scale(0deg);
  }
}
@-moz-keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100vh, 0);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0vh, 0);
  }
}
@-webkit-keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100vh, 0);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0vh, 0);
  }
}
@-o-keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100vh, 0);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0vh, 0);
  }
}
@keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100vh, 0);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0vh, 0);
  }
}
@-moz-keyframes slideOutDown {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
  }
  100% {
    transform: translate3d(0, 100%, 0);
  }
}
@-webkit-keyframes slideOutDown {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
  }
  100% {
    transform: translate3d(0, 100%, 0);
  }
}
@-o-keyframes slideOutDown {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
  }
  100% {
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes slideOutDown {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
  }
  100% {
    transform: translate3d(0, 100%, 0);
  }
}
@-moz-keyframes ringing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(20deg);
  }
  20% {
    transform: rotate(-18deg);
  }
  30% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-13deg);
  }
  50% {
    transform: rotate(8deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  70% {
    transform: rotate(3deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes ringing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(20deg);
  }
  20% {
    transform: rotate(-18deg);
  }
  30% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-13deg);
  }
  50% {
    transform: rotate(8deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  70% {
    transform: rotate(3deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-o-keyframes ringing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(20deg);
  }
  20% {
    transform: rotate(-18deg);
  }
  30% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-13deg);
  }
  50% {
    transform: rotate(8deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  70% {
    transform: rotate(3deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes ringing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(20deg);
  }
  20% {
    transform: rotate(-18deg);
  }
  30% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-13deg);
  }
  50% {
    transform: rotate(8deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  70% {
    transform: rotate(3deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-moz-keyframes slideInUpWindows {
  0% {
    transform: translate3d(0, 2000px, 0);
  }
  90% {
    transform: translate3d(0, -10px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInUpWindows {
  0% {
    transform: translate3d(0, 2000px, 0);
  }
  90% {
    transform: translate3d(0, -10px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes slideInUpWindows {
  0% {
    transform: translate3d(0, 2000px, 0);
  }
  90% {
    transform: translate3d(0, -10px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInUpWindows {
  0% {
    transform: translate3d(0, 2000px, 0);
  }
  90% {
    transform: translate3d(0, -10px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes zoomIn {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: rotate(1);
  }
}
@-webkit-keyframes zoomIn {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: rotate(1);
  }
}
@-o-keyframes zoomIn {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: rotate(1);
  }
}
@keyframes zoomIn {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: rotate(1);
  }
}
@-moz-keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
body .seo-content,
.search .seo-content,
body .content-seo,
.search .content-seo,
body .hide-content,
.search .hide-content {
  display: none;
}
.search #hideWhenSearch,
.account #hideWhenSearch {
  display: none;
}
.search.seo .seo-content,
.account.seo .seo-content,
.search.seo .content-seo,
.account.seo .content-seo,
.search.seo .hide-content,
.account.seo .hide-content {
  display: block;
}
.search.no-scroll,
.account.no-scroll {
  overflow: hidden;
  position: relative;
  height: 100vh;
}
.search.no-scroll::-webkit-scrollbar,
.account.no-scroll::-webkit-scrollbar {
  display: none;
}
[data-tooltip="true"][data-tooltip-text]:hover {
  position: relative;
}
[data-tooltip="true"][data-tooltip-text]:hover:after,
[data-tooltip="true"][data-tooltip-text]:hover:before {
  cursor: auto;
}
[data-tooltip="true"][data-tooltip-text]:hover:after {
  position: absolute;
  opacity: 0.94;
  width: 130px;
  background: #012a36;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  letter-spacing: -0.29px;
  margin: 0;
  content: attr(data-tooltip-text);
  padding: 10px 10px;
  text-align: center;
  text-transform: initial;
  font-weight: 500;
  white-space: normal;
  line-height: 16px;
  top: 54px;
  left: 50%;
  transform-origin: 0% 50%;
  animation-name: tooltip-box;
  animation-duration: 0.25s;
  animation-timing-function: cubic-bezier(0.42, 0, 0, 1.38);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
[data-tooltip="true"][data-tooltip-text]:hover:before {
  animation-name: tooltip-arrow;
  animation-duration: 0.25s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transform-origin: 0% 50%;
  opacity: 0.94;
  height: 0px;
  width: 0px;
  content: '';
  top: 39px;
  border-color: transparent transparent #012a36 transparent;
  left: 50%;
  border-width: 8px;
  border-style: solid;
  text-transform: initial;
  position: absolute;
}
[data-tooltip="true"][data-tooltip-text][data-tooltip-position="top"]:hover {
  position: relative;
}
[data-tooltip="true"][data-tooltip-text][data-tooltip-position="top"]:hover:after,
[data-tooltip="true"][data-tooltip-text][data-tooltip-position="top"]:hover:before {
  cursor: auto;
}
[data-tooltip="true"][data-tooltip-text][data-tooltip-position="top"]:hover:after {
  position: absolute;
  opacity: 0.94;
  width: 130px;
  background: #012a36;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  letter-spacing: -0.29px;
  margin: 0;
  content: attr(data-tooltip-text);
  padding: 10px 10px;
  text-align: center;
  text-transform: initial;
  font-weight: 500;
  white-space: normal;
  line-height: 16px;
  top: initial;
  bottom: calc(100% + 15px);
  left: 50%;
  transform-origin: 0% 50%;
  animation-name: tooltip-box;
  animation-duration: 0.25s;
  animation-timing-function: cubic-bezier(0.42, 0, 0, 1.38);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
[data-tooltip="true"][data-tooltip-text][data-tooltip-position="top"]:hover:before {
  animation-name: tooltip-arrow;
  animation-duration: 0.25s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transform-origin: 0% 50%;
  opacity: 0.94;
  height: 0px;
  width: 0px;
  content: '';
  top: initial;
  bottom: 100%;
  border-color: #012a36 transparent transparent transparent;
  left: 50%;
  border-width: 8px;
  border-style: solid;
  text-transform: initial;
  position: absolute;
}
.ads_afs_container {
  border-top: 1px solid rgba(224,226,236,0.5);
  display: block;
  background-color: #fff;
  box-sizing: border-box;
  padding-bottom: 2em;
}
.ads_afs_container #afscontainer1,
.ads_afs_container #afscontainer2 {
  width: 50% !important;
  display: inline-block;
  vertical-align: top;
  padding: 1em 1em 0 1em;
  box-sizing: border-box;
}
.ads_afs_container #afscontainer1 iframe,
.ads_afs_container #afscontainer2 iframe {
  box-sizing: border-box;
  display: block;
}
@media (max-width: 480px) {
  .ads_afs_container #afscontainer1,
  .ads_afs_container #afscontainer2 {
    width: 100% !important;
  }
}
.geosuggest {
  font-size: 14px;
  letter-spacing: -0.25px;
  font-size: 1rem;
  position: relative;
  width: 50%;
  margin: 1em auto;
  text-align: left;
}
.geosuggest__input {
  width: 100%;
  border: none;
  transition: all ease 0.3s;
}
.geosuggest__input:placeholder {
  color: #4a4a4a;
}
.geosuggest__input:focus {
  box-shadow: 0 0 0 transparent;
  outline: none;
}
.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 2px solid rgba(204,223,244,0.5);
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  transition: all ease 0.3s;
}
.geosuggest__suggests--hidden {
  border: none;
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}
.geosuggest__item {
  font-size: 14px;
  letter-spacing: -0.25px;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: rgba(245,245,245,0.7);
}
.geosuggest__item:last-child {
  background-image: url("/img/map/powered-by-google.png");
  background-repeat: no-repeat;
  background-position: 98% 93%;
}
.geosuggest__item--active {
  background: rgba(204,223,244,0.3);
  color: #4a4a4a;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: rgba(204,223,244,0.3);
}
.CalendarDay {
  text-align: center;
  vertical-align: middle;
  padding: 0;
  box-sizing: border-box;
  color: #565a5c;
  cursor: pointer;
  width: 39px;
  height: 36px;
  border-radius: 30px;
  position: relative;
  font-size: 14px;
  letter-spacing: -0.25px;
}
.CalendarDay__button {
  font-size: 14px;
  letter-spacing: -0.25px;
  cursor: pointer;
  background: #fff;
  padding: 0;
}
.CalendarDay__button:focus {
  outline: none;
}
.CalendarDay--highlighted-calendar:after {
  left: 45%;
  position: absolute;
  background-color: #17d280;
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 30px;
  top: 80%;
}
.CalendarDay--outside {
  border: 0;
  cursor: default;
}
.CalendarDay--outside:active {
  background: #fff;
}
.CalendarDay--hovered {
  background: #e4e7e7;
  color: inherit;
  border-radius: 30px;
}
.CalendarDay--hovered .CalendarDay__button {
  background: #e4e7e7;
}
.CalendarDay--blocked-minimum-nights {
  color: #cacccd;
  background: #fff;
  cursor: default;
}
.CalendarDay--blocked-minimum-nights:active {
  background: #fff;
}
.CalendarDay--selected-span {
  background: #ccdff4;
  color: #4a4a4a;
  border-radius: 0;
}
.CalendarDay--selected-span .CalendarDay__button {
  background: #ccdff4;
}
.CalendarDay--selected-span:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.CalendarDay--selected-span:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.CalendarDay--selected-span.CalendarDay--last-in-range {
  border-right: #005fc9;
}
.CalendarDay--selected-span .CalendarDay--hovered,
.CalendarDay--selected-span:active {
  color: #fff;
}
.CalendarDay--hovered-span,
.CalendarDay--after-hovered-start {
  background: #ccdff4;
  color: #4a4a4a;
  border-radius: 0;
}
.CalendarDay--hovered-span .CalendarDay__button,
.CalendarDay--after-hovered-start .CalendarDay__button {
  background: #ccdff4;
}
.CalendarDay--hovered-span:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.CalendarDay--hovered-span:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.CalendarDay--blocked-calendar {
  background: #cacccd;
  color: #82888a;
  cursor: default;
}
.CalendarDay--blocked-calendar:active {
  background: #cacccd;
}
.CalendarDay--blocked-out-of-range {
  color: #cacccd;
  background: #fff;
  cursor: default;
}
.CalendarDay--blocked-out-of-range:active {
  background: #fff;
}
.CalendarDay--blocked-out-of-range:after {
  opacity: 0.5;
}
.CalendarDay--selected,
.CalendarDay--selected-start,
.CalendarDay--selected-end {
  background: #ccdff4;
  color: #fff;
  z-index: 4;
  font-weight: 700;
}
.CalendarDay--selected:before,
.CalendarDay--selected-start:before,
.CalendarDay--selected-end:before {
  will-change: transform;
  position: absolute;
  display: block;
  content: '';
  width: 38px;
  height: 36px;
  animation: grow-date 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: forwards;
  border-radius: 50%;
  background: #005fc9;
  top: 0px;
  left: 0px;
  z-index: -1;
}
.CalendarDay--selected .CalendarDay__button,
.CalendarDay--selected-start .CalendarDay__button,
.CalendarDay--selected-end .CalendarDay__button {
  background: #005fc9;
}
.CalendarDay--selected-start {
  border-radius: 0;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.CalendarDay--selected-end {
  border-radius: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.CalendarDay--selected-end:first-child:after {
  margin-left: -11px;
  width: 22px;
}
.CalendarDay--hovered-span.CalendarDay--hovered {
  background: #ccdff4;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.CalendarMonth {
  text-align: center;
  padding: 0 13px;
  vertical-align: top;
  user-select: none;
}
.CalendarMonth:first-of-type {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.CalendarMonth table {
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
  border-spacing: 0;
}
.CalendarMonth--horizontal {
  display: inline-block;
  min-height: 100%;
}
.CalendarMonth--vertical {
  display: block;
}
.CalendarMonth__caption {
  background: #fff;
  font-weight: 700;
  color: #4a4a4a;
  margin-top: 7px;
  font-size: 15px;
  letter-spacing: -0.25px;
  text-align: center;
  margin-bottom: 10px;
  position: relative;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
}
.CalendarMonth--horizontal .CalendarMonth__caption,
.CalendarMonth--vertical .CalendarMonth__caption {
  padding: 15px 0 35px;
  font-size: 16px;
  letter-spacing: -0.25px;
  width: 273px;
  min-width: unset;
}
.CalendarMonth--vertical-scrollable .CalendarMonth__caption {
  padding: 5px 0;
}
.CalendarMonthGrid {
  background: #fff;
  z-index: 0;
  text-align: left;
}
.CalendarMonthGrid--animating {
  transition: transform 0.2s ease-in-out;
  z-index: 1;
}
.CalendarMonthGrid--horizontal {
  position: absolute;
  left: 9px;
  width: 1200px;
}
.CalendarMonthGrid--vertical {
  width: 300px;
  margin: 0 auto;
}
.CalendarMonthGrid--vertical-scrollable {
  width: 300px;
  margin: 0 auto;
  overflow-y: scroll;
}
.DayPicker {
  background: #fff;
  position: relative;
  text-align: left;
}
.DayPicker--horizontal {
  background: #fff;
  box-shadow: 0 10px 6px rgba(0,0,0,0.05), 0 0 0 1px #ccdff4;
  border-radius: 3px;
}
.DayPicker.DayPicker--portal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%;
}
.DayPicker__week-headers {
  margin-left: 9px;
}
.DayPicker--vertical.DayPicker--portal {
  position: initial;
}
.DayPicker--vertical .DayPicker__week-header {
  margin-left: -150px;
  left: 50%;
}
.DayPicker__week-headers {
  position: relative;
}
.DayPicker__week-header {
  font-size: 13px;
  color: #4a4a4a;
  position: absolute;
  width: 300px;
  top: 62px;
  z-index: 2;
  padding: 0 13px;
  text-align: left;
  font-weight: 500;
}
.DayPicker__week-header ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
}
.DayPicker__week-header li {
  display: inline-block;
  width: 39px;
  text-align: center;
}
.DayPicker--vertical-scrollable {
  height: 100%;
}
.DayPicker--vertical-scrollable .DayPicker__week-header {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.DayPicker--vertical-scrollable .transition-container--vertical {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
}
.transition-container {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
.transition-container--horizontal {
  transition: height 0.2s ease-in-out;
  height: 400px !important;
}
.transition-container--horizontal:before {
  position: absolute;
  background-color: #ccdff4;
  content: '';
  width: 90%;
  height: 1px;
  bottom: 75px;
  margin-left: 5%;
}
.transition-container--vertical {
  width: 100%;
}
.DayPickerNavigation__prev,
.DayPickerNavigation__next {
  cursor: pointer;
  line-height: 0.78;
  user-select: none;
}
.DayPickerNavigation__prev--default,
.DayPickerNavigation__next--default {
  background-color: #fff;
  color: #757575;
}
.DayPickerNavigation__prev--default:active,
.DayPickerNavigation__next--default:active {
  background: #f2f2f2;
}
.DayPickerNavigation--horizontal {
  position: relative;
}
.DayPickerNavigation__prev {
  left: 22px;
}
.DayPickerNavigation__next {
  right: 22px;
}
.DayPickerNavigation--horizontal .DayPickerNavigation__prev,
.DayPickerNavigation--horizontal .DayPickerNavigation__next {
  border-radius: 3px;
  padding: 6px 9px;
  top: 13px;
  z-index: 2;
  position: absolute;
}
.DayPickerNavigation--horizontal .DayPickerNavigation__prev--default svg,
.DayPickerNavigation--horizontal .DayPickerNavigation__next--default svg {
  height: 19px;
  width: 19px;
  fill: #005fc9;
}
.DayPickerNavigation--vertical {
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0,0,0,0.1);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 52px;
  width: 100%;
  z-index: 2;
}
.DayPickerNavigation__next--default {
  border-left: 0;
}
.DayPickerNavigation--vertical .DayPickerNavigation__prev,
.DayPickerNavigation--vertical .DayPickerNavigation__next {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 50%;
}
.DayPickerNavigation--vertical .DayPickerNavigation__prev--default,
.DayPickerNavigation--vertical .DayPickerNavigation__next--default {
  text-align: center;
  font-size: 2.5em;
  padding: 5px;
}
.DayPickerNavigation--vertical .DayPickerNavigation__prev--default svg,
.DayPickerNavigation--vertical .DayPickerNavigation__next--default svg {
  height: 42px;
  width: 42px;
  fill: #484848;
}
.DayPickerNavigation--vertical-scrollable {
  position: relative;
}
.DayPickerNavigation--vertical-scrollable .DayPickerNavigation__next {
  width: 100%;
}
.DateInput {
  width: auto;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: -0.25px;
  line-height: 22px;
  color: rgba(0,0,0,0.4);
  margin: 0;
  padding: 8px 0px;
  background: #fff;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.DateInput--with-caret:before,
.DateInput--with-caret:after {
  top: 66px;
  border-left: 1px solid #ccdff4;
  border-top: 1px solid #ccdff4;
  content: '';
  display: block;
  opacity: 1;
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  transform: rotateZ(45deg);
  left: 22px;
  z-index: 9999;
}
.DateInput--disabled {
  background: #cacccd;
}
.DateInput__input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  height: 100%;
  width: 100%;
}
.DateInput__input[readonly] {
  user-select: none;
}
.DateInput__display-text {
  padding: 0px;
  white-space: nowrap;
  overflow: hidden;
  padding: 3px;
}
.DateInput__display-text--has-input {
  color: #484848;
  font-weight: 500;
}
.DateInput__display-text--focused:after {
  content: '';
  display: block;
  height: 2px;
  background-color: #1c1f56;
  position: absolute;
  bottom: -4px;
  animation-fill-mode: forwards;
  transform: translateX(1px);
  max-width: 90%;
  width: 90%;
}
.DateInput__display-text--disabled {
  font-style: italic;
}
.screen-reader-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.DateRangePicker {
  padding: 0 5px 0 20px;
  position: relative;
  display: inline-block;
}
.DateRangePicker__picker {
  z-index: 9998;
  background-color: #fff;
  position: absolute;
  top: 72px;
}
.DateRangePicker__picker :focus {
  outline: none;
}
.DateRangePicker__picker--direction-left {
  left: -220px !important;
}
.DateRangePicker__picker--direction-right {
  right: 0;
}
.DateRangePicker__picker--full-screen-portal {
  transform: translateZ(1px);
}
.DateRangePicker__picker__horizontal {
  opacity: 1;
}
.DateRangePicker--portal {
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.DateRangePicker--full-screen-portal {
  background-color: #fff;
  transform: translateZ(1px);
}
.DateRangePicker__close {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}
.DateRangePicker__close svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}
.DateRangePicker__close:hover,
.DateRangePicker__close:focus {
  color: #b0b3b4;
  text-decoration: none;
}
.DateRangePickerInput {
  display: inline-block;
  width: 272px;
}
.DateRangePickerInput--disabled {
  background: #cacccd;
}
.DateRangePickerInput__arrow {
  display: inline-block;
  vertical-align: middle;
  padding: 0px 5px;
}
.DateRangePickerInput__arrow svg {
  padding: 0px 3px;
  vertical-align: middle;
  fill: #005fc9;
  height: 24px;
  width: 24px;
}
.DateRangePickerInput__clear-dates {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 10px 0 5px;
}
.DateRangePickerInput__clear-dates svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}
.DateRangePickerInput__clear-dates--hide {
  visibility: hidden;
}
.DateRangePickerInput__clear-dates:focus,
.DateRangePickerInput__clear-dates--hover {
  background: #dbdbdb;
  border-radius: 50%;
}
.DateRangePickerInput__calendar-icon {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px;
}
.DateRangePickerInput__calendar-icon svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle;
}
.SingleDatePicker {
  position: relative;
  display: inline-block;
}
.SingleDatePicker__picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
  top: 72px;
}
.SingleDatePicker__picker--direction-left {
  left: 0;
}
.SingleDatePicker__picker--direction-right {
  right: 0;
}
.SingleDatePicker__picker--portal {
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.SingleDatePicker__picker--full-screen-portal {
  background-color: #fff;
  transform: translateZ(1px);
}
.SingleDatePicker__close {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}
.SingleDatePicker__close svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}
.SingleDatePicker__close:hover,
.SingleDatePicker__close:focus {
  color: #b0b3b4;
  text-decoration: none;
}
.SingleDatePickerInput {
  background-color: #fff;
  border: 1px solid #dbdbdb;
}
.SingleDatePickerInput__clear-date {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 10px 0 5px;
}
.SingleDatePickerInput__clear-date svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}
.SingleDatePickerInput__clear-date--hide {
  visibility: hidden;
}
.SingleDatePickerInput__clear-date:focus,
.SingleDatePickerInput__clear-date--hover {
  background: #dbdbdb;
  border-radius: 50%;
}
td:last-child tr:last-child {
  border-radius: 50%;
}
.transition-container--vertical {
  margin-top: 12%;
}
.DateRangePicker__picker--full-screen-portal {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0 !important;
  position: fixed;
}
.DateRangePicker__picker--full-screen-portal .DayPicker--horizontal {
  top: 40vh;
}
.-testA-placeHolder .DateInput {
  color: #4a4a4a;
}
.-testA-placeHolder .DateInput__display-text--focused {
  background: #ccdff4;
  border-color: #ccdff4;
  border-radius: 3px;
}
.-testA-placeHolder .DateInput__display-text--focused:after {
  display: none;
}
.DateInput--with-caret:before,
.DateInput--with-caret:after {
  opacity: 1;
  transition: opacity 0.1s;
  transition-delay: 0.2s;
}
@media (min-width: 768px) {
  .-dissapear-caret:before,
  .-dissapear-caret:after {
    opacity: 0;
  }
  .-dissapear-cal {
    transition: opacity 0.1s;
    transition-delay: 0.2s;
    opacity: 0;
  }
@-moz-keyframes grow-date {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
}
@-webkit-keyframes grow-date {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
}
@-o-keyframes grow-date {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
}
@keyframes grow-date {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
}
}
.DayPickerKeyboardShortcuts__show.DayPickerKeyboardShortcuts__show--bottom-right {
  display: none;
}
.breadcrumb {
  color: #4a4a4a;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: -0.29px;
}
.breadcrumb__item {
  line-height: 30px;
}
.breadcrumb__item__arrow {
  display: inline-block;
  line-height: 7px;
  width: 10px;
  vertical-align: middle;
}
.breadcrumb__item__link {
  margin: 0 5px;
}
.hundred-ui-button {
  display: block;
  height: 50px;
  width: 100%;
  max-width: 230px;
  min-width: 100px;
  margin: 0;
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,0.1);
  position: relative;
  background-color: transparent;
  color: #4a4a4a;
  line-height: 50px;
  text-transform: inherit;
  text-align: center;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 400;
  transition: all ease 0.3s;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 3.515625% 0 3.515625%;
}
.hundred-ui-button:before {
  width: 20px;
  right: 10px;
  position: absolute;
  line-height: 50px;
  font-size: 15px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
}
.hundred-ui-button:focus,
.hundred-ui-button:hover {
  outline: none;
}
.hundred-ui-button.-left-icon {
  padding: 0 30px 0 35px;
}
.hundred-ui-button.-left-icon:before {
  right: inherit;
  left: 10px;
  position: absolute;
}
.hundred-ui-button.-right-icon {
  padding: 0px 30px 0px 5px;
}
.hundred-ui-button.-right-icon:before {
  left: inherit;
  right: 10px;
  position: absolute;
}
.hundred-ui-button.-center-icon {
  font-size: 0;
}
.hundred-ui-button.-center-icon:before {
  position: inherit;
  text-align: center;
}
.hundred-ui-button.-white-color {
  color: #fff;
  border-color: #fff;
}
.hundred-ui-button.-white-color:before {
  color: #fff;
}
.hundred-ui-button.-white-color:focus,
.hundred-ui-button.-white-color:hover {
  background-color: rgba(255,255,255,0.7);
}
.hundred-ui-button.-white-color.-filled {
  color: #4a4a4a;
  border-color: #fff;
  background-color: #fff;
}
.hundred-ui-button.-white-color.-filled:before {
  color: #4a4a4a;
}
.hundred-ui-button.-white-color.-filled:hover {
  background-color: rgba(255,255,255,0.9);
}
.hundred-ui-button.-id-rounded {
  border-radius: 30px;
}
.hundred-ui-button.-primary-color {
  color: #005fc9;
  border-color: #005fc9;
}
.hundred-ui-button.-primary-color:before {
  color: #005fc9;
}
.hundred-ui-button.-primary-color:focus,
.hundred-ui-button.-primary-color:hover {
  background-color: rgba(0,95,201,0.05);
}
.hundred-ui-button.-primary-color.-filled {
  color: #fff;
  background-color: #005fc9;
}
.hundred-ui-button.-primary-color.-filled:before {
  color: #fff;
}
.hundred-ui-button.-primary-color.-filled:focus,
.hundred-ui-button.-primary-color.-filled:hover {
  background-color: #005fc9;
}
.hundred-ui-button.-secondary-color {
  color: #353a8e;
  border-color: #353a8e;
}
.hundred-ui-button.-secondary-color:before {
  color: #353a8e;
}
.hundred-ui-button.-secondary-color:focus,
.hundred-ui-button.-secondary-color:hover {
  background-color: rgba(53,58,142,0.05);
}
.hundred-ui-button.-secondary-color.-filled {
  color: rgba(255,255,255,0.9);
  background-color: #353a8e;
}
.hundred-ui-button.-secondary-color.-filled:before {
  color: rgba(255,255,255,0.9);
}
.hundred-ui-button.-secondary-color.-filled:focus,
.hundred-ui-button.-secondary-color.-filled:hover {
  background-color: #353a8e;
}
.hundred-ui-button.-gray--2 {
  color: #b3b3b3;
  border-color: #b3b3b3;
}
.hundred-ui-button.-gray--2:before {
  color: #b3b3b3;
}
.hundred-ui-button.-gray--2:focus,
.hundred-ui-button.-gray--2:hover {
  background-color: rgba(179,179,179,0.05);
}
.hundred-ui-button.-gray--2.-filled {
  color: #4a4a4a;
  background-color: #b3b3b3;
}
.hundred-ui-button.-gray--2.-filled:before {
  color: #4a4a4a;
}
.hundred-ui-button.-gray--2.-filled:focus,
.hundred-ui-button.-gray--2.-filled:hover {
  background-color: #353a8e;
}
.hundred-ui-button.-red-color--2 {
  color: #ff7165;
  border-color: #ff7165;
}
.hundred-ui-button.-red-color--2:before {
  color: #ff7165;
}
.hundred-ui-button.-red-color--2:focus,
.hundred-ui-button.-red-color--2:hover {
  background-color: rgba(255,126,130,0.05);
}
.hundred-ui-button.-red-color--2.-filled {
  color: #fff;
  background-color: #ff7165;
}
.hundred-ui-button.-red-color--2.-filled:before {
  color: rgba(255,255,255,0.9);
}
.hundred-ui-button.-red-color--2.-filled:focus,
.hundred-ui-button.-red-color--2.-filled:hover {
  background-color: #ff7e82;
}
.hundred-ui-button.-razzmatazz {
  color: #e91e63;
  border-color: #e91e63;
}
.hundred-ui-button.-razzmatazz:before {
  color: #e91e63;
}
.hundred-ui-button.-razzmatazz:focus,
.hundred-ui-button.-razzmatazz:hover {
  background-color: rgba(233,30,99,0.05);
}
.hundred-ui-button.-razzmatazz.-filled {
  color: #fff;
  background-color: #e91e63;
}
.hundred-ui-button.-razzmatazz.-filled:before {
  color: rgba(255,255,255,0.9);
}
.hundred-ui-button.-razzmatazz.-filled:focus,
.hundred-ui-button.-razzmatazz.-filled:hover {
  background-color: rgba(233,30,99,0.9);
}
.hundred-ui-button.-afterMidnight {
  color: #1c1f56;
  border-color: #1c1f56;
}
.hundred-ui-button.-afterMidnight:before {
  color: #1c1f56;
}
.hundred-ui-button.-afterMidnight:focus,
.hundred-ui-button.-afterMidnight:hover {
  background-color: rgba(28,31,86,0.05);
}
.hundred-ui-button.-afterMidnight.-filled {
  color: #fff;
  background-color: #1c1f56;
}
.hundred-ui-button.-afterMidnight.-filled:before {
  color: rgba(255,255,255,0.9);
}
.hundred-ui-button.-afterMidnight.-filled:focus,
.hundred-ui-button.-afterMidnight.-filled:hover {
  background-color: rgba(28,31,86,0.9);
}
.hundred-ui-button.-confirmation-button {
  color: #50edc0;
  border-color: #50edc0;
}
.hundred-ui-button.-confirmation-button:before {
  color: #50edc0;
}
.hundred-ui-button.-confirmation-button:focus,
.hundred-ui-button.-confirmation-button:hover {
  background-color: rgba(80,237,192,0.05);
}
.hundred-ui-button.-confirmation-button.-filled {
  color: #fff;
  background-color: #50edc0;
}
.hundred-ui-button.-confirmation-button.-filled:before {
  color: rgba(255,255,255,0.9);
}
.hundred-ui-button.-confirmation-button.-filled:focus,
.hundred-ui-button.-confirmation-button.-filled:hover {
  background-color: rgba(80,237,192,0.9);
}
.hundred-ui-button.-action-color {
  color: #353a8e;
  border-color: #353a8e;
}
.hundred-ui-button.-action-color:before {
  color: #353a8e;
}
.hundred-ui-button.-action-color:focus,
.hundred-ui-button.-action-color:hover {
  background-color: rgba(53,58,142,0.05);
}
.hundred-ui-button.-action-color.-filled {
  color: #fff;
  background-color: #353a8e;
}
.hundred-ui-button.-action-color.-filled:before {
  color: rgba(255,255,255,0.9);
}
.hundred-ui-button.-action-color.-filled:focus,
.hundred-ui-button.-action-color.-filled:hover {
  background-color: rgba(53,58,142,0.9);
}
.hundred-ui-button.-is-success-state .-is-success-state,
.hundred-ui-button.-is-success-state.-gray--2,
.hundred-ui-button.-is-success-state.-secondary-color,
.hundred-ui-button.-is-success-state.-primary-color,
.hundred-ui-button.-is-success-state.-white-color,
.hundred-ui-button.-is-success-state.-right-icon,
.hundred-ui-button.-is-success-state.-left-icon,
.hundred-ui-button.-is-success-state.-confirmation-button {
  transition: all ease 0.5s;
  color: #fff;
  background-color: #16d280;
  border-color: #16d280;
  text-align: center;
  padding: 0;
}
.hundred-ui-button.-is-success-state .-is-success-state:focus,
.hundred-ui-button.-is-success-state.-gray--2:focus,
.hundred-ui-button.-is-success-state.-secondary-color:focus,
.hundred-ui-button.-is-success-state.-primary-color:focus,
.hundred-ui-button.-is-success-state.-white-color:focus,
.hundred-ui-button.-is-success-state.-right-icon:focus,
.hundred-ui-button.-is-success-state.-left-icon:focus,
.hundred-ui-button.-is-success-state.-confirmation-button:focus,
.hundred-ui-button.-is-success-state .-is-success-state:hover,
.hundred-ui-button.-is-success-state.-gray--2:hover,
.hundred-ui-button.-is-success-state.-secondary-color:hover,
.hundred-ui-button.-is-success-state.-primary-color:hover,
.hundred-ui-button.-is-success-state.-white-color:hover,
.hundred-ui-button.-is-success-state.-right-icon:hover,
.hundred-ui-button.-is-success-state.-left-icon:hover,
.hundred-ui-button.-is-success-state.-confirmation-button:hover {
  background-color: #16d280;
}
.hundred-ui-button.-is-success-state .-is-success-state:before,
.hundred-ui-button.-is-success-state.-gray--2:before,
.hundred-ui-button.-is-success-state.-secondary-color:before,
.hundred-ui-button.-is-success-state.-primary-color:before,
.hundred-ui-button.-is-success-state.-white-color:before,
.hundred-ui-button.-is-success-state.-right-icon:before,
.hundred-ui-button.-is-success-state.-left-icon:before,
.hundred-ui-button.-is-success-state.-confirmation-button:before {
  position: relative;
  display: inline-block;
  right: inherit;
  left: inherit;
}
.hundred-ui-button.-is-error-state.-is-error-state,
.hundred-ui-button.-is-error-state.-gray--2,
.hundred-ui-button.-is-error-state.-secondary-color,
.hundred-ui-button.-is-error-state.-primary-color,
.hundred-ui-button.-is-error-state.-white-color,
.hundred-ui-button.-is-error-state.-right-icon,
.hundred-ui-button.-is-error-state.-left-icon,
.hundred-ui-button.-is-error-state.-confirmation-button {
  color: #fff;
  background-color: #ff7165;
  border-color: #ff7165;
  font-size: 16px;
  letter-spacing: -0.25px;
  cursor: default;
  font-weight: 300;
  text-transform: inherit;
}
.hundred-ui-button.-is-error-state.-is-error-state:focus,
.hundred-ui-button.-is-error-state.-gray--2:focus,
.hundred-ui-button.-is-error-state.-secondary-color:focus,
.hundred-ui-button.-is-error-state.-primary-color:focus,
.hundred-ui-button.-is-error-state.-white-color:focus,
.hundred-ui-button.-is-error-state.-right-icon:focus,
.hundred-ui-button.-is-error-state.-left-icon:focus,
.hundred-ui-button.-is-error-state.-confirmation-button:focus,
.hundred-ui-button.-is-error-state.-is-error-state:hover,
.hundred-ui-button.-is-error-state.-gray--2:hover,
.hundred-ui-button.-is-error-state.-secondary-color:hover,
.hundred-ui-button.-is-error-state.-primary-color:hover,
.hundred-ui-button.-is-error-state.-white-color:hover,
.hundred-ui-button.-is-error-state.-right-icon:hover,
.hundred-ui-button.-is-error-state.-left-icon:hover,
.hundred-ui-button.-is-error-state.-confirmation-button:hover {
  background-color: #ff7165;
}
.hundred-ui-button.-is-error-state.-is-error-state:before,
.hundred-ui-button.-is-error-state.-gray--2:before,
.hundred-ui-button.-is-error-state.-secondary-color:before,
.hundred-ui-button.-is-error-state.-primary-color:before,
.hundred-ui-button.-is-error-state.-white-color:before,
.hundred-ui-button.-is-error-state.-right-icon:before,
.hundred-ui-button.-is-error-state.-left-icon:before,
.hundred-ui-button.-is-error-state.-confirmation-button:before {
  display: none;
}
.hundred-ui-button.-is-loading-state.-is-loading-state,
.hundred-ui-button.-is-loading-state.-gray--2,
.hundred-ui-button.-is-loading-state.-secondary-color,
.hundred-ui-button.-is-loading-state.-primary-color,
.hundred-ui-button.-is-loading-state.-white-color,
.hundred-ui-button.-is-loading-state.-right-icon,
.hundred-ui-button.-is-loading-state.-left-icon,
.hundred-ui-button.-is-loading-state.-confirmation-button {
  color: #fff;
  background-color: #b3b3b3;
  border-color: #b3b3b3;
  cursor: default;
  content: '';
}
.hundred-ui-button.-is-loading-state.-is-loading-state:after,
.hundred-ui-button.-is-loading-state.-gray--2:after,
.hundred-ui-button.-is-loading-state.-secondary-color:after,
.hundred-ui-button.-is-loading-state.-primary-color:after,
.hundred-ui-button.-is-loading-state.-white-color:after,
.hundred-ui-button.-is-loading-state.-right-icon:after,
.hundred-ui-button.-is-loading-state.-left-icon:after,
.hundred-ui-button.-is-loading-state.-confirmation-button:after {
  content: '';
  color: #fff;
  border: 3px solid #fff;
  border-color: #fff #fff rgba(255,255,255,0.3) rgba(255,255,255,0.3);
  width: 20px;
  height: 20px;
  margin: 15px auto;
  display: inline-block;
  border-radius: 50%;
  animation: loading 0.6s infinite linear;
}
.hundred-ui-button.-is-loading-state.-is-loading-state:focus,
.hundred-ui-button.-is-loading-state.-gray--2:focus,
.hundred-ui-button.-is-loading-state.-secondary-color:focus,
.hundred-ui-button.-is-loading-state.-primary-color:focus,
.hundred-ui-button.-is-loading-state.-white-color:focus,
.hundred-ui-button.-is-loading-state.-right-icon:focus,
.hundred-ui-button.-is-loading-state.-left-icon:focus,
.hundred-ui-button.-is-loading-state.-confirmation-button:focus,
.hundred-ui-button.-is-loading-state.-is-loading-state:hover,
.hundred-ui-button.-is-loading-state.-gray--2:hover,
.hundred-ui-button.-is-loading-state.-secondary-color:hover,
.hundred-ui-button.-is-loading-state.-primary-color:hover,
.hundred-ui-button.-is-loading-state.-white-color:hover,
.hundred-ui-button.-is-loading-state.-right-icon:hover,
.hundred-ui-button.-is-loading-state.-left-icon:hover,
.hundred-ui-button.-is-loading-state.-confirmation-button:hover {
  background-color: #b3b3b3;
  border-color: #b3b3b3;
}
.hundred-ui-button.-is-loading-state.-is-loading-state:before,
.hundred-ui-button.-is-loading-state.-gray--2:before,
.hundred-ui-button.-is-loading-state.-secondary-color:before,
.hundred-ui-button.-is-loading-state.-primary-color:before,
.hundred-ui-button.-is-loading-state.-white-color:before,
.hundred-ui-button.-is-loading-state.-right-icon:before,
.hundred-ui-button.-is-loading-state.-left-icon:before,
.hundred-ui-button.-is-loading-state.-confirmation-button:before {
  display: none;
}
.hundred-ui-button.-filled:disabled,
.hundred-ui-button.-no-filled:disabled {
  border-color: rgba(76,73,74,0.8);
  background-color: rgba(76,73,74,0.8);
  cursor: default;
  color: #fff;
}
.hundred-ui-button.-filled:disabled:hover,
.hundred-ui-button.-no-filled:disabled:hover,
.hundred-ui-button.-filled:disabled:focus,
.hundred-ui-button.-no-filled:disabled:focus {
  border-color: rgba(76,73,74,0.8);
  background-color: rgba(76,73,74,0.8);
  cursor: default;
  color: #fff;
}
.card-number {
  width: 164px;
  height: 126px;
  margin: auto;
  border-radius: 3px;
  background-color: #fff;
  padding: 16px;
  box-sizing: border-box;
  text-align: left;
}
.card-number__text {
  color: #61625e;
  text-align: left;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 18px;
  margin: 0 0 14px 0;
}
.card-number__number {
  line-height: 42px;
  font-size: 42px;
  font-weight: 700;
  color: #005fc9;
}
.card-number__number__symbol {
  font-size: 30px;
}
@media (min-width: 1024px) {
  .card-number {
    border: 1px solid rgba(0,0,0,0.1);
    height: auto;
    width: auto;
    min-width: 300px;
    padding: 50px;
  }
  .card-number__text {
    text-align: center;
    font-size: 24px;
    font-weight: 100;
    line-height: 1.5;
  }
  .card-number__number {
    text-align: center;
    font-size: 82px;
    font-weight: 100;
    line-height: 1.5;
    margin: 0;
  }
}
.carrousel {
  width: 100%;
  cursor: pointer;
  overflow: hidden;
}
.carrousel__number {
  display: none;
}
.carrousel__innocuos-container {
  width: 100%;
  overflow: hidden;
  height: 320px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  box-sizing: border-box;
}
.carrousel__spinner {
  display: block;
  color: #fff;
  border: 3px solid #fff;
  border-color: rgba(76,73,74,0.8) rgba(76,73,74,0.8) rgba(255,255,255,0.3) rgba(255,255,255,0.3);
  width: 20px;
  height: 20px;
  background: transparent;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-left: -10px;
  top: 50%;
  margin-top: -10px;
  will-change: transform;
  contain: strict;
  animation: loading 0.6s infinite linear;
}
.carrousel__arrow-left,
.carrousel__arrow-right {
  color: #fff;
  position: absolute;
  font-size: 20px;
  height: 100%;
  cursor: pointer;
  width: 35px;
  transition: all ease 0.3s;
  text-shadow: 0 0 2px #333;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}
.carrousel__arrow-left {
  justify-content: flex-start;
}
.carrousel__arrow-left:before {
  margin-left: 10px;
}
.carrousel__arrow-left.-is-hidden {
  opacity: 0.2;
  cursor: default;
}
.carrousel__arrow-right {
  justify-content: flex-end;
}
.carrousel__arrow-right:before {
  margin-right: 10px;
}
.carrousel__arrow-right.hundredicons-refresh {
  font-size: 20px;
  text-shadow: 0 0 2px #333;
  will-change: transform;
}
.carrousel__arrow-right.-is-hidden {
  opacity: 0;
  cursor: default;
}
.carrousel__arrow-right:before {
  flex: 0 1 auto;
}
.carrousel__arrow-right {
  right: 0;
}
.carrousel__container {
  height: 320px;
  max-height: 320px;
  min-height: 320px;
  overflow: hidden;
  will-change: transform;
  box-sizing: border-box;
  padding: 0;
  contain: strict;
}
.carrousel__container.-slow-animation {
  transition: all 0.3s ease;
}
.carrousel__container.-hidding-animation {
  transition: all 0.3s;
}
.carrousel__container__element {
  display: inline-block;
  vertical-align: top;
  height: 320px;
  max-height: 320px;
  min-height: 320px;
  background-position: center;
  background-size: cover;
}
.carrousel__container__dots {
  width: 100%;
  text-align: center;
  height: 48px;
}
.carrousel__container__dots__dot {
  width: 15px;
  color: rgba(0,0,0,0.1);
  font-size: 7px;
  display: inline-block;
  vertical-align: middle;
  line-height: 48px;
}
.carrousel__container__dots__dot.-is-active {
  color: #61625e;
}
.carrousel__container__dots:before {
  line-height: 48px;
}
.carrousel .-isImgTag {
  object-fit: cover;
  overflow: hidden;
  height: 280px !important;
  width: auto;
  margin-top: 0 !important;
}
.hundred-ui-checkbox {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  user-select: none;
  width: 100%;
  box-sizing: border-box;
}
.hundred-ui-checkbox[name]:after,
.hundred-ui-checkbox[name]:before {
  transition: all ease 0.5s;
  transform: translate(0px, 0px);
  opacity: 0;
  left: 0;
  top: 0;
}
.hundred-ui-checkbox[name]:hover:after {
  background: #4a4a4a;
  border-radius: 3px;
  color: #fff;
  content: attr(name);
  display: block;
  font-size: 12px;
  letter-spacing: -0.29px;
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
  opacity: 1;
  padding: 12px 12px;
  position: absolute;
  text-align: center;
  transform: translate(0, 38px);
  width: 180px;
  z-index: 2;
  box-sizing: border-box;
}
.hundred-ui-checkbox[name]:hover:before {
  border-color: transparent transparent #4a4a4a transparent;
  border-style: solid;
  border-width: 6px;
  content: '';
  display: block;
  opacity: 1;
  position: absolute;
  box-sizing: border-box;
  height: 12px;
  transform: translate(5px, 26px);
}
.hundred-ui-checkbox__label {
  align-items: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.hundred-ui-checkbox__label__icon,
.hundred-ui-checkbox__label__image {
  color: #4a4a4a;
  font-size: 20px;
  line-height: 18px;
  margin-right: 10px;
  flex-shrink: 0;
  margin-top: 6px;
}
.hundred-ui-checkbox__label__icon {
  width: 20px;
}
.hundred-ui-checkbox__label__image {
  order: 2;
  width: 23px;
  margin-top: 0px;
  margin-right: 5px;
}
.hundred-ui-checkbox__label__text {
  color: #4a4a4a;
  display: inline-block;
  flex-shrink: 2;
  font-size: 16px;
  letter-spacing: -0.25px;
  font-weight: 300;
  line-height: 22px;
  text-align: left;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.hundred-ui-checkbox__label:after {
  background-color: #fff;
  border-radius: 2px;
  box-sizing: border-box;
  border: 1px solid #ccdff4;
  content: "\E019";
  flex-shrink: 0;
  font-family: 'hundredrooms';
  font-size: 0;
  height: 20px;
  width: 20px;
  line-height: 19px;
  text-align: center;
  display: block;
  transition: all ease 0.2s;
  margin-right: 10px;
}
.hundred-ui-checkbox__label:after.-is-on-calendar:after {
  height: 15px;
  width: 15px;
}
.hundred-ui-checkbox__subtext {
  color: #4a4a4a;
  display: inline-block;
  flex-shrink: 2;
  font-size: 12px;
  letter-spacing: -0.29px;
  font-weight: 300;
  line-height: 22px;
  text-align: left;
  font-weight: 400;
}
.hundred-ui-checkbox__input[type="checkbox"]:not(:checked),
.hundred-ui-checkbox__input[type="checkbox"]:checked {
  left: -9999px;
  position: absolute;
  visibility: hidden;
}
.hundred-ui-checkbox.-is-checked .hundred-ui-checkbox__label:after {
  box-shadow: inset 0 0 0 1px #ccdff4;
  background-color: rgba(204,223,244,0.18);
  color: #005fc9;
  font-size: 12px;
  letter-spacing: -0.29px;
}
.hundred-ui-checkbox.-text-position-right .hundred-ui-checkbox__label__text {
  order: 3;
}
.hundred-ui-checkbox.-text-position-right .hundred-ui-checkbox__label__icon {
  order: 2;
  margin-top: 3px;
}
.hundred-ui-checkbox.-text-position-right .hundred-ui-checkbox__label:after {
  order: 1;
}
.hundred-ui-checkbox.-text-position-right .hundred-ui-checkbox__subtext {
  margin-left: 60px;
}
.claim {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: auto;
  min-height: 150px;
  padding: 0 7.03125%;
  margin: auto;
  user-select: none;
  cursor: default;
  margin: 15px 0;
  margin-bottom: 24px;
}
.claim .claim__subtitle {
  color: #353a8e;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 30px;
  order: 1;
  padding-top: 10px;
  text-align: left;
}
.claim .claim__title {
  color: #4a4a4a;
  font-size: 25px;
  font-weight: normal;
  height: auto;
  line-height: 30px;
  order: 2;
  text-align: left;
  margin-bottom: 0px;
}
@media (min-width: 768px) {
  .claim {
    height: 40vh;
    margin: auto;
    max-width: 980px;
    min-height: 250px;
    justify-content: center;
    width: 100%;
  }
  .claim .claim__title {
    color: #005fc9;
    font-size: 42px;
    font-weight: bold;
    line-height: 50px;
    max-width: 700px;
    line-height: 40px;
  }
  .claim .claim__subtitle {
    font-size: 32px;
    padding-top: 0;
    line-height: 52px;
    font-weight: 700;
  }
}
.cookie-banner {
  position: fixed;
  bottom: 0;
  background-color: #0d3540;
  color: #fff;
  width: 100vw;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  letter-spacing: -0.25px;
  animation: 0.75s linear 0.75s 1 slideInFromBottom;
  animation-fill-mode: both;
  z-index: 999;
  box-shadow: rgba(0,0,0,0.3) 0px 1px 4px 0px !important;
  left: 0px;
  padding-right: 15px;
}
.cookie-banner__text {
  max-width: 90%;
  font-size: 18px;
  letter-spacing: -0.25px;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
}
.cookie-banner__close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  font-size: 12px;
  letter-spacing: -0.29px;
  font-size: 21px;
}
@media (min-width: 768px) {
  .cookie-banner {
    z-index: 9999;
    width: 50vw;
    padding: 15px 0;
    line-height: 24px;
    text-align: justify;
    justify-content: center;
    padding: 15px;
    bottom: 30px;
    right: 30px;
    left: auto;
  }
  .cookie-banner__text {
    font-size: 16px;
    letter-spacing: -0.25px;
    max-width: 90%;
    margin-right: 30px;
  }
  .cookie-banner__close {
    right: 15px;
    top: 15px;
    font-size: 16px;
    letter-spacing: -0.25px;
  }
}
@media (min-width: 1200px) {
  .cookie-banner {
    width: 40vw;
  }
}
@-moz-keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@-o-keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.react-carousel-dots-holder {
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: all 0.5s ease;
}
.react-carousel-dots-dot {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #000;
  flex-shrink: 0;
  box-sizing: border-box;
  transition: transform 0.5s ease;
}
.react-carousel-dots-dot.active {
  background-color: #000;
}
.react-carousel-dots-dot.small {
  transform: scale(0.5);
}
.dot-holder {
  flex-shrink: 0;
  transition: transform 0.5s ease;
}
.dropdown-component {
  color: #4a4a4a;
  position: relative;
  min-width: 150px;
  cursor: pointer;
  z-index: 1;
}
.dropdown-component__title {
  border: 1px solid #ccdff4;
  border-radius: 3px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  line-height: 30px;
  background: #fff;
  transition: all 0.1s linear;
  position: relative;
  z-index: 2;
}
.dropdown-component__title__text {
  padding-right: 20px;
}
.dropdown-component__title__arrow {
  transition: transform 0.2s linear;
  display: flex;
}
.dropdown-component__title__arrow.-arrow-up {
  transform: rotate(180deg);
}
.dropdown-component__title.-title-open {
  background-color: #669fdf;
  color: #fff;
  border: none;
}
.dropdown-component__list {
  background-color: #fff;
  transition: max-height 0.3s cubic-bezier(0.27, -0.1, 0.6, 0.65), opacity 0.1s linear 2s;
  display: block;
  position: absolute;
  max-height: 200px;
  overflow-y: hidden;
  width: auto;
  top: 26px;
  border-radius: 3px;
  border: 1px solid #ccdff4;
  opacity: 1;
  letter-spacing: 0.25px;
  cursor: pointer;
}
.dropdown-component__list.-list-hidden {
  max-height: 0;
}
.dropdown-component__list__item {
  white-space: nowrap;
  line-height: 30px;
  padding: 0 10px;
}
.dropdown-component__list__item:hover {
  font-weight: 600;
  animation: letter-spacing 0.2s linear;
}
.dropdown-component__list__item:first-child {
  margin-top: 5px;
}
.dropdown-component__list__item:last-child {
  margin-bottom: 5px;
}
.dropdown-component.-is-blur {
  opacity: 0.4;
  pointer-events: none;
}
@-moz-keyframes letter-spacing {
  0% {
    letter-spacing: 0.25px;
  }
  50% {
    letter-spacing: 1px;
  }
  100% {
    letter-spacing: 0.25px;
  }
}
@-webkit-keyframes letter-spacing {
  0% {
    letter-spacing: 0.25px;
  }
  50% {
    letter-spacing: 1px;
  }
  100% {
    letter-spacing: 0.25px;
  }
}
@-o-keyframes letter-spacing {
  0% {
    letter-spacing: 0.25px;
  }
  50% {
    letter-spacing: 1px;
  }
  100% {
    letter-spacing: 0.25px;
  }
}
@keyframes letter-spacing {
  0% {
    letter-spacing: 0.25px;
  }
  50% {
    letter-spacing: 1px;
  }
  100% {
    letter-spacing: 0.25px;
  }
}
.dropdown-mobile {
  width: 100vw;
  height: 60px;
  background-color: #fff;
  color: #4a4a4a;
  display: flex;
  align-items: center;
  height: auto;
}
.dropdown-mobile__container {
  padding: 0 20px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  background: #fff;
  flex-direction: column;
  user-select: none;
  max-height: 50px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;
  z-index: 1;
}
.dropdown-mobile__container.-opened-container {
  max-height: 350px;
  transition: max-height 1s ease-in-out;
}
.dropdown-mobile__container__input {
  display: flex;
  align-items: center;
  background: #fff;
  z-index: 3;
  width: 100%;
  height: 50px;
  min-height: 50px;
}
.dropdown-mobile__container__input__title {
  padding-left: 10px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.25px;
}
.dropdown-mobile__container__input__arrow {
  position: absolute;
  right: 20px;
  transition: transform 0.3s;
}
.dropdown-mobile__container__input .-opened {
  transform: rotate(180deg);
}
.dropdown-mobile__container__expand {
  max-height: 0;
  overflow: hidden;
  transform: scaleY(0);
  display: block;
  pointer-events: none;
  background: #fff;
  width: 100vw;
  height: auto;
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 0;
  font-size: 16px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
  line-height: 18px;
  transform-origin: 50% 0%;
}
.dropdown-mobile__container__expand.-opened-content {
  transform: scaleY(1);
  max-height: 9999px;
  opacity: 1;
}
.dropdown-mobile__container__expand__content {
  display: block;
  padding: 0px 58px;
  padding-bottom: 15px;
  line-height: 1.75;
}
@media (min-width: 768px) {
  .dropdown-mobile__container__input {
    font-size: 16px;
    letter-spacing: -0.25px;
    min-height: 45px;
  }
  .dropdown-mobile__container__input__arrow {
    display: none;
  }
  .dropdown-mobile__container__input__title {
    font-weight: 500;
  }
  .dropdown-mobile__container__expand {
    font-size: 16px;
    letter-spacing: -0.25px;
    bottom: -34px;
    opacity: 1;
    padding-left: 20px;
    width: 100%;
    padding-left: 0;
  }
  .dropdown-mobile__container__expand__content {
    padding-left: 0;
    padding-right: 0;
  }
}
.dropdown {
  user-select: none;
  padding: 0 15px;
  list-style: none;
}
.dropdown__title {
  cursor: pointer;
  color: #4a4a4a;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  text-align: center;
}
.dropdown__list {
  border-radius: 3px;
  width: 190px;
  padding-top: 10px;
  display: none;
  position: absolute;
  transform: translateX(-20px) translateZ(0);
  transition: all ease 0.3s;
  background: #fff;
  z-index: 9995;
  list-style-type: none;
}
.dropdown__list:hover {
  display: block;
}
.dropdown__list:before {
  border-left: 1px solid #ccdff4;
  border-top: 1px solid #ccdff4;
  content: '';
  display: block;
  opacity: 1;
  position: absolute;
  width: 10px;
  height: 10px;
  margin-left: 90px;
  margin-top: -5px;
  background-color: #fff;
  transform: rotateZ(45deg);
}
.dropdown__list__item {
  border-left: 1px solid #ccdff4;
  border-right: 1px solid #ccdff4;
  text-align: left;
  line-height: 36px;
  border-bottom: 1px solid #ccdff4;
  cursor: pointer;
  width: 100%;
}
.dropdown__list__item:first-child {
  border-top: 1px solid #ccdff4;
  border-radius: 3px 3px 0 0;
}
.dropdown__list__item:last-child {
  border-radius: 0 0 3px 3px;
}
.dropdown__list__item__text {
  line-height: 36px;
  color: #1d70b7;
  margin: 0;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown__list__item__link {
  display: block;
  padding: 5px 15px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.25px;
  color: #1d70b7;
  margin: 0;
  line-height: 36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all ease 0.3s;
}
.dropdown__list__item__link:hover {
  background-color: #fff;
  color: #64a4da;
}
.dropdown__title:hover + .dropdown__list {
  display: block;
}
.hundred-ui-dropselect {
  width: 100%;
  max-width: 230px;
  height: 50px;
  margin: auto;
  color: rgba(255,255,255,0.9);
  border-radius: 3px;
  border: 1px solid rgba(255,255,255,0.9);
  background-color: transparent;
  transition: all ease 0.3s;
  margin: auto;
}
.hundred-ui-dropselect__input[type="checkbox"] {
  position: absolute;
  left: -99999px;
}
.hundred-ui-dropselect__input[type="checkbox"] +label {
  padding: 0;
  height: 50px;
  line-height: 50px;
  display: block;
  color: rgba(255,255,255,0.9);
  cursor: pointer;
  padding: 0 12px;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 400;
  border-radius: 3px;
  text-transform: uppercase;
  transition: all ease 0.3s;
}
.hundred-ui-dropselect__input[type="checkbox"] +label:before {
  display: none;
}
.hundred-ui-dropselect__input[type="checkbox"] +label .hundred-ui-dropselect__label__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  max-width: 90%;
}
.hundred-ui-dropselect__input[type="checkbox"] +label .hundred-ui-dropselect__label__icon {
  float: right;
  line-height: 44px;
  font-size: 1em;
  transition: all ease 0.3s;
}
.hundred-ui-dropselect__input[type="checkbox"] +label .hundred-ui-dropselect__label__icon:before {
  line-height: 50px;
}
.hundred-ui-dropselect__list {
  margin: -1px -1px 0 -1px;
  border-radius: 3px;
  border: solid 1px rgba(0,0,0,0.1);
  background-color: #fff;
  transition: height 0.3s ease-out, opacity 0.2s ease-out, visibility 0.4s ease-out;
}
.hundred-ui-dropselect__list__title {
  color: #4a4a4a;
  height: 50px;
  padding: 0 10px;
  margin: 0;
  line-height: 50px;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 300;
  transition: height 0.3s ease-out;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  cursor: default;
}
.hundred-ui-dropselect__list__item {
  color: #4a4a4a;
  height: 50px;
  padding: 0 10px;
  margin: 0;
  line-height: 50px;
  font-size: 16px;
  letter-spacing: -0.25px;
  transition: height 0.3s ease-out;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  transition: all ease 0.3s;
}
.hundred-ui-dropselect__list__item a {
  width: 100%;
  height: 50px;
  display: block;
}
.hundred-ui-dropselect__list__item:last-child {
  border-bottom: none;
}
.hundred-ui-dropselect__list__item:hover {
  color: rgba(74,74,74,0.8);
  cursor: pointer;
  font-weight: 500;
  background-color: #ccc;
}
.hundred-ui-dropselect .hundred-ui-dropselect__input[type="checkbox"]:checked ~ label {
  background-color: rgba(0,0,0,0.1);
}
.hundred-ui-dropselect .hundred-ui-dropselect__input[type="checkbox"]:checked ~ label .hundred-ui-dropselect__label__icon {
  transform: rotate(180deg);
}
.hundred-ui-dropselect:not(.-up) .hundred-ui-dropselect__input[type="checkbox"]:not(:checked) ~ .hundred-ui-dropselect__list {
  opacity: 0;
  visibility: hidden;
}
.hundred-ui-dropselect:not(.-up) .hundred-ui-dropselect__input[type="checkbox"]:not(:checked) ~ .hundred-ui-dropselect__list .hundred-ui-dropselect__list__item {
  height: 0;
}
.hundred-ui-dropselect.-up .hundred-ui-dropselect__input[type="checkbox"]:not(:checked) ~ .hundred-ui-dropselect__list {
  opacity: 0;
  visibility: hidden;
}
.hundred-ui-dropselect.-up .hundred-ui-dropselect__input[type="checkbox"]:checked ~ label .hundred-ui-dropselect__label__icon {
  transform: rotate(0deg);
}
.hundred-ui-dropselect.-up .hundred-ui-dropselect__label__icon {
  transform: rotate(180deg);
  line-height: 49px;
}
.hundred-ui-dropselect.-up .hundred-ui-dropselect__list {
  position: relative;
  margin-top: -205px;
  z-index: 9995;
  border-radius: 3px;
}
.hundred-ui-dropselect.-up.-one .hundred-ui-dropselect__list {
  margin-top: -155px;
}
.hundred-ui-dropselect.-up.-two .hundred-ui-dropselect__list {
  margin-top: -210px;
}
.hundred-ui-dropselect.-up.-three .hundred-ui-dropselect__list {
  margin-top: -260px;
}
.hundred-ui-dropselect.-up.-four .hundred-ui-dropselect__list {
  margin-top: -310px;
}
.hundred-ui-dropselect.-up.-five .hundred-ui-dropselect__list {
  margin-top: -360px;
}
.hundred-ui-dropselect.-up.-six .hundred-ui-dropselect__list {
  margin-top: -410px;
}
.hundred-ui-dropselect.-up.-seven .hundred-ui-dropselect__list {
  margin-top: -465px;
}
.hundred-ui-dropselect.-up.-eight .hundred-ui-dropselect__list {
  margin-top: -515px;
}
.hundred-ui-dropselect.-up.-nine .hundred-ui-dropselect__list {
  margin-top: -565px;
}
.hundred-ui-dropselect.-up.-ten .hundred-ui-dropselect__list {
  margin-top: -615px;
}
.hundred-ui-dropselect.-clean label {
  text-align: center;
}
.hundred-ui-dropselect.-clean label .hundred-ui-dropselect__label__icon {
  display: none;
}
.hundred-ui-dropselect.-center label {
  text-align: center;
}
.hundred-ui-dropselect.-black {
  border: 1px solid rgba(0,0,0,0.1);
}
.hundred-ui-dropselect.-black .hundred-ui-dropselect__input[type="checkbox"] +label {
  color: #4a4a4a;
}
@media (max-width: 769px) {
  .hundred-ui-dropselect {
    margin-left: 0;
  }
}
.-multicard-test-ghost {
  width: 285px !important;
  min-width: 285px !important;
  height: 255px !important;
}
.-multicard-test-ghost .ghost-snippet__header {
  display: none;
}
.-multicard-test-ghost .ghost-snippet__carrousel {
  height: 150px !important;
}
.-multicard-test-ghost .ghost-snippet__body__button {
  display: none;
}
.-multicard-test-ghost .ghost-snippet__body__best-price {
  margin-top: 2px;
  height: 70px !important;
}
.-multicard-test-ghost .ghost-snippet__footer {
  display: none;
}
.ghost-snippet {
  max-width: 400px;
  min-width: 300px;
  width: 100%;
  height: 480px;
  background-color: #fff;
  display: inline-block;
  border-radius: 3px;
  cursor: default;
  margin: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.04), 0 1px 2px rgba(0,0,0,0.06);
}
.ghost-snippet__header {
  box-sizing: border-box;
  height: 88px;
  padding: 16px;
  text-align: left;
}
.ghost-snippet__header__title {
  width: 100%;
  height: 16px;
  background: -webkit-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 75%);
  background: -moz-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 75%);
  background: -ms-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 75%);
  background: -o-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 75%);
  background: -moz-linear-gradient(left, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 75%);
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 75%);
  background: linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 75%);
  transform-origin: left;
  animation-name: ghost-1;
  animation-duration: 1.3s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  transition: all ease 0.5s;
}
.ghost-snippet__header__attributes {
  width: 100%;
  height: 16px;
  margin-top: 10px;
  background: -webkit-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: -moz-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: -ms-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: -o-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: -moz-linear-gradient(left, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  transform-origin: left;
}
.ghost-snippet__carrousel {
  height: 170px;
  width: 100%;
  background: -webkit-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 80%);
  background: -moz-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 80%);
  background: -ms-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 80%);
  background: -o-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 80%);
  background: -moz-linear-gradient(left, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 80%);
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 80%);
  background: linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 80%);
  transform-origin: left;
  animation-name: ghost-1;
  animation-duration: 1.3s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  transition: all ease 0.5s;
}
.ghost-snippet__body {
  padding: 16px;
  height: 179px;
  box-sizing: border-box;
  text-align: left;
}
.ghost-snippet__body__best-price {
  margin-top: 20px;
  height: 45px;
  width: 100%;
  background: -webkit-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 75%);
  background: -moz-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 75%);
  background: -ms-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 75%);
  background: -o-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 75%);
  background: -moz-linear-gradient(left, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 75%);
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 75%);
  background: linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 75%);
  animation-name: ghost-1;
  animation-duration: 1.3s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  transition: all ease 0.5s;
  transform-origin: left;
}
.ghost-snippet__body__button {
  height: 35px;
  margin-top: 10px;
  background: -webkit-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: -moz-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: -ms-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: -o-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: -moz-linear-gradient(left, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  animation-name: ghost-2;
  animation-duration: 1.3s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  transition: all ease 0.5s;
  transform-origin: left;
}
.ghost-snippet__footer {
  height: 42px;
  padding: 10px 16px;
}
.ghost-snippet__footer__buttons {
  height: 20px;
  background: -webkit-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: -moz-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: -ms-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: -o-linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: -moz-linear-gradient(left, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  background: linear-gradient(to right, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 60%);
  animation-name: ghost-2;
  animation-duration: 1.3s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  transition: all ease 0.5s;
  transform-origin: left;
}
@media (min-width: 768px) {
  .ghost-snippet {
    width: 300px;
  }
}
@-moz-keyframes ghost-1 {
  0% {
    transform: scaleX(0.65);
  }
  10% {
    transform: scaleX(0.8);
  }
  20% {
    transform: scaleX(0.85);
  }
  30% {
    transform: scaleX(0.9);
  }
  40% {
    transform: scaleX(0.95);
  }
  50% {
    transform: scaleX(1);
  }
  60% {
    transform: scaleX(0.95);
  }
  70% {
    transform: scaleX(0.9);
  }
  80% {
    transform: scaleX(0.85);
  }
  90% {
    transform: scaleX(0.8);
  }
  100% {
    transform: scaleX(0.65);
  }
}
@-webkit-keyframes ghost-1 {
  0% {
    transform: scaleX(0.65);
  }
  10% {
    transform: scaleX(0.8);
  }
  20% {
    transform: scaleX(0.85);
  }
  30% {
    transform: scaleX(0.9);
  }
  40% {
    transform: scaleX(0.95);
  }
  50% {
    transform: scaleX(1);
  }
  60% {
    transform: scaleX(0.95);
  }
  70% {
    transform: scaleX(0.9);
  }
  80% {
    transform: scaleX(0.85);
  }
  90% {
    transform: scaleX(0.8);
  }
  100% {
    transform: scaleX(0.65);
  }
}
@-o-keyframes ghost-1 {
  0% {
    transform: scaleX(0.65);
  }
  10% {
    transform: scaleX(0.8);
  }
  20% {
    transform: scaleX(0.85);
  }
  30% {
    transform: scaleX(0.9);
  }
  40% {
    transform: scaleX(0.95);
  }
  50% {
    transform: scaleX(1);
  }
  60% {
    transform: scaleX(0.95);
  }
  70% {
    transform: scaleX(0.9);
  }
  80% {
    transform: scaleX(0.85);
  }
  90% {
    transform: scaleX(0.8);
  }
  100% {
    transform: scaleX(0.65);
  }
}
@keyframes ghost-1 {
  0% {
    transform: scaleX(0.65);
  }
  10% {
    transform: scaleX(0.8);
  }
  20% {
    transform: scaleX(0.85);
  }
  30% {
    transform: scaleX(0.9);
  }
  40% {
    transform: scaleX(0.95);
  }
  50% {
    transform: scaleX(1);
  }
  60% {
    transform: scaleX(0.95);
  }
  70% {
    transform: scaleX(0.9);
  }
  80% {
    transform: scaleX(0.85);
  }
  90% {
    transform: scaleX(0.8);
  }
  100% {
    transform: scaleX(0.65);
  }
}
@-moz-keyframes ghost-2 {
  0% {
    transform: scaleX(0.6);
  }
  10% {
    transform: scaleX(0.65);
  }
  20% {
    transform: scaleX(0.7);
  }
  30% {
    transform: scaleX(0.75);
  }
  40% {
    transform: scaleX(0.8);
  }
  50% {
    transform: scaleX(0.85);
  }
  60% {
    transform: scaleX(0.8);
  }
  70% {
    transform: scaleX(0.75);
  }
  80% {
    transform: scaleX(0.7);
  }
  90% {
    transform: scaleX(0.65);
  }
  100% {
    transform: scaleX(0.6);
  }
}
@-webkit-keyframes ghost-2 {
  0% {
    transform: scaleX(0.6);
  }
  10% {
    transform: scaleX(0.65);
  }
  20% {
    transform: scaleX(0.7);
  }
  30% {
    transform: scaleX(0.75);
  }
  40% {
    transform: scaleX(0.8);
  }
  50% {
    transform: scaleX(0.85);
  }
  60% {
    transform: scaleX(0.8);
  }
  70% {
    transform: scaleX(0.75);
  }
  80% {
    transform: scaleX(0.7);
  }
  90% {
    transform: scaleX(0.65);
  }
  100% {
    transform: scaleX(0.6);
  }
}
@-o-keyframes ghost-2 {
  0% {
    transform: scaleX(0.6);
  }
  10% {
    transform: scaleX(0.65);
  }
  20% {
    transform: scaleX(0.7);
  }
  30% {
    transform: scaleX(0.75);
  }
  40% {
    transform: scaleX(0.8);
  }
  50% {
    transform: scaleX(0.85);
  }
  60% {
    transform: scaleX(0.8);
  }
  70% {
    transform: scaleX(0.75);
  }
  80% {
    transform: scaleX(0.7);
  }
  90% {
    transform: scaleX(0.65);
  }
  100% {
    transform: scaleX(0.6);
  }
}
@keyframes ghost-2 {
  0% {
    transform: scaleX(0.6);
  }
  10% {
    transform: scaleX(0.65);
  }
  20% {
    transform: scaleX(0.7);
  }
  30% {
    transform: scaleX(0.75);
  }
  40% {
    transform: scaleX(0.8);
  }
  50% {
    transform: scaleX(0.85);
  }
  60% {
    transform: scaleX(0.8);
  }
  70% {
    transform: scaleX(0.75);
  }
  80% {
    transform: scaleX(0.7);
  }
  90% {
    transform: scaleX(0.65);
  }
  100% {
    transform: scaleX(0.6);
  }
}
.grid-container {
  width: calc(100% - 525px);
  height: 550px;
  float: left;
}
.grid-container__big,
.grid-container__medium,
.grid-container__small,
.grid-container__small-last {
  display: block;
  float: left;
  box-sizing: border-box;
  overflow: hidden;
}
.grid-container__big__img,
.grid-container__medium__img,
.grid-container__small__img,
.grid-container__small-last__img {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #808080;
}
.grid-container__big__img__item,
.grid-container__medium__img__item,
.grid-container__small__img__item,
.grid-container__small-last__img__item {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.grid-container__big {
  border-right: #fff 4px solid;
  width: 50%;
  height: 100%;
}
.grid-container__medium {
  border-bottom: #fff 4px solid;
  width: 50%;
  height: 50%;
}
.grid-container__small,
.grid-container__small-last {
  width: 25%;
  height: 50%;
}
.grid-container__small-last {
  border-left: #fff 4px solid;
}
.grid-container__overlay {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s;
  display: flex;
  align-items: baseline;
  transform: translateY(-100%);
}
.grid-container__overlay:hover {
  opacity: 1;
}
.grid-container__text {
  background-color: #fff;
  border-radius: 3px;
  padding: 8px 10px;
  display: inline-flex;
  text-align: center;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: -0.29px;
  font-weight: 400;
  cursor: pointer;
  transform: translate(25px, -50px);
  max-width: 75%;
  box-shadow: 0px 0px 6px 1px rgba(105,105,105,0.2);
}
.grid-container__more-pics {
  top: 50%;
  transform: translateY(-50%);
  display: block;
  position: relative;
  background-color: rgba(0,0,0,0);
  border-radius: 3px;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px;
  text-align: center;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 400;
  cursor: pointer;
  max-width: 75%;
  margin: 0 auto;
}
.-ie-fallback {
  position: relative;
  overflow: hidden;
}
.-ie-fallback img {
  position: absolute;
  top: -9999px;
  right: -9999px;
  bottom: -9999px;
  left: -9999px;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
}
.-ie-fallback .grid-container__overlay {
  transform: translateY(0);
}
@media (max-width: 1200px) {
  .grid-container {
    width: calc(100% - 410px);
  }
  .grid-container__small-last {
    display: none;
  }
  .grid-container__small {
    border: none;
    width: 50%;
  }
}
.hundred-rating-infographic {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hundred-rating-infographic__center {
  height: 100px;
  width: 100px;
  background-color: #1c2056;
  border-radius: 100%;
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 50px;
  user-select: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.hundred-rating-infographic__center .span {
  user-select: none;
}
.hundred-rating-infographic__center.-hover-mobile + .hundred-rating-infographic__outline {
  height: 120px;
  width: 120px;
  opacity: 1;
}
.hundred-rating-infographic__outline {
  height: 90px;
  width: 90px;
  border-radius: 100%;
  border: 3px solid #1c1f56;
  transition: all 0.25s cubic-bezier(0.7, 0.11, 0.32, 2);
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hundred-rating-infographic__dot-container {
  position: absolute;
  height: 226px;
  width: 226px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
.hundred-rating-infographic__dot {
  width: 250px;
  height: 250px;
  border-radius: 100%;
  position: absolute;
  pointer-events: none;
}
.hundred-rating-infographic__dot__inner {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin: 10px;
  pointer-events: auto;
}
.hundred-rating-infographic__adjective {
  white-space: nowrap;
  width: auto;
  left: 50%;
  top: 94%;
  color: #1c1f56;
  font-size: 18px;
  letter-spacing: -0.25px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  line-height: 25px;
  position: absolute;
  bottom: 15px;
  transform: scale(1) translate(-50%, -50%);
  transition: all 0.25s cubic-bezier(0.7, 0.11, 0.32, 2);
  user-select: none;
  right: 50%;
  top: 90%;
}
.hundred-rating-infographic__adjective.-hover-mobile {
  transform: scale(1.3) translate(-50%, -50%);
  right: 50%;
  top: 90%;
}
.hundred-rating-infographic .-hola {
  animation: orbit 1s cubic-bezier(0.06, 0.96, 0.57, 1.07) 1;
  animation-delay: 2s;
}
.hundred-rating-infographic .-adios {
  border: 3px solid #ccdff4;
  transform: rotate(45deg) scale(1);
}
.hundred-rating-infographic .-heart {
  border: 3px solid #ccdff4;
  transform: rotate(45deg);
  height: 220px;
  width: 220px;
}
.hundred-rating-infographic .-heart .hundred-rating-infographic__dot__inner {
  opacity: 0;
  background: #28d6a4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
}
.hundred-rating-infographic .-star {
  height: 226px;
  width: 226px;
  transform: rotate(285deg);
  border: none;
}
.hundred-rating-infographic .-star .hundred-rating-infographic__dot__inner {
  opacity: 0;
  background: #7fafe4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
}
.hundred-rating-infographic .-fire {
  height: 226px;
  width: 226px;
  transform: rotate(165deg);
  border: none;
}
.hundred-rating-infographic .-fire .hundred-rating-infographic__dot__inner {
  opacity: 0;
  background: #edb2fe;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
}
.hundred-rating-infographic .-adios.-heart .hundred-rating-infographic__dot__inner {
  transform: scale(1) rotate(-45deg);
  animation: grow-ball-heart 0.3s cubic-bezier(0.29, 0.04, 0.11, 1.2);
  border: 4px solid transparent;
  transition: 0.2s ease;
  opacity: 1;
}
.hundred-rating-infographic .-adios.-heart .hundred-rating-infographic__dot__inner.-hover-mobile {
  transform: scale(1.05) rotate(315deg);
  background-color: #fff;
  color: #28d6a4;
  border: 3px solid #28d6a4;
}
.hundred-rating-infographic .-adios.-star .hundred-rating-infographic__dot__inner {
  transform: scale(1) rotate(76deg);
  animation: grow-ball-star 0.3s cubic-bezier(0.29, 0.04, 0.11, 1.2);
  border: 4px solid transparent;
  transition: 0.2s ease;
  opacity: 1;
}
.hundred-rating-infographic .-adios.-star .hundred-rating-infographic__dot__inner.-hover-mobile {
  transform: scale(1.05) rotate(360deg);
  background-color: #fff;
  color: #7fafe4;
  border: 3px solid #7fafe4;
}
.hundred-rating-infographic .-adios.-fire .hundred-rating-infographic__dot__inner {
  transform: scale(1) rotate(-156deg);
  animation: grow-ball-fire 0.3s cubic-bezier(0.29, 0.04, 0.11, 1.2);
  border: 4px solid transparent;
  transition: 0.2s ease;
  opacity: 1;
}
.hundred-rating-infographic .-adios.-fire .hundred-rating-infographic__dot__inner.-hover-mobile {
  transform: scale(1.05) rotate(205deg);
  background-color: #fff;
  color: #edb2fe;
  border: 3px solid #edb2fe;
}
@media (min-width: 768px) {
  .hundred-rating-infographic {
    width: 20%;
    display: inline-flex;
    margin-right: 103px;
    vertical-align: top;
  }
  .hundred-rating-infographic__center {
    height: 80px;
    width: 80px;
    font-size: 35px;
  }
  .hundred-rating-infographic__center:hover + .hundred-rating-infographic__outline {
    height: 90px;
    width: 90px;
    opacity: 1;
  }
  .hundred-rating-infographic__adjective {
    font-size: 15px;
    letter-spacing: -0.25px;
  }
  .hundred-rating-infographic__outline {
    height: 40px;
    width: 40px;
    transition: all 0.35s cubic-bezier(0.7, 0.11, 0.32, 2);
  }
  .hundred-rating-infographic__dot-container {
    position: absolute;
    height: 186px;
    width: 186px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .hundred-rating-infographic__dot__inner {
    width: 40px;
    height: 40px;
  }
  .hundred-rating-infographic .-adios.-heart {
    height: 180px;
    width: 180px;
  }
  .hundred-rating-infographic .-adios.-heart .hundred-rating-infographic__dot__inner {
    transform: scale(1) rotate(-45deg);
    animation: grow-ball-heart 0.3s cubic-bezier(0.29, 0.04, 0.11, 1.2);
    border: 4px solid transparent;
    transition: 0.2s ease;
  }
  .hundred-rating-infographic .-adios.-heart .hundred-rating-infographic__dot__inner:hover,
  .hundred-rating-infographic .-adios.-heart .hundred-rating-infographic__dot__inner:active {
    transform: scale(1.05) rotate(315deg);
    background-color: #fff;
    color: #28d6a4;
    border: 3px solid #28d6a4;
  }
  .hundred-rating-infographic .-adios.-star {
    height: 186px;
    width: 186px;
  }
  .hundred-rating-infographic .-adios.-star .hundred-rating-infographic__dot__inner:hover,
  .hundred-rating-infographic .-adios.-star .hundred-rating-infographic__dot__inner:active {
    transform: scale(1.05) rotate(360deg);
    background-color: #fff;
    color: #7fafe4;
    border: 3px solid #7fafe4;
  }
  .hundred-rating-infographic .-adios.-fire {
    height: 186px;
    width: 186px;
  }
  .hundred-rating-infographic .-adios.-fire .hundred-rating-infographic__dot__inner:hover,
  .hundred-rating-infographic .-adios.-fire .hundred-rating-infographic__dot__inner:active {
    transform: scale(1.05) rotate(205deg);
    background-color: #fff;
    color: #edb2fe;
    border: 3px solid #edb2fe;
  }
}
@-moz-keyframes orbit {
  0% {
    border: 8px solid #1c1f56;
    transform: scale(0.1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    border: 2px solid #ccdff4;
    transform: scale(1);
  }
}
@-webkit-keyframes orbit {
  0% {
    border: 8px solid #1c1f56;
    transform: scale(0.1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    border: 2px solid #ccdff4;
    transform: scale(1);
  }
}
@-o-keyframes orbit {
  0% {
    border: 8px solid #1c1f56;
    transform: scale(0.1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    border: 2px solid #ccdff4;
    transform: scale(1);
  }
}
@keyframes orbit {
  0% {
    border: 8px solid #1c1f56;
    transform: scale(0.1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    border: 2px solid #ccdff4;
    transform: scale(1);
  }
}
@-moz-keyframes grow-ball-heart {
  0% {
    transform: scale(0) rotate(-45deg);
  }
  50% {
    transform: scale(1.1) rotate(-45deg);
  }
  100% {
    transform: scale(1) rotate(-45deg);
  }
}
@-webkit-keyframes grow-ball-heart {
  0% {
    transform: scale(0) rotate(-45deg);
  }
  50% {
    transform: scale(1.1) rotate(-45deg);
  }
  100% {
    transform: scale(1) rotate(-45deg);
  }
}
@-o-keyframes grow-ball-heart {
  0% {
    transform: scale(0) rotate(-45deg);
  }
  50% {
    transform: scale(1.1) rotate(-45deg);
  }
  100% {
    transform: scale(1) rotate(-45deg);
  }
}
@keyframes grow-ball-heart {
  0% {
    transform: scale(0) rotate(-45deg);
  }
  50% {
    transform: scale(1.1) rotate(-45deg);
  }
  100% {
    transform: scale(1) rotate(-45deg);
  }
}
@-moz-keyframes grow-ball-star {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes grow-ball-star {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes grow-ball-star {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes grow-ball-star {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@-moz-keyframes grow-ball-fire {
  0% {
    transform: scale(0) rotate(-156deg);
  }
  50% {
    transform: scale(1.1) rotate(-156deg);
  }
  100% {
    transform: scale(1) rotate(-156deg);
  }
}
@-webkit-keyframes grow-ball-fire {
  0% {
    transform: scale(0) rotate(-156deg);
  }
  50% {
    transform: scale(1.1) rotate(-156deg);
  }
  100% {
    transform: scale(1) rotate(-156deg);
  }
}
@-o-keyframes grow-ball-fire {
  0% {
    transform: scale(0) rotate(-156deg);
  }
  50% {
    transform: scale(1.1) rotate(-156deg);
  }
  100% {
    transform: scale(1) rotate(-156deg);
  }
}
@keyframes grow-ball-fire {
  0% {
    transform: scale(0) rotate(-156deg);
  }
  50% {
    transform: scale(1.1) rotate(-156deg);
  }
  100% {
    transform: scale(1) rotate(-156deg);
  }
}
.image-link {
  width: 100%;
  min-height: 263px;
  margin: auto;
  display: block;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-link__text {
  padding: 10px;
  max-width: 350px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  line-height: 1.2;
}
.image-link__text.-green {
  background-color: #009688;
}
.image-link__text.-blue {
  background-color: #2196f3;
}
.image-link__text.-yellow {
  background-color: #f5a623;
}
.image-link__text.-purple {
  background-color: #9c27b0;
}
.image-link__text.-blue-dark {
  background-color: #4a90e2;
}
.image-link__text.-gray {
  background-color: #607d8b;
}
.hundred-ui-switch__input[type="checkbox"]:not(:checked),
.hundred-ui-switch__input[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
  visibility: hidden;
}
.hundred-ui-switch__input + label.hundred-ui-switch__label {
  position: relative;
  padding-left: 54px;
  cursor: pointer;
  display: inline-block;
  height: 22px;
  line-height: inherit;
  font-size: inherit;
  font-weight: 400;
  color: #4a4a4a;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
}
.hundred-ui-switch__input + label.hundred-ui-switch__label .hundred-ui-switch__label__text {
  font-weight: 400;
  line-height: 22px;
  font-size: 12px;
  letter-spacing: -0.29px;
  color: #4a4a4a;
  flex: 0 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hundred-ui-switch__input + label.hundred-ui-switch__label:before {
  background-color: #b3b3b3;
  transition: all ease 0.3s;
  border: 0px;
  border-radius: 30px;
  width: 44px;
  height: 18px;
  position: absolute;
  transition: all ease 0.3s;
  top: 0;
  left: 0;
  margin: 2px 0 0 0;
  box-sizing: border-box;
  content: '';
  text-transform: initial;
}
.hundred-ui-switch__input + label.hundred-ui-switch__label:after {
  background-color: #696969;
  transition: all ease 0.3s;
  border: 0px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  position: absolute;
  transition: all ease 0.3s;
  top: 0;
  left: 0;
  margin: 0;
  box-sizing: border-box;
  text-transform: initial;
}
.hundred-ui-switch__input[type="checkbox"]:not(:checked):after,
.hundred-ui-switch__input + label.hundred-ui-switch__label:after {
  content: '';
  font-size: 0;
}
.hundred-ui-switch__input[type="checkbox"]:checked.hundred-ui-switch {
  color: #16d280;
}
.hundred-ui-switch__input[type="checkbox"]:checked + label.hundred-ui-switch__label:after {
  border: 0;
  background-color: #16d280;
  margin-left: 22px;
}
.hundred-ui-switch__input[type="checkbox"]:checked + label.hundred-ui-switch__label:before {
  background-color: rgba(22,210,128,0.3);
  transform: rotate(0);
}
@media (min-width: 1024px) {
  .hundred-ui-switch.-has-tooltip {
    text-transform: initial;
  }
  .hundred-ui-switch.-has-tooltip:after,
  .hundred-ui-switch.-has-tooltip:before {
    transition: all ease 0.5s;
    transform: translate(0px, 0px);
    opacity: 0;
    text-transform: initial;
    z-index: 1;
  }
  .hundred-ui-switch.-has-tooltip:hover:after {
    opacity: 1;
    width: 170px;
    background: #4a4a4a;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    letter-spacing: -0.29px;
    line-height: 1.5;
    margin: 0;
    content: attr(title);
    padding: 5px 15px;
    position: absolute;
    text-align: center;
    transform: translate(-10px, 10px);
    text-transform: initial;
  }
  .hundred-ui-switch.-has-tooltip:hover:before {
    opacity: 1;
    content: '';
    position: absolute;
    transform: translate(67px, 20px);
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent #4a4a4a transparent;
    text-transform: initial;
  }
}
.hundred-ui-input {
  width: 100%;
  height: 50px;
  max-width: 400px;
  text-align: center;
}
.hundred-ui-input input.hundred-ui-input__input {
  width: 100%;
  height: 50px;
  margin: auto;
  border-radius: 3px;
  background-color: #fff;
  color: #4a4a4a;
  padding: 5px;
  box-sizing: border-box;
  font-weight: 300;
  font-size: 15px;
  letter-spacing: -0.25px;
  outline: none;
  transition: all ease 0.5s;
  border: 1px solid rgba(0,0,0,0.1);
  box-shadow: none;
  appearance: none;
}
.hundred-ui-input input.hundred-ui-input__input:focus {
  border: none;
  border: 1px solid rgba(0,0,0,0.1);
  border-width: 2px;
  box-shadow: none;
}
.hundred-ui-input__tooltip {
  display: block;
  margin: auto;
  max-width: 100%;
  transform: translate(0, -45px);
  padding: 5px;
  transition: all ease 0.5s;
  border-radius: 3px;
  text-align: center;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 300;
  color: rgba(255,255,255,0.9);
  opacity: 0;
}
.hundred-ui-input__tooltip::after {
  content: " ";
  position: absolute;
  transform: translate(6px, 0);
  border-width: 6px;
  border-style: solid;
  left: 0;
  opacity: 0;
}
.hundred-ui-input.-info .hundred-ui-input__tooltip,
.hundred-ui-input.-done .hundred-ui-input__tooltip,
.hundred-ui-input.-error .hundred-ui-input__tooltip {
  transform: translate(0, -90px);
  opacity: 1;
}
.hundred-ui-input.-info .hundred-ui-input__tooltip::after,
.hundred-ui-input.-done .hundred-ui-input__tooltip::after,
.hundred-ui-input.-error .hundred-ui-input__tooltip::after {
  transform: translate(6px, 19px);
  opacity: 1;
}
.hundred-ui-input.-info.-tooltip-bottom .hundred-ui-input__tooltip,
.hundred-ui-input.-done.-tooltip-bottom .hundred-ui-input__tooltip,
.hundred-ui-input.-error.-tooltip-bottom .hundred-ui-input__tooltip {
  transform: translate(0, 10px);
  opacity: 1;
}
.hundred-ui-input.-info.-tooltip-bottom .hundred-ui-input__tooltip::after,
.hundred-ui-input.-done.-tooltip-bottom .hundred-ui-input__tooltip::after,
.hundred-ui-input.-error.-tooltip-bottom .hundred-ui-input__tooltip::after {
  transform: translate(3px, -17px);
  opacity: 1;
}
.hundred-ui-input.-info input.hundred-ui-input__input {
  border-color: #353a8e;
}
.hundred-ui-input.-info input.hundred-ui-input__input::placeholder {
  color: #353a8e;
}
.hundred-ui-input.-info .hundred-ui-input__tooltip {
  background-color: #353a8e;
}
.hundred-ui-input.-info .hundred-ui-input__tooltip::after {
  border-color: #353a8e transparent transparent transparent;
}
.hundred-ui-input.-info.-tooltip-bottom .hundred-ui-input__tooltip::after {
  border-color: transparent transparent #353a8e transparent;
}
.hundred-ui-input.-done input.hundred-ui-input__input {
  border-color: #005fc9;
}
.hundred-ui-input.-done input.hundred-ui-input__input::placeholder {
  color: #005fc9;
}
.hundred-ui-input.-done .hundred-ui-input__tooltip {
  background-color: #005fc9;
}
.hundred-ui-input.-done .hundred-ui-input__tooltip::after {
  border-color: #005fc9 transparent transparent transparent;
}
.hundred-ui-input.-done.-tooltip-bottom .hundred-ui-input__tooltip::after {
  border-color: transparent transparent #005fc9 transparent;
}
.hundred-ui-input.-error input.hundred-ui-input__input {
  border-color: #ff7165;
}
.hundred-ui-input.-error input.hundred-ui-input__input::placeholder {
  color: #ff7165;
}
.hundred-ui-input.-error .hundred-ui-input__tooltip {
  background-color: #ff7165;
}
.hundred-ui-input.-error .hundred-ui-input__tooltip::after {
  border-color: #ff7165 transparent transparent transparent;
}
.hundred-ui-input.-error.-tooltip-bottom .hundred-ui-input__tooltip::after {
  border-color: transparent transparent #ff7165 transparent;
}
.language-currency {
  padding-left: 10px;
  user-select: none;
  cursor: pointer;
  width: 100%;
  max-width: 240px;
  height: 47px;
  display: block;
  border: 1px solid #ccdff4;
  border-radius: 3px;
  background-color: #fff;
  box-sizing: border-box;
}
.language-currency__image-container {
  height: 25px;
  width: 25px;
  overflow: hidden;
  position: relative;
}
.language-currency__selected,
.language-currency__list__item__link {
  display: inline-block;
  width: 100%;
  max-width: 240px;
  height: 47px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.language-currency__selected__img,
.language-currency__list__item__link__img {
  width: 30px;
  flex-grow: 0;
  margin-left: 15px;
  display: block;
  height: 23px;
  vertical-align: middle;
}
.language-currency__selected__text,
.language-currency__list__item__link__text {
  line-height: 45px;
  font-size: 14px;
  letter-spacing: -0.25px;
  appearance: none;
  color: #005fc9;
  margin: 0;
  flex-grow: 4;
  padding-left: 10px;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.language-currency__selected__icon {
  flex-grow: 0;
  line-height: 45px;
  color: #005fc9;
  font-size: 12px;
  letter-spacing: -0.29px;
  margin-right: 15px;
  transition: all ease 0.3s;
  transform-origin: 50% 47%;
}
.language-currency__selected__icon:before {
  line-height: 45px;
}
.language-currency__list {
  border-radius: 3px;
  transition: transition 0.2s ease, opacity 0.3s ease;
  background-color: #fff;
  border: 1px solid #ccdff4;
  background-color: #fff;
  color: #000;
  opacity: 0;
  transform: scale(0) translateX(-400px) translateY(-400px);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 693px;
  max-width: initial;
  height: 200px;
}
.language-currency__list:first-child {
  display: none;
}
.language-currency__list__item {
  padding-left: 10px;
  border: none;
  user-select: none;
  width: 100%;
  max-width: 229px;
  height: 47px;
  transition: all ease 0.3s;
  margin-left: 1px;
}
.language-currency__list__item:first-child {
  display: none;
}
.language-currency__list__item__link {
  width: 100%;
}
.language-currency__list__item__link__text {
  color: #4a4a4a;
}
.language-currency__list__item__link__text:hover {
  color: #005fc9;
}
.language-currency .-arrow {
  transform: rotateZ(-180deg);
}
.language-currency .-showing {
  display: flex;
  opacity: 1;
  transform: scale(1) translateX(-464px) translateY(-252px);
}
@media (max-width: 767px) {
  .language-currency .-showing {
    transform: scale(1) translateX(-10px) translateY(-254px);
  }
}
@media (max-width: 500px) {
  .language-currency .-showing {
    display: block;
    opacity: 1;
    transform: scale(1);
  }
}
.language-currency .-not-showing {
  opacity: 0;
  transform: scale(0);
}
@media (max-width: 500px) {
  .language-currency .-not-showing {
    display: none;
    opacity: 0;
    transform: scale(0);
  }
}
@media (max-width: 500px) {
  .language-currency__list {
    overflow-y: scroll;
    position: fixed;
    display: block;
    display: none;
    width: 100vw;
    height: 100vh;
    max-width: none;
    left: 0px;
    right: 0;
    top: 0;
    z-index: 3;
  }
  .language-currency__list__item {
    padding-left: 50px;
    max-width: inherit;
    margin-bottom: 10px;
  }
  .language-currency__list__item:first-child {
    display: inline-block;
    height: 35px;
  }
  .language-currency__list__item__link__text {
    font-size: 18px;
    letter-spacing: -0.25px;
  }
  .language-currency__list__item__icon {
    color: #979797;
    line-height: 14px;
    display: block;
    text-align: center;
    position: absolute;
    right: 25px;
    top: 25px;
  }
}
.location-list__list {
  margin: 30px 40px;
  display: flex;
  flex-wrap: wrap;
}
.location-list__list__item {
  width: 47.5%;
  color: #b3b3b3;
  padding-bottom: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.location-list__list__item:nth-child(2n+1) {
  margin-right: 5%;
}
.location-list__list__item:hover {
  color: #005fc9;
}
@media (min-width: 1220px) {
  .location-list__list {
    margin: 40px;
    margin-bottom: 10px;
  }
  .location-list__list__item {
    padding-bottom: 12px;
    width: 25%;
    margin-right: 20px;
    max-width: 265px;
  }
  .location-list__list__item:nth-child(2n+1) {
    margin-right: 20px;
  }
  .location-list__list__item:nth-child(4n) {
    margin-right: 0;
  }
}
.location-list.-no-top {
  margin-top: 40px;
  margin-bottom: -30px;
}
@media (min-width: 768px) {
  .location-list.-no-top {
    margin-top: -40px;
    margin-bottom: inherit;
  }
}
.location-list.-first-destinations {
  margin: -10px 0 20px;
}
@media (min-width: 768px) {
  .location-list.-first-destinations {
    margin: inherit;
  }
}
.location-list.-big-destinations {
  margin-top: -10px;
}
@media (min-width: 768px) {
  .location-list.-big-destinations {
    margin-top: inherit;
  }
}
.message-no-content {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: #ebe9e9;
  margin: auto;
  text-align: center;
  transform: translateY(100px);
}
.message-no-content__cards {
  height: 240px;
}
.message-no-content__cards.-is-establishment-card {
  transform: translate(-60px, -40px);
}
.message-no-content__cards.-is-establishment-card .message-no-content__cards__first,
.message-no-content__cards.-is-establishment-card .message-no-content__cards__second,
.message-no-content__cards.-is-establishment-card .message-no-content__cards__third {
  position: absolute;
  width: 140px;
  transition: all ease 0.3s;
  transform-origin: bottom;
  animation-timing-function: cubic-bezier(0.25, 0.1, 0.74, 1.14);
  animation-duration: 0.5s;
}
.message-no-content__cards.-is-establishment-card .message-no-content__cards__first {
  transform: rotate(-45deg);
  animation-name: initToLeft;
}
.message-no-content__cards.-is-establishment-card .message-no-content__cards__third {
  transform: rotate(45deg);
  animation-name: initToRight;
}
.message-no-content__title {
  font-size: 24px;
  text-align: center;
  line-height: 27px;
  color: #4a4a4a;
  margin: 0;
  padding: 0 20px 20px;
  font-weight: 500;
}
.message-no-content__message {
  margin: 0;
  font-size: 14px;
  letter-spacing: -0.25px;
  text-align: center;
  line-height: 21px;
  color: #4a4a4a;
  padding: 0 50px;
}
@media (max-width: 767px) {
  .message-no-content {
    transform: translateY(50px);
    width: 300px;
    height: 300px;
  }
  .message-no-content__cards {
    height: 140px;
  }
  .message-no-content__cards.-is-establishment-card {
    transform: translate(-50px, -50px);
  }
  .message-no-content__cards.-is-establishment-card .message-no-content__cards__first,
  .message-no-content__cards.-is-establishment-card .message-no-content__cards__second,
  .message-no-content__cards.-is-establishment-card .message-no-content__cards__third {
    width: 100px;
  }
  .message-no-content__title {
    font-size: 20px;
    padding-bottom: 10px;
  }
  .message-no-content__message {
    padding: 0 35px;
  }
}
.mini-link-button {
  display: block;
  width: 150px;
  height: 34px;
  background-color: #005fc9;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  line-height: 34px;
  padding: 0 5px 0 5px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-map {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  width: 100%;
  height: 105px;
  background-color: #fff;
  display: flex;
  padding: 4px;
  border-radius: 3px;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: left;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  user-select: none;
  cursor: pointer;
  backface-visibility: visible;
}
.modal-map__image-container {
  min-width: 100px;
  height: 100%;
  padding: 0;
  width: 120px;
  display: inline-block;
  position: relative;
}
.modal-map__image-container__image {
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.modal-map__image-container .modal-map__container__upper-container__rightside__items__discount {
  position: absolute;
  right: 0px;
  bottom: 0;
  padding: 0.3em 0.5em;
  background-color: #fff;
  margin: 0;
  font-size: 10px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  align-content: center;
}
.modal-map__image-container .modal-map__container__upper-container__rightside__items__discount__number {
  line-height: 1em;
  font-weight: 600;
  margin: 0;
}
.modal-map__image-container .modal-map__container__upper-container__rightside__items__discount__literal {
  color: #b3b3b3;
  padding-left: 0.5em;
}
.modal-map__container {
  width: 100%;
  max-width: 71%;
  vertical-align: top;
  padding: 3px 7px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modal-map__container__upper-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  vertical-align: top;
  align-items: middle;
}
.modal-map__container__upper-container__leftside {
  display: inline-block;
  vertical-align: top;
}
.modal-map__container__upper-container__leftside__ammenities {
  font-size: 12px;
  letter-spacing: -0.29px;
  color: #4a4a4a;
}
.modal-map__container__upper-container__leftside__partner {
  color: #4a4a4a;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}
.modal-map__container__upper-container__leftside__stars-container {
  margin-left: 3px;
}
.modal-map__container__upper-container__leftside__stars-container__star:before {
  font-size: 8px;
  letter-spacing: 1px;
}
.modal-map__container__upper-container__leftside__best {
  color: #b3b3b3;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 1em;
}
.modal-map__container__upper-container__rightside {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.modal-map__container__upper-container__rightside__items {
  display: inline-block;
  vertical-align: top;
}
.modal-map__container__upper-container__rightside__items__reco {
  position: absolute;
  top: 5%;
  left: 5%;
  background-color: #fff;
  border-radius: 100em;
  width: 0;
  height: 0;
  padding: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: initial;
  line-height: 0;
}
.modal-map__container__upper-container__rightside__items__reco__icon {
  font-size: 14px;
  letter-spacing: -0.25px;
  color: #d85cfc;
  line-height: 0;
}
.modal-map__container__upper-container__rightside__items__reco__icon:before {
  line-height: 0.5em;
}
.modal-map__container__upper-container__rightside__items__discount {
  display: inline-block;
  vertical-align: middle;
  color: #16d280;
  margin-left: 5px;
  border-radius: 3px 0 0 0;
  background-color: #fff;
}
.modal-map__container__upper-container__rightside__items__discount__percent {
  font-size: 9px;
  letter-spacing: -0.25px;
  line-height: 21px;
  margin-left: 1px;
}
.modal-map__container__upper-container__rightside__items__discount__number {
  display: inline-block;
  letter-spacing: -0.25px;
  vertical-align: top;
  margin-top: 2px;
  line-height: 16px;
  font-size: 14px;
  letter-spacing: -0.25px;
  color: #ba124b;
  font-weight: normal;
  text-decoration: line-through;
  cursor: pointer;
}
.modal-map__container__upper-container__rightside__items__instant {
  display: inline-block;
  vertical-align: top;
  margin-right: 0;
}
.modal-map__container__upper-container__rightside__items__instant__bolt {
  font-size: 15px;
  letter-spacing: -0.25px;
  color: #fdd835;
}
.modal-map__container__upper-container__rightside__price {
  display: inline-block;
  margin-left: 3px;
}
.modal-map__container__upper-container__rightside__price__number {
  color: #4a4a4a;
  font-size: 21px;
  line-height: 0.85em;
  font-weight: 600;
  margin-left: 2px;
  letter-spacing: -1px;
}
.modal-map__container__upper-container__rightside__price__type {
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0px;
  color: #4a4a4a;
}
.modal-map__container__upper-container__rightside__price__currency {
  vertical-align: top;
  font-size: 12px;
  letter-spacing: -0.29px;
  color: #4a4a4a;
  font-weight: 700;
}
.modal-map__container__low-container {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  display: flex;
  justify-content: space-between;
  height: auto;
  align-items: center;
}
.modal-map__container__low-container__leftside {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-content: center;
  align-items: center;
  vertical-align: middle;
  width: 63%;
  height: 100%;
}
.modal-map__container__low-container__leftside__cancellation-full .hundredicons-cancellation-simple,
.modal-map__container__low-container__leftside__cancellation-partial .hundredicons-cancellation-simple {
  font-size: 10px;
  letter-spacing: 0px;
  margin-right: 4px;
}
.modal-map__container__low-container__leftside__cancellation-full {
  color: #5d5fa3;
}
.modal-map__container__low-container__leftside__cancellation-partial {
  color: #afb1d2;
}
.modal-map__container__low-container__leftside__cancellation__text {
  font-size: 12px;
  letter-spacing: -0.29px;
  color: #8b8b8b;
}
.modal-map__container__low-container__leftside__info {
  color: #b3b3b3;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 0.3em 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-map__container__low-container__leftside__info__valoration__tooltip .tooltip-inner,
.modal-map__container__low-container__leftside__info__valoration__tooltip .tooltip-arrow {
  display: none;
}
.modal-map__container__low-container__leftside__info__area,
.modal-map__container__low-container__leftside__info__guest,
.modal-map__container__low-container__leftside__info__rooms {
  font-weight: 500;
  letter-spacing: -1px;
  padding: 0px 0px 0px 4px;
}
.modal-map__container__low-container__leftside__info__area__dot,
.modal-map__container__low-container__leftside__info__guest__dot,
.modal-map__container__low-container__leftside__info__rooms__dot {
  margin: 0 2px;
}
.modal-map__container__low-container__leftside__info__area__icon,
.modal-map__container__low-container__leftside__info__guest__icon,
.modal-map__container__low-container__leftside__info__rooms__icon {
  font-size: 10px;
  letter-spacing: 0px;
  margin-right: 2px;
}
.modal-map__container__low-container__leftside__block {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  letter-spacing: -0.29px;
  color: #4a4a4a;
  min-height: 20px;
  justify-content: center;
}
.modal-map__container__low-container__leftside__separator {
  content: '';
  height: 28px;
  width: 1px;
  display: block;
  margin: 0 5px;
  background-color: #b3b3b3;
  border-radius: 3px;
}
.modal-map__container__low-container__leftside__rating {
  margin-top: 0;
  font-size: 16px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
}
.modal-map__container__low-container__leftside__rating__rankings {
  display: inline-block;
  margin-right: 5px;
  color: #f5a623;
}
.modal-map__container__low-container__leftside__rating__rankings__number {
  font-size: 12px;
  letter-spacing: -0.29px;
  margin-right: 3px;
}
.modal-map__container__low-container__leftside__rating__rankings__stars {
  font-size: 11px;
  letter-spacing: 0px;
  margin-left: 2px;
}
.modal-map__container__low-container__leftside__rating__rankings__quantity {
  font-size: 12px;
  letter-spacing: -0.29px;
  color: #b3b3b3;
  font-weight: 400;
  margin-left: 3px;
}
.modal-map__container__low-container__leftside .modal-map__container__cancellation__envelop .hundredicons-cancellation {
  font-size: 1.1em;
  line-height: 1em;
}
.modal-map__container__low-container__leftside .modal-map__container__cancellation__envelop .snippet__cancellation__text {
  display: none;
}
.modal-map__container__low-container__rightside {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 38%;
  text-align: right;
  height: 100%;
}
.modal-map__container__low-container__rightside__button {
  margin: 0;
  max-width: 82px;
  width: 95%;
  min-width: 65px;
  border-radius: 3px;
  font-size: 12px;
  letter-spacing: -0.29px;
  letter-spacing: -0.25px;
  font-weight: 500;
  line-height: 1em;
  background-color: #005fc9;
  color: #fff;
  cursor: pointer;
  text-transform: inherit;
  display: block;
  padding: 0.7em 0em;
  height: auto;
}
.modal-map__container__low-container__rightside__button:hover {
  background-color: #005fc9;
}
.modal-map__container__low-container__rightside__button:active {
  background-color: #005fc9;
}
.modal-map__container__low-container__rightside__button:focus {
  background-color: #005fc9;
}
@media (max-width: 376px) {
  .modal-map {
    transition: all ease 0.2s;
  }
  .modal-map__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 3px 8px;
  }
  .modal-map__container__upper-container__leftside {
    width: 60%;
  }
  .modal-map__container__low-container__leftside {
    width: 100%;
    height: auto;
  }
  .modal-map__container__low-container__leftside .modal-map__container__cancellation__envelop {
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .modal-map__container__low-container__leftside .modal-map__container__cancellation__envelop .snippet__cancellation__text {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    letter-spacing: -0.29px;
    line-height: 1em;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .modal-map__container__low-container__rightside {
    display: none;
  }
}
@media (max-width: 360px) {
  .modal-map__container {
    padding: 3px;
  }
  .modal-map__container__upper-container__rightside {
    flex-direction: row;
  }
  .modal-map__container__upper-container__rightside__price {
    margin: 0;
  }
  .modal-map__container__upper-container__rightside__items__discount {
    white-space: nowrap;
    vertical-align: initial;
    display: initial;
  }
  .modal-map__container__upper-container__rightside__items__discount__number {
    line-height: 1em;
    vertical-align: initial;
    margin: 0;
    padding: 0;
  }
  .modal-map__container__upper-container__leftside__best {
    display: flex;
    align-items: baseline;
    align-content: center;
  }
  .modal-map__container__upper-container__leftside__best__text {
    white-space: nowrap;
  }
  .modal-map__container__low-container {
    height: auto;
    min-height: 28px;
  }
  .modal-map__container__low-container__cancellation__envelop .snippet__cancellation__text {
    overflow: hidden;
    white-space: initial;
  }
  .modal-map__image-container {
    width: 100%;
    display: flex;
    min-width: initial;
    max-width: 100px;
  }
  .modal-map__image-container__image {
    width: 100%;
  }
}
.modal-map .-es-co,
.modal-map .-ja-jp {
  font-size: 12px;
  letter-spacing: -0.29px;
  letter-spacing: -1.5px;
}
@media (min-width: 350px) {
  .modal-map .-es-co,
  .modal-map .-ja-jp {
    font-size: 14px;
    letter-spacing: -0.25px;
    letter-spacing: -1.5px;
  }
}
@media (min-width: 768px) {
  .modal-map .-es-co,
  .modal-map .-ja-jp {
    font-size: 16px;
    letter-spacing: -0.25px;
    letter-spacing: -1.5px;
  }
}
.navigation-bar {
  position: fixed;
  top: 0;
  height: 50px;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  z-index: 6;
}
.navigation-bar__nav-container {
  font-size: 18px;
  letter-spacing: -0.25px;
  width: 40%;
  height: 100%;
  display: flex;
  padding-left: 15px;
}
.navigation-bar__nav-container__back {
  width: 40px;
  color: #4a4a4a;
  display: flex;
  align-items: center;
}
.navigation-bar__nav-container__back .hundredicons-arrow-left-filled {
  width: 30px;
}
.navigation-bar__nav-container__back .hundredicons-arrow-left-filled:before {
  display: block;
  line-height: 50px;
}
.navigation-bar__nav-container__home {
  margin-top: -1px;
  display: flex;
  height: 100%;
  align-items: center;
}
.navigation-bar__nav-container__home__logo {
  align-self: center;
  width: 45vw;
  max-width: 135px;
  max-height: 45px;
  height: auto;
  color: #4a4a4a;
}
.navigation-bar__container {
  width: 60%;
  height: 100%;
  align-items: center;
  display: inline-flex;
  justify-content: flex-end;
  padding-right: 20px;
  box-sizing: border-box;
  color: #4a4a4a;
  font-size: 22px;
}
.navigation-bar__container__personal {
  position: relative;
  display: block;
  margin-top: 8px;
}
.navigation-bar__container__personal__logo {
  margin-left: 5vw;
}
.navigation-bar__container__personal__is-logged {
  line-height: 15px;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  position: absolute;
  height: 19px;
  width: 19px;
  background-color: #28d7a5;
  text-align: center;
  align-self: center;
  color: #fff;
  border-radius: 30px;
  border: 2px solid #fff;
  font-weight: 700;
  font-size: 9px;
  top: -4px;
  right: -9px;
}
.navigation-bar__container__personal .hundredicons-check:before {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}
.navigation-bar__container__favourites {
  display: block;
  margin-top: 8px;
  position: relative;
}
.navigation-bar__container__favourites__counter {
  line-height: 14px;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  display: flex;
  justify-content: center;
  position: absolute;
  height: auto;
  width: auto;
  min-width: 20px;
  background-color: #ed4b82;
  text-align: center;
  color: #fff;
  border-radius: 30px;
  font-size: 9px;
  border: 2px solid #fff;
  font-weight: 700;
  padding: 1px 3px;
  top: -4px;
  right: -11px;
}
.navigation-bar__container__favourites__counter__number {
  align-self: center;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .navigation-bar {
    display: none;
  }
}
@media (max-width: 220px) {
  .navigation-bar__nav-container__home {
    display: none;
  }
}
.navigation-tools {
  display: flex;
  justify-content: space-between;
  height: 70px;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 10px;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  transform: translateY(0);
  margin-top: 50px;
}
.navigation-tools__input {
  border: 1px solid #ccdff4;
  height: 100%;
  width: 45%;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 12px 10px;
}
.navigation-tools__input__info {
  display: inline-block;
  flex-wrap: wrap;
  overflow: hidden;
  flex-basis: 90%;
}
.navigation-tools__input__info__city {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  color: #4a4a4a;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.25px;
}
.navigation-tools__input__info__amenities {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  color: #808080;
  font-weight: 300;
  font-size: 13px;
}
.navigation-tools__input__icon {
  padding-left: 5px;
  flex-basis: 10%;
  display: inline-block;
  color: #4a90e2;
}
.navigation-tools__map,
.navigation-tools__filters {
  box-sizing: border-box;
  padding: 12px 5px;
  border-radius: 3px;
  height: 100%;
  width: 25%;
  display: inline-block;
  border: 1px solid #ccdff4;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4a4a4a;
  font-size: 14px;
  letter-spacing: -0.25px;
}
.navigation-tools__map__icon,
.navigation-tools__filters__icon {
  margin-right: 5px;
  height: 13px;
}
.navigation-tools__map__text,
.navigation-tools__filters__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.navigation-tools.-is-fixed {
  border-bottom: 1px solid #eee;
  position: fixed;
  top: -70px;
  z-index: 5;
  transform: translateY(70px);
  transition: transform ease-in 0.3s;
}
.navigation-tools.-is-hidding {
  position: fixed;
  top: -70px;
  z-index: 5;
  transform: translateY(0px);
  transition: transform ease-in 0.3s;
}
@media (min-width: 768px) {
  .navigation-tools {
    display: none;
  }
}
.page-establishment-cta {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  border: 1px solid #e8e8e8;
  width: 100%;
  height: auto;
  display: flex;
  align-items: start;
  box-sizing: border-box;
  padding: 50px;
  border-radius: 3px;
}
.page-establishment-cta__image {
  height: 60px;
}
.page-establishment-cta__image__item {
  display: block;
  max-width: 60px;
  max-height: 60px;
  height: 100%;
}
.page-establishment-cta__text {
  margin-left: 50px;
  flex-grow: 1;
  color: #4a4a4a;
  line-height: 24px;
}
.page-establishment-cta__text__title {
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  font-weight: 600;
  padding-bottom: 5px;
}
.page-establishment-cta__text__subtitle {
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  padding-right: 10px;
}
.page-establishment-cta__cta {
  flex-grow: 3;
}
.page-establishment-cta__cta .hundred-ui-button {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  max-width: 235px;
  width: 100%;
  padding: 0 20px;
  font-weight: 700;
}
.page-establishment-cta .cancellation_policy__refund_text {
  letter-spacing: -0.025em;
  line-height: 1.6em;
  text-align: left;
  margin-bottom: 1em;
}
@media (max-width: 1200px) {
  .page-establishment-cta__text {
    min-width: 285px;
    margin-left: 30px;
  }
  .page-establishment-cta__cta {
    margin-left: 5px;
  }
}
@media (max-width: 767px) {
  .page-establishment-cta {
    border: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
  .page-establishment-cta__text {
    margin-left: 80px;
    min-width: initial;
  }
  .page-establishment-cta__cta {
    margin-left: 80px;
    margin-top: 1em;
  }
  .page-establishment-cta__cta .hundred-ui-button {
    max-width: 185px;
    height: auto;
    white-space: normal;
    line-height: 21px;
    padding: 5px 20px;
  }
  .page-establishment-cta__image {
    position: absolute;
    top: auto;
    left: auto;
  }
  .page-establishment-cta.establishment-page__content__right-side__cancellation {
    padding: 20px 8px 0px;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .page-establishment-cta.establishment-page__content__right-side__cancellation .page-establishment-cta__image {
    height: 50px;
  }
  .page-establishment-cta.establishment-page__content__right-side__cancellation .page-establishment-cta__text {
    min-width: initial;
  }
  .page-establishment-cta.establishment-page__content__right-side__cancellation .page-establishment-cta__text__title {
    line-height: 1;
    padding-bottom: 1em;
  }
}
.pager {
  height: auto;
  margin: 20px 20px;
  text-align: center;
}
.pager__list__item {
  border: 1px solid #ccdff4;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
  background-color: #fff;
  margin: 5px;
  text-align: center;
  font-weight: 600;
  line-height: 30px;
  font-size: 12px;
  letter-spacing: -0.29px;
  cursor: pointer;
}
.pager__list__item:hover {
  background-color: #ccdff4;
}
.pager__list__item__icon-left,
.pager__list__item__icon-right {
  display: inline-block;
}
.pager__list__item__icon-left:before,
.pager__list__item__icon-right:before {
  vertical-align: middle;
}
.pager__list__item__link {
  display: inline-block;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  text-align: center;
  line-height: 28px;
}
.pager__list__dots {
  letter-spacing: 1px;
}
.-is-current {
  background-color: #005fc9;
  color: #fff;
}
.-disabled {
  cursor: inherit;
  opacity: 0.3;
}
.-disabled:hover {
  background-color: #fff;
}
.partner-info-button {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  align-items: center;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  height: 87px;
  justify-content: space-between;
  min-width: 302px;
  padding: 0px 10px;
  position: relative;
  white-space: nowrap;
  width: 100%;
}
.partner-info-button__left-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 33%;
}
.partner-info-button__left-section__top {
  display: flex;
  line-height: 20px;
  height: 36px;
  min-width: 137px;
}
.partner-info-button__left-section__top__logo-2,
.partner-info-button__left-section__top__logo-3,
.partner-info-button__left-section__top__logo-4,
.partner-info-button__left-section__top__logo-5,
.partner-info-button__left-section__top__logo-6,
.partner-info-button__left-section__top__logo-7,
.partner-info-button__left-section__top__logo-8,
.partner-info-button__left-section__top__logo-9,
.partner-info-button__left-section__top__logo-10,
.partner-info-button__left-section__top__logo-11,
.partner-info-button__left-section__top__logo-12,
.partner-info-button__left-section__top__logo-13,
.partner-info-button__left-section__top__logo-14,
.partner-info-button__left-section__top__logo-15,
.partner-info-button__left-section__top__logo-16,
.partner-info-button__left-section__top__logo-17,
.partner-info-button__left-section__top__logo-19,
.partner-info-button__left-section__top__logo-20,
.partner-info-button__left-section__top__logo-21,
.partner-info-button__left-section__top__logo-22,
.partner-info-button__left-section__top__logo-23,
.partner-info-button__left-section__top__logo-24,
.partner-info-button__left-section__top__logo-25,
.partner-info-button__left-section__top__logo-26,
.partner-info-button__left-section__top__logo-27,
.partner-info-button__left-section__top__logo-29,
.partner-info-button__left-section__top__logo-30,
.partner-info-button__left-section__top__logo-31,
.partner-info-button__left-section__top__logo-33,
.partner-info-button__left-section__top__logo-35,
.partner-info-button__left-section__top__logo-36,
.partner-info-button__left-section__top__logo-37,
.partner-info-button__left-section__top__logo-38,
.partner-info-button__left-section__top__logo-39 {
  margin-right: 0px;
  width: 137px;
  min-width: 137px;
  height: 36px;
  overflow: hidden;
  position: relative;
  align-self: center;
}
.partner-info-button__left-section__top__logo-2__img,
.partner-info-button__left-section__top__logo-3__img,
.partner-info-button__left-section__top__logo-4__img,
.partner-info-button__left-section__top__logo-5__img,
.partner-info-button__left-section__top__logo-6__img,
.partner-info-button__left-section__top__logo-7__img,
.partner-info-button__left-section__top__logo-8__img,
.partner-info-button__left-section__top__logo-9__img,
.partner-info-button__left-section__top__logo-10__img,
.partner-info-button__left-section__top__logo-11__img,
.partner-info-button__left-section__top__logo-12__img,
.partner-info-button__left-section__top__logo-13__img,
.partner-info-button__left-section__top__logo-14__img,
.partner-info-button__left-section__top__logo-15__img,
.partner-info-button__left-section__top__logo-16__img,
.partner-info-button__left-section__top__logo-17__img,
.partner-info-button__left-section__top__logo-19__img,
.partner-info-button__left-section__top__logo-20__img,
.partner-info-button__left-section__top__logo-21__img,
.partner-info-button__left-section__top__logo-22__img,
.partner-info-button__left-section__top__logo-23__img,
.partner-info-button__left-section__top__logo-24__img,
.partner-info-button__left-section__top__logo-25__img,
.partner-info-button__left-section__top__logo-26__img,
.partner-info-button__left-section__top__logo-27__img,
.partner-info-button__left-section__top__logo-29__img,
.partner-info-button__left-section__top__logo-30__img,
.partner-info-button__left-section__top__logo-31__img,
.partner-info-button__left-section__top__logo-33__img,
.partner-info-button__left-section__top__logo-35__img,
.partner-info-button__left-section__top__logo-36__img,
.partner-info-button__left-section__top__logo-37__img,
.partner-info-button__left-section__top__logo-38__img,
.partner-info-button__left-section__top__logo-39__img {
  width: 410px;
  cursor: pointer;
  position: absolute;
}
.partner-info-button__left-section__top__logo-2__img__fr,
.partner-info-button__left-section__top__logo-3__img__fr,
.partner-info-button__left-section__top__logo-4__img__fr,
.partner-info-button__left-section__top__logo-5__img__fr,
.partner-info-button__left-section__top__logo-6__img__fr,
.partner-info-button__left-section__top__logo-7__img__fr,
.partner-info-button__left-section__top__logo-8__img__fr,
.partner-info-button__left-section__top__logo-9__img__fr,
.partner-info-button__left-section__top__logo-10__img__fr,
.partner-info-button__left-section__top__logo-11__img__fr,
.partner-info-button__left-section__top__logo-12__img__fr,
.partner-info-button__left-section__top__logo-13__img__fr,
.partner-info-button__left-section__top__logo-14__img__fr,
.partner-info-button__left-section__top__logo-15__img__fr,
.partner-info-button__left-section__top__logo-16__img__fr,
.partner-info-button__left-section__top__logo-17__img__fr,
.partner-info-button__left-section__top__logo-19__img__fr,
.partner-info-button__left-section__top__logo-20__img__fr,
.partner-info-button__left-section__top__logo-21__img__fr,
.partner-info-button__left-section__top__logo-22__img__fr,
.partner-info-button__left-section__top__logo-23__img__fr,
.partner-info-button__left-section__top__logo-24__img__fr,
.partner-info-button__left-section__top__logo-25__img__fr,
.partner-info-button__left-section__top__logo-26__img__fr,
.partner-info-button__left-section__top__logo-27__img__fr,
.partner-info-button__left-section__top__logo-29__img__fr,
.partner-info-button__left-section__top__logo-30__img__fr,
.partner-info-button__left-section__top__logo-31__img__fr,
.partner-info-button__left-section__top__logo-33__img__fr,
.partner-info-button__left-section__top__logo-35__img__fr,
.partner-info-button__left-section__top__logo-36__img__fr,
.partner-info-button__left-section__top__logo-37__img__fr,
.partner-info-button__left-section__top__logo-38__img__fr,
.partner-info-button__left-section__top__logo-39__img__fr,
.partner-info-button__left-section__top__logo-2__img__de,
.partner-info-button__left-section__top__logo-3__img__de,
.partner-info-button__left-section__top__logo-4__img__de,
.partner-info-button__left-section__top__logo-5__img__de,
.partner-info-button__left-section__top__logo-6__img__de,
.partner-info-button__left-section__top__logo-7__img__de,
.partner-info-button__left-section__top__logo-8__img__de,
.partner-info-button__left-section__top__logo-9__img__de,
.partner-info-button__left-section__top__logo-10__img__de,
.partner-info-button__left-section__top__logo-11__img__de,
.partner-info-button__left-section__top__logo-12__img__de,
.partner-info-button__left-section__top__logo-13__img__de,
.partner-info-button__left-section__top__logo-14__img__de,
.partner-info-button__left-section__top__logo-15__img__de,
.partner-info-button__left-section__top__logo-16__img__de,
.partner-info-button__left-section__top__logo-17__img__de,
.partner-info-button__left-section__top__logo-19__img__de,
.partner-info-button__left-section__top__logo-20__img__de,
.partner-info-button__left-section__top__logo-21__img__de,
.partner-info-button__left-section__top__logo-22__img__de,
.partner-info-button__left-section__top__logo-23__img__de,
.partner-info-button__left-section__top__logo-24__img__de,
.partner-info-button__left-section__top__logo-25__img__de,
.partner-info-button__left-section__top__logo-26__img__de,
.partner-info-button__left-section__top__logo-27__img__de,
.partner-info-button__left-section__top__logo-29__img__de,
.partner-info-button__left-section__top__logo-30__img__de,
.partner-info-button__left-section__top__logo-31__img__de,
.partner-info-button__left-section__top__logo-33__img__de,
.partner-info-button__left-section__top__logo-35__img__de,
.partner-info-button__left-section__top__logo-36__img__de,
.partner-info-button__left-section__top__logo-37__img__de,
.partner-info-button__left-section__top__logo-38__img__de,
.partner-info-button__left-section__top__logo-39__img__de {
  width: 410px;
}
.partner-info-button__left-section__top__logo-2__img {
  top: -97px;
}
.partner-info-button__left-section__top__logo-3__img {
  top: -162px;
}
.partner-info-button__left-section__top__logo-4__img {
  top: -228px;
}
.partner-info-button__left-section__top__logo-5__img {
  top: -293px;
}
.partner-info-button__left-section__top__logo-6__img {
  top: -359px;
}
.partner-info-button__left-section__top__logo-7__img {
  top: -424px;
}
.partner-info-button__left-section__top__logo-8__img {
  top: -490px;
}
.partner-info-button__left-section__top__logo-9__img {
  top: -556px;
}
.partner-info-button__left-section__top__logo-10__img {
  top: -622px;
}
.partner-info-button__left-section__top__logo-11__img {
  top: -687px;
}
.partner-info-button__left-section__top__logo-12__img {
  top: -751px;
}
.partner-info-button__left-section__top__logo-12__img__fr,
.partner-info-button__left-section__top__logo-12__img__de {
  cursor: pointer;
  position: absolute;
  top: -751px;
}
.partner-info-button__left-section__top__logo-12__img__fr {
  left: -135px;
}
.partner-info-button__left-section__top__logo-12__img__de {
  left: -271px;
}
.partner-info-button__left-section__top__logo-13__img {
  top: -818px;
}
.partner-info-button__left-section__top__logo-14__img {
  top: -884px;
}
.partner-info-button__left-section__top__logo-15__img {
  top: -950px;
}
.partner-info-button__left-section__top__logo-16__img {
  top: -1015px;
}
.partner-info-button__left-section__top__logo-17__img {
  top: -1080px;
}
.partner-info-button__left-section__top__logo-19__img {
  top: -1211px;
}
.partner-info-button__left-section__top__logo-20__img {
  top: -1276px;
}
.partner-info-button__left-section__top__logo-21__img {
  top: -1343px;
}
.partner-info-button__left-section__top__logo-22__img {
  top: -1407px;
}
.partner-info-button__left-section__top__logo-23__img {
  top: -1474px;
}
.partner-info-button__left-section__top__logo-24__img {
  top: -1539px;
}
.partner-info-button__left-section__top__logo-25__img {
  top: -1605px;
}
.partner-info-button__left-section__top__logo-26__img {
  top: -1671px;
}
.partner-info-button__left-section__top__logo-27__img {
  top: -1736px;
}
.partner-info-button__left-section__top__logo-29__img {
  top: -1868px;
}
.partner-info-button__left-section__top__logo-30__img {
  top: -1934px;
}
.partner-info-button__left-section__top__logo-31__img {
  top: -2000px;
}
.partner-info-button__left-section__top__logo-33__img {
  top: -2126px;
}
.partner-info-button__left-section__top__logo-35__img {
  top: -2260px;
}
.partner-info-button__left-section__top__logo-36__img {
  top: -2326px;
}
.partner-info-button__left-section__top__logo-37__img {
  top: -2393px;
}
.partner-info-button__left-section__top__logo-38__img {
  top: -2457px;
}
.partner-info-button__left-section__top__logo-39__img {
  top: -2524px;
}
.partner-info-button__left-section__options {
  align-items: baseline;
  display: flex;
}
.partner-info-button__left-section__options__bottom {
  color: #005fc9;
  font-weight: 600;
  line-height: 16px;
  margin-right: 5px;
}
.partner-info-button__left-section__options .hundredicons-cancellation-simple {
  align-items: center;
  display: flex;
  font-size: 12px;
  letter-spacing: -0.29px;
  margin-right: 2px;
  position: relative;
  top: 2px;
}
.partner-info-button__left-section__options .hundredicons-cancellation-simple.-partial:before {
  color: #afb1d2;
}
.partner-info-button__left-section__options .hundredicons-cancellation-simple.-full:before {
  color: #5d5fa3;
}
.partner-info-button__left-section__options__icon {
  color: #fdd835;
  position: relative;
  top: 3px;
}
.partner-info-button__middle-section {
  color: #4a4a4a;
  height: 100%;
  width: 33%;
  display: flex;
  justify-content: center;
}
.partner-info-button__middle-section__prices {
  height: fit-content;
  align-self: center;
  text-align: left;
}
.partner-info-button__middle-section__prices__top {
  display: flex;
  justify-content: left;
}
.partner-info-button__middle-section__prices__top__price {
  cursor: pointer;
  font-size: 16px;
  letter-spacing: -0.25px;
  line-height: 16px;
  margin-right: 5px;
}
.partner-info-button__middle-section__prices__top__night {
  cursor: pointer;
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  line-height: 16px;
}
.partner-info-button__middle-section__prices__bottom {
  cursor: pointer;
  display: inline-block;
  margin-top: 5px;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 14px;
}
.partner-info-button__right-section {
  width: 28%;
  display: flex;
  height: 100%;
  justify-content: flex-end;
}
.partner-info-button__right-section__button-container {
  height: fit-content;
  align-self: center;
  text-align: center;
}
.partner-info-button__right-section__button-container__link {
  color: #005fc9;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: 0.22px;
  line-height: 22px;
  text-align: end;
  white-space: normal;
}
.partner-info-button__right-section__button-container__link__prices {
  display: none;
}
.partner-info-button__right-section__button-container .hundredicons-arrow-right-filled {
  margin-left: 1px;
  display: flex;
  align-items: flex-end;
}
.partner-info-button__right-section__button-container .hundredicons-arrow-right-filled:before {
  position: relative;
  top: 1px;
  font-size: 11px;
  letter-spacing: 0px;
  line-height: 18px;
}
.partner-info-button__right-section__button-container__total {
  display: none;
}
.partner-info-button.-best-price {
  border: 1px solid #005fc9;
  border-radius: 8px;
}
.partner-info-button.-best-price .partner-info-button__left-section {
  cursor: pointer;
  height: 62%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.partner-info-button.-best-price .partner-info-button__left-section__top.-not-alone {
  height: fit-content;
}
.partner-info-button.-best-price .partner-info-button__left-section__top.-not-alone .partner-info-button__left-section__options__icon:before {
  top: 30%;
}
.partner-info-button.-best-price .partner-info-button__middle-section {
  cursor: pointer;
  color: #4a4a4a;
  font-weight: 500;
  text-align: center;
}
.partner-info-button.-best-price .partner-info-button__middle-section__prices__top__price {
  display: inline-block;
  font-size: 20px;
  line-height: 16px;
}
.partner-info-button.-best-price .partner-info-button__middle-section__prices__top__night {
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  line-height: 16px;
}
.partner-info-button.-best-price .partner-info-button__middle-section__prices__bottom {
  font-size: 14px;
  letter-spacing: -0.25px;
  display: inline-block;
  line-height: 14px;
}
.partner-info-button.-best-price .partner-info-button__right-section {
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 28%;
}
.partner-info-button.-best-price .partner-info-button__right-section__button-container {
  width: 100%;
  cursor: pointer;
}
.partner-info-button.-best-price .partner-info-button__right-section__button-container__button {
  background-color: #005fc9;
  color: #fff;
  font-family: inherit;
  font-size: 16px;
  letter-spacing: -0.25px;
  height: 45px;
  font-weight: 400;
  letter-spacing: 0.22px;
  line-height: 22px;
  margin-bottom: 10px;
  margin-top: 10px;
  min-width: 76px;
  white-space: normal;
  width: 100%;
}
.partner-info-button.-best-price .partner-info-button__right-section__button-container__button:hover {
  background-color: #267dc0;
  color: #fff;
}
.partner-info-button.-best-price .partner-info-button__right-section__button-container__button__prices {
  display: none;
}
.partner-info-button.-best-price .partner-info-button__right-section__total {
  display: none;
}
@media (max-width: 413px) {
  .partner-info-button__left-section {
    width: 36%;
  }
  .partner-info-button__middle-section {
    display: none;
  }
  .partner-info-button__right-section {
    max-width: 64%;
    width: auto;
  }
  .partner-info-button__right-section__button-container__link {
    justify-content: flex-end;
    display: flex;
    line-height: 20px;
  }
  .partner-info-button__right-section__button-container__link__text {
    display: none;
  }
  .partner-info-button__right-section__button-container__link__prices {
    display: flex;
  }
  .partner-info-button__right-section__button-container__link__prices__price {
    margin-right: 5px;
  }
  .partner-info-button__right-section__button-container__total {
    display: block;
    font-size: 16px;
    letter-spacing: -0.25px;
    margin-top: 5px;
    margin-right: 11px;
    text-align: right;
    color: #4a4a4a;
  }
  .partner-info-button.-best-price .partner-info-button__middle-section {
    display: none;
  }
  .partner-info-button.-best-price .partner-info-button__right-section {
    display: flex;
    justify-content: space-evenly;
    width: auto;
  }
  .partner-info-button.-best-price .partner-info-button__right-section__button-container {
    width: 100%;
  }
  .partner-info-button.-best-price .partner-info-button__right-section__button-container__button {
    padding: 10px;
    margin: 0px;
    display: flex;
    justify-content: center;
  }
  .partner-info-button.-best-price .partner-info-button__right-section__button-container__button__text {
    display: none;
  }
  .partner-info-button.-best-price .partner-info-button__right-section__button-container__button__prices {
    display: flex;
    justify-content: left;
    white-space: nowrap;
  }
  .partner-info-button.-best-price .partner-info-button__right-section__button-container__button__prices__price {
    margin-right: 5px;
    font-size: 21px;
    line-height: 16px;
  }
  .partner-info-button.-best-price .partner-info-button__right-section__button-container__button__prices__night {
    white-space: nowrap;
    font-size: 16px;
    letter-spacing: -0.25px;
    line-height: 16px;
  }
  .partner-info-button.-best-price .partner-info-button__right-section__total {
    display: block;
    text-align: end;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.25px;
    margin-right: 11px;
    color: #4a4a4a;
  }
}
.publish-link__inner {
  display: flex;
  align-items: center;
}
.publish-link__inner__image {
  padding-right: 15px;
}
.publish-link__inner__caret {
  color: #005fc9;
}
.rheostat {
  overflow: visible;
  width: 100%;
}
.rheostat .rheostat-background {
  background-color: #fcfcfc;
  border: 1px solid #d8d8d8;
  position: relative;
}
.rheostat .rheostat-progress {
  background-color: #abc4e8;
  position: absolute;
}
.rheostat .rheostat-handle {
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  min-width: inherit;
  padding: 0.7em;
  outline: none;
  z-index: 2;
  transition: none;
}
.rheostat .rheostat-handle:hover,
.rheostat .rheostat-handle:focus {
  opacity: 1;
}
.rheostat.rheostat-horizontal {
  height: 4px;
  top: 0;
}
.rheostat.rheostat-horizontal .rheostat-background {
  background-color: #b0bec5;
  border: none;
  height: 4px;
  width: 100%;
}
.rheostat.rheostat-horizontal .rheostat-progress {
  background-color: #005fc9;
  height: 4px;
  top: 0px;
}
.rheostat.rheostat-horizontal .rheostat-handle {
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin-left: 0;
  opacity: 1;
}
.rheostat.rheostat-horizontal .rheostat-handle[aria-disabled="true"] + .rheostat-progress {
  opacity: 0;
}
.search-while-moving-button {
  cursor: pointer;
  padding: 0;
  background-color: #fff;
  line-height: 18px;
  white-space: nowrap;
  overflow: visible;
  letter-spacing: -0.25px;
  z-index: 1;
  margin: 10px;
  letter-spacing: -0.29px;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  width: 100%;
  vertical-align: middle;
  justify-content: center;
  position: absolute;
  max-width: 176px;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  box-shadow: none;
  border: 1px solid #ccdff4;
  min-height: 50px;
  height: auto;
}
.search-while-moving-button__refresh {
  align-items: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  height: calc(50px - 12px);
}
.search-while-moving-button__refresh__icon {
  color: #fff;
  margin-right: 10px;
  font-size: 14px;
  letter-spacing: -0.25px;
  display: flex;
}
.search-while-moving-button__refresh__text {
  font-weight: 500;
  color: #fff;
  display: inline-block;
  flex-shrink: 2;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 16px;
}
.search-while-moving-button__checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.search-while-moving-button__checkbox__label {
  display: flex;
  color: #4a4a4a;
  flex-shrink: 2;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 15px;
  text-align: left;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  align-items: center;
}
.search-while-moving-button__checkbox__label:after {
  background-color: #fff;
  border-radius: 2px;
  box-sizing: border-box;
  content: "\E019";
  flex-shrink: 0;
  font-family: 'hundredrooms';
  font-size: 0;
  height: 20px;
  width: 20px;
  line-height: 19px;
  text-align: center;
  display: block;
  transition: all ease 0.2s;
  margin-left: 10px;
  border: 1px solid #ccdff4;
}
.search-while-moving-button__checkbox__label__text {
  white-space: normal;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 400;
  line-height: 16px;
  padding: 2px 0;
}
.search-while-moving-button__checkbox__input[type="checkbox"]:not(:checked),
.search-while-moving-button__checkbox__input[type="checkbox"]:checked {
  left: -9999px;
  position: absolute;
  visibility: hidden;
}
.search-while-moving-button.-is-checked .search-while-moving-button__checkbox__label:after {
  box-shadow: inset 0 0 0 1px #ccdff4;
  background-color: rgba(204,223,244,0.18);
  color: #005fc9;
  font-size: 12px;
  letter-spacing: -0.29px;
}
.search-while-moving-button.-text-position-right .search-while-moving-button__checkbox__label__text {
  order: 3;
}
.search-while-moving-button.-text-position-right .search-while-moving-button__checkbox__label:after {
  margin-right: 10px;
  margin-left: 0px;
  order: 1;
}
.search-while-moving-button.-not-showing {
  width: auto;
  background-color: #e91e63;
  border: 1px solid #fbd2e1;
}
.search-while-moving-button.-not-showing .search-while-moving-button__label__text {
  color: #fff;
}
.search-while-moving-button.-not-showing .search-while-moving-button__label:after {
  display: none;
}
.search-while-moving-button.-refresh-button-bounce {
  animation: bounce 0.5s linear 1;
}
@media (max-width: 319px) {
  .search-while-moving-button {
    padding: 0 0.5em;
    max-width: 147px;
  }
}
@-moz-keyframes bounce {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.15);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes bounce {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.15);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes bounce {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.15);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.15);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.hundred-ui-select {
  height: 50px;
  width: 100%;
  max-width: 230px;
  min-width: 100px;
  height: 50px;
  display: block;
  padding: 0 30px 0 10px;
  margin: 0;
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,0.1);
  appearance: none;
  color: #4a4a4a;
  line-height: 50px;
  text-transform: inherit;
  font-size: 1.2em;
  font-weight: 400;
  transition: all ease 0.3s;
  cursor: pointer;
  box-shadow: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: transparent;
  background-image: url("https://www.hundredrooms.com/img/ui-kit/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: 94% 50%;
  background-size: 16px;
}
.hundred-ui-select.-arrow-gray {
  background-image: url("https://www.hundredrooms.com/img/ui-kit/arrow-down-gray.svg");
}
.hundred-ui-select::-ms-expand {
  display: none;
}
.hundred-ui-select:hover {
  background-color: rgba(0,0,0,0.01);
  outline: none;
}
.services {
  width: 100%;
}
.services__title {
  color: #4a4a4a;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 30px;
}
.services__list {
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  padding: 0;
  flex-wrap: wrap;
}
.services__list__item,
.services__list__last-item {
  flex-shrink: 1;
  flex-basis: 25%;
  max-width: 25%;
  display: flex;
  align-items: center;
  margin-bottom: 45px;
  padding: 10px;
  box-sizing: border-box;
}
.services__list__item__container,
.services__list__last-item__container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.services__list__item__container__icon,
.services__list__last-item__container__icon {
  margin-right: 38px;
  display: inline-block;
  color: #808080;
  position: relative;
}
.services__list__item__container__icon:before,
.services__list__last-item__container__icon:before {
  font-size: 24px;
  position: absolute;
  display: block;
  line-height: normal;
  top: 50%;
  transform: translateY(-50%);
}
.services__list__item__container__text,
.services__list__last-item__container__text {
  color: #4a4a4a;
  font-size: 16px;
  letter-spacing: -0.25px;
  vertical-align: middle;
  display: inline-block;
  line-height: normal;
}
.services__list__last-item {
  color: #005fc9;
  font-size: 16px;
  letter-spacing: -0.25px;
  line-height: 25px;
  cursor: pointer;
  letter-spacing: -0.25px;
}
@media (max-width: 767px) {
  .services__title {
    margin-bottom: 0px;
  }
  .services__list__item,
  .services__list__last-item {
    padding: 6px;
  }
  .services__list__item__container,
  .services__list__last-item__container {
    display: flex;
    flex-direction: column;
  }
  .services__list__item__container__icon,
  .services__list__last-item__container__icon {
    margin-right: 38px;
    margin-bottom: 50px;
    margin: 0 auto;
    width: 30px;
    height: 30px;
    display: inline-block;
    color: #808080;
    position: relative;
  }
  .services__list__item__container__text,
  .services__list__last-item__container__text {
    color: #4a4a4a;
    font-size: 14px;
    letter-spacing: -0.25px;
    vertical-align: middle;
    display: inline-block;
    line-height: normal;
    text-align: center;
  }
  .services__list__last-item {
    text-align: center;
  }
}
.sharing-favourites {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  width: 100%;
}
.sharing-favourites__container {
  width: 100%;
  height: 70px;
  background-color: #f5f7f8;
  margin-top: 30px;
  display: flex;
}
.sharing-favourites__container__sharing,
.sharing-favourites__container__favourites {
  box-sizing: border-box;
  width: 50%;
  height: 100%;
}
.sharing-favourites__container__sharing {
  border-right: 0.5px solid #b0bec5;
  display: flex;
  justify-content: space-between;
  padding: 0 60px;
  align-items: center;
  color: #4a4a4a;
}
.sharing-favourites__container__sharing .hundredicons-hr-copylinkl-share-icon,
.sharing-favourites__container__sharing .hundredicons-hr-icon-facebook,
.sharing-favourites__container__sharing .hundredicons-whatsapp-icon-share,
.sharing-favourites__container__sharing .hundredicons-check {
  font-size: 20px;
  cursor: pointer;
}
.sharing-favourites__container__sharing .hundredicons-check {
  color: #97c93c;
  animation: fadein 2s;
}
.sharing-favourites__container__sharing .hundredicons-hr-copylinkl-share-icon {
  animation: enter 1s;
}
.sharing-favourites__container__sharing .hundredicons-hr-copylinkl-share-icon:hover,
.sharing-favourites__container__sharing .hundredicons-hr-icon-facebook:hover,
.sharing-favourites__container__sharing .hundredicons-whatsapp-icon-share:hover {
  color: #005fc9;
}
.sharing-favourites__container__favourites {
  font-size: 14px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 0 6vw;
  cursor: pointer;
}
.sharing-favourites__container__favourites .hundredicons-hr-icon-favorito,
.sharing-favourites__container__favourites .hundredicons-hr-icon-favorito-filled {
  width: 30px;
  display: flex;
  text-align: center;
  font-size: 22px;
  justify-content: center;
}
.sharing-favourites__container__favourites__text {
  width: 70%;
}
.sharing-favourites__container__favourites .hundredicons-hr-icon-favorito-filled {
  color: #ed4b82;
  animation: heartIn 0.6s ease-in-out;
}
.sharing-favourites__container__favourites .hundredicons-hr-icon-favorito:hover {
  color: #ed4b82;
}
@media (max-width: 767px) {
  .sharing-favourites__container {
    margin: 10px 0;
    height: 100%;
    flex-direction: column;
  }
  .sharing-favourites__container__sharing,
  .sharing-favourites__container__favourites {
    width: 100%;
  }
  .sharing-favourites__container__sharing {
    height: 5em;
    padding: 0px 15vw;
    border-bottom: 0.5px solid #b0bec5;
  }
  .sharing-favourites__container__favourites {
    height: 5em;
    padding-bottom: 5px;
    padding: 0px 30vw;
    justify-content: space-around;
  }
  .sharing-favourites__container__favourites .hundredicons-favorites,
  .sharing-favourites__container__favourites .hundredicons-favorites-filled {
    padding-right: 1em;
  }
  .sharing-favourites__container__favourites__text {
    width: 70%;
  }
}
@media (min-width: 768px) {
  .sharing-favourites__container__sharing .hundredicons-whatsapp-icon-share {
    display: none;
  }
}
@-moz-keyframes heartIn {
  from {
    transform: scale(0.3);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.7);
  }
  75% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes heartIn {
  from {
    transform: scale(0.3);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.7);
  }
  75% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}
@-o-keyframes heartIn {
  from {
    transform: scale(0.3);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.7);
  }
  75% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}
@keyframes heartIn {
  from {
    transform: scale(0.3);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.7);
  }
  75% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}
.steps {
  background-color: #dae8f7;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  padding-top: 280px;
  margin-top: -260px;
  padding-bottom: 20px;
}
.steps .steps__title,
.steps .steps__subtitle {
  display: none;
}
.steps .steps__list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  flex-direction: column;
}
.steps .steps__list__line {
  display: none;
}
.steps .steps__list__item {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  width: 90px;
  flex-direction: row;
  max-width: none;
  width: 100%;
  margin: 15px 0;
}
.steps .steps__list__item__image {
  background-color: #fff;
  border-radius: 50%;
  display: block;
  flex-shrink: 0;
  height: 46px;
  margin-bottom: 7px;
  overflow: hidden;
  text-align: center;
  width: 46px;
}
.steps .steps__list__item__image__first,
.steps .steps__list__item__image__second,
.steps .steps__list__item__image__third {
  background-image: url("https://static.100r.systems/img/pages/home/steps/steps-sprite-B.svg");
  background-repeat: no-repeat;
  background-size: 120px;
  display: block;
  height: 100%;
  width: 100%;
}
.steps .steps__list__item__image__first {
  background-position: 5px 8px;
}
.steps .steps__list__item__image__second {
  background-position: -35px 8px;
}
.steps .steps__list__item__image__third {
  background-position: -78px 8px;
}
.steps .steps__list__item__text {
  color: #4a4a4a;
  display: block;
  flex-shrink: 1;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
  max-width: unset;
  text-align: left;
  margin-left: 15px;
  height: auto;
}
.steps .steps__list__item__description {
  display: none;
}
.steps.home-version-new {
  padding-top: 280px;
  margin-top: -260px;
  min-height: 505px;
}
.steps.home-version-new .steps__list {
  flex-direction: column;
}
.steps.home-version-new .steps__list__item {
  width: 100%;
  flex-direction: row;
  margin: 15px 0;
}
.steps.home-version-new .steps__list__item__text {
  max-width: inherit;
  text-align: left;
  margin-left: 15px;
  height: auto;
}
.steps.home-version-new .steps__list__item__text .step-link {
  font-weight: 700;
}
.steps.home-version-new .steps__list__item__text .step-link:hover {
  color: #005fc9;
}
.steps.home-version-new .steps__list__item__image {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .steps {
    min-height: inherit;
    margin-top: -90px;
    padding-top: 200px;
  }
  .steps .steps__list__item {
    max-width: 300px;
    width: 33%;
    padding: 0 16px;
  }
  .steps .steps__list__item__image {
    width: 70px;
    height: 70px;
    margin-bottom: 30px;
  }
  .steps .steps__list__item__image__first,
  .steps .steps__list__item__image__second,
  .steps .steps__list__item__image__third {
    background-size: 200px;
  }
  .steps .steps__list__item__image__first {
    background-position: 4px 9px;
  }
  .steps .steps__list__item__image__second {
    background-position: -63px 9px;
  }
  .steps .steps__list__item__image__third {
    background-position: -133px 9px;
  }
  .steps .steps__list__item__text {
    font-size: 18px;
    letter-spacing: -0.25px;
    line-height: 24px;
    width: 100%;
    max-width: inherit;
    padding-bottom: 10px;
    height: auto;
  }
  .steps .steps__list__item__description {
    color: #4a4a4a;
    display: block;
    font-size: 16px;
    letter-spacing: -0.25px;
    height: 48px;
    line-height: 24px;
    max-width: 280px;
    text-align: center;
    width: 100%;
  }
  .steps.home-version-new {
    margin-top: -230px;
    display: block;
    background-color: #fff;
  }
  .steps.home-version-new .steps__list {
    flex-direction: row;
    max-width: inherit;
    margin: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .steps.home-version-new .steps__list__item {
    flex-direction: column;
    padding: 0 22px;
    width: 33%;
  }
  .steps.home-version-new .steps__list__item__text {
    text-align: center;
    font-size: 16px;
    letter-spacing: -0.25px;
    margin: 0;
  }
  .steps.home-version-new .steps__list__item__image {
    margin-bottom: 25px;
    border: 1px solid #ccdff4;
  }
}
.textarea {
  padding: 5px;
  display: block;
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,0.2);
  resize: none;
  font-weight: 300;
  font-size: 15px;
  letter-spacing: -0.25px;
  margin-bottom: 15px;
  color: #4a4a4a;
}
.textarea:focus {
  outline: none;
  transition: all ease 0.5s;
  border-width: 2px;
}
.toggle-switch__container__switch {
  position: relative;
  margin: 20px auto;
  height: 26px;
  width: 120px;
  background: #ccdff4;
  border-radius: 3px;
}
.toggle-switch__container__switch__label {
  position: relative;
  z-index: 2;
  float: left;
  width: 58px;
  line-height: 26px;
  font-size: 11px;
  letter-spacing: 0px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.toggle-switch__container__switch__label:after {
  content: none;
}
.toggle-switch__container__switch__label:before {
  content: none;
}
.toggle-switch__container__switch__label:active {
  font-weight: 700;
}
.toggle-switch__container__switch__label-night {
  padding-left: 2px;
}
.toggle-switch__container__switch__label-total {
  padding-right: 2px;
}
.toggle-switch__container__switch__input {
  display: none;
}
.toggle-switch__container__switch__input:checked+ [class*="__label"] {
  color: #005fc9;
  transition: 0.15s ease-out;
}
.toggle-switch__container__switch__input:checked+ [class*="__label-total"]~ [class*="__selection"] {
  left: 50%;
}
.toggle-switch__container__switch__selection {
  display: block;
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 2px;
  width: 58px;
  height: 22px;
  background: #fff;
  border-radius: 3px;
  transition: left 0.15s ease-out;
}
.tooltip-top,
.tooltip-right,
.tooltip-left,
.tooltip-bottom {
  position: absolute;
  z-index: 999999;
  display: block;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 16px;
  text-align: center;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 13px;
  border: none;
  animation-name: tooltip-appear;
  animation-duration: 0.2s;
  animation-timing-function: linear;
}
@media (max-width: 767px) {
  .tooltip-top,
  .tooltip-right,
  .tooltip-left,
  .tooltip-bottom {
    display: none;
  }
}
.tooltip-top .not-showing-tooltip,
.tooltip-right .not-showing-tooltip,
.tooltip-left .not-showing-tooltip,
.tooltip-bottom .not-showing-tooltip {
  display: none;
}
.tooltip-top .tooltip-inner,
.tooltip-right .tooltip-inner,
.tooltip-left .tooltip-inner,
.tooltip-bottom .tooltip-inner {
  max-width: 160px;
  padding: 10px 10px;
  color: #fff;
  text-align: center;
  background-color: rgba(1,42,54,0.95);
  border-radius: 6px;
}
.tooltip-top .tooltip-arrow,
.tooltip-right .tooltip-arrow,
.tooltip-left .tooltip-arrow,
.tooltip-bottom .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip-top {
  margin-top: -3px;
  padding: 5px 0;
}
.tooltip-top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: rgba(1,42,54,0.95);
}
.tooltip-right {
  margin-left: 3px;
  padding: 0 5px;
}
.tooltip-right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: rgba(1,42,54,0.95);
}
.tooltip-bottom {
  margin-top: 3px;
  padding: 5px 0;
}
.tooltip-bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: rgba(1,42,54,0.95);
}
.tooltip-left {
  margin-left: -3px;
  padding: 0 5px;
}
.tooltip-left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: rgba(1,42,54,0.95);
}
@-webkit-keyframes tooltip-bounce {
  from {
    opacity: 0;
    transform: scale(0, 0);
  }
  70% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}
@-webkit-keyframes tooltip-appear {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
.valoration {
  height: auto;
  margin-right: 5px;
}
.valoration__data-container {
  color: #fff;
  background-color: #353a8e;
  border-radius: 3px;
  width: 130%;
  height: 18px;
  text-align: center;
  font-weight: 600;
}
.valoration__data-container__text {
  line-height: 20px;
  display: block;
}
.valoration__data-container__tooltip .tooltip-arrow {
  left: 20%;
}
.valoration__data-container.no-tooltip:hover:after,
.valoration__data-container.no-tooltip:hover:before {
  display: none;
}
.a-without-results-message {
  width: 100%;
  max-width: 400px;
  min-width: 300px;
  margin: auto;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}
.a-without-results-message__icon {
  font-size: 100px;
  color: #ccdff4;
}
.a-without-results-message__title {
  margin: 30px auto 20px auto;
  color: #4a4a4a;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
}
.a-without-results-message__text {
  margin: 0 auto;
  text-align: center;
  color: #4a4a4a;
  font-size: 15px;
  letter-spacing: -0.25px;
  font-weight: 300;
  line-height: 1.5;
}
.establishment-card {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.04), 0 1px 2px rgba(0,0,0,0.06);
  contain: strict;
  cursor: default;
  display: block;
  display: inline-block;
  font-family: 'San Francisco', -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  height: 371px;
  margin: 10px auto;
  max-width: 600px;
  min-width: 320px;
  position: relative;
  user-select: none;
  vertical-align: top;
  width: 100%;
}
.establishment-card__top {
  height: 211px;
  position: relative;
}
.establishment-card__top .react-carousel-dots-holder {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.establishment-card__top .react-carousel-dots-holder .react-carousel-dots-dot {
  border: none;
  height: 6px;
  width: 6px;
  transition: all 0.2s ease;
}
.establishment-card__top .react-carousel-dots-holder .active {
  background-color: #fff;
  height: 8px;
  width: 8px;
}
.establishment-card__top .react-carousel-dots-holder .dot-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  margin-left: 2px;
}
.establishment-card__top__recomendation {
  align-items: center;
  background-color: #fff;
  border-radius: 0 3px 3px 0;
  display: flex;
  height: 18px;
  justify-content: center;
  position: absolute;
  top: 13px;
  width: auto;
  z-index: 2;
}
.establishment-card__top__recomendation__container {
  display: block;
  line-height: 23px;
}
.establishment-card__top__recomendation__container .hundredicons-recomended-icon {
  position: relative;
  top: 2px;
}
.establishment-card__top__recomendation__container .hundredicons-recomended-icon:before {
  font-size: 18px;
  letter-spacing: -0.25px;
}
.establishment-card__top__recomendation:hover:before,
.establishment-card__top__recomendation:hover:after {
  top: inherit;
  bottom: inherit;
}
.establishment-card__top__recomendation__background {
  color: #d85cfc;
  display: inline-block;
  font-size: 22px;
  height: 22px;
  left: 5px;
  padding-top: 4px;
  position: absolute;
  top: 0;
}
.establishment-card__top__recomendation__background:before {
  font-size: 20px;
}
.establishment-card__top__recomendation__text {
  color: #4a4a4a;
  font-size: 12px;
  letter-spacing: -0.29px;
  font-weight: 400;
  left: 0;
  line-height: 12px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}
.establishment-card__top__recomendation__tooltip .tooltip-arrow {
  left: 9%;
}
.establishment-card__top__carrousel .carrousel__arrow-left,
.establishment-card__top__carrousel .carrousel__arrow-right,
.establishment-card__top__carrousel .carrousel__innocuos-container,
.establishment-card__top__carrousel .carrousel__container,
.establishment-card__top__carrousel .carrousel__container__element,
.establishment-card__top__carrousel .carrousel__container__overlayer {
  height: 211px;
}
.establishment-card__top__carrousel__image {
  background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 100%);
  background: -moz-linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 100%);
  background: -ms-linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 100%);
  background: -o-linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 100%);
  background: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 211px;
  width: 100%;
}
.establishment-card__top__favourite {
  opacity: 0;
  transition: opacity 0.1s;
  align-items: flex-end;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 35px;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 50px;
  z-index: 2;
}
.establishment-card__top__favourite__icon {
  color: #fff;
  font-size: 28px;
  height: 28px;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}
.establishment-card__top__favourite .hundredicons-hr-icon-favorito-filled {
  animation: heartIn 0.6s ease-in-out;
}
.establishment-card__top__envelopeButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  cursor: pointer;
}
.establishment-card__top__envelopeButtons .establishment-card__top__discount {
  position: absolute;
  left: auto;
  right: 0;
  bottom: 0px;
  background-color: #fff;
  border-radius: 3px 0 0 0;
  height: 27px;
  max-width: 70px;
  display: flex;
  justify-content: center;
  padding: 0 0.5em;
  z-index: 2;
}
.establishment-card__top__envelopeButtons .establishment-card__top__discount__text {
  align-items: center;
  display: flex;
}
.establishment-card__top__envelopeButtons .establishment-card__top__discount__text__number {
  line-height: 1em;
  margin-right: 0;
  line-height: 15px;
  font-size: 16px;
  letter-spacing: -0.25px;
  color: #ba124b;
  font-weight: normal;
  text-decoration: line-through;
}
.establishment-card__top__envelopeButtons .establishment-card__top__discount__text__number.-ja-jp,
.establishment-card__top__envelopeButtons .establishment-card__top__discount__text__number.-es-co {
  font-size: 15px;
  letter-spacing: -0.25px;
}
.establishment-card__top .carrousel__innocuos-container,
.establishment-card__top .establishment-card__top__carrousel,
.establishment-card__top .carrousel__container,
.establishment-card__top .carrousel__container__element,
.establishment-card__top .establishment-card__top__carrousel__image,
.establishment-card__top .carrousel__arrow-left,
.establishment-card__top .carrousel__arrow-right {
  height: 174px;
}
.establishment-card__top .carrousel__arrow-container {
  opacity: 0;
}
.establishment-card__top:hover .carrousel__arrow-container,
.establishment-card__top:hover .establishment-card__top__favourite {
  opacity: 1;
}
.establishment-card__top .-favourite-heart-opacity {
  opacity: 1;
}
.establishment-card__body {
  display: flex;
  box-sizing: border-box;
  height: 160px;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 133px;
  padding: 10px 10px 0;
}
.establishment-card__body__top {
  align-items: flex-start;
  display: flex;
  flex-basis: 100%;
  height: 30px;
  justify-content: space-between;
}
.establishment-card__body__top__discount__tooltip .tooltip-arrow {
  left: 88%;
}
.establishment-card__body__top__airbnb-title {
  color: #4a4a4a;
  font-weight: 500px;
  line-height: 22px;
  font-size: 15px;
  letter-spacing: -0.25px;
}
.establishment-card__body__top__airbnb-subtitle {
  color: rgba(0,0,0,0.4);
  font-size: 15px;
  letter-spacing: -0.25px;
  line-height: 21px;
  text-align: left;
  width: 130px;
}
.establishment-card__body__top__title {
  font-size: 15px;
  letter-spacing: -0.25px;
  font-weight: 500;
  line-height: 15px;
}
.establishment-card__body__top__title__truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 5px;
  text-align: left;
}
.establishment-card__body__top__title__no-truncate {
  display: flex;
  cursor: pointer;
  font-size: 12px;
  letter-spacing: -0.29px;
  align-self: flex-start;
  display: flex;
  margin-bottom: 2px;
  text-align: left;
}
.establishment-card__body__top__features {
  display: inline-block;
  cursor: pointer;
  text-transform: lowercase;
}
.establishment-card__body__top__features__capacity,
.establishment-card__body__top__features__rooms,
.establishment-card__body__top__features__dot {
  font-size: 15px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
}
.establishment-card__body__top__container {
  display: inline-block;
  text-align: left;
  color: #4a4a4a;
}
.establishment-card__body__top__container__type__info {
  font-weight: 600;
}
.establishment-card__body__top__container__type__info__stars-container {
  font-size: 8px;
  margin-left: 5px;
  letter-spacing: 3px;
}
.establishment-card__body__top__container__type__info__name {
  font-size: 12px;
  letter-spacing: -0.29px;
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 195px;
}
.establishment-card__body__top__container__type__info__type {
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0px;
  letter-spacing: -0.25px;
  font-weight: 700;
}
.establishment-card__body__top__price-container {
  display: flex;
  margin-top: -2px;
}
.establishment-card__body__top__price-container__price {
  color: #4a4a4a;
  font-size: 18px;
  letter-spacing: -0.25px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 16px;
  margin-top: 5px;
}
.establishment-card__body__top__price-container__price.-is-test.-ja-jp,
.establishment-card__body__top__price-container__price.-is-test.-es-co {
  font-size: 16px;
  letter-spacing: -0.25px;
}
.establishment-card__body__top__price-container__bolt {
  color: #fdd835;
  font-size: 18px;
  letter-spacing: -0.25px;
  vertical-align: top;
  margin-right: 5px;
}
.establishment-card__body__left,
.establishment-card__body__right {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.establishment-card__body__left {
  height: 85px;
  justify-content: space-between;
  min-width: 15px;
  overflow: hidden;
  padding-top: 0;
  width: auto;
}
.establishment-card__body__left__tooltip__instant-book .tooltip-arrow {
  left: 8%;
}
.establishment-card__body__left__tooltip__cancellation .tooltip-arrow {
  left: 8%;
}
.establishment-card__body__left__attributes {
  text-align: left;
  color: #4a4a4a;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  font-size: 15px;
  letter-spacing: -0.25px;
  margin-top: 5px;
}
.establishment-card__body__left__attributes__container {
  display: inline-block;
}
.establishment-card__body__left__attributes__valoration .valoration__data-container {
  background-color: transparent;
  color: #353a8e;
  font-weight: 700;
  padding-right: 0px;
  letter-spacing: -0.5px;
  cursor: pointer;
  width: 100%;
  min-width: 19px;
}
.establishment-card__body__left__attributes__valoration__tooltip .tooltip-arrow {
  left: 11%;
}
.establishment-card__body__left__attributes__ammenities__area,
.establishment-card__body__left__attributes__ammenities__people,
.establishment-card__body__left__attributes__ammenities__rooms {
  padding-right: 7px;
}
.establishment-card__body__left__attributes__ammenities__area__icon,
.establishment-card__body__left__attributes__ammenities__people__icon,
.establishment-card__body__left__attributes__ammenities__rooms__icon {
  font-size: 13px;
  padding-right: 0;
}
.establishment-card__body__left__ranking {
  margin-bottom: 11px;
  position: relative;
  bottom: 0;
  align-items: flex-end;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
}
.establishment-card__body__left__ranking:hover:before,
.establishment-card__body__left__ranking:hover:after {
  top: inherit;
  bottom: inherit;
}
.establishment-card__body__left__ranking__text {
  color: #4a4a4a;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 14px;
  overflow-y: visible;
  text-align: left;
  width: 100%;
}
.establishment-card__body__left__ranking__text__truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.establishment-card__body__left__ranking__text__no-truncate {
  font-size: 12px;
  letter-spacing: -0.29px;
}
.establishment-card__body__left__ranking__text__number {
  color: #818496;
}
.establishment-card__body__left__ranking__icon {
  font-size: 22px;
  height: 22px;
  line-height: 22px;
  width: 27px;
}
.establishment-card__body__left__ranking__icon:before {
  position: absolute;
  display: block;
  margin-top: 4px;
}
.establishment-card__body__left__tooltip .tooltip-arrow {
  left: 9%;
}
.establishment-card__body__left__extra-features {
  align-items: baseline;
  color: #8b8b8b;
  cursor: pointer;
  display: block;
  flex-direction: column;
  font-size: 15px;
  letter-spacing: -0.25px;
  height: 60%;
  justify-content: space-between;
  margin-left: -2px;
  margin-top: -2px;
  padding: 1px 0 1%;
  text-align: left;
}
.establishment-card__body__left__extra-features__instant-book .hundredicons-instantbook:before {
  background-position: center;
  margin-right: 5px;
  padding: 0;
}
.establishment-card__body__left__extra-features__cancellation .hundredicons-cancellation:before {
  margin-left: 0 !important;
  padding: 0;
  height: 15px;
  width: 15px;
  margin-right: 5px !important;
}
.establishment-card__body__left__extra-features__instant-book__text,
.establishment-card__body__left__extra-features__cancellation__text {
  color: #8b8b8b;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 17px;
}
.establishment-card__body__left__hundredrating {
  line-height: 12px;
  min-height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.establishment-card__body__left__hundredrating__tooltip .tooltip-arrow {
  left: 11%;
}
.establishment-card__body__left__hundredrating__rating,
.establishment-card__body__left__hundredrating__info {
  display: flex;
}
.establishment-card__body__left__hundredrating__rating {
  color: #4a4a4a;
  font-size: 16px;
  letter-spacing: -0.25px;
  font-weight: 600;
  position: relative;
}
.establishment-card__body__left__hundredrating__rating:empty {
  display: none;
}
.establishment-card__body__left__hundredrating__info__info-right {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.establishment-card__body__left__hundredrating__info__separator-mobile {
  height: 18px;
  border-right: 1px solid #b3b3b3;
  position: relative;
  margin: 0 5px 0 5px;
  display: inline;
}
.establishment-card__body__left__hundredrating__info__separator-desktop {
  display: none;
}
.establishment-card__body__left__hundredrating__info__adjective-mobile,
.establishment-card__body__left__hundredrating__info__comments {
  font-size: 15px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
}
.establishment-card__body__left__hundredrating__info__adjective-mobile__dot {
  padding: 0 3px;
}
.establishment-card__body__left__hundredrating__info__adjective-mobile__dot:empty {
  padding: 0;
}
.establishment-card__body__left__hundredrating__info__adjective-desktop {
  display: none;
}
.establishment-card__body__left__hundredrating:empty {
  display: none;
}
.establishment-card__body__left__top__features {
  color: #8b8b8b;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 17px;
  min-height: 17px;
}
.establishment-card__body__right {
  align-items: flex-end;
  cursor: pointer;
  flex-basis: auto;
  font-size: 12px;
  letter-spacing: -0.29px;
  justify-content: space-between;
  line-height: 18px;
  min-height: 60px;
  width: auto;
}
.establishment-card__body__right__numb-partners {
  color: #808080;
  letter-spacing: -0.5px;
  margin-bottom: 6px;
  font-size: 15px;
  letter-spacing: -0.25px;
}
.establishment-card__body__right__partner {
  display: flex;
  justify-content: flex-end;
  height: 30px;
  margin-bottom: -10px;
  margin-top: -5px;
  max-width: 100px;
  min-width: 82px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.establishment-card__body__right__partner__logo {
  color: #808080;
  letter-spacing: -0.5px;
  margin-bottom: 0px;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.establishment-card__body__right__partner__logo__image {
  position: absolute;
  height: auto;
  width: 297px;
}
.establishment-card__body__right__partner .logo-container_2 {
  width: 100px;
}
.establishment-card__body__right__partner .logo-container_2 img {
  top: -131px;
}
.establishment-card__body__right__partner .logo-container_3 {
  width: 57px;
}
.establishment-card__body__right__partner .logo-container_3 img {
  top: -216px;
}
.establishment-card__body__right__partner .logo-container_4 {
  width: 94px;
}
.establishment-card__body__right__partner .logo-container_4 img {
  top: -298px;
}
.establishment-card__body__right__partner .logo-container_5 {
  width: 90px;
}
.establishment-card__body__right__partner .logo-container_5 img {
  top: -380px;
}
.establishment-card__body__right__partner .logo-container_6 {
  width: 30px;
}
.establishment-card__body__right__partner .logo-container_6 img {
  top: -464px;
}
.establishment-card__body__right__partner .logo-container_7 {
  width: 92px;
}
.establishment-card__body__right__partner .logo-container_7 img {
  top: -545px;
}
.establishment-card__body__right__partner .logo-container_8 {
  width: 100px;
}
.establishment-card__body__right__partner .logo-container_8 img {
  top: -631px;
}
.establishment-card__body__right__partner .logo-container_9 {
  width: 87px;
}
.establishment-card__body__right__partner .logo-container_9 img {
  top: -713px;
}
.establishment-card__body__right__partner .logo-container_10 {
  width: 87px;
}
.establishment-card__body__right__partner .logo-container_10 img {
  top: -797px;
}
.establishment-card__body__right__partner .logo-container_11 {
  width: 91px;
}
.establishment-card__body__right__partner .logo-container_11 img {
  top: -880px;
}
.establishment-card__body__right__partner .logo-container_12 {
  width: 99px;
}
.establishment-card__body__right__partner .logo-container_12 img {
  top: -963px;
}
.establishment-card__body__right__partner .logo-container_12_fr {
  width: 80px;
}
.establishment-card__body__right__partner .logo-container_12_fr img {
  left: -98px;
  top: -963px;
}
.establishment-card__body__right__partner .logo-container_12_de {
  width: 95px;
}
.establishment-card__body__right__partner .logo-container_12_de img {
  left: -197px;
  top: -963px;
}
.establishment-card__body__right__partner .logo-container_13 {
  width: 63px;
}
.establishment-card__body__right__partner .logo-container_13 img {
  top: -1045px;
}
.establishment-card__body__right__partner .logo-container_14 {
  width: 90px;
}
.establishment-card__body__right__partner .logo-container_14 img {
  top: -1127px;
}
.establishment-card__body__right__partner .logo-container_15 {
  width: 70px;
}
.establishment-card__body__right__partner .logo-container_15 img {
  top: -1211px;
}
.establishment-card__body__right__partner .logo-container_16 {
  width: 83px;
}
.establishment-card__body__right__partner .logo-container_16 img {
  top: -1291px;
}
.establishment-card__body__right__partner .logo-container_17 {
  width: 96px;
}
.establishment-card__body__right__partner .logo-container_17 img {
  top: -1373px;
}
.establishment-card__body__right__partner .logo-container_19 {
  width: 74px;
}
.establishment-card__body__right__partner .logo-container_19 img {
  top: -1544px;
}
.establishment-card__body__right__partner .logo-container_20 {
  width: 69px;
}
.establishment-card__body__right__partner .logo-container_20 img {
  top: -1625px;
}
.establishment-card__body__right__partner .logo-container_21 {
  width: 95px;
}
.establishment-card__body__right__partner .logo-container_21 img {
  top: -1710px;
}
.establishment-card__body__right__partner .logo-container_22 {
  width: 86px;
}
.establishment-card__body__right__partner .logo-container_22 img {
  top: -1792px;
}
.establishment-card__body__right__partner .logo-container_23 {
  width: 87px;
}
.establishment-card__body__right__partner .logo-container_23 img {
  top: -1876px;
}
.establishment-card__body__right__partner .logo-container_24 {
  width: 89px;
}
.establishment-card__body__right__partner .logo-container_24 img {
  top: -1960px;
}
.establishment-card__body__right__partner .logo-container_25 {
  width: 85px;
}
.establishment-card__body__right__partner .logo-container_25 img {
  top: -2042px;
}
.establishment-card__body__right__partner .logo-container_26 {
  width: 79px;
}
.establishment-card__body__right__partner .logo-container_26 img {
  top: -2126px;
}
.establishment-card__body__right__partner .logo-container_27 {
  width: 83px;
}
.establishment-card__body__right__partner .logo-container_27 img {
  top: -2210px;
}
.establishment-card__body__right__partner .logo-container_29 {
  width: 80px;
}
.establishment-card__body__right__partner .logo-container_29 img {
  top: -2377px;
}
.establishment-card__body__right__partner .logo-container_30 {
  width: 82px;
}
.establishment-card__body__right__partner .logo-container_30 img {
  top: -2458px;
}
.establishment-card__body__right__partner .logo-container_31 {
  width: 67px;
}
.establishment-card__body__right__partner .logo-container_31 img {
  top: -2542px;
}
.establishment-card__body__right__partner .logo-container_33 {
  width: 83px;
}
.establishment-card__body__right__partner .logo-container_33 img {
  top: -2708px;
}
.establishment-card__body__right__partner .logo-container_35 {
  width: 89px;
}
.establishment-card__body__right__partner .logo-container_35 img {
  top: -2873px;
}
.establishment-card__body__right__partner .logo-container_36 {
  width: 91px;
}
.establishment-card__body__right__partner .logo-container_36 img {
  top: -2957px;
}
.establishment-card__body__right__partner .logo-container_37 {
  width: 100px;
}
.establishment-card__body__right__partner .logo-container_37 img {
  top: -3040px;
}
.establishment-card__body__right__partner .logo-container_38 {
  width: 100px;
}
.establishment-card__body__right__partner .logo-container_38 img {
  top: -3122px;
}
.establishment-card__body__right__partner .logo-container_39 {
  width: 91px;
}
.establishment-card__body__right__partner .logo-container_39 img {
  top: -3206px;
}
.establishment-card__body__right__button {
  align-items: center;
  border-radius: 3px;
  color: #005fc9;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  margin-bottom: 2px;
  margin-right: -8px;
}
.establishment-card__body__right__button__text {
  text-align: right;
  font-size: 15px;
  letter-spacing: -0.25px;
}
.establishment-card__body__right__button__icon {
  padding-left: 2.5px;
  font-size: 10px;
  letter-spacing: 0px;
  height: 10px;
  line-height: 10px;
}
@media (orientation: landscape) {
  .establishment-card {
    height: 300px;
    width: 300px;
    margin: 10px 5px;
  }
  .establishment-card__top {
    height: 211px;
  }
  .establishment-card__top__carrousel .carrousel__innocuous-container,
  .establishment-card__top__carrousel .carrousel__container,
  .establishment-card__top__carrousel .carrousel__container__element {
    height: 211px;
  }
  .establishment-card__top__carrousel__image {
    height: 211px;
  }
}
@media (max-width: 413px) {
  .establishment-card .establishment-card__body__left__hundredrating {
    width: 200px;
    white-space: nowrap;
  }
  .establishment-card .establishment-card__body__left__hundredrating__info {
    overflow: hidden;
  }
  .establishment-card .establishment-card__body__left__hundredrating__info__info-right {
    overflow: hidden;
  }
  .establishment-card .establishment-card__body__left__hundredrating__info__comments {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .establishment-card .establishment-card__top {
    height: 211px;
  }
  .establishment-card .establishment-card__top .carrousel__innocuos-container,
  .establishment-card .establishment-card__top .establishment-card__top__carrousel,
  .establishment-card .establishment-card__top .carrousel__container,
  .establishment-card .establishment-card__top .carrousel__container__element,
  .establishment-card .establishment-card__top .establishment-card__top__carrousel__image {
    height: 211px;
  }
  .establishment-card .establishment-card__top .carrousel__arrow-left,
  .establishment-card .establishment-card__top .carrousel__arrow-right {
    display: none;
  }
  .establishment-card .establishment-card__top__favourite {
    opacity: 1;
  }
  .establishment-card .establishment-card__top__recomendation {
    padding: 5px 0;
  }
  .establishment-card .establishment-card__top__recomendation__text {
    font-size: 15px;
    letter-spacing: -0.25px;
  }
  .establishment-card .establishment-card__top__discount {
    padding-right: 12px;
  }
  .establishment-card .establishment-card__top__discount__text__number {
    font-size: 15px;
    letter-spacing: -0.25px;
    font-weight: 400;
  }
  .establishment-card .establishment-card__body {
    height: 160px;
    padding: 15px 10px 0;
    padding-right: 5px;
  }
  .establishment-card .establishment-card__body__right {
    padding-top: 0px;
  }
  .establishment-card .establishment-card__body__right__numb-partners {
    font-size: 15px;
    letter-spacing: -0.25px;
  }
  .establishment-card .establishment-card__body__right__partner {
    margin-bottom: -5px;
    margin-top: 0;
  }
  .establishment-card .establishment-card__body__right__button {
    margin-bottom: 3px;
    margin-right: -3px;
    justify-content: center;
    color: #fff;
    background-color: #005fc9;
    width: 100px;
    height: 45px;
    align-self: flex-end;
  }
  .establishment-card .establishment-card__body__right__button__text {
    font-size: 16px;
    letter-spacing: -0.25px;
    text-align: center;
  }
  .establishment-card .establishment-card__body__right__button__icon {
    display: none;
  }
  .establishment-card .establishment-card__body__left {
    height: 110px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .establishment-card .establishment-card__body__left__top__features {
    font-size: 15px;
    letter-spacing: -0.25px;
    line-height: 23px;
    padding-left: 5px;
  }
  .establishment-card .establishment-card__body__left__extra-features__instant-book__text,
  .establishment-card .establishment-card__body__left__extra-features__cancellation__text {
    font-size: 15px;
    letter-spacing: -0.25px;
    line-height: 23px;
  }
  .establishment-card .establishment-card__body__top__container__type__info__type {
    font-size: 12px;
    letter-spacing: -0.29px;
  }
  .establishment-card .establishment-card__body__top__container__type__info__name {
    font-size: 15px;
    letter-spacing: -0.25px;
    max-width: calc(100vw - 115px);
  }
}
@media (min-width: 768px) {
  .establishment-card {
    border-radius: 0;
    height: 307px;
    margin: 10px;
    position: relative;
    min-width: 300px;
    width: 300px;
  }
  .establishment-card__top {
    height: 174px;
  }
  .establishment-card__top__carrousel .carrousel__arrow-left,
  .establishment-card__top__carrousel .carrousel__arrow-right,
  .establishment-card__top__carrousel .carrousel__innocuos-container,
  .establishment-card__top__carrousel .carrousel__container,
  .establishment-card__top__carrousel .carrousel__container__element {
    height: 174px;
  }
  .establishment-card__top__carrousel__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 174px;
  }
  .establishment-card__top__favourite {
    top: 0px;
    right: 5px;
  }
  .establishment-card__top__favourite__icon {
    font-size: 25px;
    height: 25px;
  }
  .establishment-card__top__recomendation {
    height: 27px;
  }
  .establishment-card__top__recomendation__text {
    line-height: 29px;
  }
  .establishment-card__top__recomendation__tooltip .tooltip-arrow {
    left: 14%;
  }
  .establishment-card__top__envelopeButtons .establishment-card__top__discount__text__number.-ja-jp,
  .establishment-card__top__envelopeButtons .establishment-card__top__discount__text__number.-es-co {
    font-size: 14px;
    letter-spacing: -0.25px;
  }
  .establishment-card__body {
    height: 133px;
    padding: 10px 10px 0;
  }
  .establishment-card__body__top {
    height: 30px;
  }
  .establishment-card__body__top__price-container__price {
    font-size: 18px;
    letter-spacing: -0.25px;
  }
  .establishment-card__body__left {
    padding-bottom: 0px;
    padding-top: 0px;
    width: 163px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .establishment-card__body__left__extra-features {
    margin-left: 0px;
  }
  .establishment-card__body__left__extra-features__instant-book {
    font-size: 12px;
    letter-spacing: -0.29px;
    color: #8b8b8b;
  }
  .establishment-card__body__left__extra-features__instant-book .hundredicons-instantbook:before {
    background-position: left;
    margin-right: 3px;
  }
  .establishment-card__body__left__extra-features__cancellation {
    font-size: 12px;
    letter-spacing: -0.29px;
    color: #8b8b8b;
    margin-left: -2px;
  }
  .establishment-card__body__left__hundredrating {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 29px;
  }
  .establishment-card__body__left__hundredrating__tooltip .tooltip-arrow {
    left: 12%;
  }
  .establishment-card__body__left__hundredrating__rating,
  .establishment-card__body__left__hundredrating__info {
    display: flex;
  }
  .establishment-card__body__left__hundredrating__rating {
    color: #4a4a4a;
    font-size: 16px;
    letter-spacing: -0.25px;
    font-weight: 600;
  }
  .establishment-card__body__left__hundredrating__info__separator-desktop {
    height: 25px;
    border-right: 1px solid #b3b3b3;
    position: relative;
    margin: 0 5px 0 5px;
    display: inline;
  }
  .establishment-card__body__left__hundredrating__info__separator-mobile {
    display: none;
  }
  .establishment-card__body__left__hundredrating__info__adjective-desktop,
  .establishment-card__body__left__hundredrating__info__comments {
    font-size: 12px;
    letter-spacing: -0.29px;
    color: #4a4a4a;
    display: block;
  }
  .establishment-card__body__left__hundredrating__info__comments {
    margin-top: 2px;
  }
  .establishment-card__body__left__hundredrating__info__adjective-mobile {
    display: none;
  }
  .establishment-card__body__right__partner__logo {
    font-size: 12px;
    letter-spacing: -0.29px;
    line-height: 15px;
    margin-bottom: 0;
  }
  .establishment-card__body__right__partner__logo__image {
    width: 242px;
  }
  .establishment-card__body__right__partner .logo-container_2 {
    width: 82px;
  }
  .establishment-card__body__right__partner .logo-container_2 img {
    top: -103px;
  }
  .establishment-card__body__right__partner .logo-container_3 {
    width: 48px;
  }
  .establishment-card__body__right__partner .logo-container_3 img {
    top: -173px;
  }
  .establishment-card__body__right__partner .logo-container_4 {
    width: 78px;
  }
  .establishment-card__body__right__partner .logo-container_4 img {
    top: -239px;
  }
  .establishment-card__body__right__partner .logo-container_5 {
    width: 74px;
  }
  .establishment-card__body__right__partner .logo-container_5 img {
    top: -307px;
  }
  .establishment-card__body__right__partner .logo-container_6 {
    width: 26px;
  }
  .establishment-card__body__right__partner .logo-container_6 img {
    top: -377px;
  }
  .establishment-card__body__right__partner .logo-container_7 {
    width: 76px;
  }
  .establishment-card__body__right__partner .logo-container_7 img {
    top: -441px;
  }
  .establishment-card__body__right__partner .logo-container_8 {
    width: 81px;
  }
  .establishment-card__body__right__partner .logo-container_8 img {
    top: -511px;
  }
  .establishment-card__body__right__partner .logo-container_9 {
    width: 73px;
  }
  .establishment-card__body__right__partner .logo-container_9 img {
    top: -577px;
  }
  .establishment-card__body__right__partner .logo-container_10 {
    width: 71px;
  }
  .establishment-card__body__right__partner .logo-container_10 img {
    top: -647px;
  }
  .establishment-card__body__right__partner .logo-container_11 {
    width: 74px;
  }
  .establishment-card__body__right__partner .logo-container_11 img {
    top: -713px;
  }
  .establishment-card__body__right__partner .logo-container_12 {
    width: 81px;
  }
  .establishment-card__body__right__partner .logo-container_12 img {
    top: -782px;
  }
  .establishment-card__body__right__partner .logo-container_12_fr {
    width: 66px;
  }
  .establishment-card__body__right__partner .logo-container_12_fr img {
    top: -782px;
    left: -80px;
  }
  .establishment-card__body__right__partner .logo-container_12_de {
    width: 77px;
  }
  .establishment-card__body__right__partner .logo-container_12_de img {
    top: -782px;
    left: -161px;
  }
  .establishment-card__body__right__partner .logo-container_13 {
    width: 53px;
  }
  .establishment-card__body__right__partner .logo-container_13 img {
    top: -849px;
  }
  .establishment-card__body__right__partner .logo-container_14 {
    width: 74px;
  }
  .establishment-card__body__right__partner .logo-container_14 img {
    top: -915px;
  }
  .establishment-card__body__right__partner .logo-container_15 {
    width: 58px;
  }
  .establishment-card__body__right__partner .logo-container_15 img {
    top: -987px;
  }
  .establishment-card__body__right__partner .logo-container_16 {
    width: 68px;
  }
  .establishment-card__body__right__partner .logo-container_16 img {
    top: -1051px;
  }
  .establishment-card__body__right__partner .logo-container_17 {
    width: 79px;
  }
  .establishment-card__body__right__partner .logo-container_17 img {
    top: -1120px;
  }
  .establishment-card__body__right__partner .logo-container_19 {
    width: 61px;
  }
  .establishment-card__body__right__partner .logo-container_19 img {
    top: -1257px;
  }
  .establishment-card__body__right__partner .logo-container_20 {
    width: 57px;
  }
  .establishment-card__body__right__partner .logo-container_20 img {
    top: -1322px;
  }
  .establishment-card__body__right__partner .logo-container_21 {
    width: 77px;
  }
  .establishment-card__body__right__partner .logo-container_21 img {
    top: -1391px;
  }
  .establishment-card__body__right__partner .logo-container_22 {
    width: 70px;
  }
  .establishment-card__body__right__partner .logo-container_22 img {
    top: -1457px;
  }
  .establishment-card__body__right__partner .logo-container_23 {
    width: 71px;
  }
  .establishment-card__body__right__partner .logo-container_23 img {
    top: -1525px;
  }
  .establishment-card__body__right__partner .logo-container_24 {
    width: 73px;
  }
  .establishment-card__body__right__partner .logo-container_24 img {
    top: -1595px;
  }
  .establishment-card__body__right__partner .logo-container_25 {
    width: 70px;
  }
  .establishment-card__body__right__partner .logo-container_25 img {
    top: -1662px;
  }
  .establishment-card__body__right__partner .logo-container_26 {
    width: 65px;
  }
  .establishment-card__body__right__partner .logo-container_26 img {
    top: -1729px;
  }
  .establishment-card__body__right__partner .logo-container_27 {
    width: 68px;
  }
  .establishment-card__body__right__partner .logo-container_27 img {
    top: -1797px;
  }
  .establishment-card__body__right__partner .logo-container_29 {
    width: 55px;
  }
  .establishment-card__body__right__partner .logo-container_29 img {
    top: -1934px;
  }
  .establishment-card__body__right__partner .logo-container_30 {
    width: 68px;
  }
  .establishment-card__body__right__partner .logo-container_30 img {
    top: -1999px;
  }
  .establishment-card__body__right__partner .logo-container_31 {
    width: 55px;
  }
  .establishment-card__body__right__partner .logo-container_31 img {
    top: -2069px;
  }
  .establishment-card__body__right__partner .logo-container_33 {
    width: 68px;
  }
  .establishment-card__body__right__partner .logo-container_33 img {
    top: -2206px;
  }
  .establishment-card__body__right__partner .logo-container_35 {
    width: 73px;
  }
  .establishment-card__body__right__partner .logo-container_35 img {
    top: -2340px;
  }
  .establishment-card__body__right__partner .logo-container_36 {
    width: 75px;
  }
  .establishment-card__body__right__partner .logo-container_36 img {
    top: -2407px;
  }
  .establishment-card__body__right__partner .logo-container_37 {
    width: 82px;
  }
  .establishment-card__body__right__partner .logo-container_37 img {
    top: -2474px;
  }
  .establishment-card__body__right__partner .logo-container_38 {
    width: 82px;
  }
  .establishment-card__body__right__partner .logo-container_38 img {
    top: -2541px;
  }
  .establishment-card__body__right__partner .logo-container_39 {
    width: 75px;
  }
  .establishment-card__body__right__partner .logo-container_39 img {
    top: -2611px;
  }
  .establishment-card__body__right__numb-partners {
    font-size: 12px;
    letter-spacing: -0.29px;
    display: block;
    color: #005fc9;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .establishment-card__body__right__button {
    justify-content: center;
    color: #fff;
    background-color: #005fc9;
    width: 90px;
    height: 40px;
    align-self: flex-end;
  }
  .establishment-card__body__right__button__text {
    font-size: 14px;
    letter-spacing: -0.25px;
    text-align: center;
  }
  .establishment-card__body__right__button__icon {
    display: none;
  }
}
@media (min-width: 1310px) {
  .establishment-card {
    margin: 4px;
  }
}
.establishment-card .-unique {
  color: #808080;
  display: none;
}
.establishment-card .-firefox-clamp {
  overflow: hidden;
}
.establishment-card .establishment-info {
  position: relative;
  height: 2.9em;
}
@media (max-width: 767px) {
  .establishment-card .establishment-info {
    height: 2em;
  }
}
.establishment-card .establishment-info:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 5px;
  right: 0;
  width: 10%;
  height: 1.2em;
  background: -webkit-linear-gradient(to right, rgba(255,255,255,0), #fff 50%);
  background: -moz-linear-gradient(to right, rgba(255,255,255,0), #fff 50%);
  background: -ms-linear-gradient(to right, rgba(255,255,255,0), #fff 50%);
  background: -o-linear-gradient(to right, rgba(255,255,255,0), #fff 50%);
  background: linear-gradient(to right, rgba(255,255,255,0), #fff 50%);
}
@media (max-width: 767px) {
  .establishment-card .establishment-info:after {
    bottom: -2px;
  }
}
.-is-hovered {
  box-shadow: 0px 0px 5px #005fc9;
}
.-es-co,
.-ja-jp {
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: -1.5px;
}
.-no-lower {
  text-transform: none;
}
@media (min-width: 768px) {
  .-no-lower {
    font-size: 14px;
    letter-spacing: -0.25px;
    letter-spacing: -1px;
  }
}
.hundredrating-airbnb-card .establishment-card__body {
  flex-direction: column;
}
.hundredrating-airbnb-card .establishment-card__body__right {
  min-height: 50px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
.hundredrating-airbnb-card .establishment-card__body__right__partner__logo {
  margin-bottom: 25px;
}
.hundredrating-airbnb-card .establishment-card__body__top {
  align-items: flex-start;
}
.hundredrating-airbnb-card .establishment-card__body__left {
  display: none;
}
.-no-display-tooltip {
  display: none;
}
.-no-pointer {
  cursor: default;
}
@-moz-keyframes heartIn {
  from {
    transform: scale(0.3);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.7);
  }
  75% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
@media (max-width: 767px) {
 {
      min-height: 306px;
    }
    __body {
      min-height: 95px;
    }
    __price {
      font-size: 18px;
      letter-spacing: -0.25px;
    }
}
}
@-webkit-keyframes heartIn {
  from {
    transform: scale(0.3);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.7);
  }
  75% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
@media (max-width: 767px) {
 {
      min-height: 306px;
    }
    __body {
      min-height: 95px;
    }
    __price {
      font-size: 18px;
      letter-spacing: -0.25px;
    }
}
}
@-o-keyframes heartIn {
  from {
    transform: scale(0.3);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.7);
  }
  75% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
@media (max-width: 767px) {
 {
      min-height: 306px;
    }
    __body {
      min-height: 95px;
    }
    __price {
      font-size: 18px;
      letter-spacing: -0.25px;
    }
}
}
@keyframes heartIn {
  from {
    transform: scale(0.3);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.7);
  }
  75% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
@media (max-width: 767px) {
 {
      min-height: 306px;
    }
    __body {
      min-height: 95px;
    }
    __price {
      font-size: 18px;
      letter-spacing: -0.25px;
    }
}
}
.smart-banner-footer-redirect {
  width: 100%;
  height: 70px;
  border-top: 1px solid #d8d1d1;
  background-color: #fff;
  position: fixed;
  bottom: -200px;
  transition: bottom 0.5s;
}
.smart-banner-footer-redirect__inner {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.smart-banner-footer-redirect__inner__text {
  color: #4a4a4a;
  font-size: 12px;
  letter-spacing: -0.29px;
  font-weight: 600;
  margin-right: 1vw;
}
.smart-banner-footer-redirect__inner__confirmation-button,
.smart-banner-footer-redirect__inner__denial-button {
  font-size: 12px;
  letter-spacing: -0.29px;
  margin: 0 2vw;
  height: 30px;
  line-height: 30px;
  width: auto;
  font-weight: 500;
}
.smart-banner-footer-redirect__inner__denial-button {
  display: none;
  margin-right: 0;
}
.smart-banner-footer-redirect__inner .hundred-ui-button {
  padding: 0;
  border: 1px solid rgba(0,0,0,0.3);
  min-width: 125px;
}
.smart-banner-footer-redirect__inner__cross {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #4a4a4a;
}
.smart-banner-footer-redirect__inner__cross:before {
  font-weight: 600;
}
@media (min-width: 414px) {
  .smart-banner-footer-redirect__inner__text {
    font-size: 20px;
  }
  .smart-banner-footer-redirect__inner__denial-button,
  .smart-banner-footer-redirect__inner__confirmation-button {
    width: 100%;
    height: 50px;
    line-height: 50px;
    display: block;
    font-size: 20px;
  }
  .smart-banner-footer-redirect__inner__cross {
    display: none;
  }
}
.footer-molecule {
  background-color: #fff;
  border-top: 1px solid #ccdff4;
  box-sizing: border-box;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.footer-molecule #publishStablishment {
  width: 34%;
}
.footer-molecule__first-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 45px 7.03125% 50px;
  max-width: 1280px;
  margin: auto;
}
.footer-molecule__first-footer__links {
  width: 100%;
  margin: auto;
}
.footer-molecule__first-footer__links__about-us,
.footer-molecule__first-footer__links__establishment-type,
.footer-molecule__first-footer__links__publish-establishment,
.footer-molecule__first-footer__links__international {
  text-align: left;
  padding: 0 0 30px 0;
}
.footer-molecule__first-footer__links__about-us__title,
.footer-molecule__first-footer__links__establishment-type__title,
.footer-molecule__first-footer__links__publish-establishment__title,
.footer-molecule__first-footer__links__international__title {
  font-size: 18px;
  letter-spacing: -0.25px;
  line-height: 19px;
  margin: 0;
  cursor: default;
  padding-bottom: 10px;
  letter-spacing: -0.5px;
  font-weight: 700;
  text-align: left;
}
.footer-molecule__first-footer__links__about-us__subtext,
.footer-molecule__first-footer__links__establishment-type__subtext,
.footer-molecule__first-footer__links__publish-establishment__subtext,
.footer-molecule__first-footer__links__international__subtext {
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 20px;
  color: rgba(0,0,0,0.4);
  margin: 0 0 20px;
  text-align: left;
}
.footer-molecule__first-footer__links__about-us__link,
.footer-molecule__first-footer__links__establishment-type__link,
.footer-molecule__first-footer__links__publish-establishment__link,
.footer-molecule__first-footer__links__international__link {
  letter-spacing: -0.5px;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 30px;
  color: #005fc9;
  text-transform: uppercase;
}
.footer-molecule__first-footer__newsletter {
  display: none;
  width: 100%;
  margin: auto;
  text-align: left;
}
.footer-molecule__first-footer__newsletter__title {
  font-size: 18px;
  letter-spacing: -0.25px;
  line-height: 19px;
  margin: 0 0 14px 0;
}
.footer-molecule__first-footer__newsletter__text {
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 24px;
  color: #9b9b9b;
  margin: 0 0 10px;
  text-align: left;
}
.footer-molecule__first-footer__newsletter__input {
  width: 70%;
  display: inline-block;
  height: 47px;
  border: 1px solid #ccdff4;
  border-radius: 3px;
  font-size: 14px;
  letter-spacing: -0.25px;
  padding: 0 10px;
  margin-right: 5%;
}
.footer-molecule__first-footer__newsletter__input::placeholder {
  color: #005fc9;
}
.footer-molecule__first-footer__newsletter__input:focus {
  outline: none;
  border-color: #005fc9;
}
.footer-molecule__first-footer__newsletter__button {
  display: inline-block;
  width: 25%;
  min-width: inherit;
  height: 47px;
  border-radius: 3px;
  padding: 0 10px;
  text-align: center;
  color: #fff;
  background-color: #005fc9;
}
.footer-molecule__first-footer__newsletter__button:hover,
.footer-molecule__first-footer__newsletter__button:focus {
  background: rgba(0,95,201,0.9);
}
.footer-molecule__second-footer {
  align-items: initial;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 1280px;
  margin: auto;
  border-top: 1px solid #e6e6e6;
  box-sizing: border-box;
  padding: 30px 7.03125%;
}
.footer-molecule__second-footer__copyright {
  text-align: center;
  order: 3;
  user-select: none;
  cursor: default;
}
.footer-molecule__second-footer__copyright__logo {
  display: none;
}
.footer-molecule__second-footer__copyright__text {
  font-size: 12px;
  letter-spacing: -0.29px;
  text-align: center;
  line-height: 19px;
  margin: 0;
}
.footer-molecule__second-footer__made {
  width: 100%;
  margin: 30px auto;
  order: 2;
  user-select: none;
  cursor: default;
}
.footer-molecule__second-footer__made__text {
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 23px;
  color: #9b9b9b;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
  width: 165px;
}
.footer-molecule__second-footer__made__text .hundredicons-palmtree {
  color: #00a600;
  font-size: 16px;
  letter-spacing: -0.25px;
}
.footer-molecule__second-footer__made__text .hundredicons-heart-filled {
  color: #d0021b;
  font-size: 11px;
  letter-spacing: 0px;
  vertical-align: baseline;
}
.footer-molecule__second-footer__social-links {
  padding: 0 7.03125%;
  order: 1;
  user-select: none;
}
.footer-molecule__second-footer__social-links.-not-border-top {
  border-top: 0;
}
.footer-molecule__second-footer__social-links__list {
  display: flex;
  justify-content: center;
}
.footer-molecule__second-footer__social-links__list__item {
  padding: 0 10px;
  display: inline-block;
  vertical-align: middle;
}
.footer-molecule__second-footer__social-links__list__item:first-child {
  padding-left: 0;
}
.footer-molecule__second-footer__social-links__list__item:last-child {
  padding-right: 0;
}
.footer-molecule__second-footer__social-links__list__item__link {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 0.90000004px solid rgba(176,190,197,0.5);
  display: block;
  text-align: center;
  transition: all ease 0.3s;
  cursor: pointer;
  line-height: 40px;
  background-image: url("https://static.100r.systems/img/footer/footer-sprite.png");
  background-repeat: no-repeat;
}
.footer-molecule__second-footer__social-links__list__item__link.-social-icons-facebook {
  background-size: 100%;
  background-position: -2px 7px;
}
.footer-molecule__second-footer__social-links__list__item__link.-social-icons-twitter {
  background-position: 4px -16px;
  background-size: 80%;
}
.footer-molecule__second-footer__social-links__list__item__link.-social-icons-linkedin {
  background-position: 3px -55px;
  background-size: 90%;
}
.footer-molecule__second-footer__social-links__list__item__link.-social-icons-google {
  background-position: 3px -76px;
  background-size: 86%;
}
.footer-molecule__second-footer__social-links__list__item__link.-social-icons-youtube {
  background-position: 3px -100px;
  background-size: 83%;
}
.footer-molecule__second-footer__social-links__list__item__link.-social-icons-instagram {
  background-position: 2px -144px;
  background-size: 90%;
}
.footer-molecule__second-footer__social-links__list__item__link.-social-icons-pinterest {
  background-position: 2px -180px;
  background-size: 90%;
}
@media (min-width: 1024px) {
  .footer-molecule__second-footer__copyright__logo {
    width: 42.96px;
    height: 30px;
    display: block;
    margin: 0 20px;
    background-image: url("https://static.100r.systems/img/footer/footer-sprite.png");
    background-position: 4px -247px;
    background-repeat: no-repeat;
    background-size: 80%;
  }
}
@media (min-width: 768px) {
  .footer-molecule {
    border-top: 1px solid #e6e6e6;
    padding-bottom: 40px;
  }
  .footer-molecule__first-footer {
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 0;
    padding-left: 40px;
    padding-right: 40px;
  }
  .footer-molecule__first-footer__links {
    align-items: flex-start;
    display: flex;
    width: 100%;
    margin-top: 0;
  }
  .footer-molecule__first-footer__links__about-us,
  .footer-molecule__first-footer__links__establishment-type {
    width: 34%;
  }
  .footer-molecule__first-footer__links__about-us__title,
  .footer-molecule__first-footer__links__establishment-type__title {
    text-align: left;
    width: 95%;
    margin-bottom: 13px;
    padding-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .footer-molecule__first-footer__newsletter {
    width: 25%;
    margin-top: 0;
  }
  .footer-molecule__first-footer__newsletter__input {
    width: 65%;
    margin-right: 3%;
  }
  .footer-molecule__first-footer__newsletter__button {
    width: 30%;
  }
  .footer-molecule__second-footer {
    flex-direction: row;
    align-items: flex-start;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 0;
  }
  .footer-molecule__second-footer__copyright {
    order: 1;
    width: 33%;
    display: flex;
    align-items: center;
  }
  .footer-molecule__second-footer__copyright__img {
    display: inline-block;
    width: 44px;
    height: 29px;
    flex-shrink: 0;
    margin: 0 20px 0 0;
  }
  .footer-molecule__second-footer__copyright__text {
    display: inline-block;
    max-width: 230px;
    text-align: left;
    font-weight: 300;
  }
  .footer-molecule__second-footer__copyright__text a {
    color: #005fc9;
  }
  .footer-molecule__second-footer__made {
    order: 2;
    width: 33%;
    margin: 0;
  }
  .footer-molecule__second-footer__made__text {
    width: 260px;
  }
  .footer-molecule__second-footer__social-links {
    order: 3;
    width: 34%;
    padding-right: 0;
  }
  .footer-molecule__second-footer__social-links__list {
    justify-content: flex-end;
  }
}
@media (max-width: 767px) {
  .footer-molecule__second-footer__copyright__logo {
    width: 42.96px;
    height: 30px;
    display: block;
    margin: 0 auto 20px;
    background-image: url("https://static.100r.systems/img/footer/footer-sprite.png");
    background-position: 4px -247px;
    background-repeat: no-repeat;
    background-size: 80%;
  }
}
.form-molecule {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  width: 100%;
  height: 50px;
}
.form-molecule__input {
  flex: 1 1 auto;
}
.form-molecule__input input.hundred-ui-input__input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.form-molecule__button {
  flex: 0 0 auto;
  max-width: 100px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.grid-matching-component {
  color: #4a4a4a;
  background-color: #fff;
  min-width: 320px;
}
.grid-matching-component__top {
  min-width: 320px;
  padding: 15px 0px;
  display: flex;
  border-bottom: 1px solid #ccdff4;
  text-align: center;
}
.grid-matching-component__top__logo {
  width: 44%;
  min-width: 143px;
}
.grid-matching-component__top__rating {
  width: 20%;
}
.grid-matching-component__top__cta {
  min-width: 100px;
  width: 34%;
  text-align: center;
  box-sizing: border-box;
}
.grid-matching-component__top__comments,
.grid-matching-component__top__price,
.grid-matching-component__top__extras {
  display: none;
}
.grid-matching-component .grid-matching-row {
  border-bottom: 1px solid #ccdff4;
}
.grid-matching-component__legend {
  text-align: left;
  padding: 20px 10px;
}
.grid-matching-component__legend span {
  margin-right: 40px;
  margin-bottom: 12px;
}
.grid-matching-component__legend__cancelation:before {
  margin-right: 3px;
  margin-bottom: 1px;
}
.grid-matching-component__legend__instant-booking:before {
  font-size: 3px;
}
@media (min-width: 408px) {
  .grid-matching-component__top span {
    width: 23%;
  }
  .grid-matching-component__top__price {
    display: block;
    min-width: 80px;
  }
  .grid-matching-component__top__cta__text {
    display: none;
  }
}
@media (min-width: 480px) {
  .grid-matching-component__top span {
    width: 20%;
  }
  .grid-matching-component__top__extras {
    display: block;
  }
}
@media (min-width: 550px) {
  .grid-matching-component__top span {
    width: 16.6%;
  }
  .grid-matching-component__top__comments {
    display: block;
  }
}
.grid-matching-row {
  align-items: center;
  background-color: #fff;
  color: #4a4a4a;
  display: flex;
  height: 70px;
  min-width: 320px;
  width: 100%;
}
.grid-matching-row__logo,
.grid-matching-row__rating,
.grid-matching-row__comments,
.grid-matching-row__price,
.grid-matching-row__extras,
.grid-matching-row__cta {
  cursor: pointer;
}
.grid-matching-row__comments,
.grid-matching-row__price,
.grid-matching-row__extras {
  display: none;
}
.grid-matching-row__rating {
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  letter-spacing: -0.25px;
  font-weight: 600;
  width: 20%;
}
.grid-matching-row__cta {
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  width: 34%;
}
.grid-matching-row__cta__button {
  background-color: #005fc9;
  color: #fff;
  display: flex;
  font-size: 16px;
  letter-spacing: -0.25px;
  line-height: 18px;
  justify-content: center;
  max-width: 90px;
}
.grid-matching-row__cta__button__desktop {
  text-align: center;
  white-space: normal;
  display: none;
}
.grid-matching-row__cta__button__mobile {
  display: inline;
}
.grid-matching-row__cta .hundred-ui-button:hover {
  background-color: #267dc0;
}
.grid-matching-row__logo {
  height: 100%;
  display: inline-flex;
  justify-content: left;
  align-items: center;
  position: relative;
  min-width: 143px;
  overflow: hidden;
  flex-wrap: wrap;
  width: 44%;
}
.grid-matching-row__logo__image-container {
  height: 30px;
  overflow: hidden;
  width: 113px;
  max-width: 113px;
  position: relative;
}
.grid-matching-row__logo__image-container__image {
  position: absolute;
  left: 5px;
  width: 322px;
}
.grid-matching-row__logo__extras {
  flex-basis: auto;
  height: 50%;
  display: flex;
  align-items: center;
  position: relative;
  top: 2px;
  width: 30px;
}
.grid-matching-row__logo__extras div {
  display: flex;
}
.grid-matching-row__logo__extras div .hundredicons-instantbook {
  font-size: 4px;
  width: auto;
}
.grid-matching-row__logo__extras div .hundredicons-cancellation {
  width: auto;
}
.grid-matching-row__logo__extras div .hundredicons-cancellation.minified.full,
.grid-matching-row__logo__extras div .hundredicons-cancellation:before {
  margin-right: 0;
}
.grid-matching-row__logo__comments {
  height: auto;
  padding-left: 5px;
  text-align: left;
  width: 100%;
}
.grid-matching-row__logo .logo-container_2 {
  width: 87px;
}
.grid-matching-row__logo .logo-container_2 img {
  top: -72px;
}
.grid-matching-row__logo .logo-container_3 {
  width: 50px;
}
.grid-matching-row__logo .logo-container_3 img {
  top: -127px;
}
.grid-matching-row__logo .logo-container_4 {
  width: 81px;
}
.grid-matching-row__logo .logo-container_4 img {
  top: -176px;
}
.grid-matching-row__logo .logo-container_5 {
  width: 70px;
}
.grid-matching-row__logo .logo-container_5 img {
  top: -225px;
}
.grid-matching-row__logo .logo-container_6 {
  width: 34px;
}
.grid-matching-row__logo .logo-container_6 img {
  top: -281px;
}
.grid-matching-row__logo .logo-container_7 {
  width: 91px;
}
.grid-matching-row__logo .logo-container_7 img {
  top: -332px;
}
.grid-matching-row__logo .logo-container_8 {
  width: 106px;
}
.grid-matching-row__logo .logo-container_8 img {
  top: -384px;
}
.grid-matching-row__logo .logo-container_9 {
  width: 66px;
}
.grid-matching-row__logo .logo-container_9 img {
  top: -434px;
}
.grid-matching-row__logo .logo-container_10 {
  width: 88px;
}
.grid-matching-row__logo .logo-container_10 img {
  top: -486px;
}
.grid-matching-row__logo .logo-container_11 {
  width: 82px;
}
.grid-matching-row__logo .logo-container_11 img {
  top: -536px;
}
.grid-matching-row__logo .logo-container_12 {
  width: 97px;
}
.grid-matching-row__logo .logo-container_12 img {
  top: -590px;
}
.grid-matching-row__logo .logo-container_12_fr {
  width: 73px;
}
.grid-matching-row__logo .logo-container_12_fr img {
  top: -590px;
  left: -102px;
}
.grid-matching-row__logo .logo-container_12_de {
  width: 94px;
}
.grid-matching-row__logo .logo-container_12_de img {
  top: -590px;
  left: -210px;
}
.grid-matching-row__logo .logo-container_13 {
  width: 60px;
}
.grid-matching-row__logo .logo-container_13 img {
  top: -641px;
}
.grid-matching-row__logo .logo-container_14 {
  width: 69px;
}
.grid-matching-row__logo .logo-container_14 img {
  top: -692px;
}
.grid-matching-row__logo .logo-container_15 {
  width: 56px;
}
.grid-matching-row__logo .logo-container_15 img {
  top: -744px;
}
.grid-matching-row__logo .logo-container_16 {
  width: 78px;
}
.grid-matching-row__logo .logo-container_16 img {
  top: -792px;
}
.grid-matching-row__logo .logo-container_17 img {
  top: -846px;
}
.grid-matching-row__logo .logo-container_19 {
  width: 60px;
}
.grid-matching-row__logo .logo-container_19 img {
  top: -950px;
}
.grid-matching-row__logo .logo-container_20 {
  width: 56px;
}
.grid-matching-row__logo .logo-container_20 img {
  top: -1000px;
}
.grid-matching-row__logo .logo-container_21 {
  width: 102px;
}
.grid-matching-row__logo .logo-container_21 img {
  top: -1054px;
}
.grid-matching-row__logo .logo-container_22 {
  width: 63px;
}
.grid-matching-row__logo .logo-container_22 img {
  top: -1102px;
}
.grid-matching-row__logo .logo-container_23 {
  width: 75px;
}
.grid-matching-row__logo .logo-container_23 img {
  top: -1156px;
}
.grid-matching-row__logo .logo-container_24 {
  width: 86px;
}
.grid-matching-row__logo .logo-container_24 img {
  top: -1207px;
}
.grid-matching-row__logo .logo-container_25 {
  width: 58px;
}
.grid-matching-row__logo .logo-container_25 img {
  top: -1257px;
}
.grid-matching-row__logo .logo-container_26 {
  width: 76px;
}
.grid-matching-row__logo .logo-container_26 img {
  top: -1309px;
}
.grid-matching-row__logo .logo-container_27 {
  width: 53px;
}
.grid-matching-row__logo .logo-container_27 img {
  top: -1361px;
}
.grid-matching-row__logo .logo-container_29 {
  width: 53px;
}
.grid-matching-row__logo .logo-container_29 img {
  top: -1466px;
}
.grid-matching-row__logo .logo-container_30 {
  width: 55px;
}
.grid-matching-row__logo .logo-container_30 img {
  top: -1517px;
}
.grid-matching-row__logo .logo-container_31 {
  width: 67px;
}
.grid-matching-row__logo .logo-container_31 img {
  top: -1568px;
}
.grid-matching-row__logo .logo-container_33 {
  width: 72px;
}
.grid-matching-row__logo .logo-container_33 img {
  top: -1667px;
}
.grid-matching-row__logo .logo-container_35 {
  width: 103px;
}
.grid-matching-row__logo .logo-container_35 img {
  top: -1774px;
}
.grid-matching-row__logo .logo-container_36 {
  width: 82px;
}
.grid-matching-row__logo .logo-container_36 img {
  top: -1824px;
}
.grid-matching-row__logo .logo-container_37 {
  width: 102px;
}
.grid-matching-row__logo .logo-container_37 img {
  top: -1878px;
}
.grid-matching-row__logo .logo-container_38 {
  width: 108px;
}
.grid-matching-row__logo .logo-container_38 img {
  top: -1926px;
}
.grid-matching-row__logo .logo-container_39 {
  width: 83px;
}
.grid-matching-row__logo .logo-container_39 img {
  top: -1981px;
}
@media (min-width: 408px) {
  .grid-matching-row span {
    width: 23%;
  }
  .grid-matching-row__price {
    font-size: 16px;
    letter-spacing: -0.25px;
    height: 100%;
    display: inline-flex;
    min-width: 80px;
    justify-content: center;
    align-items: center;
  }
  .grid-matching-row__cta__button__desktop {
    display: inline;
  }
  .grid-matching-row__cta__button__mobile {
    display: none;
  }
}
@media (min-width: 480px) {
  .grid-matching-row span {
    width: 20%;
  }
  .grid-matching-row__extras {
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .grid-matching-row__extras .hundredicons-cancellation {
    width: auto;
  }
  .grid-matching-row__extras .hundredicons-cancellation.minified.full:before {
    margin-right: 0;
  }
  .grid-matching-row__extras .hundredicons-instantbook {
    font-size: 3px;
    width: auto;
  }
  .grid-matching-row__logo__extras {
    display: none;
  }
}
@media (min-width: 550px) {
  .grid-matching-row span {
    width: 16.6%;
  }
  .grid-matching-row__comments {
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    letter-spacing: -0.25px;
  }
  .grid-matching-row__logo__comments {
    display: none;
  }
  .grid-matching-row__logo__image-container {
    position: relative;
    overflow: hidden;
    height: 50px;
    color: transparent;
    display: flex;
    align-items: center;
    max-width: 143px;
    min-width: 143px;
  }
  .grid-matching-row__logo__image-container__image {
    left: 10px;
    width: 400px;
  }
  .grid-matching-row__logo .logo-container_2 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_2 img {
    top: -87px;
  }
  .grid-matching-row__logo .logo-container_3 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_3 img {
    top: -150px;
  }
  .grid-matching-row__logo .logo-container_4 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_4 img {
    top: -214px;
  }
  .grid-matching-row__logo .logo-container_5 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_5 img {
    top: -278px;
  }
  .grid-matching-row__logo .logo-container_6 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_6 img {
    top: -342px;
  }
  .grid-matching-row__logo .logo-container_7 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_7 img {
    top: -408px;
  }
  .grid-matching-row__logo .logo-container_8 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_8 img {
    top: -472px;
  }
  .grid-matching-row__logo .logo-container_9 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_9 img {
    top: -535px;
  }
  .grid-matching-row__logo .logo-container_10 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_10 img {
    top: -599px;
  }
  .grid-matching-row__logo .logo-container_11 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_11 img {
    top: -662px;
  }
  .grid-matching-row__logo .logo-container_12 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_12 img {
    top: -726px;
  }
  .grid-matching-row__logo .logo-container_12_de {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_12_de img {
    top: -726px;
    left: -255px;
  }
  .grid-matching-row__logo .logo-container_12_fr {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_12_fr img {
    top: -726px;
    left: -123px;
  }
  .grid-matching-row__logo .logo-container_13 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_13 img {
    top: -791px;
  }
  .grid-matching-row__logo .logo-container_14 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_14 img {
    top: -854px;
  }
  .grid-matching-row__logo .logo-container_15 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_15 img {
    top: -918px;
  }
  .grid-matching-row__logo .logo-container_16 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_16 img {
    top: -982px;
  }
  .grid-matching-row__logo .logo-container_17 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_17 img {
    top: -1046px;
  }
  .grid-matching-row__logo .logo-container_19 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_19 img {
    top: -1174px;
  }
  .grid-matching-row__logo .logo-container_20 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_20 img {
    top: -1236px;
  }
  .grid-matching-row__logo .logo-container_21 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_21 img {
    top: -1303px;
  }
  .grid-matching-row__logo .logo-container_22 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_22 img {
    top: -1364px;
  }
  .grid-matching-row__logo .logo-container_23 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_23 img {
    top: -1430px;
  }
  .grid-matching-row__logo .logo-container_24 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_24 img {
    top: -1493px;
  }
  .grid-matching-row__logo .logo-container_25 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_25 img {
    top: -1557px;
  }
  .grid-matching-row__logo .logo-container_26 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_26 img {
    top: -1622px;
  }
  .grid-matching-row__logo .logo-container_27 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_27 img {
    top: -1686px;
  }
  .grid-matching-row__logo .logo-container_29 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_29 img {
    top: -1815px;
  }
  .grid-matching-row__logo .logo-container_30 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_30 img {
    top: -1881px;
  }
  .grid-matching-row__logo .logo-container_31 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_31 img {
    top: -1944px;
  }
  .grid-matching-row__logo .logo-container_33 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_33 img {
    top: -2069px;
  }
  .grid-matching-row__logo .logo-container_35 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_35 img {
    top: -2196px;
  }
  .grid-matching-row__logo .logo-container_36 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_36 img {
    top: -2261px;
  }
  .grid-matching-row__logo .logo-container_37 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_37 img {
    top: -2327px;
  }
  .grid-matching-row__logo .logo-container_38 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_38 img {
    top: -2390px;
  }
  .grid-matching-row__logo .logo-container_39 {
    width: 100%;
  }
  .grid-matching-row__logo .logo-container_39 img {
    top: -2455px;
  }
}
.sprite-window-class {
  height: 100%;
  box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  z-index: 99999;
  overflow: hidden;
  width: 100%;
  position: absolute;
}
.-no-pointer {
  cursor: default;
}
.header-molecule {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #fff;
  height: 64px;
  width: 100%;
  backface-visibility: hidden;
  box-sizing: border-box;
}
.header-molecule-container {
  box-sizing: border-box;
  height: 75px;
  padding: 20px 7.03125%;
  width: 100%;
}
.header-molecule__logo {
  display: inline-block;
  float: left;
  cursor: pointer;
}
.header-molecule__logo__image__img {
  display: inline-block;
  height: 24px;
}
.header-molecule__logo__image__text {
  display: none;
  color: #4a4a4a;
}
.header-molecule__nav {
  display: none;
}
.header-molecule__search-bar.search-bar-molecule {
  height: 64px;
  min-height: 64px;
  display: inline-block;
  vertical-align: top;
  margin-top: -20px;
  padding: 0;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__mobile {
  display: none;
  position: absolute;
  margin: 0;
  left: 0;
  width: 100vw;
  top: 64px;
  border-radius: 0;
  border: 1px solid rgba(224,226,236,0.5);
  box-shadow: none;
  padding-left: 20px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop {
  display: none;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box {
  height: 64px;
  box-shadow: none;
  border: 0;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box .-grow:after,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box .-decrease:after {
  bottom: 0;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests {
  height: 64px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__title,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates__title,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests__title {
  display: none;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__bottom,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates__bottom,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests__bottom {
  margin-top: -4px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box .DateRangePickerInput {
  margin-top: 8px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box .DateRangePickerInput__arrow {
  line-height: 50px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination {
  border-left: 1px solid rgba(224,226,236,0.5);
  border-right: 1px solid rgba(224,226,236,0.3);
  padding: 0 10px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .geosuggest {
  width: 90%;
  display: inline-block;
  vertical-align: middle;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .geosuggest__input {
  height: 64px;
  font-size: 16px;
  letter-spacing: -0.25px;
  line-height: 64px;
  padding-top: 3px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .geosuggest__suggests {
  margin-top: 0;
  width: calc(100vw - 136px);
  margin-left: -11px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .geosuggest__item {
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 45px;
  height: 45px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .geosuggest__item:first-child,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .geosuggest__item:last-child {
  border-radius: 0;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .hundredicons-cross {
  margin-top: 0;
  margin-left: 0;
  width: 10%;
  line-height: 55px;
  vertical-align: middle;
  display: inline-block;
  transition: all ease 0.5s;
  text-align: center;
  color: #c1c5d5;
  cursor: pointer;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .hundredicons-cross:before {
  line-height: 64px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .hundredicons-cross.-hidden {
  font-size: 0;
  transform: rotate(180deg);
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates {
  border-right: 1px solid rgba(224,226,236,0.3);
  width: 260px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates .DateRangePickerInput {
  width: 233px;
  margin-top: 5px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates .DateRangePickerInput .DateInput {
  font-size: 14px;
  letter-spacing: -0.25px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates .DateRangePickerInput .DateInput__display-text--focused:after {
  bottom: -13px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates .DateRangePickerInput .DateInput--with-caret:after,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates .DateRangePickerInput .DateInput--with-caret:before {
  top: 69px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates .DateRangePickerInput .DateInput__display-text {
  line-height: 45px;
  height: 50px;
  font-size: 16px;
  letter-spacing: -0.25px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates .DateRangePicker__picker {
  top: 80px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates .DateRangePicker__picker--direction-left {
  left: -220px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__dates__title {
  height: 64px;
  line-height: 67px;
  font-size: 14px;
  letter-spacing: -0.25px;
  text-align: center;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests {
  display: flex;
  align-items: center;
  border-right: none;
  padding: 0 10px;
  width: 107px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests__bottom__text {
  font-size: 16px;
  letter-spacing: -0.25px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests__text,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests__buttons {
  padding-top: 0;
  line-height: 66px;
  height: 66px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests__text {
  font-size: 14px;
  letter-spacing: -0.25px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests__buttons {
  line-height: 62px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests__buttons__button:last-child {
  margin-right: 0;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action {
  padding: 0;
  border-right: 1px solid rgba(224,226,236,0.5);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 120px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp {
  background-color: #353a8e;
  width: 70px;
  padding: 0 15px;
  min-width: 90px;
  justify-content: center;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button:before {
  color: #005fc9;
  font-size: 21px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp {
  display: flex;
  position: relative;
  align-items: center;
  width: auto;
  height: 40px;
  font-size: 14px;
  letter-spacing: -0.25px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp:before,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp .hundredicons-search,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp .hundredicons-icon-loading {
  display: block;
  position: absolute;
  top: 15px;
  left: 23px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp:before:before,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp .hundredicons-search:before,
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp .hundredicons-icon-loading:before {
  color: #005fc9;
  font-size: 20px;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp .hundredicons-icon-loading:before {
  display: block;
  animation: infinite-spinning 1s linear infinite;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp__text {
  margin: 0 auto;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__action__button-serp .-ie-button-fallback {
  margin: 0;
  text-align: center;
}
.header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop.-is-hidden {
  display: none;
}
.header-molecule.-has-search-bar .header-molecule-container {
  padding: 20px;
}
.header-molecule.-has-search-bar .header-molecule__logo {
  margin-right: 20px;
}
.header-molecule.-hidden-first-header {
  height: 64px;
}
.header-molecule.-hidden-first-header .header-molecule__search-bar.search-bar-molecule .search-bar-molecule__mobile {
  top: 0;
}
.header-molecule .search-bar-molecule__desktop__box__destination__input #recentSearchesSuggestionList {
  margin-top: 0;
}
@media (min-width: 768px) {
  .header-molecule-container {
    padding: 20px;
  }
  .header-molecule__logo {
    vertical-align: middle;
  }
  .header-molecule__logo__image__img {
    vertical-align: middle;
    height: 20.25px;
  }
  .header-molecule__logo__image__img-text {
    display: inline-block;
    width: auto;
    height: 16px;
    line-height: 26px;
    margin-left: 8px;
    vertical-align: middle;
  }
  .header-molecule.-has-search-bar {
    border-bottom: 1px solid rgba(224,226,236,0.5);
    height: 65px;
  }
  .header-molecule.-has-search-bar .header-molecule-container {
    padding: 20px;
    height: 64px;
    display: inherit;
    justify-content: space-between;
    align-items: center;
  }
  .header-molecule.-has-search-bar .header-molecule__logo {
    margin-right: 20px;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__mobile {
    display: none;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop {
    width: calc(100vw - 136px);
    display: block;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box__destination {
    max-width: 340px;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box__destination__input .geosuggest__suggests {
    max-width: 351px;
    margin-left: -11px;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box__action .hundredicons-search:before,
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box__action .hundredicons-icon-loading:before {
    color: #005fc9;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box__action .-is-serp-loading:before {
    color: #fff;
    left: 34px;
    top: 6px;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box .-serp-search-button {
    padding-right: 15px;
    display: flex;
    justify-content: center;
    min-width: 131px;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box .-serp-search-button .hundredicons-search:before {
    display: none;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box .-serp-search-button .hundredicons-icon-loading:before {
    color: #fff;
    left: 40%;
    top: 13%;
  }
  .header-molecule.-has-search-bar .search-bar-molecule__desktop__box .-serp-search-button .-loading-serp {
    color: #353a8e;
  }
}
@media (max-width: 767px) {
  .header-molecule {
    display: none;
  }
}
@media (min-width: 1024px) {
  .header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop {
    width: 662px;
  }
  .header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .geosuggest__suggests {
    max-width: 662px;
    margin-left: -11px;
  }
  .header-molecule__nav-container {
    height: 100%;
  }
  .header-molecule__nav {
    display: inline-block;
    float: right;
    vertical-align: middle;
    color: #4a4a4a;
    background: none;
    width: auto;
    height: 100%;
    box-shadow: inherit;
  }
  .header-molecule__nav__item,
  .header-molecule__nav__item__seo-links,
  .header-molecule__nav__item__personal-area {
    display: inline-block;
    text-align: right;
    line-height: 26px;
    cursor: pointer;
    height: 100%;
    padding: 5px 15px;
  }
  .header-molecule__nav__item__link,
  .header-molecule__nav__item__seo-links__link,
  .header-molecule__nav__item__personal-area__link {
    color: #4a4a4a;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.25px;
  }
  .header-molecule__nav__item__link:hover,
  .header-molecule__nav__item__seo-links__link:hover,
  .header-molecule__nav__item__personal-area__link:hover {
    color: #4a4a4a;
    opacity: 0.9;
    background: none;
  }
  .header-molecule__nav__item__img {
    height: 10px;
    margin-left: 10px;
    display: inline-block;
  }
  .header-molecule__nav__item__personal-area.dropdown {
    position: relative;
  }
  .header-molecule__nav__item__personal-area .dropdown__list {
    width: auto;
    right: 0;
    transform: none;
    overflow: initial;
  }
  .header-molecule__nav__item__personal-area .dropdown__list:before {
    margin-left: 0;
    right: 33%;
  }
  .header-molecule__nav__item__personal-area .dropdown__list__item__link {
    padding: 5px 15px;
  }
  .header-molecule__nav__item__personal-area .dropdown__list .personal-area__header__tabs__tab__text {
    min-width: 130px;
    padding: 0 17px;
    font-weight: 500;
  }
  .header-molecule__nav .hundredicons-iconcama,
  .header-molecule__nav .hundredicons-icon-plane {
    display: flex;
    align-items: baseline;
    flex-direction: row-reverse;
  }
  .header-molecule__nav .hundredicons-iconcama:before,
  .header-molecule__nav .hundredicons-icon-plane:before {
    margin-left: 5px;
    color: #4a4a4a;
  }
}
@media (min-width: 1250px) {
  .header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop {
    width: 910px;
  }
  .header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination {
    padding: 0 5px 0 22px;
  }
  .header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__input .geosuggest__suggests {
    max-width: 910px;
    margin-left: -23px;
  }
  .header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__destination__date {
    padding: 0 2px 0 22px;
  }
  .header-molecule__search-bar.search-bar-molecule .search-bar-molecule__desktop__box__guests {
    width: 130px;
    padding: 0 15px 0 22px;
  }
}
@-moz-keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.hundred-rating__tooltip-container__inner {
  display: flex;
  align-items: center;
  color: #4a4a4a;
}
.hundred-rating__tooltip-container__inner__line-block,
.hundred-rating__tooltip-container__inner__line-noblock {
  height: 25px;
  border-right: 1px solid #b3b3b3;
  position: relative;
  margin: 0 5px 0 5px;
  display: inline;
}
.hundred-rating__tooltip-container__inner__line-block {
  height: 25px;
}
.hundred-rating__tooltip-container__inner__line-noblock {
  height: 18px;
}
.hundred-rating__tooltip-container__inner__dot {
  margin: 0 3px;
}
.hundred-rating__tooltip-container__inner__rating {
  font-weight: 600;
}
.hundred-rating.-is-line .hundred-rating__tooltip-container__inner__sub-block {
  display: flex;
  align-items: center;
}
.leads-form {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: -0.25px;
  max-width: 100%;
  width: 715px;
  height: 680px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fff;
  border-radius: 2px;
}
.leads-form .\--is-multileads-container {
  width: 300%;
}
.leads-form .\--is-multileads-inner-container {
  width: 33.3%;
}
.leads-form__innocuos-container {
  transform: translateX(0%);
  height: auto;
  width: 200%;
}
.leads-form__container-form,
.leads-form__container-thank-you,
.leads-form__container-multi-leads {
  border-top: 10px solid #005fc9;
  border-bottom: 10px solid #005fc9;
  display: inline-block;
  vertical-align: top;
  height: 680px;
  width: 50%;
}
.leads-form__container-multi-leads {
  width: 33.3%;
}
.leads-form__header {
  padding: 20px 30px 25px;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leads-form__header__title {
  display: inline-block;
  width: 85%;
  color: #4a4a4a;
  font-size: 24px;
  font-weight: 700;
  display: inline-block;
  vertical-align: top;
  margin: 0;
  line-height: 30px;
}
.leads-form__header__close {
  color: #808080;
  font-size: 15px;
  letter-spacing: -0.25px;
}
.leads-form__destination-container {
  text-align: left;
  padding: 0 30px;
  margin-bottom: 25px;
}
.leads-form__destination-container__title {
  text-align: left;
  font-weight: 500;
  color: #005fc9;
  font-size: 12px;
  letter-spacing: -0.29px;
  margin-bottom: 10px;
}
.leads-form__destination-container__destination {
  color: #4a4a4a;
  font-size: 16px;
  letter-spacing: -0.25px;
  font-weight: 700;
}
.leads-form__datesandguests {
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
}
.leads-form__datesandguests__dates-container {
  text-align: left;
  width: 50%;
  display: inline-block;
}
.leads-form__datesandguests__dates-container__title {
  font-weight: 500;
  color: #005fc9;
  font-size: 12px;
  letter-spacing: -0.29px;
  margin-bottom: 2px;
}
.leads-form__datesandguests__dates-container__dates {
  color: #4a4a4a;
  font-size: 16px;
  letter-spacing: -0.25px;
  font-weight: 700;
}
.leads-form__datesandguests__dates-container__dates__date {
  margin-bottom: 3px;
}
.leads-form__datesandguests__dates-container__dates__date__icon {
  font-size: 12px;
  letter-spacing: -0.29px;
  margin-left: 5px;
  margin-right: 5px;
  color: #4a4a4a;
}
.leads-form__datesandguests__dates-container__dates__date__icon:before {
  line-height: 30px;
}
.leads-form__datesandguests__dates-container__checkbox-container {
  width: 100%;
  font-weight: ;
}
.leads-form__datesandguests__dates-container__checkbox-container__checkbox {
  font-weight: normal;
}
.leads-form__datesandguests__dates-container__checkbox-container .hundred-ui-checkbox__label__text {
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.leads-form__datesandguests__guests-container {
  margin-left: 15px;
  display: inline-block;
  width: 50%;
}
.leads-form__datesandguests__guests-container__title {
  text-align: left;
  font-weight: 500;
  color: #005fc9;
  font-size: 12px;
  letter-spacing: -0.29px;
  margin-bottom: 10px;
}
.leads-form__datesandguests__guests-container__adults,
.leads-form__datesandguests__guests-container__childrens {
  font-size: 14px;
  letter-spacing: -0.25px;
  height: 45px;
  line-height: 45px;
  width: 48%;
  display: inline-block;
  max-width: inherit;
}
.leads-form__datesandguests__guests-container__adults {
  margin-right: 2%;
}
.leads-form__datesandguests__guests-container__childrens {
  margin-left: 2%;
}
.leads-form__form {
  padding: 9px 30px;
}
.leads-form__form__personal-information__name,
.leads-form__form__personal-information__surname {
  width: 49%;
  display: inline-block;
  vertical-align: middle;
}
.leads-form__form__personal-information__countries,
.leads-form__form__personal-information__phone {
  display: inline-block;
  vertical-align: middle;
}
.leads-form__form__personal-information__email {
  width: 49%;
  display: inline-block;
  margin-left: 2%;
  vertical-align: middle;
}
.leads-form__form__personal-information__phone {
  width: 23%;
  margin-left: 2%;
}
.leads-form__form__personal-information__countries {
  width: 24%;
  font-size: 14px;
  letter-spacing: -0.25px;
  width: 24%;
}
.leads-form__form__personal-information__textarea {
  width: 100%;
  max-width: inherit;
}
.leads-form__form__personal-information__name,
.leads-form__form__personal-information__surname,
.leads-form__form__personal-information__countries,
.leads-form__form__personal-information__phone,
.leads-form__form__personal-information__email {
  margin-bottom: 16px;
}
.leads-form__form__personal-information__surname {
  margin-left: 2%;
}
.leads-form__form__personal-information__textarea {
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,0.1);
  font-size: 15px;
  letter-spacing: -0.25px;
  padding: 10px;
  resize: vertical;
  height: auto;
  font-weight: 300;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.leads-form__form__personal-information__textarea:focus {
  outline: none;
}
.leads-form__checkboxes {
  margin: 0 30px;
}
.leads-form__checkboxes .hundred-ui-checkbox__label__text {
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.leads-form__checkboxes .hundred-ui-checkbox__label:after {
  font-weight: 100;
}
.leads-form__termscancel {
  align-items: center;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}
.leads-form__termscancel__terms {
  display: inline-block;
  margin-bottom: 0;
  color: #4a4a4a;
  font-size: 12px;
  letter-spacing: -0.29px;
  font-weight: 300;
  width: 100%;
  text-align: start;
}
.leads-form__termscancel__terms__link {
  color: #005fc9;
  cursor: pointer;
}
.leads-form__termscancel__cancel {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  padding: 0;
  margin: auto;
  width: 40%;
  max-width: inherit;
  text-transform: none;
  border: none;
  text-align: right;
  text-decoration: underline;
}
.leads-form__buttons {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  padding: 25px 30px 8px;
}
.leads-form__buttons__agree,
.leads-form__buttons__cancel {
  margin: auto;
  width: 100%;
  max-width: inherit;
  text-transform: capitalize;
}
.leads-form__buttons__cancel {
  height: 45px;
  margin-top: 20px;
  border: none;
}
.leads-form__buttons .hundred-ui-button {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.leads-form__divider {
  display: inline-block;
  margin: auto;
  width: 100%;
  height: 1px;
  background-color: rgba(0,0,0,0.1);
  margin: 20px auto 25px;
}
.leads-form__container-multi-leads__text {
  box-sizing: border-box;
  padding: 0 30px;
  color: #4a4a4a;
}
.leads-form__container-multi-leads__text__title {
  font-size: 26px;
  letter-spacing: -0.5px;
  font-weight: 700;
  margin-bottom: 30px;
}
.leads-form__container-multi-leads__text__message {
  font-size: 17px;
  letter-spacing: -0.25px;
  text-align: left;
  line-height: 35px;
  height: 117px;
}
.leads-form__container-multi-leads__text__message__second-line {
  display: block;
}
.leads-form__container-multi-leads__similars-container {
  box-sizing: border-box;
  padding: 0 30px 35px;
  height: auto;
  width: 100%;
  display: block;
}
.leads-form__container-multi-leads__similars-container .snippet-similar {
  height: 175px;
  border: 2px solid #ccdff4;
  background-color: #ebeef2;
  width: 24%;
  margin: 0 4px;
}
.leads-form__container-multi-leads__similars-container .snippet-similar:first-child {
  margin-left: 0;
}
.leads-form__container-multi-leads__similars-container .snippet-similar:last-child {
  margin-right: 0;
}
.leads-form__container-multi-leads__similars-container .snippet-similar.-no-checked {
  opacity: 0.35;
}
.leads-form__container-multi-leads__buttons {
  padding: 0 30px;
}
.leads-form__container-multi-leads__buttons__agree {
  max-width: none;
  width: 100%;
  margin-bottom: 5px;
}
.leads-form__container-multi-leads__buttons .hundred-ui-button {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.leads-form__container-multi-leads__buttons .hundred-ui-button:disabled {
  background-color: #005fc9;
  opacity: 0.35;
}
.leads-form__container-multi-leads__buttons__cancel {
  border: none;
  text-align: right;
  float: right;
  text-decoration: underline;
}
.leads-form__container-multi-leads__buttons .hundred-ui-button {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  padding-right: 0;
}
.leads-form__container-multi-leads,
.leads-form__container-thank-you {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-align: center;
  height: 680px;
}
.leads-form__container-multi-leads__header-close,
.leads-form__container-thank-you__header-close {
  line-height: 30px;
  text-align: right;
  display: block;
  padding: 20px 30px 30px;
  color: #808080;
  font-size: 15px;
  letter-spacing: -0.25px;
}
.leads-form__container-multi-leads__image-container,
.leads-form__container-thank-you__image-container {
  margin-bottom: 25px;
  height: 60px;
  display: flex;
  justify-content: center;
}
.leads-form__container-multi-leads__image-container__image,
.leads-form__container-thank-you__image-container__image {
  display: block;
  height: 100%;
}
.leads-form__container-thank-you__message-container {
  height: 360px;
  padding: 0 30px;
}
.leads-form__container-thank-you__message-container__title {
  margin-bottom: 40px;
  color: #4a4a4a;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  line-height: 1.5;
}
.leads-form__container-thank-you__message-container__subtitle {
  font-size: 20px;
  color: #4a4a4a;
  font-weight: $normal;
  text-align: center;
  line-height: 1.5;
}
.leads-form__container-thank-you__message-container__text {
  font-size: 20px;
  color: #4a4a4a;
  margin: 40px auto 40px auto;
  font-weight: $normal;
  text-align: center;
  line-height: 1.5;
}
.leads-form__container-thank-you__button-container {
  padding: 20px 30px;
}
.leads-form__container-thank-you__button-container__button {
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 500;
  margin: auto;
  width: 100%;
  max-width: inherit;
  text-transform: none;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .leads-form {
    margin: 0 auto;
  }
  .leads-form__container-multi-leads__similars-container .snippet-similar {
    flex-basis: 24%;
  }
}
@media (max-width: 767px) {
  .leads-form {
    height: auto;
    width: 100vw;
  }
  .leads-form__container-form {
    height: auto;
  }
  .leads-form__header {
    align-items: baseline;
  }
  .leads-form__header__close {
    margin-bottom: 0;
  }
  .leads-form__destination-container__title {
    margin-bottom: 10px;
  }
  .leads-form__datesandguests {
    flex-direction: column;
  }
  .leads-form__datesandguests__dates-container {
    width: 100%;
    margin-bottom: 25px;
  }
  .leads-form__datesandguests__dates-container__dates__date {
    margin-bottom: 3px;
  }
  .leads-form__datesandguests__dates-container__title {
    margin-bottom: 5px;
  }
  .leads-form__datesandguests__guests-container {
    width: 100%;
    margin-left: 0px;
  }
  .leads-form__datesandguests__guests-container__title {
    margin-bottom: 10px;
  }
  .leads-form__divider {
    height: 1px;
  }
  .leads-form__form__personal-information__name,
  .leads-form__form__personal-information__surname,
  .leads-form__form__personal-information__countries,
  .leads-form__form__personal-information__phone,
  .leads-form__form__personal-information__email {
    margin-left: 0;
    width: 100%;
    max-width: inherit;
  }
  .leads-form__container-multi-leads__text__title {
    font-size: 24px;
  }
  .leads-form__container-multi-leads__text__message {
    text-align: left;
    line-height: 25px;
    font-size: 16px;
    letter-spacing: -0.25px;
  }
  .leads-form__container-multi-leads__text__message__second-line {
    margin-top: 10px;
  }
  .leads-form__container-multi-leads__image-container {
    margin-top: 30px;
  }
  .leads-form__container-multi-leads__similars-container {
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }
  .leads-form__container-multi-leads__similars-container .snippet-similar {
    max-width: 180px;
    flex-basis: 48%;
    margin: 8px 0;
  }
  .leads-form__container-thank-you {
    height: 100vh;
  }
  .leads-form__container-multi-leads {
    height: auto;
  }
  .leads-form__container-thank-you,
  .leads-form__container-multi-leads {
    min-height: 100vh;
    position: relative;
  }
  .leads-form__container-thank-you__message-container,
  .leads-form__container-multi-leads__message-container {
    height: 250px;
  }
  .leads-form__container-thank-you__message-container__title,
  .leads-form__container-multi-leads__message-container__title {
    font-size: 22px;
  }
  .leads-form__container-thank-you__message-container__subtitle,
  .leads-form__container-multi-leads__message-container__subtitle,
  .leads-form__container-thank-you__message-container__text,
  .leads-form__container-multi-leads__message-container__text {
    font-size: 16px;
    letter-spacing: -0.25px;
    text-align: left;
  }
  .leads-form__container-thank-you__message-container__text,
  .leads-form__container-multi-leads__message-container__text {
    font-size: 16px;
    letter-spacing: -0.25px;
  }
  .leads-form__container-thank-you__button-container,
  .leads-form__container-multi-leads__button-container {
    padding: 50px 30px;
  }
  .leads-form__container-thank-you__inner-container,
  .leads-form__container-multi-leads__inner-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: 600px;
    margin: 0 auto;
    min-height: 100vh;
  }
  .leads-form__container-thank-you__message-container__text,
  .leads-form__container-multi-leads__message-container__text {
    margin-top: 20px;
  }
  .leads-form__container-thank-you__header-close,
  .leads-form__container-multi-leads__header-close {
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 1;
    padding-bottom: 0px;
    cursor: pointer;
  }
}
.low-availability {
  display: flex;
  flex-direction: column;
  color: #4a4a4a;
  padding: 20px;
  align-items: flex-start;
}
.low-availability__title {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 500;
}
.low-availability__text {
  font-size: 15px;
  letter-spacing: -0.25px;
  line-height: 21px;
  margin-bottom: 15px;
  text-align: left;
}
.low-availability__button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.low-availability__button-container__button {
  max-width: unset;
  border-color: #353a8e;
  color: #353a8e;
  line-height: 50px;
  height: 50px;
  max-width: unset;
  font-size: 15px;
  letter-spacing: -0.25px;
}
.low-availability__button-container .-cancel {
  margin-bottom: 15px;
  justify-content: center;
}
.low-availability__button-container .-cancel .hundredicons-cross {
  display: none;
}
@media (min-width: 500px) {
  .low-availability__button-container {
    flex-direction: row;
  }
  .low-availability__button-container__button {
    max-width: max-content;
    height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
  }
  .low-availability__button-container .-cancel {
    margin-right: 20px;
    margin-bottom: 0px;
    justify-content: initial;
  }
  .low-availability__button-container .-cancel .hundredicons-cross {
    display: inline-block;
    line-height: 5px;
    margin-left: 10px;
    font-size: 12px;
    letter-spacing: -0.29px;
  }
}
.menu-mobile {
  width: 440px;
  max-width: 85vw;
  min-height: 100vh;
  background-color: #fafafa;
}
.menu-mobile__list__item {
  padding: 0 30px;
  text-align: left;
  line-height: 80px;
  height: 80px;
}
.menu-mobile__list__item__link {
  font-weight: 500;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  width: 100%;
  line-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  cursor: pointer;
}
.menu-mobile__list__item__link__icon {
  flex-shrink: 0;
  font-size: 22px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  color: #4a4a4a;
  line-height: 80px;
}
.menu-mobile__list__item__link__icon:before {
  display: block;
  vertical-align: inherit;
  line-height: 80px;
}
.menu-mobile__list__item__link__text {
  font-size: 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 80px;
  flex-shrink: 2;
  margin: 0;
  color: #4a4a4a;
  padding-right: 5px;
}
.menu-mobile__list__item__link__badge {
  flex-shrink: 0;
  height: 40px;
  width: 40px;
  font-size: 16px;
  letter-spacing: -0.25px;
  border-radius: 20px;
  background-color: #e0e2ec;
  line-height: 43px;
  text-align: center;
  color: #4a4a4a;
}
.notification-modal.hundredmodal__content {
  width: 100%;
}
.notification-modal .notification {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 80px;
  width: 100%;
  padding: 1em;
  background: rgba(1,42,54,0.95);
}
.notification-modal .notification__content {
  padding-rigth: 1em;
}
.notification-modal .notification__content__title {
  color: #fff;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  letter-spacing: -0.5px;
}
.notification-modal .notification__action .btn {
  color: #356bc9;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  letter-spacing: -0.5px;
  background: none;
  text-transform: none;
  cursor: pointer;
  -webkit-appearance: none;
}
.notification-modal .notification__action .btn:hover {
  color: #fff;
}
@media (max-width: 767px) {
  .notification-modal.hundredmodal__content {
    height: auto;
    padding-bottom: 0;
  }
}
.page-establishment-hundred-rating {
  min-width: 300px;
  width: 100%;
  height: 100%;
}
.page-establishment-hundred-rating__title {
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}
.page-establishment-hundred-rating__text {
  color: #4a4a4a;
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  margin-bottom: 40px;
  line-height: 1.75;
}
.page-establishment-hundred-rating .hundred-rating-infographic {
  height: 300px;
  padding-bottom: 50px;
  position: relative;
}
.page-establishment-hundred-rating .hundred-rating-infographic__dot__inner {
  margin: 3px;
}
.page-establishment-hundred-rating .dropdown-mobile {
  width: 100%;
}
.page-establishment-hundred-rating .dropdown-mobile__container {
  padding-left: 0;
}
.page-establishment-hundred-rating .dropdown-mobile__container .page-establishment-hundred-rating__list__ratings__partners {
  max-width: 700px;
}
.page-establishment-hundred-rating .dropdown-mobile__container .page-establishment-hundred-rating__list__ratings__partners__partner {
  display: inline-flex;
  min-width: 125px;
  padding-right: 20px;
  white-space: nowrap;
  width: 33%;
}
.page-establishment-hundred-rating .dropdown-mobile__container .page-establishment-hundred-rating__list__ratings__partners__partner__number {
  font-weight: 600;
  padding-left: 4px;
}
@media (min-width: 768px) {
  .page-establishment-hundred-rating .hundred-rating-infographic {
    padding-bottom: 0px;
  }
  .page-establishment-hundred-rating .hundred-rating-infographic__dot__inner {
    font-size: 20px !important;
  }
  .page-establishment-hundred-rating__list {
    width: 58%;
    display: inline-block;
  }
  .page-establishment-hundred-rating__text {
    margin-bottom: 20px;
  }
}
@media (min-width: 1024px) {
  .page-establishment-hundred-rating__list {
    width: 64%;
  }
}
.page-establishment-info-new {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  float: left;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 400;
  justify-content: space-between;
  margin: 0;
  padding: 20px 70px 10px;
  padding-bottom: 12px;
  position: relative;
  width: 100%;
}
.page-establishment-info-new__header-container {
  color: #4a4a4a;
  margin-bottom: 15px;
}
.page-establishment-info-new__header-container__title {
  display: flex;
  font-size: 21px;
  font-weight: 600;
  justify-content: space-between;
  line-height: 26px;
  margin-bottom: 14px;
  overflow: hidden;
}
.page-establishment-info-new__header-container__title__name__stars-container {
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: 2px;
  margin-left: 10px;
}
.page-establishment-info-new__header-container__title__average {
  border: 1px solid #1c1f56;
  border-radius: 2px;
  color: #1c1f56;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: -0.25px;
  height: 24px;
  line-height: 14px;
  margin-top: 4px;
  padding: 4px 0;
  text-align: center;
  width: 36px;
}
.page-establishment-info-new__header-container__amenities {
  display: inline-block;
  font-size: 16px;
  letter-spacing: -0.25px;
  font-weight: 400;
  line-height: 25px;
}
.page-establishment-info-new__header-container__amenities__right__categoryLiteral {
  display: none;
}
.page-establishment-info-new__header-container__amenities__left {
  display: block;
}
.page-establishment-info-new__header-container__amenities__left__separator {
  display: inline-block;
  padding: 0 4px;
}
.page-establishment-info-new__header-container__amenities__left__amenitie {
  display: inline-block;
}
.page-establishment-info-new__header-container__dates-container {
  white-space: nowrap;
}
.page-establishment-info-new__header-container__dates-container img {
  margin: 0 8px;
}
.page-establishment-info-new__body-container {
  align-items: flex-end;
  display: flex;
  height: 100%;
}
.page-establishment-info-new__body-container__partners-container {
  width: 100%;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 80px;
}
.page-establishment-info-new__body-container__partners-container__partner {
  display: flex;
  margin-bottom: 3px;
  width: 100%;
}
.page-establishment-info-new__body-container__partners-container table {
  width: 100%;
}
.page-establishment-info-new__body-container__partners-container table tr {
  border-color: #ccdff4;
  border-style: solid;
  border-width: 1px 0;
}
.page-establishment-info-new__body-container__partners-container table tr:first-child {
  border-width: 0 0 1px 0;
}
.page-establishment-info-new__body-container__partners-container table tr:last-child {
  border-width: 1px 0 0 0;
}
.page-establishment-info-new__body-container__partners-container table tr:only-child {
  border: none;
}
.page-establishment-info-new__body-container__partners-container table.-no-winner tr {
  border-color: #ccdff4;
  border-style: solid;
  border-width: 1px 0;
}
.page-establishment-info-new__body-container__partners-container table.-no-winner tr:last-child {
  border-width: 1px 0 0 0;
}
.page-establishment-info-new__body-container__partners-container__more-oferts {
  color: #005fc9;
  cursor: pointer;
  display: block;
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: 0.22px;
  line-height: 22px;
  margin: 10px 0;
  text-align: right;
  text-decoration: underline;
}
@media (min-width: 768px) {
  .page-establishment-info-new {
    max-width: 525px;
    padding: 20px 40px 10px;
    width: 100%;
  }
  .page-establishment-info-new__tooltip__rating {
    width: 200px;
  }
  .page-establishment-info-new__tooltip__rating .tooltip-inner {
    transform: translateX(20px);
  }
  .page-establishment-info-new__header-container__title {
    font-size: 24px;
  }
  .page-establishment-info-new__header-container__dates-container {
    display: none;
  }
  .page-establishment-info-new__header-container__amenities {
    flex-direction: row-reverse;
    display: flex;
    justify-content: flex-end;
  }
  .page-establishment-info-new__header-container__amenities__right__categoryLiteral {
    display: inline-block;
  }
  .page-establishment-info-new__header-container__amenities__separation-line {
    margin: 0 17px;
    border: 0.5px solid #ccdff4;
    display: block;
    width: 1px;
    box-sizing: border-box;
  }
  .page-establishment-info-new__header-container__amenities__left {
    display: grid;
  }
  .page-establishment-info-new__header-container__amenities__left__separator {
    display: none;
  }
  .page-establishment-info-new__body-container.-best-price-alone {
    align-items: end;
  }
}
@media (max-width: 767px) {
  .page-establishment-info-new {
    min-width: 320px;
    padding: 20px 3%;
  }
}
.-no-cursor {
  cursor: default;
}
.page-establishment-info {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  position: relative;
  background-color: #fff;
  height: 550px;
  max-width: 525px;
  width: 100%;
  padding: 30px 70px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 12px;
  float: left;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 400;
  margin: 0 auto;
}
.page-establishment-info__header-container {
  color: #4a4a4a;
}
.page-establishment-info__header-container__title {
  font-size: 22px;
  line-height: 26px;
  overflow: hidden;
  margin-bottom: 5px;
  font-weight: 600;
}
.page-establishment-info__header-container__title__name__stars-container {
  margin-left: 10px;
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: 2px;
}
.page-establishment-info__header-container__amenities {
  line-height: 25px;
  margin-bottom: 10px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-establishment-info__header-container__amenities__separator {
  box-sizing: border-box;
  height: 20px;
  width: 2px;
  border: 1px solid #eff0f5;
}
.page-establishment-info__header-container__amenities__amenitie {
  font-size: 13px;
  letter-spacing: -0.25px;
  padding: 0 7px;
  display: inline-block;
}
.page-establishment-info__header-container__amenities__amenitie:first-child {
  padding-left: 0px;
}
.page-establishment-info__header-container__properties {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.page-establishment-info__header-container__properties__icons {
  display: flex;
  height: auto;
  justify-content: flex-start;
  align-content: center;
  align-items: stretch;
}
.page-establishment-info__header-container__properties__icons__container {
  padding: 0 1em;
  border-left: 1px solid #eff0f5;
  display: flex;
  align-items: center;
}
.page-establishment-info__header-container__properties__icons__container:first-child {
  padding-left: 0;
  border-left: none;
}
.page-establishment-info__header-container__properties__icons__container__name {
  display: none;
  font-size: 13px;
}
.page-establishment-info__header-container__properties__icons .hundredicons-recomended-icon {
  color: #d85cfc;
  font-size: 20px;
  line-height: 0.8em;
}
.page-establishment-info__header-container__properties__cancellation_policy {
  cursor: pointer;
}
.page-establishment-info__header-container__properties__cancellation_policy .hundredicons-cancellation-simple {
  display: flex;
  align-items: center;
}
.page-establishment-info__header-container__properties__cancellation_policy .hundredicons-cancellation-simple:before {
  padding-right: 5px;
  font-size: 12px;
  letter-spacing: -0.29px;
}
.page-establishment-info__header-container__properties__cancellation_policy .-partial:before {
  color: #afb1d2;
}
.page-establishment-info__header-container__properties__cancellation_policy .-full:before {
  color: #5d5fa3;
}
.page-establishment-info__body-container__separation-line {
  border-bottom: 1px solid #e8e8e8;
  display: block;
  width: 100%;
  box-sizing: border-box;
}
.page-establishment-info__body-container__average-container,
.page-establishment-info__body-container__valoration-container,
.page-establishment-info__body-container__dates-container,
.page-establishment-info__body-container__price-container,
.page-establishment-info__body-container__partners-container {
  display: flex;
  justify-content: space-between;
  padding: 13px 0;
}
.page-establishment-info__body-container__average-container__left-average,
.page-establishment-info__body-container__valoration-container__left-average,
.page-establishment-info__body-container__dates-container__left-average,
.page-establishment-info__body-container__price-container__left-average,
.page-establishment-info__body-container__partners-container__left-average,
.page-establishment-info__body-container__average-container__left-dates,
.page-establishment-info__body-container__valoration-container__left-dates,
.page-establishment-info__body-container__dates-container__left-dates,
.page-establishment-info__body-container__price-container__left-dates,
.page-establishment-info__body-container__partners-container__left-dates,
.page-establishment-info__body-container__average-container__left-valoration,
.page-establishment-info__body-container__valoration-container__left-valoration,
.page-establishment-info__body-container__dates-container__left-valoration,
.page-establishment-info__body-container__price-container__left-valoration,
.page-establishment-info__body-container__partners-container__left-valoration {
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
}
.page-establishment-info__body-container__average-container__left-average,
.page-establishment-info__body-container__valoration-container__left-average,
.page-establishment-info__body-container__dates-container__left-average,
.page-establishment-info__body-container__price-container__left-average,
.page-establishment-info__body-container__partners-container__left-average,
.page-establishment-info__body-container__average-container__right-average,
.page-establishment-info__body-container__valoration-container__right-average,
.page-establishment-info__body-container__dates-container__right-average,
.page-establishment-info__body-container__price-container__right-average,
.page-establishment-info__body-container__partners-container__right-average,
.page-establishment-info__body-container__average-container__left-valoration,
.page-establishment-info__body-container__valoration-container__left-valoration,
.page-establishment-info__body-container__dates-container__left-valoration,
.page-establishment-info__body-container__price-container__left-valoration,
.page-establishment-info__body-container__partners-container__left-valoration,
.page-establishment-info__body-container__average-container__right-valoration,
.page-establishment-info__body-container__valoration-container__right-valoration,
.page-establishment-info__body-container__dates-container__right-valoration,
.page-establishment-info__body-container__price-container__right-valoration,
.page-establishment-info__body-container__partners-container__right-valoration,
.page-establishment-info__body-container__average-container__left-dates,
.page-establishment-info__body-container__valoration-container__left-dates,
.page-establishment-info__body-container__dates-container__left-dates,
.page-establishment-info__body-container__price-container__left-dates,
.page-establishment-info__body-container__partners-container__left-dates,
.page-establishment-info__body-container__average-container__right-dates,
.page-establishment-info__body-container__valoration-container__right-dates,
.page-establishment-info__body-container__dates-container__right-dates,
.page-establishment-info__body-container__price-container__right-dates,
.page-establishment-info__body-container__partners-container__right-dates,
.page-establishment-info__body-container__average-container__left-partners,
.page-establishment-info__body-container__valoration-container__left-partners,
.page-establishment-info__body-container__dates-container__left-partners,
.page-establishment-info__body-container__price-container__left-partners,
.page-establishment-info__body-container__partners-container__left-partners {
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  line-height: 24px;
  color: #4a4a4a;
  display: inline-block;
}
.page-establishment-info__body-container__average-container__left-partners,
.page-establishment-info__body-container__valoration-container__left-partners,
.page-establishment-info__body-container__dates-container__left-partners,
.page-establishment-info__body-container__price-container__left-partners,
.page-establishment-info__body-container__partners-container__left-partners {
  margin-top: -3px;
}
.page-establishment-info__body-container__average-container__right-average,
.page-establishment-info__body-container__valoration-container__right-average,
.page-establishment-info__body-container__dates-container__right-average,
.page-establishment-info__body-container__price-container__right-average,
.page-establishment-info__body-container__partners-container__right-average {
  color: #353a8e;
  font-size: 16px;
  letter-spacing: -0.25px;
  font-weight: bold;
}
.page-establishment-info__body-container__average-container__left-average,
.page-establishment-info__body-container__valoration-container__left-average,
.page-establishment-info__body-container__dates-container__left-average,
.page-establishment-info__body-container__price-container__left-average,
.page-establishment-info__body-container__partners-container__left-average,
.page-establishment-info__body-container__average-container__right-average,
.page-establishment-info__body-container__valoration-container__right-average,
.page-establishment-info__body-container__dates-container__right-average,
.page-establishment-info__body-container__price-container__right-average,
.page-establishment-info__body-container__partners-container__right-average {
  cursor: pointer;
}
.page-establishment-info__body-container__average-container__right-valoration,
.page-establishment-info__body-container__valoration-container__right-valoration,
.page-establishment-info__body-container__dates-container__right-valoration,
.page-establishment-info__body-container__price-container__right-valoration,
.page-establishment-info__body-container__partners-container__right-valoration {
  cursor: pointer;
  display: flex;
  font-weight: 400;
}
.page-establishment-info__body-container__average-container__right-valoration__bubble,
.page-establishment-info__body-container__valoration-container__right-valoration__bubble,
.page-establishment-info__body-container__dates-container__right-valoration__bubble,
.page-establishment-info__body-container__price-container__right-valoration__bubble,
.page-establishment-info__body-container__partners-container__right-valoration__bubble {
  width: 20px;
  height: 20px;
}
.page-establishment-info__body-container__average-container__right-valoration__comments,
.page-establishment-info__body-container__valoration-container__right-valoration__comments,
.page-establishment-info__body-container__dates-container__right-valoration__comments,
.page-establishment-info__body-container__price-container__right-valoration__comments,
.page-establishment-info__body-container__partners-container__right-valoration__comments {
  color: #4a4a4a;
  line-height: 24px;
  margin-left: 5px;
}
.page-establishment-info__body-container__average-container__right-dates,
.page-establishment-info__body-container__valoration-container__right-dates,
.page-establishment-info__body-container__dates-container__right-dates,
.page-establishment-info__body-container__price-container__right-dates,
.page-establishment-info__body-container__partners-container__right-dates {
  font-weight: 400;
  color: #4a4a4a;
  line-height: 24px;
  margin-left: 5px;
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
}
.page-establishment-info__body-container__price-container {
  display: flex;
  align-items: center;
}
.page-establishment-info__body-container__price-container__left-price {
  color: #4a4a4a;
}
.page-establishment-info__body-container__price-container__left-price__top {
  font-weight: 700;
  line-height: 24px;
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
}
.page-establishment-info__body-container__price-container__left-price__bottom {
  display: flex;
  line-height: 20px;
}
.page-establishment-info__body-container__price-container__left-price__bottom .hundredicons-cancellation-simple {
  font-size: 12px;
  letter-spacing: -0.29px;
  display: flex;
  align-items: center;
}
.page-establishment-info__body-container__price-container__left-price__bottom .-partial:before {
  color: #afb1d2;
}
.page-establishment-info__body-container__price-container__left-price__bottom .-full:before {
  color: #5d5fa3;
}
.page-establishment-info__body-container__price-container__left-price__bottom__partner_name {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
}
.page-establishment-info__body-container__price-container__right-price__top {
  color: #4a4a4a;
  text-align: right;
}
.page-establishment-info__body-container__price-container__right-price__top__discount {
  color: #ba124b;
  display: inline-block;
  font-size: 20px;
  font-weight: 350;
  line-height: 24px;
  margin-right: 6px;
  text-decoration: line-through;
}
.page-establishment-info__body-container__price-container__right-price__top__price {
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
}
.page-establishment-info__body-container__price-container__right-price__top__night {
  font-size: 13px;
  line-height: 21px;
}
.page-establishment-info__body-container__price-container__right-price__bottom {
  display: block;
  color: #808080;
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: right;
}
.page-establishment-info__body-container__partners-container {
  padding-top: 7px;
  padding-bottom: 5px;
  min-height: 35px;
  font-size: 12px;
  letter-spacing: -0.29px;
}
.page-establishment-info__body-container__partners-container__right-partners {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  font-size: 13px;
}
.page-establishment-info__body-container__partners-container__right-partners__partner {
  margin-bottom: 3px;
  display: flex;
}
.page-establishment-info__body-container__partners-container__right-partners__partner__isInstant {
  color: #fdd835;
  margin-right: 5px;
  font-size: 15px;
  letter-spacing: -0.25px;
}
.page-establishment-info__body-container__partners-container__right-partners__partner__name,
.page-establishment-info__body-container__partners-container__right-partners__partner__price {
  line-height: 16px;
}
.page-establishment-info__body-container__partners-container__right-partners__partner__name {
  color: #4a4a4a;
}
.page-establishment-info__body-container__partners-container__right-partners__partner__price:not(:empty) {
  color: #005fc9;
  cursor: pointer;
  margin-left: 5px;
}
.page-establishment-info__body-container__partners-container__no-partners {
  display: flex;
  align-items: center;
  line-height: 14px;
  color: #808080;
  font-size: 12px;
  letter-spacing: -0.29px;
}
.page-establishment-info__body-container__button-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.page-establishment-info__body-container__button-container__button {
  max-width: none;
  width: 100%;
  background-color: #005fc9;
  color: #fff;
  font-size: 15px;
  letter-spacing: -0.25px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-family: inherit;
}
.page-establishment-info__body-container__button-container__button:hover,
.page-establishment-info__body-container__button-container__button:focus {
  background-color: #005fc9;
  color: #fff;
}
.page-establishment-info__body-container__button-container__button__icon {
  color: #fdd835;
  margin-right: 5px;
  vertical-align: text-top;
}
.page-establishment-info__body-container__button-container__bottom {
  color: #808080;
  line-height: 14px;
  text-align: center;
  font-size: 12px;
  letter-spacing: -0.29px;
  letter-spacing: -0.25px;
}
@media (max-width: 1200px) {
  .page-establishment-info {
    padding: 30px 30px 10px;
    max-width: 410px;
  }
  .page-establishment-info__header-container__amenities__amenitie {
    padding: 0 5px;
  }
  .page-establishment-info__tooltip__rating {
    width: 200px;
  }
  .page-establishment-info__tooltip__rating .tooltip-inner {
    transform: translateX(-25px);
  }
}
@media (max-width: 767px) {
  .page-establishment-info {
    padding: 20px 5%;
    max-width: 98%;
    min-width: 320px;
    height: auto;
  }
  .page-establishment-info__body-container__button-container {
    display: none;
  }
}
.-no-cursor {
  cursor: default;
}
@media (max-width: 359px) {
  .page-establishment-info__body-container__price-container__right-price__top__night {
    display: none;
  }
  .page-establishment-info__body-container__price-container__left-price__top {
    font-size: 14px;
    letter-spacing: -0.25px;
  }
}
.page-establishment-inline {
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  height: 66px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.page-establishment-inline__first {
  display: flex;
}
.page-establishment-inline__first__image {
  height: 40px;
  margin: 13px 25px;
  display: flex;
  align-items: center;
}
.page-establishment-inline__first__quote {
  display: flex;
  align-items: center;
  height: 66px;
  color: #4a4a4a;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 21px;
  min-width: 175px;
}
@media (min-width: 1500px) {
  .page-establishment-inline__first__quote {
    font-size: 14px;
    letter-spacing: -0.25px;
  }
}
.page-establishment-inline .sharing-favourites {
  width: 525px;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-transform: uppercase;
}
.page-establishment-inline .sharing-favourites__container {
  margin-top: 0;
  height: 100%;
  background-color: #fff;
  align-items: center;
  width: 390px;
  margin: 0 auto;
}
.page-establishment-inline .sharing-favourites__container__favourites,
.page-establishment-inline .sharing-favourites__container__sharing {
  font-size: 12px;
  letter-spacing: -0.29px;
}
.page-establishment-inline .sharing-favourites__container__sharing {
  border-left: 0.5px solid #e6effa;
  border-right: none;
  order: 2;
  padding: 0 20px;
  width: 60%;
}
.page-establishment-inline .sharing-favourites__container__favourites {
  width: 40%;
  padding: 0 22px;
}
.page-establishment-inline .sharing-favourites__container .hundredicons-hr-icon-facebook,
.page-establishment-inline .sharing-favourites__container .hundredicons-whatsapp-icon-share,
.page-establishment-inline .sharing-favourites__container .hundredicons-hr-copylinkl-share-icon,
.page-establishment-inline .sharing-favourites__container .hundredicons-favorites,
.page-establishment-inline .sharing-favourites__container .hundredicons-favorites-filled,
.page-establishment-inline .sharing-favourites__container .hundredicons-check {
  font-size: 18px;
  letter-spacing: -0.25px;
  margin-left: 13px;
}
.page-establishment-inline .sharing-favourites__container .hundredicons-favorites,
.page-establishment-inline .sharing-favourites__container .hundredicons-favorites-filled {
  order: 2;
}
.page-establishment-inline .sharing-favourites__container .hundredicons-favorites:hover {
  color: #005fc9;
}
@media (max-width: 767px) {
  .page-establishment-inline {
    display: none;
  }
  .page-establishment-inline .sharing-favourites__container {
    flex-direction: inherit;
    margin: inherit;
  }
  .page-establishment-inline .sharing-favourites__container__sharing {
    border-bottom: none;
  }
}
@media (max-width: 1200px) {
  .page-establishment-inline .sharing-favourites {
    width: 410px;
  }
  .page-establishment-inline .sharing-favourites__container__sharing {
    width: 60%;
  }
  .page-establishment-inline .sharing-favourites__container__favourites {
    width: 40%;
  }
}
@media (max-width: 767px) {
  .policy-modal ~ i {
    display: none;
  }
}
.policy-modal {
  background-color: #fff;
  height: max-content;
  width: 100vw;
  display: block;
  position: relative;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 3px;
}
.policy-modal__title,
.policy-modal__subtitle {
  color: #005fc9;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  margin-bottom: 10px;
}
.policy-modal__subtitle {
  font-size: 16px;
  letter-spacing: -0.25px;
  font-weight: 500;
  line-height: 20px;
}
.policy-modal__buttons-container {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 100%;
  margin-top: 20px;
  flex-direction: column;
}
.policy-modal__buttons-container__accept,
.policy-modal__buttons-container__cancel {
  width: 100%;
  max-width: none;
}
.policy-modal__buttons-container__cancel {
  border: none;
  color: #4a4a4a;
  text-decoration: underline;
}
.policy-modal__text {
  line-height: 14px;
  font-size: 12px;
  letter-spacing: -0.29px;
}
.policy-modal .-bold-text {
  font-weight: 600;
}
.policy-modal .-uderline-text {
  text-decoration: underline;
}
@media (min-width: 768px) {
  .policy-modal {
    height: auto;
    width: 700px;
  }
  .policy-modal__buttons-container {
    width: 250px;
    flex-direction: row;
  }
  .policy-modal__buttons-container__accept,
  .policy-modal__buttons-container__cancel {
    max-width: 140px;
  }
}
#publishAdInline {
  display: block;
}
.publish-ad {
  width: 100%;
  height: auto;
  background-color: #fff;
}
.publish-ad__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  max-width: 1200px;
  margin: auto;
  padding: 150px 40px 50px;
}
.publish-ad__content__line,
.publish-ad__content__last-line {
  box-sizing: border-box;
  width: 100%;
  height: 1px;
  background-color: #ccdff4;
  position: relative;
  top: 20px;
  z-index: 0;
}
.publish-ad__content__last-line {
  position: relative;
  bottom: 0;
  top: auto;
  max-width: inherit;
}
.publish-ad__content__title {
  color: #4a4a4a;
  font-size: 28px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 50px;
  background-color: #fff;
  z-index: 1;
  margin-bottom: 25px;
  letter-spacing: -0.25px;
}
.publish-ad__content__body {
  color: #8b8b8b;
  font-size: 18px;
  letter-spacing: -0.25px;
  line-height: 24px;
  max-width: 300px;
  text-align: center;
  margin-bottom: 70px;
}
.publish-ad__content__ads {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin-bottom: 100px;
}
.publish-ad__content__ads .publish-link__inner__image {
  max-width: 150px;
}
@media (max-width: 767px) {
  .publish-ad__content {
    align-items: left;
    border-top: 1px solid #ccdff4;
    border-bottom: 1px solid #ccdff4;
    padding: 30px 7.03125% 0px;
  }
  .publish-ad__content__line,
  .publish-ad__content__last-line {
    display: none;
  }
  .publish-ad__content__title {
    text-align: left;
    padding: 0;
    font-size: 21px;
    margin-bottom: 20px;
  }
  .publish-ad__content__body {
    max-width: unset;
    margin-bottom: 30px;
    text-align: left;
    font-size: 14px;
    letter-spacing: -0.25px;
  }
  .publish-ad__content__ads {
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 0;
    margin-bottom: 20px;
  }
  .publish-ad__content__ads .publish-link {
    flex: 1;
    flex-shrink: 0;
    flex-basis: 50%;
    margin-bottom: 30px;
  }
  .publish-ad__content__ads .publish-link__inner__image {
    padding-right: 5px;
    max-width: 125px;
  }
}
.search-bar-molecule {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  padding: 0 7.03125%;
  margin: auto;
}
.search-bar-molecule__mobile {
  display: flex;
  justify-content: space-between;
  line-height: 65px;
  width: 100%;
  height: 64px;
  min-height: 64px;
  border-radius: 3px;
  color: #4a4a4a;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
  border: 1px solid #ccdff4;
  max-width: 89%;
  letter-spacing: -0.5px;
  padding-left: 15px;
  margin: 10px auto;
  background: #fff;
  box-sizing: border-box;
}
.search-bar-molecule__mobile__destination {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  letter-spacing: -0.25px;
  font-weight: normal;
  line-height: 63px;
  color: #4a4a4a;
  max-width: 80%;
  letter-spacing: -0.5px;
}
.search-bar-molecule__mobile__container {
  display: flex;
  height: 100%;
  width: 20%;
  background-color: #005fc9;
  float: right;
  border-radius: 0 3px 3px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-bar-molecule__mobile__container__icon {
  height: 56px;
  font-size: 21px;
  color: #fff;
}
.search-bar-molecule__desktop {
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
}
.search-bar-molecule__desktop__container {
  width: 100%;
}
.search-bar-molecule__desktop__box {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.15);
  border: 1px solid #ccdff4;
  border-radius: 3px;
  background-color: #fff;
}
.search-bar-molecule__desktop__box.-ja-jp .search-bar-molecule__desktop__box__destination .geosuggest__input {
  font-size: 14px;
  letter-spacing: -0.25px;
}
.search-bar-molecule__desktop__box.-ja-jp .search-bar-molecule__desktop__box__destination .geosuggest__item {
  font-size: 14px;
  letter-spacing: -0.25px;
}
.search-bar-molecule__desktop__box.-ja-jp .search-bar-molecule__desktop__box__dates .DateInput__display-text {
  font-size: 14px;
  letter-spacing: -0.25px;
}
.search-bar-molecule__desktop__box.-ja-jp .search-bar-molecule__desktop__box__guests__bottom__text {
  font-size: 14px;
  letter-spacing: -0.25px;
}
.search-bar-molecule__desktop__box__destination {
  width: 100%;
  height: 70px;
  padding: 14px 21px 12px;
  border-bottom: 1px solid #ccdff4;
  text-align: left;
  flex-shrink: 1;
}
.search-bar-molecule__desktop__box__destination__title {
  color: #005fc9;
  display: block;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 12px;
  font-weight: normal;
  text-transform: uppercase;
  padding-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.search-bar-molecule__desktop__box__destination__input {
  position: relative;
  width: 100%;
}
.search-bar-molecule__desktop__box__destination__input .search-form-destination__remove-button {
  position: relative;
  margin-top: -20px;
  margin-left: 90%;
  color: rgba(74,74,74,0.5);
  font-size: 15px;
  letter-spacing: -0.25px;
  height: $heightInputs;
  width: 23px;
  line-height: $heightInputs;
  text-align: center;
  cursor: pointer;
  transition: all cubic-bezier(0.78, 0.6, 0.4, 0.99) 0.5s;
}
.search-bar-molecule__desktop__box__destination__input .search-form-destination__remove-button.-hidden {
  transform: rotate(180deg);
  visibility: hidden;
  opacity: 0;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest {
  padding: 0;
  width: 100%;
  margin: 0;
  display: inline-block;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__input {
  height: 26px;
  line-height: 21px;
  font-size: 18px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
  padding-top: 3px;
  padding-left: 0;
  padding-right: 0;
  letter-spacing: -0.5px;
  margin: 0;
  width: 100%;
  border: 0px solid transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__input:focus {
  outline: none;
  border: none;
  box-shadow: none;
  box-shadow: 0 0 0 transparent;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__input-wrapper .geosuggest__input {
  height: 100%;
  font-weight: 500;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__input-wrapper .geosuggest__input::-webkit-input-placeholder {
  color: rgba(0,0,0,0.4);
}
@media (min-width: 768px) {
  .search-bar-molecule__desktop__box__destination__input .geosuggest__input-wrapper .geosuggest__input {
    height: auto;
  }
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__input-wrapper .-formInvalid::-webkit-input-placeholder {
  color: #ff7165;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__input-wrapper .-testA-placeHolder::-webkit-input-placeholder {
  color: #4a4a4a;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__suggests {
  margin: 16px -22px;
  width: auto;
  margin-top: 14px;
  color: #4a4a4a;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.15);
  border-radius: 3px;
  background: #fff;
  border: none;
  overflow: hidden;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__item {
  padding: 0 22px;
  line-height: 64px;
  padding-left: 22px;
  font-size: 18px;
  letter-spacing: -0.25px;
  height: 64px;
  line-height: 64px;
  display: flex;
  border-left: 1px solid #ccdff4;
  border-right: 1px solid #ccdff4;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__item:first-child {
  border-top: 1px solid #ccdff4;
  border-radius: 3px 3px 0 0;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__item:last-child {
  border-bottom: 1px solid #ccdff4;
  border-radius: 0 0 3px 3px;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__item .suggest-destination {
  width: 45%;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__item .suggest-arrival,
.search-bar-molecule__desktop__box__destination__input .geosuggest__item .suggest-leaving {
  padding-right: 10px;
  width: 140px;
}
.search-bar-molecule__desktop__box__destination__input .geosuggest__item .suggest-guests {
  padding-right: 10px;
  width: 130px;
}
.search-bar-molecule__desktop__box__destination__input #recentSearchesSuggestionList {
  width: auto;
  margin-top: 40px;
}
.search-bar-molecule__desktop__box__dates {
  border-bottom: 1px solid #ccdff4;
  padding: 14px 21px 12px;
  height: 70px;
}
.search-bar-molecule__desktop__box__dates__title {
  color: #005fc9;
  display: block;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 12px;
  text-transform: uppercase;
  padding-bottom: 4px;
  width: 100%;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-bar-molecule__desktop__box__dates .DateRangePicker {
  padding-left: 0;
  height: 21px;
  font-size: 18px;
  letter-spacing: -0.25px;
  margin-left: -3px;
}
.search-bar-molecule__desktop__box__dates .DateRangePicker .DateRangePickerInput {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-bar-molecule__desktop__box__dates .DateRangePicker .DateRangePickerInput__arrow {
  padding: 0;
}
.search-bar-molecule__desktop__box__dates .DateInput {
  padding: 0;
}
.search-bar-molecule__desktop__box__dates .DateInput__input {
  cursor: pointer;
  height: 21px;
}
.search-bar-molecule__desktop__box__dates .DateInput__input__display-text {
  line-height: 21px;
  font-size: 18px;
  letter-spacing: -0.25px;
  height: 21px;
  padding: 3px 0;
}
.search-bar-molecule__desktop__box__dates .DateInput .-formInvalid::-webkit-input-placeholder {
  color: #ff7165;
}
.search-bar-molecule__desktop__box__dates .-testA-placeHolder {
  color: #4a4a4a;
}
.search-bar-molecule__desktop__box__dates__info {
  position: absolute;
  bottom: 80px;
  margin-left: 0;
  display: flex;
  align-items: center;
}
.search-bar-molecule__desktop__box__dates__info.-without-checkboxes {
  bottom: 90px;
}
.search-bar-molecule__desktop__box__dates__info__dot {
  position: absolute;
  width: 5px;
  background-color: #17d280;
  height: 5px;
  border-radius: 50%;
  margin-left: 30px;
  margin-top: 1px;
}
.search-bar-molecule__desktop__box__dates__info__info {
  margin-left: 40px;
  font-size: 11px;
  letter-spacing: 0px;
}
.search-bar-molecule__desktop__box__dates__info__button-container {
  display: none;
}
.search-bar-molecule__desktop__box__guests {
  width: 100%;
  padding: 14px 21px 12px;
  user-select: none;
  text-align: left;
  flex-shrink: 0;
}
.search-bar-molecule__desktop__box__guests__title {
  display: none;
  color: #005fc9;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 12px;
  text-transform: uppercase;
  padding-bottom: 4px;
  width: 100%;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-bar-molecule__desktop__box__guests__bottom {
  display: flex;
  justify-content: space-between;
  align-items: left;
}
.search-bar-molecule__desktop__box__guests__bottom__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4a4a4a;
  display: inline-block;
  font-size: 18px;
  letter-spacing: -0.25px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 32px;
  text-transform: lowercase;
  vertical-align: middle;
  width: auto;
}
.search-bar-molecule__desktop__box__guests__bottom__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.search-bar-molecule__desktop__box__guests__bottom__buttons__button {
  align-items: center;
  border-radius: 50%;
  border: 1px solid #ccdff4;
  color: #005fc9;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  letter-spacing: -0.29px;
  height: 40px;
  justify-content: center;
  margin: 0;
  text-align: center;
  width: 40px;
  pointer-events: inherit;
}
.search-bar-molecule__desktop__box__guests__bottom__buttons__button:before {
  font-weight: 700;
  line-height: 0px;
  margin-right: 0px;
}
.search-bar-molecule__desktop__box__guests__bottom__buttons__button:first-child {
  margin-left: 0;
  margin-right: 10px;
}
.search-bar-molecule__desktop__box__guests__bottom__buttons__button:before {
  line-height: 0px;
}
.search-bar-molecule__desktop__box__action {
  width: auto;
  padding: 10px;
  text-align: right;
  flex-shrink: 0;
}
.search-bar-molecule__desktop__box__action__button-serp,
.search-bar-molecule__desktop__box__action__button {
  width: 100%;
  height: 60px;
  background-color: #353a8e;
  border-radius: 3px;
  color: #fff;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: -0.25px;
  font-weight: 600;
  padding: 0 20px;
  text-transform: uppercase;
  cursor: pointer;
}
.search-bar-molecule__desktop__box__action .hundredicons-search:before,
.search-bar-molecule__desktop__box__action .hundredicons-icon-loading:before {
  color: #fff;
  margin-right: 10px;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: -0.25px;
  vertical-align: middle;
}
.search-bar-molecule__desktop__box__action .hundredicons-icon-loading:before {
  display: inline-block;
  animation: infinite-spinning 1s linear infinite;
}
.search-bar-molecule__desktop__box .-serp-search-button {
  padding-right: 15px;
  display: flex;
  justify-content: center;
}
.search-bar-molecule__desktop__box .-serp-search-button .-loading-serp {
  color: #353a8e;
}
.search-bar-molecule__desktop__box .-serp-search-button .hundredicons-icon-loading:before {
  position: absolute;
  color: #fff;
  left: 40%;
  top: 29%;
}
.search-bar-molecule__bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 980px;
  margin: auto;
}
.search-bar-molecule__bottom__checkboxes {
  display: flex;
  flex-direction: flex-start;
  width: 100%;
  margin: 0 auto;
  max-width: 980px;
  height: 75px;
  line-height: 75px;
  padding-left: 25px;
  align-items: center;
}
.search-bar-molecule__bottom__checkboxes.-is-on-calendar {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.search-bar-molecule__bottom__checkboxes__item {
  width: auto;
}
.search-bar-molecule__bottom__checkboxes .hundred-ui-checkbox__label__text {
  color: #9e9e9e;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: normal;
  line-height: 50px;
}
.search-bar-molecule__bottom__checkboxes .hundred-ui-checkbox__label__text.-is-on-calendar {
  font-size: 12px;
  letter-spacing: -0.29px;
}
.search-bar-molecule__bottom__checkboxes__title {
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: normal;
  line-height: 19px;
  margin: 0 10px 0 0;
  line-height: 20px;
  text-align: left;
}
.search-bar-molecule__bottom__checkboxes__title.-is-on-calendar {
  font-size: 12px;
  letter-spacing: -0.29px;
  margin-left: 5px;
}
.search-bar-molecule__bottom__checkboxes__checkbox {
  font-size: 12px;
  letter-spacing: -0.29px;
  font-weight: normal;
  line-height: 50px;
  color: #4a4a4a;
}
.search-bar-molecule__bottom__compare-image {
  align-items: center;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px;
  width: 100%;
}
.home.sem .search-bar-molecule__bottom__compare-image {
  display: flex;
  align-items: baseline;
}
.search-bar-molecule__bottom__compare-image__img {
  background-image: url("/img/pages/sem/compare-free-arrow.png");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  text-indent: -1000%;
  overflow: hidden;
  height: 24px;
  width: 30px;
}
@media (min-width: 768px) {
  .search-bar-molecule__bottom__compare-image__img {
    background-image: url("/img/pages/sem/compare-free-arrow.png");
  }
}
.search-bar-molecule__bottom__compare-image__text {
  display: table;
  white-space: pre-wrap;
  margin-right: 0.1em;
  width: 5em;
  line-height: 1.1em;
  font-size: 0.9em;
  transform-origin: center right;
  transform: rotate(-15deg) translateY(-10px);
  text-align: center;
  color: #fff;
  font-weight: 500;
}
@media (min-width: 768px) {
  .search-bar-molecule__bottom__compare-image__text {
    color: #005fc9;
    font-size: 1.1em;
  }
}
.search-bar-molecule.-show-color-box {
  background-color: #005fc9;
  border-radius: 8px;
  padding: 18px 22px 0;
  width: calc(100% - 32px);
  max-width: 600px;
  transform: translateZ(0);
}
.search-bar-molecule.-show-color-box .search-bar-molecule__mobile {
  padding: 4px 4px 4px 15px;
  max-width: 100%;
  border: 0;
  box-shadow: none;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__mobile__destination {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #61625e;
  line-height: 56px;
  font-weight: normal;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__mobile__destination.-has-not-destination:before {
  animation-name: opacity;
  animation-duration: 0.8s;
  animation-delay: 0.3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  display: inline-block;
  content: ' ';
  height: 22px;
  background-color: #005fc9;
  width: 2px;
  transform: translateY(3px);
}
.search-bar-molecule.-show-color-box .search-bar-molecule__mobile__container {
  background-color: #1c1f56;
  border-radius: 3px;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__mobile__container__icon {
  color: #fff;
  height: 56px;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes {
  min-height: 38px;
  padding: 7px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes.-is-empty {
  height: 0;
  min-height: 0;
  padding-top: 5px;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes__title,
.search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes__checkbox {
  color: #fff;
  font-size: 13px;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes__text {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.25px;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes__item .hundred-ui-checkbox__label__text {
  color: #fff;
  font-size: 13px;
}
.search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes__item .hundred-ui-checkbox__label:after {
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}
.search-bar-molecule .-grow {
  position: relative;
}
.search-bar-molecule .-grow:after {
  content: '';
  display: block;
  width: 0%;
  height: 2px;
  background-color: #1c1f56;
  position: absolute;
  bottom: -1px;
  max-width: 63%;
  animation: grow 0.3s linear;
  animation-fill-mode: forwards;
}
@media (min-width: 1024px) {
  .search-bar-molecule__mobile {
    display: none;
  }
}
@media (min-width: 768px) {
  .search-bar-molecule {
    height: 20vh;
    min-height: 100px;
  }
  .search-bar-molecule .search-form-destination__remove-button {
    display: inline-block;
    margin-left: 0;
    margin-top: 0;
  }
  .search-bar-molecule.-show-color-box {
    background-color: #fff;
    padding: 30px 30px 0;
    height: auto;
    min-height: inherit;
    max-width: 1040px;
  }
  .search-bar-molecule.-show-color-box .search-bar-molecule__desktop__box__action__button {
    background-color: #353a8e;
  }
  .search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes {
    min-height: 30px;
    height: auto;
  }
  .search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes__title {
    color: #4a4a4a;
  }
  .search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes.-is-empty {
    height: inherit;
    min-height: 62px;
  }
  .search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes .hundred-ui-checkbox__label:after {
    box-shadow: inset 0 0 0 1px #ccdff4;
  }
  .search-bar-molecule.-show-color-box .search-bar-molecule__bottom__checkboxes .hundred-ui-checkbox__label__text {
    color: #9e9e9e;
  }
  .search-bar-molecule__bottom__checkboxes.is-on-calendar {
    width: 50%;
  }
  .search-bar-molecule__desktop {
    display: block;
  }
  .search-bar-molecule__desktop__box {
    flex-direction: row;
    height: 82px;
  }
  .search-bar-molecule__desktop__box__destination {
    max-width: 400px;
    border-right: 1px solid #ccdff4;
    height: 82px;
    border-bottom: 0;
    padding: 0 5px 0 22px;
  }
  .search-bar-molecule__desktop__box__destination__input #recentSearchesSuggestionList {
    width: 86vw;
  }
  .search-bar-molecule__desktop__box__destination__input .geosuggest {
    width: 88%;
  }
  .search-bar-molecule__desktop__box__destination__input .geosuggest__input {
    height: 44px;
    font-size: 18px;
    letter-spacing: -0.25px;
    line-height: 44px;
  }
  .search-bar-molecule__desktop__box__destination__input .geosuggest__suggests {
    width: 86vw;
    margin-left: -23px;
    max-width: 980px;
  }
  .search-bar-molecule__desktop__box__destination__suggestion-list {
    position: relative;
  }
  .search-bar-molecule__desktop__box__destination__suggestion-list__title {
    position: absolute;
    top: 15px;
    color: #1c1f56;
  }
  .search-bar-molecule__desktop__box .-grow:after {
    bottom: 1px;
    transform: translateX(-22px);
    max-width: inherit;
  }
  .search-bar-molecule__desktop__box__dates {
    border-right: 1px solid #ccdff4;
    border-bottom: 0;
    padding: 0;
    height: 82px;
    padding-left: 22px;
    max-width: 33%;
  }
  .search-bar-molecule__desktop__box__dates__info__button-container {
    height: 75px;
    line-height: 75px;
    position: absolute;
    right: 0;
    margin-right: 25px;
    bottom: 0;
    display: flex;
    align-items: center;
  }
  .search-bar-molecule__desktop__box__dates__info__button-container__nights {
    padding-right: 20px;
    color: #9e9e9e;
    font-size: 14px;
    letter-spacing: -0.25px;
  }
  .search-bar-molecule__desktop__box__dates__info__button-container__button {
    height: 40px;
    width: 125px;
    border-radius: 3px;
    background-color: #353a8e;
    color: #fff;
    font-weight: 600;
    line-height: 16px;
    font-size: 14px;
    letter-spacing: -0.25px;
    opacity: 1;
    transition: opacity 0.3s;
  }
  .search-bar-molecule__desktop__box__dates__info__button-container__button:before {
    padding-right: 10px;
    color: #fff;
  }
  .search-bar-molecule__desktop__box__dates__info__button-container__button:hover {
    cursor: pointer;
  }
  .search-bar-molecule__desktop__box__dates__info__button-container__button:disabled {
    cursor: default;
    color: #fff;
    opacity: 0.5;
  }
  .search-bar-molecule__desktop__box__dates__info__button-container__button:disabled:before {
    color: #fff;
  }
  .search-bar-molecule__desktop__box__dates__info__button-container__button:disabled:hover,
  .search-bar-molecule__desktop__box__dates__info__button-container__button:disabled:focus {
    border-color: #353a8e;
    background-color: #353a8e;
    cursor: default;
    color: #fff;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker {
    margin-top: 0.1em;
    padding-left: 0;
    height: inherit;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .-no-margin {
    height: 310px !important;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .-below-margin {
    height: 360px !important;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .-below-margin:before {
    bottom: 45px;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .-with-cheapest {
    height: 360px !important;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .-with-cheapest:before {
    bottom: 45px;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .-without-checkboxes {
    bottom: 20px;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .DateRangePickerInput {
    width: 272px;
    overflow: visible;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .DateRangePickerInput .DateInput {
    padding: 0;
    height: 44px;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .DateRangePickerInput .DateInput__input {
    cursor: pointer;
    height: 44px;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .DateRangePickerInput .DateInput__display-text {
    line-height: 35px;
    height: 35px;
  }
  .search-bar-molecule__desktop__box__dates .DateRangePicker .DateRangePickerInput__arrow {
    height: 44px;
    line-height: 44px;
  }
  .search-bar-molecule__desktop__box__guests {
    padding: 0 18px 0 18px;
    height: 82px;
    width: auto;
    min-width: 130px;
  }
  .search-bar-molecule__desktop__box__guests__bottom {
    margin-top: 7px;
    padding-top: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
  }
  .search-bar-molecule__desktop__box__guests__bottom__text {
    font-size: 18px;
    letter-spacing: -0.25px;
    line-height: 25px;
    min-width: 31px;
    margin-right: 5px;
    text-align: left;
  }
  .search-bar-molecule__desktop__box__guests__bottom__text__description {
    display: none;
  }
  .search-bar-molecule__desktop__box__guests__bottom__buttons {
    padding-top: 5px;
  }
  .search-bar-molecule__desktop__box__guests__bottom__buttons__button {
    height: 26px;
    width: 26px;
    font-size: 10px;
    letter-spacing: 0px;
    margin: 0 3px;
  }
  .search-bar-molecule__desktop__box__guests__bottom__buttons__button:first-child {
    margin-right: 3px;
  }
  .search-bar-molecule__desktop__box__action {
    min-width: 150px;
  }
  .search-bar-molecule__desktop__box__action__button {
    background-color: #005fc9;
  }
  .search-bar-molecule__desktop__box__action__button:before {
    color: #fff;
  }
  .search-bar-molecule__desktop__box__destination__title,
  .search-bar-molecule__desktop__box__dates__title,
  .search-bar-molecule__desktop__box__guests__title {
    display: block;
    height: auto;
    width: auto;
    margin-top: calc(30px - 1.2em);
    padding-bottom: 0;
    margin-bottom: 0;
    overflow: initial;
    font-size: 15px;
    letter-spacing: -0.25px;
    letter-spacing: -0.25px;
    line-height: 1.2em;
    text-transform: inherit;
  }
  .search-bar-molecule__bottom {
    flex-direction: row;
  }
  .search-bar-molecule__bottom__checkboxes__title {
    line-height: 50px;
  }
  .search-bar-molecule__bottom__compare-image {
    justify-content: flex-end;
    padding-top: 10px;
    padding-right: 40px;
  }
  .search-bar-molecule__bottom__compare-image__img {
    background-image: url("/img/pages/sem/compare-free-arrow-desktop.png");
  }
}
.search-bar-molecule.-sem-outline-test .search-bar-molecule__desktop {
  border-radius: 5px;
  border: 8px solid #005fc9;
}
.search-bar-molecule.-sem-outline-test .search-bar-molecule__desktop__box {
  border: none;
}
@media (min-width: 1201px) {
  .search-bar-molecule__desktop__box__date {
    width: 280px;
  }
}
@-moz-keyframes grow-dates {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@-webkit-keyframes grow-dates {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@-o-keyframes grow-dates {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@keyframes grow-dates {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@-moz-keyframes grow {
  from {
    opacity: 100%;
    width: 0%;
  }
  to {
    opacity: 0%;
    width: 100%;
  }
}
@-webkit-keyframes grow {
  from {
    opacity: 100%;
    width: 0%;
  }
  to {
    opacity: 0%;
    width: 100%;
  }
}
@-o-keyframes grow {
  from {
    opacity: 100%;
    width: 0%;
  }
  to {
    opacity: 0%;
    width: 100%;
  }
}
@keyframes grow {
  from {
    opacity: 100%;
    width: 0%;
  }
  to {
    opacity: 0%;
    width: 100%;
  }
}
@-moz-keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 1201px) {
  .search-bar-molecule__desktop__box__destination__input {
    display: inline-flex;
  }
}
.ak-establishment-card {
  margin: 0 auto;
  background-color: #fff;
  width: 100%;
  height: 386px;
  max-width: 414px;
  width: auto;
  box-shadow: 0 1px 3px rgba(0,0,0,0.04), 0 1px 2px rgba(0,0,0,0.06);
}
.ak-establishment-card .establishment-card__top {
  max-height: 211px;
}
.ak-establishment-card .establishment-card__top .carrousel__innocuos-container,
.ak-establishment-card .establishment-card__top .carrousel__arrow-left,
.ak-establishment-card .establishment-card__top .carrousel__arrow-right {
  max-height: 211px;
}
.ak-establishment-card .establishment-card__top__carrousel__image {
  height: 211px;
}
.ak-establishment-card__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 15px 20px;
  color: #4a4a4a;
  font-size: 15px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  height: 175px;
  box-sizing: border-box;
}
.ak-establishment-card__body__title {
  display: block;
  font-weight: 500;
  line-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 70px;
  margin-bottom: 5px;
}
.ak-establishment-card__body__title__link:hover {
  color: #005fc9;
}
.ak-establishment-card__body__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.ak-establishment-card__body__type,
.ak-establishment-card__body__people-area,
.ak-establishment-card__body__rating-partner,
.ak-establishment-card__body__com-cta {
  margin-bottom: 5px;
}
.ak-establishment-card__body__rating-partner,
.ak-establishment-card__body__com-cta {
  display: flex;
  justify-content: space-between;
}
.ak-establishment-card__body__rating-partner__partner {
  color: #808080;
}
.ak-establishment-card__body__com-cta__cta {
  display: flex;
  color: #005fc9;
}
.ak-establishment-card__body__com-cta__cta__icon {
  display: flex;
  align-items: flex-end;
  font-size: 13px;
  padding-left: 1px;
}
.ak-establishment-card:hover {
  box-shadow: 0px 0px 5px #005fc9;
}
@media (min-width: 767px) {
  .ak-establishment-card {
    max-width: 260px;
    width: 260px;
    height: 300px;
  }
  .ak-establishment-card .establishment-card__top {
    height: 150px;
  }
  .ak-establishment-card .establishment-card__top__carrousel__image {
    height: 150px;
  }
  .ak-establishment-card__body {
    box-sizing: border-box;
    padding: 10px;
    height: 150px;
    line-height: 1.2em;
    font-size: 12px;
    letter-spacing: -0.29px;
    letter-spacing: -0.25px;
  }
  .ak-establishment-card__body__title {
    height: 45px;
    line-height: 1.3em;
    margin-bottom: 10px;
  }
  .ak-establishment-card__body__com-cta__cta__icon {
    align-items: center;
    font-size: 11px;
    letter-spacing: 0px;
  }
}
.snippet-ad {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.04), 0 1px 2px rgba(0,0,0,0.06);
  cursor: pointer;
  display: block;
}
.snippet-ad__header__image {
  background-size: cover;
}
.snippet-ad__body {
  display: flex;
}
.snippet-ad__body-left__text__title {
  color: #4a4a4a;
}
.snippet-ad__body-left__text__subtitle {
  line-height: 18px;
}
.snippet-ad__footer__button {
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: -0.25px;
}
.-serp-ad {
  display: block;
  height: 307px;
  margin: 10px auto;
  max-width: 600px;
  min-width: 320px;
  width: 100%;
}
.-serp-ad .snippet-ad__header__image {
  background-position: initial;
  background-repeat: no-repeat;
  display: flex;
  height: 174px;
}
.-serp-ad .snippet-ad__header__text {
  color: #909090;
  background-color: #fff;
  display: inline-block;
  letter-spacing: 0px;
  vertical-align: top;
  padding: 3px 5px;
  border-radius: 3px;
  margin-left: 15px;
  margin-top: 15px;
  font-size: 10px;
  letter-spacing: 0px;
  height: 12px;
}
.-serp-ad .snippet-ad__body {
  height: 123px;
  justify-content: space-between;
  padding: 10px 0px 0 10px;
}
.-serp-ad .snippet-ad__body-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.-serp-ad .snippet-ad__body-right__image {
  height: 20px;
}
.-serp-ad .snippet-ad__body-left__text__title {
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 500;
  text-align: left;
  text-overflow: ellipsis;
  letter-spacing: -0.5px;
  overflow: visible;
  margin-bottom: 6px;
  line-height: 19px;
}
.-serp-ad .snippet-ad__body-left__text__subtitle {
  color: #909090;
  font-size: 12px;
  letter-spacing: -0.29px;
  font-weight: 400;
  text-align: left;
  letter-spacing: -0.5px;
  width: 90%;
}
.-serp-ad .snippet-ad__body-bottom {
  display: none;
}
.-serp-ad .snippet-ad__footer__button {
  box-sizing: border-box;
  font-weight: 600;
  height: 40px;
  letter-spacing: -1px;
  line-height: 14px;
  margin-bottom: 3px;
  margin-right: 2px;
  padding: 5px;
  text-align: center;
  text-transform: initial;
  width: 90px;
}
@media (min-width: 768px) {
  .-serp-ad {
    display: inline-block;
    margin: 10px;
    width: 300px;
    min-width: 300px;
  }
}
@media (min-width: 1310px) {
  .-serp-ad {
    margin: 4px;
  }
}
.-seo-ad {
  margin: 0 auto;
  max-width: 414px;
  min-width: 320px;
  width: auto;
}
.-seo-ad .snippet-ad__header__image {
  background-color: #00f;
  height: 210px;
  max-height: 210px;
}
.-seo-ad .snippet-ad__header__text {
  display: none;
}
.-seo-ad .snippet-ad__body {
  flex-wrap: wrap;
  padding: 13px;
}
.-seo-ad .snippet-ad__body-left {
  width: 70%;
  min-height: 80px;
}
.-seo-ad .snippet-ad__body-left__text {
  font-weight: 500;
}
.-seo-ad .snippet-ad__body-left__text__title,
.-seo-ad .snippet-ad__body-left__text__subtitle {
  padding-bottom: 9px;
  font-size: 15px;
  letter-spacing: -0.25px;
}
.-seo-ad .snippet-ad__body-left__text__subtitle {
  color: #808080;
}
.-seo-ad .snippet-ad__body-right {
  width: 30%;
  text-align: end;
}
.-seo-ad .snippet-ad__body-right__image {
  width: 66px;
}
.-seo-ad .snippet-ad__body-right button {
  display: none;
}
.-seo-ad .snippet-ad__body-bottom {
  width: 100%;
  height: 44px;
}
.-seo-ad .snippet-ad__footer__button {
  font-weight: 500;
  height: 100%;
  width: 100%;
}
@media (min-width: 767px) {
  .-seo-ad {
    height: 300px;
    max-width: 260px;
    min-width: 260px;
    width: 260px;
  }
  .-seo-ad .snippet-ad__header__image {
    height: 150px;
  }
  .-seo-ad .snippet-ad__body-left__text {
    min-height: 90px;
  }
  .-seo-ad .snippet-ad__body-left__text__title,
  .-seo-ad .snippet-ad__body-left__text__subtitle {
    font-size: 12px;
    letter-spacing: -0.29px;
    padding-right: 5px;
    text-align: initial;
  }
  .-seo-ad .snippet-ad__body-right {
    width: 25%;
  }
  .-seo-ad .snippet-ad__body-bottom {
    height: 36px;
  }
}
.snippet-similar {
  margin: 5px 1px 5px 1px;
  width: 136px;
  height: 160px;
  background-color: #fff;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  transition: all ease 0.3s;
  box-sizing: border-box;
  position: relative;
}
.snippet-similar__checkbox {
  position: absolute;
  top: 15px;
  right: 15px;
  height: 20px;
  width: 20px;
  border: 2px solid #ccdff4;
  background-color: #fff;
  border-radius: 30px;
  text-align: center;
}
.snippet-similar__checkbox:before {
  font-size: 10px;
  letter-spacing: 0px;
  color: #005fc9;
  line-height: 16px;
}
.snippet-similar__image-container {
  position: relative;
}
.snippet-similar__image {
  display: block;
  width: 100%;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.snippet-similar__discount {
  position: absolute;
  left: auto;
  right: 0;
  bottom: 0px;
  background-color: #fff;
  border-radius: 3px 0 0 0;
  height: 22px;
  max-width: 70px;
  display: flex;
  justify-content: center;
  padding: 0 0.5em;
  z-index: 2;
}
.snippet-similar__discount__text {
  align-items: center;
  display: flex;
}
.snippet-similar__discount__text__number {
  line-height: 1em;
  margin-right: 0;
  line-height: 15px;
  font-size: 12px;
  letter-spacing: -0.29px;
  color: #ba124b;
  font-weight: 400;
}
.snippet-similar__discount__text__number.-ja-jp,
.snippet-similar__discount__text__number.-es-co {
  font-size: 15px;
  letter-spacing: -0.25px;
}
.snippet-similar__discount__line-through {
  border-bottom: 1px solid #ba124b;
  display: block;
  width: 80%;
  top: 11px;
  left: 6px;
  position: absolute;
  box-sizing: border-box;
}
.snippet-similar__type {
  bottom: 5px;
  position: absolute;
  left: 0;
  color: #fff;
  font-size: 12px;
  letter-spacing: -0.29px;
  padding: 0 12px;
  width: calc(100% - 24px);
  background-color: transparent;
  max-width: 100%;
  text-align: left;
}
.snippet-similar__type__info {
  width: 100%;
  display: flex;
  line-height: 20px;
  height: 16px;
  align-items: center;
}
.snippet-similar__type__info__type {
  z-index: 2;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: -0.25px;
  text-align: left;
  height: 20px;
  margin-right: 3px;
  white-space: nowrap;
}
.snippet-similar__type__info__name {
  z-index: 2;
  font-size: 15px;
  letter-spacing: -0.25px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
}
.snippet-similar__type__info__stars-container {
  z-index: 2;
  margin-left: 7px;
  display: flex;
}
.snippet-similar__type__info__stars {
  z-index: 2;
  display: flex;
}
.snippet-similar__type__info__stars:before {
  font-size: 10px;
  letter-spacing: 0px;
  letter-spacing: 3px;
}
.snippet-similar__type__text {
  font-size: 15px;
  letter-spacing: -0.25px;
  color: #fff;
  font-weight: 400;
  margin-left: -5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0,0,0,0.4);
  line-height: 1.2em;
  font-size: 11px;
  letter-spacing: 0px;
}
.snippet-similar__recomendation {
  align-items: center;
  background-color: #fff;
  border-radius: 0 3px 3px 0;
  display: flex;
  height: 18px;
  justify-content: center;
  left: 13px;
  padding: 5px 0;
  position: absolute;
  top: 13px;
  width: auto;
  z-index: 2;
}
.snippet-similar__recomendation__container {
  display: block;
  line-height: 0;
  padding-bottom: 5px;
}
.snippet-similar__recomendation__container .hundredicons-recomended-icon {
  font-size: 16px;
  letter-spacing: -0.25px;
  margin-left: 5px;
  position: relative;
}
.snippet-similar__recomendation__container .hundredicons-recomended-icon:before {
  font-size: 12px;
  letter-spacing: -0.29px;
}
.snippet-similar__recomendation:hover:before,
.snippet-similar__recomendation:hover:after {
  top: inherit;
  bottom: inherit;
}
.snippet-similar__recomendation__background {
  color: #d85cfc;
  display: inline-block;
  font-size: 22px;
  height: 22px;
  left: 5px;
  padding-top: 4px;
  position: absolute;
  top: 0;
}
.snippet-similar__recomendation__background:before {
  font-size: 20px;
}
.snippet-similar__recomendation__text {
  color: #4a4a4a;
  font-size: 15px;
  letter-spacing: -0.25px;
  font-weight: 400;
  left: 0;
  line-height: 12px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}
.snippet-similar__recomendation__tooltip .tooltip-arrow {
  left: 9%;
}
.snippet-similar__body {
  padding-top: 5px;
  margin-bottom: 4px;
  height: 26px;
  text-align: left;
}
.snippet-similar__body__price {
  font-size: 16px;
  letter-spacing: -0.25px;
  font-weight: 600;
  line-height: 26px;
  margin: 0;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
}
.snippet-similar__body__price__currency {
  font-size: 10px;
  letter-spacing: 0px;
  vertical-align: top;
  margin-left: 2px;
  line-height: 21px;
}
.snippet-similar__body__instant-booking {
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 26px;
  display: inline-block;
  vertical-align: middle;
  color: #fdd835;
  margin: 0 5px;
}
.snippet-similar__body__instant-booking:before {
  line-height: 26px;
}
.snippet-similar__body__hotdeals {
  background-color: #16d280;
  height: 15px;
  width: 32px;
  border-radius: 3px;
  text-align: center;
  font-size: 9px;
  line-height: 16px;
  color: #fff;
  font-weight: 400;
  float: right;
  margin-top: 6px;
}
.snippet-similar__footer {
  margin-top: 0px;
  font-size: 12px;
  letter-spacing: -0.29px;
}
.snippet-similar__footer__text {
  padding: 0 7px;
  height: 100%;
  display: block;
}
.snippet-similar__footer__text-upper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 10px 0;
  align-items: space-between;
  margin-bottom: 0;
}
.snippet-similar__footer__text-upper__left {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.snippet-similar__footer__text-upper__left__rating {
  display: flex;
  margin-top: 5px;
}
.snippet-similar__footer__text-upper__left__rating .hundred-rating__tooltip-container__inner__dot {
  display: none;
}
.snippet-similar__footer__text-upper__left__rating .hundred-rating__tooltip-container__inner__sub-block__adjective {
  display: none;
}
.snippet-similar__footer__text-upper .modal-map__container__upper-container__leftside__ammenities {
  text-align: left;
}
.snippet-similar__footer__price {
  font-weight: 700;
  color: #4a4a4a;
  display: flex;
  float: right;
  line-height: 100%;
  margin-bottom: 2px;
}
.snippet-similar__footer__price:before {
  color: #fdd835;
}
@media (min-width: 360px) {
  .snippet-similar {
    width: 162px;
  }
  .snippet-similar__footer__text-upper__left__rating .hundred-rating__tooltip-container__inner__dot {
    display: block;
  }
  .snippet-similar__footer__text-upper__left__rating .hundred-rating__tooltip-container__inner__sub-block__adjective {
    display: block;
  }
}
@media (min-width: 768px) {
  .snippet-similar {
    width: 227px;
    height: 220px;
  }
  .snippet-similar__recomendation {
    top: 13px;
    left: 13px;
    height: 27px;
  }
  .snippet-similar__recomendation__text {
    line-height: 29px;
  }
  .snippet-similar__recomendation__container .hundredicons-recomended-icon:before {
    font-size: 16px;
    letter-spacing: -0.25px;
  }
  .snippet-similar__recomendation__tooltip .tooltip-arrow {
    left: 14%;
  }
  .snippet-similar__type {
    align-items: center;
    background-color: transparent;
    bottom: 0px;
    cursor: pointer;
    display: flex;
    height: 30px;
    padding: 0 7px;
    position: absolute;
    font-size: 16px;
    letter-spacing: -0.25px;
  }
  .snippet-similar__footer {
    height: 80px;
  }
  .snippet-similar__footer__text-upper {
    height: 100%;
  }
  .snippet-similar__footer__text-upper__left {
    font-size: 16px;
    letter-spacing: -0.25px;
  }
  .snippet-similar__footer__text-upper__left .modal-map__container__upper-container__leftside__ammenities {
    font-size: 16px;
    letter-spacing: -0.25px;
  }
  .snippet-similar__footer__text-upper__left__rating {
    margin-top: 0px;
  }
  .snippet-similar__footer__text-upper__left__rating .hundred-rating__tooltip-container__inner {
    width: 150%;
  }
  .snippet-similar__image {
    height: 140px;
  }
  .snippet-similar__discount {
    height: 27px;
  }
  .snippet-similar__discount__text__number {
    font-size: 16px;
    letter-spacing: -0.25px;
  }
  .snippet-similar__discount__line-through {
    top: 13px;
  }
}
.snippet {
  max-width: 414px;
  min-width: 300px;
  width: 100%;
  height: 480px;
  background-color: #fff;
  display: block;
  border-radius: 3px;
  cursor: default;
  overflow: hidden;
  display: inline-block;
  user-select: none;
  margin: 10px auto;
  box-shadow: 0 1px 3px rgba(0,0,0,0.04), 0 1px 2px rgba(0,0,0,0.06);
  vertical-align: top;
  contain: strict;
}
.snippet__header {
  padding: 16px;
  box-sizing: border-box;
  height: 92px;
  text-align: left;
}
.snippet__header__top-container {
  display: flex;
}
.snippet__header__title {
  color: #4a4a4a;
  width: 100%;
  text-align: left;
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  font-weight: 500;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: -2px;
}
.snippet__header__attributes {
  color: #b3b3b3;
  width: 100%;
  font-weight: 400;
  text-align: left;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 16px;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  cursor: pointer;
}
.snippet__header__attributes__info {
  font-weight: 500;
  position: absolute;
  width: 239px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transform: translateY(2px);
}
.snippet__header__attributes__valoration {
  display: inline-block;
  margin-right: 5px;
  font-size: 12px;
  letter-spacing: -0.29px;
}
.snippet__header__attributes__valoration .valoration__data-container {
  padding: 0 4px;
}
.snippet__header__attributes__valoration__tooltip .tooltip-arrow {
  left: 16%;
}
.snippet__header__attributes span {
  vertical-align: text-top;
  font-size: 12px;
  letter-spacing: -0.29px;
}
.snippet__header__rankings {
  color: #f5a623;
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 70%;
  margin-top: 4px;
  min-height: 24px;
  cursor: pointer;
}
.snippet__header__rankings[name]:after,
.snippet__header__rankings[name]:before {
  transition: all ease 0.5s;
  transform: translate(0px, 0px);
  opacity: 0;
  left: 0;
  top: 0;
}
.snippet__header__rankings[name]:hover:after {
  background: #4a4a4a;
  border-radius: 3px;
  color: #fff;
  content: attr(name);
  display: block;
  font-size: 12px;
  letter-spacing: -0.29px;
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
  opacity: 1;
  padding: 12px 12px;
  position: absolute;
  text-align: center;
  transform: translate(-5px, 28px);
  width: 180px;
  z-index: 2;
  box-sizing: border-box;
}
.snippet__header__rankings[name]:hover:before {
  border-color: transparent transparent #4a4a4a transparent;
  border-style: solid;
  border-width: 6px;
  content: '';
  display: block;
  opacity: 1;
  position: absolute;
  box-sizing: border-box;
  height: 12px;
  transform: translate(0px, 18px);
}
.snippet__header__rankings__number {
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 12px;
  margin-right: 7px;
  font-weight: 500;
}
.snippet__header__rankings__stars {
  font-size: 10px;
  letter-spacing: 0px;
  margin-right: 3px;
}
.snippet__header__rankings__stars:before {
  line-height: 10px;
}
.snippet__header__rankings__quantity {
  color: #b3b3b3;
  margin-left: 4px;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 12px;
  font-weight: 400;
}
.snippet__header__rankings__with-out {
  font-size: 10px;
  letter-spacing: 0px;
  color: #b3b3b3;
  margin: 0;
  line-height: inherit;
}
.snippet__header__offers {
  width: auto;
  display: inline-block;
  vertical-align: top;
  text-align: right;
  cursor: pointer;
  float: right;
  position: relative;
  right: -5px;
}
.snippet__header__offers__hotdeals {
  width: auto;
  height: 25px;
  color: #fff;
  display: inline-block;
  text-align: right;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 25px;
  font-weight: 600;
  position: relative;
  background-color: #16d280;
  border-radius: 3px;
  padding: 0 5px;
  margin-right: -3px;
  margin-top: 32px;
  transform: translateY(-4px);
}
.snippet__header__offers__hotdeals:after,
.snippet__header__offers__hotdeals:before {
  transition: all ease 0.5s;
  transform: translate3d(0, 0, 0);
  opacity: 0;
}
.snippet__carrousel__image {
  background-position: center;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.snippet__carrousel .carrousel__arrow-container {
  position: relative;
}
.snippet__carrousel .carrousel__innocuos-container {
  height: 170px;
  background-color: #fafafa;
}
.snippet__carrousel .carrousel__arrow-left,
.snippet__carrousel .carrousel__arrow-right {
  height: 170px;
  width: 60px;
  max-height: 170px;
  min-height: 170px;
  display: flex;
}
.snippet__carrousel .carrousel__arrow-left {
  right: inherit;
  left: 0;
}
.snippet__carrousel .carrousel__arrow-right {
  right: 0;
  left: inherit;
}
.snippet__carrousel .carrousel__container {
  height: 170px;
  max-height: 170px;
  min-height: 170px;
}
.snippet__carrousel .carrousel__container__element {
  height: 170px;
  max-height: 170px;
  min-height: 170px;
}
.snippet__cancellation__envelop {
  height: 0;
  position: relative;
  z-index: 10;
}
.snippet__cancellation {
  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
  width: auto;
  padding: 0.5em 1em;
  background-color: rgba(255,255,255,0.85);
  cursor: pointer;
  font-size: 0.87em;
}
.snippet__cancellation__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0,0,0,0.4);
  line-height: 1.2em;
  font-size: 11px;
  letter-spacing: 0px;
}
.snippet__body {
  box-sizing: border-box;
  height: 167px;
  transition: all ease-in-out 0.35s;
  will-change: transform;
}
.snippet__body.-is-showed {
  border-top: 1px solid #efecec;
  height: 329px;
  transform: translate3d(0, -150px, 0);
  margin-bottom: 0;
  z-index: 1;
  position: relative;
}
.snippet__body.-is-showed.-is-showed {
  transform: rotate(180deg);
  box-shadow: 0px -2px 4px 0px rgba(0,0,0,0.1);
}
.snippet__body__carrousel {
  height: 167px;
}
.snippet__body__carrousel.-is-showed {
  height: auto;
}
.snippet__body__carrousel:not(.-is-showed) .carrousel__container {
  will-change: inherit;
  transform: inherit;
}
.snippet__body__carrousel .carrousel__innocuos-container {
  width: 100%;
  overflow: hidden;
  height: 339px;
  background: #fff;
}
.snippet__body__carrousel .carrousel__container {
  height: 339px;
  overflow: hidden;
  text-align: left;
}
.snippet__body__carrousel .carrousel__container__element {
  height: auto;
}
.snippet__body__comparative {
  padding: 16px;
  background: #fff;
  width: 100%;
}
.snippet__body__comparative__empty {
  display: inline-block;
  height: 39px;
  text-align: center;
  width: 100%;
}
.snippet__body__comparative__empty__text {
  text-align: center;
  font-size: 12px;
  letter-spacing: -0.29px;
  margin: 0;
  line-height: 50px;
  color: #4a4a4a;
  font-weight: 500;
}
.snippet__body__comparative__best-partner {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  text-align: left;
  height: 39px;
}
.snippet__body__comparative__best-partner__container {
  padding: 0 0 16px;
}
.snippet__body__comparative__best-partner__logo {
  height: 38px;
  width: 38px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  border: 1px solid rgba(185,198,203,0.25);
  text-align: center;
  overflow: hidden;
}
.snippet__body__comparative__best-partner__logo__image {
  width: 22px;
  height: 22px;
  vertical-align: middle;
  display: inline-block;
  margin-top: 7px;
}
.snippet__body__comparative__best-partner__text {
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
  max-width: 95px;
}
.snippet__body__comparative__best-partner__text__name {
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 14px;
  color: #4a4a4a;
  margin-top: 4px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.snippet__body__comparative__best-partner__text__best-price {
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 14px;
  color: #b3b3b3;
  margin-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.snippet__body__comparative__best-partner.-without-price {
  color: rgba(76,73,74,0.8);
  font-size: 14px;
  letter-spacing: -0.25px;
  text-align: center;
  width: 100%;
  padding-top: 10px;
}
.snippet__body__comparative__price-box {
  width: 50%;
  text-align: right;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  height: 39px;
}
.snippet__body__comparative__price-box__bolt,
.snippet__body__comparative__price-box__reco {
  display: inline-block;
  color: #fdd835;
  font-size: 20px;
  vertical-align: top;
  margin-top: 5px;
  margin-right: 5px;
}
.snippet__body__comparative__price-box__bolt:after,
.snippet__body__comparative__price-box__reco:after,
.snippet__body__comparative__price-box__bolt:before,
.snippet__body__comparative__price-box__reco:before {
  transition: all ease 0.5s;
  transform: translate3d(0, 0, 1, 180deg);
  opacity: 0;
}
.snippet__body__comparative__price-box__reco {
  color: #d85cfc;
}
.snippet__body__comparative__price-box__price {
  display: inline-block;
  color: #4a4a4a;
  text-align: right;
  height: 26px;
  margin-top: 4px;
}
.snippet__body__comparative__price-box__price__currency {
  color: #4a4a4a;
  display: inline-block;
  font-size: 12px;
  letter-spacing: -0.29px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 15px;
  margin-right: 3px;
  vertical-align: top;
}
.snippet__body__comparative__price-box__price__number {
  font-size: 24px;
  letter-spacing: -1.25px;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}
.snippet__body__comparative__price-box__type {
  display: block;
  width: 100%;
  margin-top: -4px;
  text-align: right;
  color: #b3b3b3;
  line-height: 10px;
  font-size: 10px;
  letter-spacing: 0px;
  text-transform: uppercase;
}
.snippet__body__comparative .-full-width {
  width: 100%;
}
.snippet__body__comparative .-full-width .snippet__body__comparative__best-partner__text {
  max-width: 100%;
}
.snippet__body__comparative__button-box {
  padding: 16px 0 0 0;
  text-align: center;
}
.snippet__body__comparative__button-box__button {
  margin: auto;
  width: 100%;
  max-width: 100%;
  border-radius: 3px;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 500;
  line-height: 50px;
  height: 50px;
  background-color: #005fc9;
  color: #fff;
  cursor: pointer;
  text-transform: inherit;
  display: block;
}
.snippet__body__comparative__button-box__button:hover {
  background-color: #005fc9;
  outline: none;
}
.snippet__body__comparative__button-box__button:focus {
  outline: none;
}
.snippet__body__comparative__button-box__button.-without-price {
  background-color: #b3b3b3;
}
.snippet__body__comparative__button-box__button.-without-price:hover {
  background-color: #b3b3b3;
}
.snippet__body__comparative__button-box__button.-without-price:focus {
  background-color: #b3b3b3;
}
.snippet__body__comparative__comparative {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.snippet__body__comparative__comparative__line {
  background-color: #efecec;
  height: 1px;
  display: block;
  flex: 1 1 auto;
}
.snippet__body__comparative__comparative__text {
  color: #b3b3b3;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 14px;
  font-weight: 400;
  padding: 0 10px;
  flex: 1 1 auto;
}
.snippet__body__comparative__comparative__link {
  color: #005fc9;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 14px;
  cursor: pointer;
  font-weight: 400;
  padding: 0 10px;
}
.snippet__body__comparative__comparative__link:hover {
  color: #005fc9;
}
.snippet__body__comparative__list {
  margin-top: 16px;
}
.snippet__body__comparative__list__item {
  display: block;
  padding: 8px 0;
  text-align: left;
}
.snippet__body__comparative__list__item__favicon {
  color: #4a4a4a;
  cursor: default;
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  margin-right: 5px;
}
.snippet__body__comparative__list__item__name {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
  text-align: left;
  font-weight: 500;
  cursor: default;
  line-height: 40px;
  width: 35%;
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.snippet__body__comparative__list__item__price {
  display: inline-block;
  vertical-align: middle;
  color: #4a4a4a;
  font-size: 14px;
  letter-spacing: -0.25px;
  text-align: left;
  cursor: default;
  font-weight: 500;
  line-height: 40px;
  padding: 0 5px;
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.snippet__body__comparative__list__item__button {
  display: inline-block;
  vertical-align: middle;
  height: 40px;
  background-color: #005fc9;
  color: #fff;
  border-radius: 2px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 42px;
  cursor: pointer;
  text-align: center;
  text-transform: inherit;
  max-width: inherit;
  min-width: 70px;
  padding: 0 5px;
  width: 29%;
}
.snippet__body__comparative__list__item__button.-whitout-price {
  font-size: 10px;
  letter-spacing: 0px;
}
.snippet__body__comparative__list__item__button:hover {
  background-color: #005fc9;
  outline: none;
}
.snippet__body__comparative__list__item__button:focus {
  outline: none;
}
.snippet__body__comparative__list__item__button.-whitout-price {
  background-color: rgba(76,73,74,0.8);
}
.snippet__body__services {
  display: inline-block;
  vertical-align: top;
  padding-top: 30px;
  width: 100%;
}
.snippet__body__services__list__item {
  display: inline-block;
  vertical-align: top;
  width: 33.3%;
  margin: 0;
  min-height: 90px;
}
.snippet__body__services__list__item__container {
  text-align: center;
  padding: 7.03125%;
}
.snippet__body__services__list__item__icon {
  font-size: 25px;
  width: 100%;
  display: block;
  text-align: center;
  height: 40px;
  color: #696969;
}
.snippet__body__services__list__item__icon:before {
  line-height: 40px;
}
.snippet__body__services__list__item__text {
  padding-top: 10px;
  font-size: 12px;
  letter-spacing: -0.29px;
  font-weight: 400;
  height: 45px;
  text-align: center;
  margin: 0;
  color: #4a4a4a;
  width: 100%;
  line-height: 1.3;
}
.snippet__body__sentimental {
  margin-top: 50px;
  padding: 0 16px;
}
.snippet__body__sentimental__rankings {
  text-align: center;
  color: #f5a623;
  margin-bottom: 20px;
}
.snippet__body__sentimental__rankings__number {
  display: block;
  font-size: 30px;
  font-weight: 500;
}
.snippet__body__sentimental__rankings__stars {
  margin-right: 3px;
  font-size: 10px;
  letter-spacing: 0px;
}
.snippet__body__sentimental__rankings__quantity {
  display: block;
  color: #b3b3b3;
}
.snippet__body__sentimental__bottom-text {
  color: #b3b3b3;
  text-align: center;
  margin-bottom: 0;
  display: inline-block;
}
.snippet__body__map {
  display: inline-block;
  vertical-align: top;
  height: 379px;
  width: 100%;
  background-size: cover;
  background-position: center;
  padding: 0;
}
.snippet__footer {
  height: 50px;
  border-top: 1px solid rgba(0,0,0,0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.snippet__footer__save,
.snippet__footer__share,
.snippet__footer__details {
  flex: 0 1 auto;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  color: #b3b3b3;
  font-weight: 400;
  cursor: pointer;
  transition: all ease 0.3s;
}
.snippet__footer__save {
  display: none;
  width: 25%;
  border-right: 1px solid rgba(0,0,0,0.05);
}
.snippet__footer__save__icon {
  font-size: 21px;
  line-height: 50px;
}
.snippet__footer__save__icon:before {
  line-height: 50px;
  vertical-align: middle;
}
.snippet__footer__save.-is-saved {
  color: #005fc9;
}
.snippet__footer__share {
  width: 50%;
}
.snippet__footer__share__text {
  font-weight: 500;
  display: inline-block;
}
.snippet__footer__share__icon {
  margin-right: 5px;
  font-size: 20px;
  line-height: 48px;
}
.snippet__footer__share__icon:before {
  line-height: 50px;
  vertical-align: middle;
}
.snippet__footer__details {
  width: 50%;
  flex: 1 2 auto;
  color: #005fc9;
  border-left: 1px solid rgba(0,0,0,0.05);
}
.snippet__footer__details__text {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: 0;
  line-height: 16px;
}
.snippet__footer__details__icon {
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 0;
  margin-left: 5px;
}
@media (min-width: 396px) {
  .snippet__carrousel__arrow-left {
    margin-left: 0;
  }
  .snippet__carrousel__arrow-right {
    left: inherit;
    margin-left: 325px;
  }
}
@media (min-width: 768px) {
  .snippet {
    width: 300px;
    margin: 10px;
  }
}
@media (max-width: 767px) {
  .snippet .snippet__header__attributes span {
    vertical-align: middle;
  }
}
@media (max-width: 660px) {
  .snippet .snippet__header__attributes__info {
    width: 320px;
  }
}
@media (min-width: 1024px) {
  .snippet__header__title {
    line-height: 18px;
  }
  .snippet__header__attributes {
    font-size: 12px;
    letter-spacing: -0.29px;
  }
  .snippet__header__rankings__number {
    font-weight: 400;
  }
  .snippet__header__rankings__quantity {
    font-size: 12px;
    letter-spacing: -0.29px;
    font-weight: 400;
  }
  .snippet__header__offers__hotdeals {
    font-weight: 500;
  }
  .snippet__header__offers__tooltip .tooltip-arrow {
    left: 84%;
  }
  .snippet__carrousel .carrousel:not(:hover) .snippet__carrousel__arrow-left,
  .snippet__carrousel .carrousel:not(:hover) .snippet__carrousel__arrow-right {
    opacity: 0;
  }
  .snippet__carrousel .carrousel__arrow-left,
  .snippet__carrousel .carrousel__arrow-right {
    display: flex;
  }
  .snippet__carrousel .carrousel__arrow-left {
    margin-left: 0;
  }
  .snippet__carrousel .carrousel__arrow-right {
    margin-left: 265px;
    left: inherit;
  }
  .snippet__body {
    margin: 0;
    height: 167px;
    overflow: hidden;
    will-change: inherit;
  }
  .snippet__body .carrousel__innocuos-container,
  .snippet__body .carrousel__container {
    display: inherit;
  }
  .snippet__body__comparative__price-box__price__currency {
    line-height: 15px;
    font-size: 12px;
    letter-spacing: -0.29px;
  }
  .snippet__body__comparative__price-box__price__number {
    line-height: 24px;
    font-size: 21px;
  }
  .snippet__body__button-box__button {
    font-size: 14px;
    letter-spacing: -0.25px;
    font-weight: 300;
  }
  .snippet__body__comparative__empty__text {
    line-height: 39px;
  }
  .snippet__body__comparative__text,
  .snippet__body__comparative__link {
    font-size: 12px;
    letter-spacing: -0.29px;
  }
  .snippet__footer__save:hover,
  .snippet__footer__share:hover,
  .snippet__footer__details:hover {
    background-color: rgba(179,179,179,0.1);
  }
}
.suggestion-box {
  background-color: #b3ebe8;
  border-top: 0.5px solid #65a7a5;
  width: 100%;
  height: 80px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 50px;
  background-repeat: repeat-x;
  background-position: -4% 94%;
  background-size: auto 30px;
}
.suggestion-box.-airbnb-decoration {
  background-image: url("/img/suggestion/desktop-ilustracion-suggestionbox.svg");
}
.suggestion-box.-closing-mobile {
  animation: grow-from-bottom 0.5s linear;
  animation-direction: reverse;
  animation-fill-mode: forwards;
}
.suggestion-box.-suggestion-box-growing {
  animation: grow-from-bottom 0.5s linear;
}
.suggestion-box__close {
  z-index: 999;
  transform: translateZ(1);
  cursor: pointer;
}
.suggestion-box__close:before {
  display: flex;
  background-color: #fd595e;
  border-radius: 100%;
  color: #fff;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 10px;
  top: -18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  letter-spacing: -0.25px;
}
.suggestion-box__inside {
  display: flex;
  justify-content: center;
  align-items: center;
}
.suggestion-box__inside__close {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #026964;
  cursor: pointer;
}
.suggestion-box__inside__logo {
  height: 30px;
  margin-right: 0.4em;
}
.suggestion-box__inside__container {
  margin-left: 0.4em;
}
.suggestion-box__inside__container__text {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.25px;
  color: #026964;
  text-align: center;
}
.suggestion-box__inside__container__button {
  display: none;
}
@media (min-width: 768px) {
  .suggestion-box {
    display: block;
    position: relative;
    height: 50px;
    width: 50px;
    background-color: #fd595e;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 50% 45%;
    cursor: pointer;
    padding: 0;
    box-sizing: unset;
    border-top: none;
    transition: all 0.3s cubic-bezier(0.01, 1.63, 0.24, 0.94);
    background-image: none;
  }
  .suggestion-box.-airbnb-decoration {
    background-image: url("/img/suggestion/airbnb-logo.svg");
  }
  .suggestion-box__close,
  .suggestion-box__inside {
    opacity: 0;
    visibility: hidden;
  }
  .suggestion-box__inside__container__button {
    height: 30px;
    line-height: 30px;
  }
  .suggestion-box:hover:not(.-close-no-hover),
  .suggestion-box.-open {
    background-image: none;
    animation: scale-it 0.3s linear;
    height: 245px;
    width: 245px;
    background-color: transparent;
    background-image: none;
    right: -35px !important;
    bottom: -35px !important;
    transition: none;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box,
  .suggestion-box.-open .suggestion-box {
    transition: none;
    background-image: none;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__close,
  .suggestion-box.-open .suggestion-box__close {
    transition: opacity 0.1s;
    opacity: 1;
    visibility: visible;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__close:before,
  .suggestion-box.-open .suggestion-box__close:before {
    background-color: #fd595e;
    font-size: 22px;
    padding: 8px;
    border-radius: 100%;
    color: #fff;
    z-index: 1;
    right: 50px;
    top: -10px;
    position: absolute;
    cursor: pointer;
    width: auto;
    height: auto;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside,
  .suggestion-box.-open .suggestion-box__inside {
    transition: opacity 0.3s;
    opacity: 1;
    visibility: visible;
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #b3ebe8;
    border-radius: 100%;
    border: 1px solid #026964;
    box-shadow: 0 0 5px 2px rgba(0,0,0,0.17);
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: 0 40%;
    background-size: 100%;
    background-repeat: repeat-x;
    animation: moving-background 6s linear infinite;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside.-airbnb-decoration,
  .suggestion-box.-open .suggestion-box__inside.-airbnb-decoration {
    background-image: url("/img/suggestion/desktop-ilustracion-suggestionbox.svg");
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside__close,
  .suggestion-box.-open .suggestion-box__inside__close {
    position: absolute;
    top: 20px;
    left: 70px;
    font-size: 10px;
    letter-spacing: 0px;
    color: #0cd2c6;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside__logo,
  .suggestion-box.-open .suggestion-box__inside__logo {
    display: block;
    position: absolute;
    width: 100px;
    top: 40px;
    margin-right: 0;
    height: auto;
    left: 70px;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside__container,
  .suggestion-box.-open .suggestion-box__inside__container {
    bottom: 45px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside__container__text,
  .suggestion-box.-open .suggestion-box__inside__container__text {
    margin-bottom: 12px;
    font-size: 14px;
    letter-spacing: -0.25px;
    max-width: 70%;
    text-align: center;
    font-weight: 500;
    color: #026964;
    margin-right: 5px;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside__container__button,
  .suggestion-box.-open .suggestion-box__inside__container__button {
    display: block;
    width: auto;
    color: #fff;
    background-color: #fd595e;
    height: 30px !important;
    line-height: inherit;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside__container__button:hover,
  .suggestion-box.-open .suggestion-box__inside__container__button:hover,
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside__container__button:active,
  .suggestion-box.-open .suggestion-box__inside__container__button:active {
    background-color: #fd595e;
  }
}
@-moz-keyframes grow-from-bottom {
  from {
    bottom: -100px;
  }
  to {
    bottom: 0;
  }
}
@-webkit-keyframes grow-from-bottom {
  from {
    bottom: -100px;
  }
  to {
    bottom: 0;
  }
}
@-o-keyframes grow-from-bottom {
  from {
    bottom: -100px;
  }
  to {
    bottom: 0;
  }
}
@keyframes grow-from-bottom {
  from {
    bottom: -100px;
  }
  to {
    bottom: 0;
  }
}
@-moz-keyframes moving-background {
  from {
    background-position: 0 40%;
  }
  to {
    background-position: -245px 40%;
  }
}
@-webkit-keyframes moving-background {
  from {
    background-position: 0 40%;
  }
  to {
    background-position: -245px 40%;
  }
}
@-o-keyframes moving-background {
  from {
    background-position: 0 40%;
  }
  to {
    background-position: -245px 40%;
  }
}
@keyframes moving-background {
  from {
    background-position: 0 40%;
  }
  to {
    background-position: -245px 40%;
  }
}
@-moz-keyframes scale-it {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.07);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes scale-it {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.07);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-o-keyframes scale-it {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.07);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-it {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.07);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.-suggestion-box-hidden {
  display: none;
}
.confirm-modal {
  background-color: #fff;
  width: 600px;
  margin: auto;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.04), 0 1px 2px rgba(0,0,0,0.06);
  padding: 30px;
  box-sizing: border-box;
  text-align: right;
  user-select: none;
  cursor: default;
}
.confirm-modal__close {
  display: block;
  color: #fff;
  transform: translate(70px, -70px);
  font-size: 18px;
  letter-spacing: -0.25px;
  height: 0;
  cursor: pointer;
}
.confirm-modal__header__title {
  font-weight: 500;
  font-size: 26px;
  line-height: 35px;
  text-align: left;
  color: #4a4a4a;
  margin-bottom: 20px;
}
.confirm-modal__header__message {
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 26px;
  text-align: left;
  color: #4a4a4a;
  margin-bottom: 50px;
}
.confirm-modal__footer {
  display: flex;
  justify-content: space-between;
}
.confirm-modal__footer__cancel-button,
.confirm-modal__footer__confirm-button {
  width: 50%;
  border-radius: 25px;
  max-width: none;
}
.confirm-modal__footer__cancel-button {
  margin-right: 20px;
  color: #4a4a4a;
  background-color: #fff;
}
.confirm-modal__footer__confirm-button {
  background-color: #005fc9;
  color: #fff;
}
.confirm-modal__footer__confirm-button:hover,
.confirm-modal__footer__confirm-button:focus {
  background-color: #005fc9;
  color: #fff;
}
@media (max-width: 767px) {
  .confirm-modal {
    width: 100%;
    border-radius: 0;
    height: 100%;
    padding: 20px;
  }
  .confirm-modal__close {
    display: inline-block;
    font-size: 25px;
    color: #4a4a4a;
    height: auto;
    padding-bottom: 20px;
    transform: translate(0, 0);
  }
  .confirm-modal__footer {
    flex-direction: column;
    justify-content: flex-start;
  }
  .confirm-modal__footer__cancel-button,
  .confirm-modal__footer__confirm-button {
    width: 100%;
    margin: 20px auto 0 0;
    width: 100%;
    max-width: 320px;
  }
}
.menu_filters-container-modal {
  overflow: hidden;
  position: fixed;
}
.menu_filters-container-modal .hundredmodal__content {
  height: 100vh;
}
.filters {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #f4f6f7;
  user-select: none;
  text-align: left;
  position: relative;
  box-sizing: border-box;
}
.filters__rating__form {
  line-height: 40px;
  color: #4a4a4a;
  display: block;
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
}
.filters__rating__form .checkbox-valoration-filters {
  margin-right: 15px;
}
.filters__rating__form .rating {
  font-weight: 600;
}
.filters__sections__valoration__header {
  display: none;
}
.filters__sections__order__body__radios__container {
  display: flex;
  flex-direction: column;
}
.filters__sections__order__body__radios__container__radio {
  width: auto;
  display: flex;
  flex-direction: row;
  padding: 7px 0;
}
.filters__sections__order__body__radios__container__radio__text {
  color: #4a4a4a;
  display: inline-block;
  -webkit-flex-shrink: 2;
  -ms-flex-negative: 2;
  flex-shrink: 2;
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  font-weight: 300;
  line-height: 22px;
  text-align: left;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.filters__sections__order__body__radios__container__radio__item {
  height: 19px;
  width: 19px;
  margin-right: 10px;
  border: 1px solid #c3d4e9;
  background-color: #edf5ff;
  box-sizing: border-box;
  display: block;
}
.filters__sections__price__header__average {
  width: auto;
  color: #4a4a4a;
}
.filters__sections__price__header__average.-no-average {
  opacity: 0;
}
.filters__sections__price__body__show-totals__checkbox {
  margin-right: 20px;
}
.filters__sections__price__body__range__resume-container__resume {
  color: #005fc9;
  font-size: 18px;
  letter-spacing: -0.25px;
  font-weight: 400;
  text-align: left;
  width: auto;
  line-height: 1.2em;
}
.filters__sections__price__body__range__rheostat-container {
  padding: 5px 0px;
}
.filters__sections__price__body__range__rheostat-container .rheostat-horizontal {
  top: 54px;
}
.filters__sections__price__body__range__histogram {
  width: 100%;
  height: 50px;
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 0px;
}
.filters__sections__price__body__range__histogram__item {
  background-color: #ccdff4;
  width: 100%;
  transition: height 300ms;
}
.filters__sections__price__body__range__histogram__item:hover {
  background-color: #7eade1;
}
.filters__sections__price__body__range__histogram__item[data-price="1"] {
  min-height: 2%;
}
@media (max-width: 319px) {
  .filters__sections__price__body__range__histogram__item {
    transition: none;
  }
}
.filters__sections__establishment__body__innocuos-container {
  position: relative;
}
.filters__sections__establishment__body__stars {
  position: absolute;
  top: 112px;
  right: 5px;
  display: flex;
  flex-direction: column-reverse;
}
.filters__sections__establishment__body__stars:after {
  content: '';
  height: 165px;
  width: 1px;
  background-color: #ccdff4;
  position: absolute;
  right: 130px;
  top: 10px;
}
.filters__sections__establishment__body__types {
  text-align: center;
  padding-bottom: 20px;
}
.filters__sections__establishment__body__types__stars {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding: 7px 0;
}
.filters__sections__establishment__body__types__stars__star {
  font-size: 13px;
  padding: 0 2px;
}
.filters__sections__establishment__body__types__type {
  display: inline-block;
  padding: 7px 0;
}
.filters__sections__establishment__body__types__type label {
  justify-content: left;
}
.filters__sections__establishment__body__rooms {
  align-items: center;
  padding-top: 20px;
}
.filters__sections__establishment__body__rooms__container__text {
  font-size: 18px;
  letter-spacing: -0.25px;
  font-weight: 500;
  box-sizing: border-box;
  color: #005fc9;
  display: inline-block;
  padding-right: 5px;
  margin-right: 30px;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 230px;
}
.filters__sections__establishment__body__rooms__container__buttons {
  display: inline-block;
  width: 100px;
  text-align: right;
}
.filters__sections__establishment__body__rooms__container__buttons__button {
  border-radius: 50%;
  display: inline-block;
  font-size: 15px;
  letter-spacing: -0.25px;
  height: 40px;
  line-height: 40px;
  margin: 6.5px;
  text-align: center;
  vertical-align: middle;
  width: 40px;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #ccdff4;
  color: #4a4a4a;
}
.filters__sections__establishment__body__rooms__container__buttons__button:hover {
  background-color: rgba(204,223,244,0.18);
  color: #fff;
}
.filters__sections__establishment__body__rooms__container__buttons__button:before {
  color: #005fc9;
  line-height: 36px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.25px;
}
.filters__sections__establishment__body__rooms__container__buttons__button:nth-child(2) {
  margin-right: 0;
}
.filters__sections__services__body__buttons {
  margin-top: 14px;
  text-align: left;
}
.filters__sections__services__body__buttons__button {
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccdff4;
  cursor: pointer;
  display: inline-block;
  height: 90px;
  margin: 5.5px 11px 5.5px 0px;
  text-align: center;
  text-align: center;
  vertical-align: middle;
  width: 90px;
}
.filters__sections__services__body__buttons__button__icon {
  color: #b0bec5;
  display: block;
  font-size: 28px;
  height: 50px;
}
.filters__sections__services__body__buttons__button__icon:before {
  line-height: 62px;
}
.filters__sections__services__body__buttons__button__bottom {
  align-items: center;
  display: flex;
  height: 40px;
}
.filters__sections__services__body__buttons__button__bottom__text {
  color: #4a4a4a;
  font-size: 12px;
  letter-spacing: -0.29px;
  font-weight: 300;
  line-height: 16px;
  margin: 0px auto;
  text-align: center;
  width: 90%;
  overflow: hidden;
}
.filters__sections__services__body__buttons__button:hover {
  border: 2px solid #ccdff4;
}
.filters__sections__services__body__buttons__button.-is-selected {
  background-color: rgba(204,223,244,0.18);
  border: 2px solid #ccdff4;
}
.filters__sections__services__body__buttons__button.-is-selected .filters__sections__services__body__buttons__button__icon {
  color: #fff;
}
.filters__sections__services__body__buttons__button.-is-selected .filters__sections__services__body__buttons__button__bottom__text {
  color: #fff;
}
.filters__sections__services__body__show-more {
  cursor: pointer;
  display: block;
  width: 100%;
  transition: all ease 0.3s;
}
.filters__sections__services__body__show-more.-is-hidden {
  opacity: 0;
  cursor: default;
}
.filters__sections__services__body__show-more__text {
  color: #005fc9;
  font-size: 16px;
  letter-spacing: -0.25px;
  font-weight: 400;
  line-height: 50px;
  margin: 0;
  text-align: center;
}
.filters__sections__options__header__description {
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
}
.filters__sections__options__body__types .hundred-ui-checkbox__label {
  padding: 5px 0;
}
.filters__sections__options__body__types .hundred-ui-checkbox__label .hundredicons-bolt-filled:before {
  color: #fdd835;
}
.filters__sections__options__body__types .hundred-ui-checkbox__label .hundredicons-recomended-icon:before {
  color: #d85cfc;
}
.filters__sections__options__body__types .hundred-ui-checkbox__label .hundredicons-percent:before {
  color: #16d280;
}
.filters__sections__options__body__types .hundred-ui-checkbox__label .hundredicons-cancellation {
  font-size: 1.25em;
}
.filters__sections__options__body__types .hundred-ui-checkbox__label__image {
  width: 21px;
  margin-right: 8px;
}
.filters__sections__options__body__types__type {
  display: inline-block;
  margin: 5px auto;
}
.filters__sections__options__body__types__type.reco-deactivated {
  cursor: default;
  opacity: 0.25;
  z-index: 1;
}
.filters__sections__zones__body__types__type {
  display: inline-block;
  padding: 7px 0;
}
.filters__sections__zones__body__show-more {
  cursor: pointer;
  display: block;
  width: 100%;
  transition: all ease 0.3s;
}
.filters__sections__zones__body__show-more.-is-hidden {
  opacity: 0;
  cursor: default;
}
.filters__sections__zones__body__show-more__text {
  color: #005fc9;
  font-size: 16px;
  letter-spacing: -0.25px;
  font-weight: 400;
  line-height: 50px;
  margin: 0;
}
.filters__buttons-apply {
  background-color: #fff;
  border-top: 1px solid #ccdff4;
  bottom: 0;
  box-sizing: border-box;
  display: inline-block;
  height: 64px;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
  animation: repaint 100ms 1s 10;
}
.filters__buttons-apply__buttons {
  align-items: center;
  display: flex;
  height: 64px;
  float: right;
}
.filters__buttons-apply__buttons__button-cancel,
.filters__buttons-apply__buttons__button-apply {
  border-radius: 30px;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 500;
  height: 42px;
  line-height: 42px;
  width: 120px;
  color: #4a4a4a;
}
.filters__buttons-apply__buttons__button-apply {
  background-color: #005fc9;
  color: #fff;
  transition: all ease 0.3s;
}
.filters__buttons-apply__buttons__button-apply:hover,
.filters__buttons-apply__buttons__button-apply:focus {
  border-color: #005fc9;
  background-color: #fff;
}
.filters.-is-mobile {
  width: 100vw;
  height: 200%;
  position: fixed;
  overflow-y: auto;
  background-color: #fff;
}
.filters.-is-mobile .filters__header-mobile {
  align-items: center;
  border-bottom: 1px solid #ccdff4;
  box-sizing: border-box;
  display: flex;
  height: 50px;
  justify-content: space-between;
  left: 0;
  padding: 0 15px;
  transform: translate3d(0, 0, 0);
  width: 100%;
  z-index: 3;
  align-items: center;
}
.filters.-is-mobile .filters__header-mobile__icon {
  color: #005fc9;
  font-size: 18px;
  letter-spacing: -0.25px;
  width: 25%;
  display: inline-block;
}
.filters.-is-mobile .filters__header-mobile__reset {
  padding: 10px;
  width: 25%;
}
.filters.-is-mobile .filters__header-mobile__reset__button {
  line-height: 30px;
  display: block;
  width: 100%;
  border: none;
  background-color: #fff;
  color: #005fc9;
  height: 100%;
  font-weight: 400;
  text-align: right;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
.filters.-is-mobile .filters__header-mobile__reset__button:disabled {
  opacity: 0;
}
.filters.-is-mobile .filters__header-mobile__reset .hundred-ui-button {
  min-width: 0;
}
.filters.-is-mobile .filters__header-mobile__title {
  display: flex;
  justify-content: center;
  color: #4a4a4a;
  flex-shrink: 1;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  width: 50%;
  line-height: 50px;
}
.filters.-is-mobile .filters__sections {
  position: fixed;
  overflow-y: auto;
  max-height: 80%;
  width: 100%;
}
.filters.-is-mobile .filters__sections__price,
.filters.-is-mobile .filters__sections__establishment,
.filters.-is-mobile .filters__sections__order,
.filters.-is-mobile .filters__sections__services,
.filters.-is-mobile .filters__sections__options,
.filters.-is-mobile .filters__sections__zones,
.filters.-is-mobile .filters__sections__valoration {
  border-bottom: 1px solid rgba(204,223,244,0.5);
}
.filters.-is-mobile .filters__sections__price__header,
.filters.-is-mobile .filters__sections__establishment__header,
.filters.-is-mobile .filters__sections__order__header,
.filters.-is-mobile .filters__sections__services__header,
.filters.-is-mobile .filters__sections__options__header,
.filters.-is-mobile .filters__sections__zones__header,
.filters.-is-mobile .filters__sections__valoration__header {
  box-sizing: border-box;
  display: block;
  height: 79px;
  padding: 0 25px 0;
  width: 100%;
  background-color: #fff;
}
.filters.-is-mobile .filters__sections__price__header .filters__sections__price__header__toggle__icon,
.filters.-is-mobile .filters__sections__establishment__header .filters__sections__price__header__toggle__icon,
.filters.-is-mobile .filters__sections__order__header .filters__sections__price__header__toggle__icon,
.filters.-is-mobile .filters__sections__services__header .filters__sections__price__header__toggle__icon,
.filters.-is-mobile .filters__sections__options__header .filters__sections__price__header__toggle__icon,
.filters.-is-mobile .filters__sections__zones__header .filters__sections__price__header__toggle__icon,
.filters.-is-mobile .filters__sections__valoration__header .filters__sections__price__header__toggle__icon,
.filters.-is-mobile .filters__sections__price__header .filters__sections__establishment__header__toggle__icon,
.filters.-is-mobile .filters__sections__establishment__header .filters__sections__establishment__header__toggle__icon,
.filters.-is-mobile .filters__sections__order__header .filters__sections__establishment__header__toggle__icon,
.filters.-is-mobile .filters__sections__services__header .filters__sections__establishment__header__toggle__icon,
.filters.-is-mobile .filters__sections__options__header .filters__sections__establishment__header__toggle__icon,
.filters.-is-mobile .filters__sections__zones__header .filters__sections__establishment__header__toggle__icon,
.filters.-is-mobile .filters__sections__valoration__header .filters__sections__establishment__header__toggle__icon,
.filters.-is-mobile .filters__sections__price__header .filters__sections__services__header__toggle__icon,
.filters.-is-mobile .filters__sections__establishment__header .filters__sections__services__header__toggle__icon,
.filters.-is-mobile .filters__sections__order__header .filters__sections__services__header__toggle__icon,
.filters.-is-mobile .filters__sections__services__header .filters__sections__services__header__toggle__icon,
.filters.-is-mobile .filters__sections__options__header .filters__sections__services__header__toggle__icon,
.filters.-is-mobile .filters__sections__zones__header .filters__sections__services__header__toggle__icon,
.filters.-is-mobile .filters__sections__valoration__header .filters__sections__services__header__toggle__icon,
.filters.-is-mobile .filters__sections__price__header .filters__sections__options__header__toggle__icon,
.filters.-is-mobile .filters__sections__establishment__header .filters__sections__options__header__toggle__icon,
.filters.-is-mobile .filters__sections__order__header .filters__sections__options__header__toggle__icon,
.filters.-is-mobile .filters__sections__services__header .filters__sections__options__header__toggle__icon,
.filters.-is-mobile .filters__sections__options__header .filters__sections__options__header__toggle__icon,
.filters.-is-mobile .filters__sections__zones__header .filters__sections__options__header__toggle__icon,
.filters.-is-mobile .filters__sections__valoration__header .filters__sections__options__header__toggle__icon,
.filters.-is-mobile .filters__sections__price__header .filters__sections__order__header__toggle__icon,
.filters.-is-mobile .filters__sections__establishment__header .filters__sections__order__header__toggle__icon,
.filters.-is-mobile .filters__sections__order__header .filters__sections__order__header__toggle__icon,
.filters.-is-mobile .filters__sections__services__header .filters__sections__order__header__toggle__icon,
.filters.-is-mobile .filters__sections__options__header .filters__sections__order__header__toggle__icon,
.filters.-is-mobile .filters__sections__zones__header .filters__sections__order__header__toggle__icon,
.filters.-is-mobile .filters__sections__valoration__header .filters__sections__order__header__toggle__icon,
.filters.-is-mobile .filters__sections__price__header .filters__sections__zones__header__toggle__icon,
.filters.-is-mobile .filters__sections__establishment__header .filters__sections__zones__header__toggle__icon,
.filters.-is-mobile .filters__sections__order__header .filters__sections__zones__header__toggle__icon,
.filters.-is-mobile .filters__sections__services__header .filters__sections__zones__header__toggle__icon,
.filters.-is-mobile .filters__sections__options__header .filters__sections__zones__header__toggle__icon,
.filters.-is-mobile .filters__sections__zones__header .filters__sections__zones__header__toggle__icon,
.filters.-is-mobile .filters__sections__valoration__header .filters__sections__zones__header__toggle__icon,
.filters.-is-mobile .filters__sections__price__header text-align: center,
.filters.-is-mobile .filters__sections__establishment__header text-align: center,
.filters.-is-mobile .filters__sections__order__header text-align: center,
.filters.-is-mobile .filters__sections__services__header text-align: center,
.filters.-is-mobile .filters__sections__options__header text-align: center,
.filters.-is-mobile .filters__sections__zones__header text-align: center,
.filters.-is-mobile .filters__sections__valoration__header text-align: center {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 100px;
  padding-left: 1px;
  padding-top: 1px;
  line-height: 36px;
  transition: all ease 0.3s;
  will-change: transform;
  font-size: 16px;
  letter-spacing: -0.25px;
}
.filters.-is-mobile .filters__sections__price__header .filters__sections__price__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__establishment__header .filters__sections__price__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__order__header .filters__sections__price__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__services__header .filters__sections__price__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__options__header .filters__sections__price__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__zones__header .filters__sections__price__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__valoration__header .filters__sections__price__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__price__header .filters__sections__establishment__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__establishment__header .filters__sections__establishment__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__order__header .filters__sections__establishment__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__services__header .filters__sections__establishment__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__options__header .filters__sections__establishment__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__zones__header .filters__sections__establishment__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__valoration__header .filters__sections__establishment__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__price__header .filters__sections__services__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__establishment__header .filters__sections__services__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__order__header .filters__sections__services__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__services__header .filters__sections__services__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__options__header .filters__sections__services__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__zones__header .filters__sections__services__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__valoration__header .filters__sections__services__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__price__header .filters__sections__options__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__establishment__header .filters__sections__options__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__order__header .filters__sections__options__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__services__header .filters__sections__options__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__options__header .filters__sections__options__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__zones__header .filters__sections__options__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__valoration__header .filters__sections__options__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__price__header .filters__sections__order__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__establishment__header .filters__sections__order__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__order__header .filters__sections__order__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__services__header .filters__sections__order__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__options__header .filters__sections__order__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__zones__header .filters__sections__order__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__valoration__header .filters__sections__order__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__price__header .filters__sections__zones__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__establishment__header .filters__sections__zones__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__order__header .filters__sections__zones__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__services__header .filters__sections__zones__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__options__header .filters__sections__zones__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__zones__header .filters__sections__zones__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__valoration__header .filters__sections__zones__header__toggle__icon:before,
.filters.-is-mobile .filters__sections__price__header text-align: center:before,
.filters.-is-mobile .filters__sections__establishment__header text-align: center:before,
.filters.-is-mobile .filters__sections__order__header text-align: center:before,
.filters.-is-mobile .filters__sections__services__header text-align: center:before,
.filters.-is-mobile .filters__sections__options__header text-align: center:before,
.filters.-is-mobile .filters__sections__zones__header text-align: center:before,
.filters.-is-mobile .filters__sections__valoration__header text-align: center:before {
  line-height: 36px;
  display: inline-block;
  vertical-align: middle;
}
.filters.-is-mobile .filters__sections__price__header.-is-expanded .filters__sections__price__header__toggle__icon,
.filters.-is-mobile .filters__sections__establishment__header.-is-expanded .filters__sections__price__header__toggle__icon,
.filters.-is-mobile .filters__sections__order__header.-is-expanded .filters__sections__price__header__toggle__icon,
.filters.-is-mobile .filters__sections__services__header.-is-expanded .filters__sections__price__header__toggle__icon,
.filters.-is-mobile .filters__sections__options__header.-is-expanded .filters__sections__price__header__toggle__icon,
.filters.-is-mobile .filters__sections__zones__header.-is-expanded .filters__sections__price__header__toggle__icon,
.filters.-is-mobile .filters__sections__valoration__header.-is-expanded .filters__sections__price__header__toggle__icon,
.filters.-is-mobile .filters__sections__price__header.-is-expanded .filters__sections__establishment__header__toggle__icon,
.filters.-is-mobile .filters__sections__establishment__header.-is-expanded .filters__sections__establishment__header__toggle__icon,
.filters.-is-mobile .filters__sections__order__header.-is-expanded .filters__sections__establishment__header__toggle__icon,
.filters.-is-mobile .filters__sections__services__header.-is-expanded .filters__sections__establishment__header__toggle__icon,
.filters.-is-mobile .filters__sections__options__header.-is-expanded .filters__sections__establishment__header__toggle__icon,
.filters.-is-mobile .filters__sections__zones__header.-is-expanded .filters__sections__establishment__header__toggle__icon,
.filters.-is-mobile .filters__sections__valoration__header.-is-expanded .filters__sections__establishment__header__toggle__icon,
.filters.-is-mobile .filters__sections__price__header.-is-expanded .filters__sections__services__header__toggle__icon,
.filters.-is-mobile .filters__sections__establishment__header.-is-expanded .filters__sections__services__header__toggle__icon,
.filters.-is-mobile .filters__sections__order__header.-is-expanded .filters__sections__services__header__toggle__icon,
.filters.-is-mobile .filters__sections__services__header.-is-expanded .filters__sections__services__header__toggle__icon,
.filters.-is-mobile .filters__sections__options__header.-is-expanded .filters__sections__services__header__toggle__icon,
.filters.-is-mobile .filters__sections__zones__header.-is-expanded .filters__sections__services__header__toggle__icon,
.filters.-is-mobile .filters__sections__valoration__header.-is-expanded .filters__sections__services__header__toggle__icon,
.filters.-is-mobile .filters__sections__price__header.-is-expanded .filters__sections__order__header__toggle__icon,
.filters.-is-mobile .filters__sections__establishment__header.-is-expanded .filters__sections__order__header__toggle__icon,
.filters.-is-mobile .filters__sections__order__header.-is-expanded .filters__sections__order__header__toggle__icon,
.filters.-is-mobile .filters__sections__services__header.-is-expanded .filters__sections__order__header__toggle__icon,
.filters.-is-mobile .filters__sections__options__header.-is-expanded .filters__sections__order__header__toggle__icon,
.filters.-is-mobile .filters__sections__zones__header.-is-expanded .filters__sections__order__header__toggle__icon,
.filters.-is-mobile .filters__sections__valoration__header.-is-expanded .filters__sections__order__header__toggle__icon,
.filters.-is-mobile .filters__sections__price__header.-is-expanded .filters__sections__options__header__toggle__icon,
.filters.-is-mobile .filters__sections__establishment__header.-is-expanded .filters__sections__options__header__toggle__icon,
.filters.-is-mobile .filters__sections__order__header.-is-expanded .filters__sections__options__header__toggle__icon,
.filters.-is-mobile .filters__sections__services__header.-is-expanded .filters__sections__options__header__toggle__icon,
.filters.-is-mobile .filters__sections__options__header.-is-expanded .filters__sections__options__header__toggle__icon,
.filters.-is-mobile .filters__sections__zones__header.-is-expanded .filters__sections__options__header__toggle__icon,
.filters.-is-mobile .filters__sections__valoration__header.-is-expanded .filters__sections__options__header__toggle__icon,
.filters.-is-mobile .filters__sections__price__header.-is-expanded .filters__sections__zones__header__toggle__icon,
.filters.-is-mobile .filters__sections__establishment__header.-is-expanded .filters__sections__zones__header__toggle__icon,
.filters.-is-mobile .filters__sections__order__header.-is-expanded .filters__sections__zones__header__toggle__icon,
.filters.-is-mobile .filters__sections__services__header.-is-expanded .filters__sections__zones__header__toggle__icon,
.filters.-is-mobile .filters__sections__options__header.-is-expanded .filters__sections__zones__header__toggle__icon,
.filters.-is-mobile .filters__sections__zones__header.-is-expanded .filters__sections__zones__header__toggle__icon,
.filters.-is-mobile .filters__sections__valoration__header.-is-expanded .filters__sections__zones__header__toggle__icon,
.filters.-is-mobile .filters__sections__price__header.-is-expanded .filters__sections__valoration__header__toggle__icon,
.filters.-is-mobile .filters__sections__establishment__header.-is-expanded .filters__sections__valoration__header__toggle__icon,
.filters.-is-mobile .filters__sections__order__header.-is-expanded .filters__sections__valoration__header__toggle__icon,
.filters.-is-mobile .filters__sections__services__header.-is-expanded .filters__sections__valoration__header__toggle__icon,
.filters.-is-mobile .filters__sections__options__header.-is-expanded .filters__sections__valoration__header__toggle__icon,
.filters.-is-mobile .filters__sections__zones__header.-is-expanded .filters__sections__valoration__header__toggle__icon,
.filters.-is-mobile .filters__sections__valoration__header.-is-expanded .filters__sections__valoration__header__toggle__icon {
  transform: rotate(180deg);
}
.filters.-is-mobile .filters__sections__price__header.-is-expanded .filters__sections__options__header__left__resume,
.filters.-is-mobile .filters__sections__establishment__header.-is-expanded .filters__sections__options__header__left__resume,
.filters.-is-mobile .filters__sections__order__header.-is-expanded .filters__sections__options__header__left__resume,
.filters.-is-mobile .filters__sections__services__header.-is-expanded .filters__sections__options__header__left__resume,
.filters.-is-mobile .filters__sections__options__header.-is-expanded .filters__sections__options__header__left__resume,
.filters.-is-mobile .filters__sections__zones__header.-is-expanded .filters__sections__options__header__left__resume,
.filters.-is-mobile .filters__sections__valoration__header.-is-expanded .filters__sections__options__header__left__resume,
.filters.-is-mobile .filters__sections__price__header.-is-expanded .filters__sections__price__header__left__resume,
.filters.-is-mobile .filters__sections__establishment__header.-is-expanded .filters__sections__price__header__left__resume,
.filters.-is-mobile .filters__sections__order__header.-is-expanded .filters__sections__price__header__left__resume,
.filters.-is-mobile .filters__sections__services__header.-is-expanded .filters__sections__price__header__left__resume,
.filters.-is-mobile .filters__sections__options__header.-is-expanded .filters__sections__price__header__left__resume,
.filters.-is-mobile .filters__sections__zones__header.-is-expanded .filters__sections__price__header__left__resume,
.filters.-is-mobile .filters__sections__valoration__header.-is-expanded .filters__sections__price__header__left__resume,
.filters.-is-mobile .filters__sections__price__header.-is-expanded .filters__sections__order__header__left__icon,
.filters.-is-mobile .filters__sections__establishment__header.-is-expanded .filters__sections__order__header__left__icon,
.filters.-is-mobile .filters__sections__order__header.-is-expanded .filters__sections__order__header__left__icon,
.filters.-is-mobile .filters__sections__services__header.-is-expanded .filters__sections__order__header__left__icon,
.filters.-is-mobile .filters__sections__options__header.-is-expanded .filters__sections__order__header__left__icon,
.filters.-is-mobile .filters__sections__zones__header.-is-expanded .filters__sections__order__header__left__icon,
.filters.-is-mobile .filters__sections__valoration__header.-is-expanded .filters__sections__order__header__left__icon,
.filters.-is-mobile .filters__sections__price__header.-is-expanded .filters__sections__establishment__header__left__resume,
.filters.-is-mobile .filters__sections__establishment__header.-is-expanded .filters__sections__establishment__header__left__resume,
.filters.-is-mobile .filters__sections__order__header.-is-expanded .filters__sections__establishment__header__left__resume,
.filters.-is-mobile .filters__sections__services__header.-is-expanded .filters__sections__establishment__header__left__resume,
.filters.-is-mobile .filters__sections__options__header.-is-expanded .filters__sections__establishment__header__left__resume,
.filters.-is-mobile .filters__sections__zones__header.-is-expanded .filters__sections__establishment__header__left__resume,
.filters.-is-mobile .filters__sections__valoration__header.-is-expanded .filters__sections__establishment__header__left__resume,
.filters.-is-mobile .filters__sections__price__header.-is-expanded .filters__sections__zones__header__left__resume,
.filters.-is-mobile .filters__sections__establishment__header.-is-expanded .filters__sections__zones__header__left__resume,
.filters.-is-mobile .filters__sections__order__header.-is-expanded .filters__sections__zones__header__left__resume,
.filters.-is-mobile .filters__sections__services__header.-is-expanded .filters__sections__zones__header__left__resume,
.filters.-is-mobile .filters__sections__options__header.-is-expanded .filters__sections__zones__header__left__resume,
.filters.-is-mobile .filters__sections__zones__header.-is-expanded .filters__sections__zones__header__left__resume,
.filters.-is-mobile .filters__sections__valoration__header.-is-expanded .filters__sections__zones__header__left__resume,
.filters.-is-mobile .filters__sections__price__header.-is-expanded .filters__sections__services__header__left__resume,
.filters.-is-mobile .filters__sections__establishment__header.-is-expanded .filters__sections__services__header__left__resume,
.filters.-is-mobile .filters__sections__order__header.-is-expanded .filters__sections__services__header__left__resume,
.filters.-is-mobile .filters__sections__services__header.-is-expanded .filters__sections__services__header__left__resume,
.filters.-is-mobile .filters__sections__options__header.-is-expanded .filters__sections__services__header__left__resume,
.filters.-is-mobile .filters__sections__zones__header.-is-expanded .filters__sections__services__header__left__resume,
.filters.-is-mobile .filters__sections__valoration__header.-is-expanded .filters__sections__services__header__left__resume {
  display: none;
}
.filters.-is-mobile .filters__sections__price__header__left,
.filters.-is-mobile .filters__sections__establishment__header__left,
.filters.-is-mobile .filters__sections__order__header__left,
.filters.-is-mobile .filters__sections__services__header__left,
.filters.-is-mobile .filters__sections__options__header__left,
.filters.-is-mobile .filters__sections__zones__header__left,
.filters.-is-mobile .filters__sections__valoration__header__left {
  display: flex;
  flex-direction: column;
  float: left;
  height: 100%;
  justify-content: center;
  text-align: left;
  vertical-align: top;
  width: 80%;
}
.filters.-is-mobile .filters__sections__price__header__left__title,
.filters.-is-mobile .filters__sections__establishment__header__left__title,
.filters.-is-mobile .filters__sections__order__header__left__title,
.filters.-is-mobile .filters__sections__services__header__left__title,
.filters.-is-mobile .filters__sections__options__header__left__title,
.filters.-is-mobile .filters__sections__zones__header__left__title,
.filters.-is-mobile .filters__sections__valoration__header__left__title {
  color: #4a4a4a;
  display: block;
  font-size: 18px;
  letter-spacing: -0.25px;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filters.-is-mobile .filters__sections__price__header__left__resume,
.filters.-is-mobile .filters__sections__establishment__header__left__resume,
.filters.-is-mobile .filters__sections__order__header__left__resume,
.filters.-is-mobile .filters__sections__services__header__left__resume,
.filters.-is-mobile .filters__sections__options__header__left__resume,
.filters.-is-mobile .filters__sections__zones__header__left__resume,
.filters.-is-mobile .filters__sections__valoration__header__left__resume {
  color: #4a4a4a;
  display: block;
  font-size: 16px;
  letter-spacing: -0.25px;
  line-height: 25px;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filters.-is-mobile .filters__sections__price__header__toggle,
.filters.-is-mobile .filters__sections__establishment__header__toggle,
.filters.-is-mobile .filters__sections__order__header__toggle,
.filters.-is-mobile .filters__sections__services__header__toggle,
.filters.-is-mobile .filters__sections__options__header__toggle,
.filters.-is-mobile .filters__sections__zones__header__toggle,
.filters.-is-mobile .filters__sections__valoration__header__toggle {
  display: flex;
  flex-direction: column;
  float: right;
  height: 100%;
  justify-content: center;
  text-align: right;
  width: 10%;
}
.filters.-is-mobile .filters__sections__price__header__toggle__icon,
.filters.-is-mobile .filters__sections__establishment__header__toggle__icon,
.filters.-is-mobile .filters__sections__order__header__toggle__icon,
.filters.-is-mobile .filters__sections__services__header__toggle__icon,
.filters.-is-mobile .filters__sections__options__header__toggle__icon,
.filters.-is-mobile .filters__sections__zones__header__toggle__icon,
.filters.-is-mobile .filters__sections__valoration__header__toggle__icon {
  align-self: center;
  font-size: 14px;
  letter-spacing: -0.25px;
  display: inline-block;
  width: 14px;
  color: #4a4a4a;
  color: #005fc9;
  transition: all ease 0.3s;
}
.filters.-is-mobile .filters__sections__price__body,
.filters.-is-mobile .filters__sections__establishment__body,
.filters.-is-mobile .filters__sections__order__body,
.filters.-is-mobile .filters__sections__services__body,
.filters.-is-mobile .filters__sections__options__body,
.filters.-is-mobile .filters__sections__zones__body,
.filters.-is-mobile .filters__sections__valoration__body {
  background-color: #fff;
  padding: 0 25px 25px;
}
.filters.-is-mobile .filters__sections__price__body__innocuos-container__description,
.filters.-is-mobile .filters__sections__establishment__body__innocuos-container__description,
.filters.-is-mobile .filters__sections__order__body__innocuos-container__description,
.filters.-is-mobile .filters__sections__services__body__innocuos-container__description,
.filters.-is-mobile .filters__sections__options__body__innocuos-container__description,
.filters.-is-mobile .filters__sections__zones__body__innocuos-container__description,
.filters.-is-mobile .filters__sections__valoration__body__innocuos-container__description {
  display: block;
  margin-bottom: 15px;
  font-size: 16px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
}
.filters.-is-mobile .filters__sections__price__body__explain,
.filters.-is-mobile .filters__sections__establishment__body__explain,
.filters.-is-mobile .filters__sections__order__body__explain,
.filters.-is-mobile .filters__sections__services__body__explain,
.filters.-is-mobile .filters__sections__options__body__explain,
.filters.-is-mobile .filters__sections__zones__body__explain,
.filters.-is-mobile .filters__sections__valoration__body__explain {
  color: #4a4a4a;
  display: block;
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  margin-bottom: 20px;
  width: 100%;
}
.filters.-is-mobile .filters__sections__price__body.-is-collapsed,
.filters.-is-mobile .filters__sections__establishment__body.-is-collapsed,
.filters.-is-mobile .filters__sections__order__body.-is-collapsed,
.filters.-is-mobile .filters__sections__services__body.-is-collapsed,
.filters.-is-mobile .filters__sections__options__body.-is-collapsed,
.filters.-is-mobile .filters__sections__zones__body.-is-collapsed,
.filters.-is-mobile .filters__sections__valoration__body.-is-collapsed {
  border-bottom: 1px solid #ccdff4;
  margin: 0;
  opacity: 0;
  transform: scaleY(0);
  padding: 0;
  height: 0;
}
.filters.-is-mobile .filters__sections__price__body.-is-expanded,
.filters.-is-mobile .filters__sections__establishment__body.-is-expanded,
.filters.-is-mobile .filters__sections__order__body.-is-expanded,
.filters.-is-mobile .filters__sections__services__body.-is-expanded,
.filters.-is-mobile .filters__sections__options__body.-is-expanded,
.filters.-is-mobile .filters__sections__zones__body.-is-expanded,
.filters.-is-mobile .filters__sections__valoration__body.-is-expanded {
  opacity: 1;
}
.filters.-is-mobile .filters__sections__price__body .filters__rating__form__line,
.filters.-is-mobile .filters__sections__establishment__body .filters__rating__form__line,
.filters.-is-mobile .filters__sections__order__body .filters__rating__form__line,
.filters.-is-mobile .filters__sections__services__body .filters__rating__form__line,
.filters.-is-mobile .filters__sections__options__body .filters__rating__form__line,
.filters.-is-mobile .filters__sections__zones__body .filters__rating__form__line,
.filters.-is-mobile .filters__sections__valoration__body .filters__rating__form__line {
  margin-bottom: 16px;
}
.filters.-is-mobile .filters__sections__price__body__range {
  padding-top: 0;
}
.filters.-is-mobile .filters__sections__price__body__range__rheostat-container {
  padding-top: 0;
  margin: 0 10px;
  margin-top: 28px;
}
.filters.-is-mobile .filters__sections__price__body__range__rheostat-container .rheostat-horizontal {
  top: 54px;
}
.filters.-is-mobile .filters__sections__price__body__range__rheostat-container .rheostat-horizontal .rheostat-handle {
  min-width: 32px;
  min-height: 32px;
  border: solid 1px rgba(204,223,244,0.5);
}
.filters.-is-mobile .filters__sections__price__body__range__resume-container {
  flex-flow: row wrap;
  position: relative;
  height: 68px;
  margin-left: -10px;
}
.filters.-is-mobile .filters__sections__price__body__range__resume-container__resume {
  width: 100%;
  line-height: 130px;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filters.-is-mobile .filters__sections__price__body__range__resume-container__tot-night {
  display: none;
}
.filters.-is-mobile .filters__sections__price__body__range__resume-container__toggle .toggle-switch__container {
  margin-bottom: 20px;
  position: absolute;
  left: 0px;
}
.filters.-is-mobile .filters__sections__price__body__range__resume-container__toggle .toggle-switch__container__switch {
  margin: 0px;
  height: 34px;
}
.filters.-is-mobile .filters__sections__price__body__range__resume-container__toggle .toggle-switch__container__switch__label {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.29px;
  padding: 0 2px;
  letter-spacing: 0.25;
  height: 28px;
  top: 3px;
  left: 1px;
  padding-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filters.-is-mobile .filters__sections__price__body__range__resume-container__toggle .toggle-switch__container__switch__selection {
  margin-top: 1px;
  margin-left: 1px;
  height: 28px;
  width: 56px;
}
.filters.-is-mobile .filters__sections__services__body__show-more,
.filters.-is-mobile .filters__sections__zones__body__show-more {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.filters.-is-mobile .filters__sections__services__body__show-more__text,
.filters.-is-mobile .filters__sections__zones__body__show-more__text {
  text-align: left;
  display: inline-block;
  margin-right: 10px;
}
.filters.-is-mobile .filters__sections__services__body__show-more__rot,
.filters.-is-mobile .filters__sections__zones__body__show-more__rot {
  height: 14px;
  width: 14px;
  display: inline-block;
  color: #005fc9;
}
.filters.-is-mobile .filters__sections__services__body__show-more__rot__is-showed,
.filters.-is-mobile .filters__sections__zones__body__show-more__rot__is-showed {
  display: none;
}
.filters.-is-mobile .filters__sections__valoration__body__innocuos-container .filters__sections__valoration__header {
  display: none;
}
.filters.-is-mobile .filters__sections__zones__body__innocuos-container__description {
  margin-bottom: 20px;
}
.filters.-is-mobile .filters__sections__establishment__body__types {
  min-height: 340px;
  max-width: 45%;
}
.filters.-is-mobile .filters__sections__establishment__body__stars {
  top: 160px;
  right: 40px;
}
.filters.-is-mobile .filters__sections__establishment__body__innocuos-container {
  position: relative;
}
.filters.-is-mobile .filters__sections__establishment__body__innocuos-container__description {
  margin-bottom: 20px;
}
.filters.-is-mobile .filters__sections__establishment__body__rooms {
  padding-top: 0;
}
.filters.-is-mobile .filters__sections__establishment__body__rooms__head {
  font-size: 16px;
  letter-spacing: -0.25px;
  margin-bottom: 10px;
  color: #4a4a4a;
}
.filters.-is-mobile .filters__sections__establishment__body__rooms__head__title {
  display: none;
}
.filters.-is-mobile .filters__sections__establishment__body__rooms__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.filters.-is-mobile .filters__sections__establishment__body__rooms__container__text {
  width: 160px;
  margin-right: 0;
}
.filters.-is-mobile .filters__sections__establishment__body__rooms__container__buttons {
  width: 100px;
}
.filters.-is-mobile .filters__sections__services__body__buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.filters.-is-mobile .filters__sections__services__body__buttons__button {
  position: relative;
  height: 110px;
  width: 46%;
  margin: 2%;
}
.filters.-is-mobile .filters__sections__services__body__buttons__button__top-icon {
  transition: all 1s ease-in-out;
}
.filters.-is-mobile .filters__sections__services__body__buttons__button__top-icon__shown {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 20px;
  width: 20px;
  border: 2px solid #ccdff4;
  background-color: #fff;
  border-radius: 30px;
  text-align: center;
}
.filters.-is-mobile .filters__sections__services__body__buttons__button__top-icon__shown:before {
  font-size: 10px;
  letter-spacing: 0px;
  color: #005fc9;
  line-height: 16px;
}
.filters.-is-mobile .filters__sections__services__body__buttons__button.-no-selected .hundredicons-check {
  opacity: 0;
  transition: all ease 0.2s;
}
.filters.-is-mobile .filters__sections__services__body__buttons__button.-is-selected {
  background-color: rgba(204,223,244,0.18);
  border: 1px solid #ccdff4;
}
.filters.-is-mobile .filters__sections__services__body__buttons__button.-is-selected .hundredicons-check {
  opacity: 1;
  transition: all ease 0.2s;
  opacity: 1;
}
.filters.-is-mobile .filters__sections__services__body__buttons__button__icon:before {
  color: #61625e;
  font-size: 24px;
  line-height: 70px;
}
.filters.-is-mobile .filters__sections__services__body__buttons__button__bottom__text {
  margin: auto;
  color: #4a4a4a;
  font-size: 14px;
  letter-spacing: -0.25px;
  padding: 0 5px;
  overflow: hidden;
}
.filters.-is-mobile .filters__sections__services__body__buttons__button__bottom__text__is-shown {
  margin: 0px auto;
  position: relative;
  text-align: center;
  color: #005fc9;
  line-height: 16px;
  letter-spacing: -0.25px;
  width: 90%;
}
.filters.-is-mobile .filters__buttons-apply {
  width: 100vw;
  height: 82px;
  animation: repaint-mobile 100ms 1s 10;
}
.filters.-is-mobile .filters__buttons-apply__buttons {
  float: none;
  justify-content: center;
  height: 82px;
}
.filters.-is-mobile .filters__buttons-apply__buttons__button-cancel,
.filters.-is-mobile .filters__buttons-apply__buttons__button-apply {
  width: 40%;
}
.filters.-is-mobile .filters__buttons-apply__buttons__button-apply {
  background-color: #005fc9;
  border: 2px solid #005fc9;
}
.filters.-is-mobile .filters__buttons-apply__buttons__button-apply:disabled {
  opacity: 0.5;
}
.filters.-is-mobile .filters__buttons-apply__buttons__button-cancel {
  border: 2px solid #ccdff4;
  margin-right: 10px;
}
.filters.-is-desktop {
  width: 420px;
  position: initial;
  background-color: #fff;
}
.filters.-is-desktop .filters__line {
  width: 100%;
  display: block;
  padding-left: 30px;
  padding-right: 30px;
  opacity: 0.5;
}
.filters.-is-desktop .filters__line:before {
  background-color: #ccdff4;
  content: '';
  display: block;
  height: 1px;
  margin: auto;
  width: 100%;
}
.filters.-is-desktop .filters__header {
  width: 100%;
}
.filters.-is-desktop .filters__header__search {
  height: 100%;
  padding: 12px 30px 8px 30px;
  box-sizing: border-box;
  color: #4a4a4a;
  line-height: 18px;
  font-size: 18px;
  letter-spacing: -0.25px;
  font-weight: 500;
}
.filters.-is-desktop .filters__header__search__destination {
  overflow: visible;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filters.-is-desktop .filters__header__search__info__date-arrival,
.filters.-is-desktop .filters__header__search__info__date-leave,
.filters.-is-desktop .filters__header__search__info__arrow,
.filters.-is-desktop .filters__header__search__info__separation,
.filters.-is-desktop .filters__header__search__info__guests {
  display: inline-block;
}
.filters.-is-desktop .filters__header__search__info__arrow {
  margin: 0 8px;
  font-size: 8px;
}
.filters.-is-desktop .filters__header__search__info__separation {
  box-sizing: border-box;
  width: 2px;
  height: 17px;
  border: 1px solid #ccdff4;
  margin: 0 10px;
  margin-bottom: -2px;
}
.filters.-is-desktop .filters__header__navigation {
  box-sizing: border-box;
  height: 64px;
  min-height: 64px;
  padding: 11px 30px;
}
.filters.-is-desktop .filters__header__navigation__close {
  color: #005fc9;
  display: inline-block;
  font-size: 18px;
  letter-spacing: -0.25px;
  height: 42px;
  width: 42px;
  border: 2px solid #ccdff4;
  border-radius: 100px;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
}
.filters.-is-desktop .filters__header__navigation__close:before {
  display: inline-block;
  vertical-align: middle;
  line-height: 38px;
  font-size: 14px;
  letter-spacing: -0.25px;
}
.filters.-is-desktop .filters__header__navigation__close:hover {
  background-color: rgba(204,223,244,0.18);
}
.filters.-is-desktop .filters__header__navigation__reset {
  display: inline-block;
  text-align: right;
  vertical-align: middle;
  width: 88%;
}
.filters.-is-desktop .filters__header__navigation__reset .hundred-ui-button {
  font-size: 12px;
  letter-spacing: -0.29px;
  font-weight: 500;
  padding: 0 22px;
}
.filters.-is-desktop .filters__header__navigation__reset__button {
  border-radius: 30px;
  border: 2px solid #ccdff4;
  color: #4a4a4a;
  display: inline-block;
  height: 42px;
  line-height: 42px;
  min-width: inherit;
  text-transform: initial;
  width: auto;
  background-color: rgba(204,223,244,0.18);
  border: 2px solid #ccdff4;
}
.filters.-is-desktop .filters__header__navigation__reset__button:disabled {
  opacity: 0.5;
}
.filters.-is-desktop .filters__header__navigation__reset__button:focus {
  outline: none;
}
.filters.-is-desktop .filters__sections {
  width: 100%;
  padding-bottom: 63px;
}
.filters.-is-desktop .filters__sections__price,
.filters.-is-desktop .filters__sections__services,
.filters.-is-desktop .filters__sections__establishment,
.filters.-is-desktop .filters__sections__options,
.filters.-is-desktop .filters__sections__order,
.filters.-is-desktop .filters__sections__zones {
  padding: 20px 30px;
}
.filters.-is-desktop .filters__sections__price__header__title,
.filters.-is-desktop .filters__sections__services__header__title,
.filters.-is-desktop .filters__sections__establishment__header__title,
.filters.-is-desktop .filters__sections__options__header__title,
.filters.-is-desktop .filters__sections__order__header__title,
.filters.-is-desktop .filters__sections__zones__header__title {
  color: #4a4a4a;
  display: block;
  font-size: 18px;
  letter-spacing: -0.25px;
  font-weight: 500;
  width: 100%;
  line-height: 22px;
  margin-bottom: 5px;
}
.filters.-is-desktop .filters__sections__price__header__description,
.filters.-is-desktop .filters__sections__services__header__description,
.filters.-is-desktop .filters__sections__establishment__header__description,
.filters.-is-desktop .filters__sections__options__header__description,
.filters.-is-desktop .filters__sections__order__header__description,
.filters.-is-desktop .filters__sections__zones__header__description {
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  display: block;
}
.filters.-is-desktop .filters__sections__price__body,
.filters.-is-desktop .filters__sections__services__body,
.filters.-is-desktop .filters__sections__establishment__body,
.filters.-is-desktop .filters__sections__options__body,
.filters.-is-desktop .filters__sections__order__body,
.filters.-is-desktop .filters__sections__zones__body {
  padding-top: 20px;
}
.filters.-is-desktop .filters__sections__price__body__innocuos-container__description,
.filters.-is-desktop .filters__sections__services__body__innocuos-container__description,
.filters.-is-desktop .filters__sections__establishment__body__innocuos-container__description,
.filters.-is-desktop .filters__sections__options__body__innocuos-container__description,
.filters.-is-desktop .filters__sections__order__body__innocuos-container__description,
.filters.-is-desktop .filters__sections__zones__body__innocuos-container__description {
  display: none;
}
.filters.-is-desktop .filters__sections__price__body__explain,
.filters.-is-desktop .filters__sections__services__body__explain,
.filters.-is-desktop .filters__sections__establishment__body__explain,
.filters.-is-desktop .filters__sections__options__body__explain,
.filters.-is-desktop .filters__sections__order__body__explain,
.filters.-is-desktop .filters__sections__zones__body__explain {
  color: #4a4a4a;
  display: block;
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  margin-bottom: 20px;
  width: 100%;
}
.filters.-is-desktop .filters__sections__price__header {
  display: inline-block;
  width: 100%;
}
.filters.-is-desktop .filters__sections__price__header__title {
  width: 60%;
  display: inline-block;
}
.filters.-is-desktop .filters__sections__price__header__toggle {
  display: inline-block;
  float: right;
}
.filters.-is-desktop .filters__sections__price__header__toggle .toggle-switch__container__switch {
  margin: 0px;
  height: 34px;
}
.filters.-is-desktop .filters__sections__price__header__toggle .toggle-switch__container__switch__label {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.29px;
  padding: 0 2px;
  letter-spacing: 0.25;
  height: 28px;
  top: 3px;
  left: 1px;
  padding-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filters.-is-desktop .filters__sections__price__header__toggle .toggle-switch__container__switch__selection {
  margin-top: 1px;
  margin-left: 1px;
  height: 28px;
  width: 56px;
}
.filters.-is-desktop .filters__sections__price__body {
  padding-top: 30px;
  padding-bottom: 30px;
}
.filters.-is-desktop .filters__sections__price__body__range__description {
  display: none;
}
.filters.-is-desktop .filters__sections__price__body__range__resume-container {
  font-size: 18px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  font-weight: 400;
  text-align: left;
  width: auto;
  line-height: 1.4em;
  padding-bottom: 30px;
}
.filters.-is-desktop .filters__sections__price__body__range__resume-container__toggle {
  display: none;
}
.filters.-is-desktop .filters__sections__price__body__range__resume-container__resume {
  color: #005fc9;
  font-weight: 500;
}
.filters.-is-desktop .filters__sections__price__body__range__resume-container__tot-night {
  color: #ccdff4;
}
.filters.-is-desktop .filters__sections__price__body__range__rheostat-container {
  padding: 5px 0px;
}
.filters.-is-desktop .filters__sections__price__body__range__rheostat-container .rheostat-horizontal {
  top: 54px;
}
.filters.-is-desktop .filters__sections__price__body__range__rheostat-container .rheostat-horizontal .rheostat-handle {
  min-width: 32px;
  min-height: 32px;
  border: solid 1px rgba(204,223,244,0.5);
}
.filters.-is-desktop .filters__sections__establishment__body__rooms__head {
  box-sizing: border-box;
  display: block;
  height: 79px;
  width: 100%;
}
.filters.-is-desktop .filters__sections__establishment__body__rooms__head__title {
  color: #4a4a4a;
  display: block;
  font-size: 18px;
  letter-spacing: -0.25px;
  font-weight: 500;
  width: 100%;
  line-height: 22px;
  margin-bottom: 5px;
}
.filters.-is-desktop .filters__sections__establishment__body__rooms__head__description {
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
}
.filters.-is-desktop .filters__sections__establishment__body__filters-line {
  background-color: #fff;
  width: 100%;
  display: block;
}
.filters.-is-desktop .filters__sections__establishment__body__filters-line:before {
  background-color: #ccdff4;
  content: '';
  display: block;
  height: 1px;
  margin: auto;
  width: 100%;
}
.filters.-is-desktop .filters__sections__services__body__show-more,
.filters.-is-desktop .filters__sections__zones__body__show-more {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.filters.-is-desktop .filters__sections__services__body__show-more__text,
.filters.-is-desktop .filters__sections__zones__body__show-more__text {
  text-align: left;
  display: inline-block;
  margin-right: 10px;
}
.filters.-is-desktop .filters__sections__services__body__show-more__rot,
.filters.-is-desktop .filters__sections__zones__body__show-more__rot {
  line-height: 50px;
  width: 14px;
  display: inline-block;
  color: #005fc9;
}
.filters.-is-desktop .filters__sections__services__body__show-more__rot__icon,
.filters.-is-desktop .filters__sections__zones__body__show-more__rot__icon {
  line-height: 50px;
}
.filters.-is-desktop .filters__sections__services__body__show-more__rot__icon:before,
.filters.-is-desktop .filters__sections__zones__body__show-more__rot__icon:before {
  line-height: 50px;
  display: inline-block;
  vertical-align: middle;
}
.filters.-is-desktop .filters__sections__services__body__show-more__rot__is-showed,
.filters.-is-desktop .filters__sections__zones__body__show-more__rot__is-showed {
  display: none;
}
.filters.-is-desktop .filters__sections__services__body__buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.filters.-is-desktop .filters__sections__services__body__buttons__button {
  position: relative;
  margin-bottom: 20px;
  height: 110px;
  width: 110px;
  margin-top: 0;
}
.filters.-is-desktop .filters__sections__services__body__buttons__button:hover {
  background-color: rgba(204,223,244,0.18);
  border: 1px solid #ccdff4;
}
.filters.-is-desktop .filters__sections__services__body__buttons__button__top-icon {
  transition: all 1s ease-in-out;
}
.filters.-is-desktop .filters__sections__services__body__buttons__button__top-icon__shown {
  text-align: center;
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  border: 2px solid #ccdff4;
  background-color: #fff;
  border-radius: 30px;
}
.filters.-is-desktop .filters__sections__services__body__buttons__button__top-icon__shown:before {
  font-size: 10px;
  letter-spacing: 0px;
  color: #005fc9;
  line-height: 16px;
}
.filters.-is-desktop .filters__sections__services__body__buttons__button.-no-selected .hundredicons-check {
  opacity: 0;
  transition: all ease 0.2s;
}
.filters.-is-desktop .filters__sections__services__body__buttons__button.-is-selected {
  background-color: rgba(204,223,244,0.18);
  border: 1px solid #ccdff4;
}
.filters.-is-desktop .filters__sections__services__body__buttons__button.-is-selected .hundredicons-check {
  opacity: 1;
  transition: all ease 0.2s;
  opacity: 1;
}
.filters.-is-desktop .filters__sections__services__body__buttons__button__icon:before {
  color: #61625e;
  font-size: 24px;
  line-height: 70px;
}
.filters.-is-desktop .filters__sections__services__body__buttons__button__bottom {
  height: 50px;
}
.filters.-is-desktop .filters__sections__services__body__buttons__button__bottom__text {
  color: #4a4a4a;
  font-size: 14px;
  letter-spacing: -0.25px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filters.-is-desktop .filters__sections__services__body__buttons__button__bottom__text__is-shown {
  margin: 0px auto;
  position: relative;
  text-align: center;
  color: #005fc9;
  line-height: 16px;
  letter-spacing: -0.25px;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filters.-is-desktop .filters__sections__services__body__buttons :nth-child(3n) {
  margin-right: 0;
}
.filters.-is-desktop .filters__buttons-apply {
  animation: repaint 100ms 1s 10;
  width: 420px;
  padding: 0px 20px;
}
.filters.-is-desktop .filters__buttons-apply__buttons__button-apply {
  min-width: 160px;
  width: auto;
  background-color: #005fc9;
  border: 2px solid #005fc9;
}
.filters.-is-desktop .filters__buttons-apply__buttons__button-apply:disabled {
  background-color: #005fc9;
  opacity: 0.5;
}
.filters.-is-desktop .filters__buttons-apply__buttons__button-cancel {
  border: none;
  width: 84px;
}
.filters.-is-desktop .filters__buttons-apply__buttons__button-cancel:hover {
  background-color: #fff;
}
@-moz-keyframes repaint {
  from {
    height: 64.1px;
  }
}
@-webkit-keyframes repaint {
  from {
    height: 64.1px;
  }
}
@-o-keyframes repaint {
  from {
    height: 64.1px;
  }
}
@keyframes repaint {
  from {
    height: 64.1px;
  }
}
@-moz-keyframes repaint-mobile {
  from {
    height: 82.1px;
  }
}
@-webkit-keyframes repaint-mobile {
  from {
    height: 82.1px;
  }
}
@-o-keyframes repaint-mobile {
  from {
    height: 82.1px;
  }
}
@keyframes repaint-mobile {
  from {
    height: 82.1px;
  }
}
.login-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 700px;
  overflow-x: hidden;
  border-radius: 3px;
}
.login-modal__left,
.login-modal__right {
  flex: 0 1 auto;
  width: 350px;
  height: 500px;
}
.login-modal__left {
  background-color: #f1f1f1;
  border-right: rgba(0,0,0,0.1);
  overflow: hidden;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.login-modal__left__favourite__title,
.login-modal__left__default__title,
.login-modal__left__offers__title {
  width: 100%;
}
.login-modal__left__favourite__title__text,
.login-modal__left__default__title__text,
.login-modal__left__offers__title__text {
  color: #005fc9;
  text-align: center;
  text-transform: capitalize;
  font-size: 48px;
  line-height: 64px;
  font-weight: 500;
  padding: 50px 7.03125% 70px 7.03125%;
  margin: 0;
}
.login-modal__left__favourite__list,
.login-modal__left__default__list,
.login-modal__left__offers__list {
  text-align: left;
  padding: 0px 5px 20px 40px;
}
.login-modal__left__favourite__list__item,
.login-modal__left__default__list__item,
.login-modal__left__offers__list__item {
  line-height: 40px;
  list-style: none;
  display: flex;
}
.login-modal__left__favourite__list__item__text,
.login-modal__left__default__list__item__text,
.login-modal__left__offers__list__item__text {
  color: #4a4a4a;
  font-weight: 300;
  font-size: 15px;
  letter-spacing: -0.25px;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 92%;
}
.login-modal__left__favourite__list__item__icon,
.login-modal__left__default__list__item__icon,
.login-modal__left__offers__list__item__icon {
  padding-right: 4px;
  color: #005fc9;
  font-size: 16px;
  letter-spacing: -0.25px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}
.login-modal__left__favourite__animation,
.login-modal__left__default__animation,
.login-modal__left__offers__animation {
  width: 100%;
  padding: 30px 40px;
  margin: auto;
  text-align: center;
  box-sizing: border-box;
}
.login-modal__left__favourite__animation__background,
.login-modal__left__default__animation__background,
.login-modal__left__offers__animation__background {
  width: 100%;
}
.login-modal__left__favourite__title__text,
.login-modal__left__offers__title__text {
  font-size: 50px;
  line-height: 50px;
  font-weight: 300;
  letter-spacing: -3.2px;
}
.login-modal__left__favourite__subtitle,
.login-modal__left__offers__subtitle {
  max-width: 270px;
  margin: auto;
}
.login-modal__left__favourite__subtitle__text,
.login-modal__left__offers__subtitle__text {
  color: #4a4a4a;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 300;
  text-align: center;
}
.login-modal__left__favourite__animation,
.login-modal__left__offers__animation {
  width: 300px;
}
.login-modal__right {
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.login-modal__right::-webkit-scrollbar {
  display: none;
}
.login-modal__right__messages {
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: all ease 0.5s;
}
.login-modal__right__messages.-slide-down {
  margin: 0px;
}
.login-modal__right__messages.-done,
.login-modal__right__messages.-error,
.login-modal__right__messages.-info {
  min-height: 50px;
  height: auto;
  padding: 10px 7.03125% 10px 7.03125%;
}
.login-modal__right__messages.-done {
  background: #005fc9;
}
.login-modal__right__messages.-error {
  background: #ff7165;
}
.login-modal__right__messages.-info {
  background: #353a8e;
}
.login-modal__right__messages__text {
  color: #fff;
  font-weight: 300;
  text-align: left;
  margin: 0;
  flex: 0 1 auto;
  max-width: 100%;
}
.login-modal__right__tabs {
  padding: 30px 30px 50px 30px;
  align-items: stretch;
  width: 300px;
  margin: auto;
  overflow: hidden;
  text-align: left;
}
.login-modal__right__tabs__innocuous-container {
  justify-content: space-between;
  width: 465px;
  transform: translateX(-136px);
  transition: all ease 0.5s;
  transition-delay: 0.1s;
}
.login-modal__right__tabs__innocuous-container.-forgot-password {
  transform: translateX(168px);
}
.login-modal__right__tabs__tab,
.login-modal__right__tabs__tab--actived {
  display: inline-block;
  vertical-align: top;
  width: 145px;
  margin: 0;
  font-size: 18px;
  letter-spacing: -0.25px;
  color: rgba(76,73,74,0.8);
  font-weight: 300;
  text-transform: uppercase;
  cursor: pointer;
  transition: all ease 0.5s;
  line-height: 30px;
  letter-spacing: -1px;
}
.login-modal__right__tabs__tab__text,
.login-modal__right__tabs__tab--actived__text,
.login-modal__right__tabs__tab__icon,
.login-modal__right__tabs__tab--actived__icon {
  margin: 0;
  display: inline-block;
  vertical-align: middle;
}
.login-modal__right__tabs__tab__text,
.login-modal__right__tabs__tab--actived__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  max-width: 83px;
}
.login-modal__right__tabs__tab__icon,
.login-modal__right__tabs__tab--actived__icon {
  line-height: 16px;
  padding-left: 5px;
}
.login-modal__right__tabs__tab--actived {
  color: #005fc9;
}
.login-modal__right__views {
  overflow: hidden;
  width: 320px;
  margin: auto;
}
.login-modal__right__views__innocuous-container {
  width: 1020px;
  transition: all ease 0.5s;
}
.login-modal__right__views__innocuous-container.-forgot-password {
  transform: translateX(0px);
}
.login-modal__right__views__innocuous-container.-login {
  transform: translateX(-340px);
}
.login-modal__right__views__innocuous-container.-register {
  transform: translateX(-680px);
}
.login-modal__right__views__social-buttons {
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px 10px 30px;
  margin: auto;
}
.login-modal__right__views__social-buttons__button {
  flex: 0 1 auto;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  letter-spacing: -0.29px;
  width: 75px;
  min-width: 60px;
  color: #696969;
}
.login-modal__right__views__social-buttons__button:before {
  color: #696969;
  line-height: 40px;
  font-size: 16px;
  letter-spacing: -0.25px;
  right: inherit;
}
.login-modal__right__views__separator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px 10px 30px;
}
.login-modal__right__views__separator__o {
  flex: 0 1 auto;
  padding: 0 5px 0 5px;
  color: #696969;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: -0.25px;
}
.login-modal__right__views__separator__line {
  flex: 3 1 auto;
  border-bottom: 1px solid #b3b3b3;
  height: 1px;
}
.login-modal__right__views__forgot-password {
  display: inline-block;
  vertical-align: top;
  width: 320px;
  margin: 0 10px 0 0px;
}
.login-modal__right__views__forgot-password__explain {
  padding: 10px 30px 10px 30px;
}
.login-modal__right__views__forgot-password__explain__first,
.login-modal__right__views__forgot-password__explain__second {
  line-height: 1.7;
  text-align: left;
  font-size: 14px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
  margin: 0;
  font-weight: 300;
}
.login-modal__right__views__forgot-password__input {
  padding: 10px 30px 10px 30px;
}
.login-modal__right__views__forgot-password__send-button {
  padding: 10px 30px 10px 30px;
}
.login-modal__right__views__forgot-password__send-button__button {
  text-transform: uppercase;
  margin: auto;
  width: 100%;
  max-width: inherit;
}
.login-modal__right__views__login__title,
.login-modal__right__views__register__title {
  color: #005fc9;
  font-size: 40px;
  line-height: 50px;
  font-weight: 100;
  text-transform: uppercase;
  text-align: left;
  margin: auto;
  display: none;
  padding: 0px 30px 10px 30px;
  display: none;
}
.login-modal__right__views__login {
  display: inline-block;
  vertical-align: top;
  width: 320px;
  margin: 0 10px 0 10px;
}
.login-modal__right__views__with-email {
  padding: 10px 30px 10px 30px;
}
.login-modal__right__views__with-email__email {
  padding: 0 0 3.515625% 0;
}
.login-modal__right__views__with-email__remember-me {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.515625% 0 3.515625% 0;
  justify-content: space-between;
  font-size: 11px;
  letter-spacing: 0px;
}
.login-modal__right__views__with-email__remember-me__checkbox,
.login-modal__right__views__with-email__remember-me__link {
  flex: 0 1 auto;
  margin: 0;
}
.login-modal__right__views__with-email__remember-me__checkbox__text {
  text-align: left;
  color: #4a4a4a;
}
.login-modal__right__views__with-email__remember-me__link {
  cursor: pointer;
  text-align: right;
  color: #005fc9;
}
.login-modal__right__views__with-email__remember-me__link:hover {
  text-decoration: underline;
}
.login-modal__right__views__send-button {
  padding: 10px 30px 10px 30px;
}
.login-modal__right__views__send-button__button {
  margin: auto;
  text-transform: uppercase;
  width: 100%;
  max-width: inherit;
}
.login-modal__right__views__register {
  display: inline-block;
  vertical-align: top;
  width: 320px;
  margin: 0 10px 0 10px;
}
.login-modal__right__views__register__conditions__text {
  padding: 3.515625% 0 3.515625% 0;
  margin: 0;
  text-align: center;
  color: #4a4a4a;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: -0.29px;
  cursor: pointer;
}
.login-modal__right__views__register__conditions__text__link {
  color: #005fc9;
}
.login-modal__right__views__register__conditions__text__link:hover {
  text-decoration: underline;
}
@media (max-width: 767px) {
  .login-modal {
    border: none;
    width: 100vw;
    border-radius: 0;
  }
  .login-modal__left {
    display: none;
  }
  .login-modal__right {
    width: 100%;
    height: 110vh;
    padding: 60px 0 10vh 0;
    border-radius: 0;
  }
  .login-modal__right__tabs {
    padding: 7.03125% 0 7.03125% 0;
    text-align: left;
  }
  .login-modal__right__tabs__innocuous-container {
    transform: translateX(-104px);
  }
  .login-modal__right__tabs__innocuous-container.-forgot-password {
    transform: translateX(200px);
  }
  .login-modal__right__views {
    margin: auto;
  }
  .login-modal__right__views__login,
  .login-modal__right__views__register {
    padding-bottom: 100px;
  }
  .login-modal__right__views__login__title,
  .login-modal__right__views__register__title {
    font-size: 58px;
    letter-spacing: -0.05em;
    display: block;
    padding-bottom: 30px;
    text-align: center;
    text-transform: none;
    line-height: 0.9em;
  }
}
.price-evolution {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  width: 100%;
  user-select: none;
  transition: all ease-in-out 0.4s;
  overflow: hidden;
  background: #fff;
  margin: auto;
  max-width: 940px;
  position: absolute;
  right: 20px;
  top: 69px;
  padding: 20px 0;
  border: 1px solid #e6e6e6;
  overflow: visible;
  border-radius: 3px;
  z-index: 999;
}
.price-evolution:after {
  content: '';
  top: -11px;
  background-color: #fff;
  border-left: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  display: block;
  opacity: 1;
  position: absolute;
  width: 20px;
  height: 20px;
  transform: rotateZ(45deg);
  right: 45px;
}
.price-evolution__title {
  margin-left: 70px;
  font-size: 18px;
  letter-spacing: -0.25px;
  font-weight: 600;
  color: #4a4a4a;
  margin-bottom: 15px;
}
.price-evolution__dates {
  width: auto;
  max-width: 900px;
  height: 40px;
  display: flex;
  margin: 0 70px;
  justify-content: space-between;
  align-items: flex-start;
  color: #4a4a4a;
  margin-bottom: 5px;
}
.price-evolution__dates__text-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.price-evolution__dates__text-container__upper {
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 19px;
  color: #4a4a4a;
}
.price-evolution__dates__text-container__lower {
  font-weight: 500;
  color: #4a4a4a;
}
.price-evolution__dates__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 19px;
}
.price-evolution__dates__container__month {
  padding: 0 5px;
  display: block;
  cursor: pointer;
  width: 125px;
  padding-bottom: 2px;
}
.price-evolution__dates__container__week {
  display: block;
  cursor: pointer;
  width: 125px;
  padding-bottom: 2px;
}
.price-evolution__dates__container__month__underline,
.price-evolution__dates__container__week__underline {
  border-bottom: 1px solid #353a8e;
  width: 125px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
}
.price-evolution__dates__container__month__clear,
.price-evolution__dates__container__week__clear {
  border-bottom: 1px solid #d3e3f5;
  width: 125px;
  cursor: pointer;
  text-align: center;
}
.price-evolution__chart {
  width: 95%;
  max-width: 940px;
  min-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.price-evolution__chart__content {
  position: relative;
  height: 100%;
}
.price-evolution__chart__content .ct-grids .ct-grid {
  stroke: #d3e3f5;
  stroke-dasharray: 0px;
}
.price-evolution__chart__content .ct-chart-line {
  background-color: #fff;
}
.price-evolution__chart__content .ct-chart-line .ct-series-a .ct-line {
  stroke-dasharray: 4px;
  stroke: #005fc9;
  stroke-width: 2px;
}
.price-evolution__chart__content .ct-chart-line .ct-series-a .ct-point {
  stroke-width: 0px;
}
.price-evolution__chart__content .ct-chart-line .ct-series-b .ct-line {
  stroke: #dae8f7;
  stroke-width: 2px;
}
.price-evolution__chart__content .ct-chart-line .ct-series-b .ct-point {
  fill: #eee;
  stroke: #eee;
  stroke-width: 2px;
}
.price-evolution__chart__content .ct-chart-line .ct-series-b .ct-point__searchable {
  fill: #fff;
  stroke: #dae8f7;
  cursor: pointer;
}
.price-evolution__chart__content .ct-chart-line .ct-series-b .ct-point__searchable:hover {
  fill: #dae8f7;
  stroke-width: 6px;
}
.price-evolution__chart__content .ct-chart-line .ct-series-b .ct-point__current {
  stroke: #005fc9;
  stroke-width: 4px;
}
.price-evolution__chart__content .ct-chart-line .ct-series-b .ct-point__cheapest {
  stroke: #50edc0;
  stroke-width: 4px;
}
.price-evolution__chart__content .ct-chart-line .ct-series-b .ct-point__cheapest:hover {
  fill: #50edc0;
}
.price-evolution__chart__content .ct-labels .ct-label-container {
  width: 110px;
}
.price-evolution__chart__content .ct-labels .ct-label-container .ct-label {
  font-size: 12px;
  letter-spacing: -0.29px;
  margin-top: 5px;
  width: 100%;
  display: block;
  text-align: center;
}
.price-evolution__chart__content .ct-labels .ct-label-container.ct-limit .ct-label {
  margin-left: 0;
}
.price-evolution__chart__content .ct-labels .ct-label-container.ct-start .ct-label {
  text-align: left;
}
.price-evolution__chart__content .ct-labels .ct-label-container.ct-current .ct-label {
  color: #005fc9;
}
.price-evolution__chart__content .ct-labels .ct-label-container.ct-end .ct-label {
  text-align: right;
}
.price-evolution__chart__legend {
  display: flex;
  justify-content: space-between;
  width: 78%;
  height: 30px;
  line-height: 30px;
  margin: 0 70px;
}
.price-evolution__chart__legend__item__text {
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 16px;
  color: #4a4a4a;
  height: auto;
}
.price-evolution__chart__legend__item__image {
  margin-right: 5px;
  vertical-align: middle;
}
.price-evolution__chart__legend__item__line {
  border: 2px solid #ccdff4;
  box-sizing: border-box;
  height: 2px;
  width: 20px;
  display: inline-block;
  margin-right: 6px;
  border-radius: 3px;
  vertical-align: middle;
}
.price-evolution__chart__legend__item__dots {
  border: 2px dotted #353a8e;
  box-sizing: border-box;
  height: 2px;
  width: 20px;
  display: inline-block;
  margin-right: 6px;
  border-radius: 3px;
  vertical-align: middle;
}
.price-evolution__chart__legend__item__circle-green,
.price-evolution__chart__legend__item__circle-blue {
  vertical-align: middle;
  height: 14px;
  width: 14px;
  border-radius: 100%;
  box-sizing: border-box;
  display: inline-block;
  margin-right: 6px;
}
.price-evolution__chart__legend__item__circle-green {
  border: 4px solid #50edc0;
}
.price-evolution__chart__legend__item__circle-blue {
  border: 4px solid #005fc9;
}
.price-evolution__search-dates {
  border-radius: 3px;
  width: 100%;
  max-width: 900px;
  min-width: 400px;
  background-color: #e5fdf6;
  height: 104px;
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
}
.price-evolution__search-dates__top-container,
.price-evolution__search-dates__bottom-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.price-evolution__search-dates__bottom-container {
  justify-content: flex-start;
  padding-left: 120px;
}
.price-evolution__search-dates__image-container {
  display: flex;
  justify-content: left;
  width: 104px;
  height: 100%;
  max-width: 104px;
  width: 7%;
  min-width: 60px;
}
.price-evolution__search-dates__image-container__image {
  max-width: 38px;
  height: auto;
  display: block;
}
.price-evolution__search-dates__text-container {
  display: inline-block;
  width: 65%;
  height: 100%;
}
.price-evolution__search-dates__text-container__upper {
  font-size: 16px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
  line-height: 19px;
  text-align: left;
  font-weight: 500;
}
.price-evolution__search-dates__text-container__center {
  color: #4a4a4a;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  font-size: 12px;
  letter-spacing: -0.29px;
}
.price-evolution__search-dates__button-container {
  display: flex;
  align-items: center;
  width: 20%;
  height: 100%;
}
.price-evolution__search-dates__button-container__button {
  border: 0px;
  color: #fff;
  height: 40px;
  line-height: inherit;
}
.price-evolution.-is-showed {
  height: 483px;
}
.price-evolution.-is-not-showed {
  height: 100px;
}
.price-evolution__carrousel {
  max-height: 220px;
  position: relative;
  overflow: initial;
  padding: 0 1em;
  box-sizing: border-box;
}
.price-evolution__carrousel .carrousel__innocuos-container {
  height: 100%;
}
.price-evolution__carrousel .carrousel__container,
.price-evolution__carrousel .carrousel__container__element {
  height: 180px;
  max-height: none;
  min-height: initial;
}
.price-evolution__carrousel .carrousel__arrow-left,
.price-evolution__carrousel .carrousel__arrow-right {
  max-height: none;
  min-height: initial;
  text-shadow: none;
  color: #005fc9;
  height: 160px;
}
.price-evolution__carrousel .carrousel__arrow-left.-is-hidden,
.price-evolution__carrousel .carrousel__arrow-right.-is-hidden {
  opacity: 0.2;
  cursor: default;
}
.price-evolution__carrousel .carrousel__arrow-right {
  right: 10px;
}
@media (max-width: 1310px) {
  .price-evolution {
    width: 100%;
    user-select: none;
    max-height: 425px;
    transition: all ease-in-out 0.4s;
    max-width: 620px;
  }
  .price-evolution__header__text-container {
    width: 62%;
  }
  .price-evolution__dates {
    width: 530px;
    margin: 0 55px;
  }
  .price-evolution__dates__text-container {
    display: inline-block;
  }
  .price-evolution__dates__text-container__upper {
    display: inline-block;
    margin-right: 5px;
  }
  .price-evolution__dates__text-container__lower {
    display: inline-block;
  }
  .price-evolution__chart {
    width: 100%;
    height: 180px;
    padding: 0 1em;
  }
  .price-evolution__chart__content {
    width: 100%;
  }
  .price-evolution__chart__legend {
    width: 77%;
    min-height: 30px;
  }
  .price-evolution__chart__legend__item__text {
    max-width: 85px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 3px;
    line-height: 12px;
    text-align: start;
  }
  .price-evolution__search-dates {
    max-height: 50px;
    max-width: 580px;
    background-color: #fff;
    margin-top: 20px;
  }
  .price-evolution__search-dates__bottom-container {
    padding-left: 0;
    margin-top: 5px;
  }
  .price-evolution__search-dates__text-container {
    width: 69%;
    overflow: hidden;
  }
  .price-evolution__search-dates__text-container__upper {
    font-size: 12px;
    letter-spacing: -0.29px;
    margin-top: 0px;
  }
  .price-evolution__search-dates__image-container {
    display: none;
  }
  .price-evolution__search-dates__button-container {
    height: 40px;
    width: 170px;
    align-items: flex-start;
  }
  .price-evolution__search-dates__button-container__button {
    width: 170px;
  }
}
.ct-tooltip {
  width: 13em;
  position: absolute;
  z-index: 1000000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s ease-in-out;
}
.ct-tooltip:before {
  display: none;
  content: "";
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  border: 8px solid transparent;
  transform: translateX(-50%);
  border-top-color: #fff;
}
.ct-tooltip.tooltip-show {
  opacity: 1;
}
.ct-tooltip.tooltip-show:before {
  display: block;
}
.ct-tooltip .chartist-tooltip-value {
  border-radius: 3px;
  font-size: 10px;
  letter-spacing: 0px;
  padding: 1em;
  background: #fff;
  text-align: center;
  position: relative;
  box-shadow: 0 5px 20px rgba(0,0,0,0.05), 0 5px 20px rgba(0,0,0,0.05);
  display: block;
}
.ct-tooltip .chartist-tooltip-value__title {
  font-weight: 600;
  text-align: center;
  line-height: 14px;
}
.ct-tooltip .chartist-tooltip-value__subtitle {
  text-align: center;
  line-height: 14px;
}
.ct-tooltip .chartist-tooltip-value__check {
  font-weight: 600;
  text-align: center;
  line-height: 20px;
}
.-multicard-test-price-ev {
  max-width: 620px;
  min-width: 620px;
}
@media (min-width: 1310px) {
  .-multicard-test-price-ev {
    max-width: 895px;
    min-width: 895px;
  }
}
.simple-form-modal {
  max-width: 650px;
  min-height: 300px;
  max-height: 100vh;
  width: 650px;
  margin: auto;
  border-radius: 5px;
  padding: 7.03125%;
  background-color: #fafafa;
  border: 1px solid rgba(0,0,0,0.05);
  overflow: scroll;
  box-sizing: border-box;
}
.simple-form-modal::-webkit-scrollbar {
  display: none;
}
.simple-form-modal__title-header {
  width: 95%;
  margin: auto;
  font-size: 42px;
  letter-spacing: -3.2px;
  line-height: 60px;
  color: #005fc9;
  text-align: center;
}
.simple-form-modal__message {
  width: 95%;
  max-width: 400px;
  font-size: 18px;
  letter-spacing: -0.25px;
  line-height: 25px;
  font-weight: 300;
  color: #4a4a4a;
  margin: 20px auto 10px auto;
  text-align: center;
}
.simple-form-modal__message.-is-padded {
  margin: 40px auto;
}
.simple-form-modal__subtitle {
  width: 95%;
  max-width: 400px;
  margin: 1em auto;
  font-size: 3em;
  line-height: 1.2em;
  font-weight: 100;
  color: #005fc9;
  text-align: center;
}
.simple-form-modal__title-form {
  width: 95%;
  max-width: 400px;
  margin: 40px auto 20px auto;
  font-size: 16px;
  letter-spacing: -0.25px;
  line-height: 16px;
  font-weight: 300;
  color: #4a4a4a;
  text-align: center;
}
.simple-form-modal__form {
  margin: auto;
  width: 90%;
  max-width: 350px;
}
.simple-form-modal__terms-of-serv {
  font-size: 0.9em;
  text-align: center;
  color: #4a4a4a;
  margin-top: 1em;
}
.simple-form-modal__terms-of-serv a {
  text-decoration: underline;
}
@media (max-width: 767px) {
  .simple-form-modal {
    width: 100%;
    max-width: none;
    overflow: scroll;
    min-height: 100vh;
    padding: 40px 3.515625% 40px;
    border-radius: 0px;
    border: none;
  }
  .simple-form-modal__title-header {
    margin-top: 40px;
  }
}
.suggestion-box {
  background-color: #b3ebe8;
  border-top: 0.5px solid #65a7a5;
  width: 100%;
  height: 80px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 50px;
  background-repeat: repeat-x;
  background-position: -4% 94%;
  background-size: auto 30px;
}
.suggestion-box.-airbnb-decoration {
  background-image: url("/img/suggestion/desktop-ilustracion-suggestionbox.svg");
}
.suggestion-box.-closing-mobile {
  animation: grow-from-bottom 0.5s linear;
  animation-direction: reverse;
  animation-fill-mode: forwards;
}
.suggestion-box.-suggestion-box-growing {
  animation: grow-from-bottom 0.5s linear;
}
.suggestion-box__close {
  z-index: 999;
  transform: translateZ(1);
  cursor: pointer;
}
.suggestion-box__close:before {
  display: flex;
  background-color: #fd595e;
  border-radius: 100%;
  color: #fff;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 10px;
  top: -18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  letter-spacing: -0.25px;
}
.suggestion-box__inside {
  display: flex;
  justify-content: center;
  align-items: center;
}
.suggestion-box__inside__close {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #026964;
  cursor: pointer;
}
.suggestion-box__inside__logo {
  height: 30px;
  margin-right: 0.4em;
}
.suggestion-box__inside__container {
  margin-left: 0.4em;
}
.suggestion-box__inside__container__text {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.25px;
  color: #026964;
  text-align: center;
}
.suggestion-box__inside__container__button {
  display: none;
}
@media (min-width: 768px) {
  .suggestion-box {
    display: block;
    position: relative;
    height: 50px;
    width: 50px;
    background-color: #fd595e;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 50% 45%;
    cursor: pointer;
    padding: 0;
    box-sizing: unset;
    border-top: none;
    transition: all 0.3s cubic-bezier(0.01, 1.63, 0.24, 0.94);
    background-image: none;
  }
  .suggestion-box.-airbnb-decoration {
    background-image: url("/img/suggestion/airbnb-logo.svg");
  }
  .suggestion-box__close,
  .suggestion-box__inside {
    opacity: 0;
    visibility: hidden;
  }
  .suggestion-box__inside__container__button {
    height: 30px;
    line-height: 30px;
  }
  .suggestion-box:hover:not(.-close-no-hover),
  .suggestion-box.-open {
    background-image: none;
    animation: scale-it 0.3s linear;
    height: 245px;
    width: 245px;
    background-color: transparent;
    background-image: none;
    right: -35px !important;
    bottom: -35px !important;
    transition: none;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box,
  .suggestion-box.-open .suggestion-box {
    transition: none;
    background-image: none;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__close,
  .suggestion-box.-open .suggestion-box__close {
    transition: opacity 0.1s;
    opacity: 1;
    visibility: visible;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__close:before,
  .suggestion-box.-open .suggestion-box__close:before {
    background-color: #fd595e;
    font-size: 22px;
    padding: 8px;
    border-radius: 100%;
    color: #fff;
    z-index: 1;
    right: 50px;
    top: -10px;
    position: absolute;
    cursor: pointer;
    width: auto;
    height: auto;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside,
  .suggestion-box.-open .suggestion-box__inside {
    transition: opacity 0.3s;
    opacity: 1;
    visibility: visible;
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #b3ebe8;
    border-radius: 100%;
    border: 1px solid #026964;
    box-shadow: 0 0 5px 2px rgba(0,0,0,0.17);
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: 0 40%;
    background-size: 100%;
    background-repeat: repeat-x;
    animation: moving-background 6s linear infinite;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside.-airbnb-decoration,
  .suggestion-box.-open .suggestion-box__inside.-airbnb-decoration {
    background-image: url("/img/suggestion/desktop-ilustracion-suggestionbox.svg");
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside__close,
  .suggestion-box.-open .suggestion-box__inside__close {
    position: absolute;
    top: 20px;
    left: 70px;
    font-size: 10px;
    letter-spacing: 0px;
    color: #0cd2c6;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside__logo,
  .suggestion-box.-open .suggestion-box__inside__logo {
    display: block;
    position: absolute;
    width: 100px;
    top: 40px;
    margin-right: 0;
    height: auto;
    left: 70px;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside__container,
  .suggestion-box.-open .suggestion-box__inside__container {
    bottom: 45px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside__container__text,
  .suggestion-box.-open .suggestion-box__inside__container__text {
    margin-bottom: 12px;
    font-size: 14px;
    letter-spacing: -0.25px;
    max-width: 70%;
    text-align: center;
    font-weight: 500;
    color: #026964;
    margin-right: 5px;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside__container__button,
  .suggestion-box.-open .suggestion-box__inside__container__button {
    display: block;
    width: auto;
    color: #fff;
    background-color: #fd595e;
    height: 30px !important;
    line-height: inherit;
  }
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside__container__button:hover,
  .suggestion-box.-open .suggestion-box__inside__container__button:hover,
  .suggestion-box:hover:not(.-close-no-hover) .suggestion-box__inside__container__button:active,
  .suggestion-box.-open .suggestion-box__inside__container__button:active {
    background-color: #fd595e;
  }
}
@-moz-keyframes grow-from-bottom {
  from {
    bottom: -100px;
  }
  to {
    bottom: 0;
  }
}
@-webkit-keyframes grow-from-bottom {
  from {
    bottom: -100px;
  }
  to {
    bottom: 0;
  }
}
@-o-keyframes grow-from-bottom {
  from {
    bottom: -100px;
  }
  to {
    bottom: 0;
  }
}
@keyframes grow-from-bottom {
  from {
    bottom: -100px;
  }
  to {
    bottom: 0;
  }
}
@-moz-keyframes moving-background {
  from {
    background-position: 0 40%;
  }
  to {
    background-position: -245px 40%;
  }
}
@-webkit-keyframes moving-background {
  from {
    background-position: 0 40%;
  }
  to {
    background-position: -245px 40%;
  }
}
@-o-keyframes moving-background {
  from {
    background-position: 0 40%;
  }
  to {
    background-position: -245px 40%;
  }
}
@keyframes moving-background {
  from {
    background-position: 0 40%;
  }
  to {
    background-position: -245px 40%;
  }
}
@-moz-keyframes scale-it {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.07);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes scale-it {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.07);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-o-keyframes scale-it {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.07);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-it {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.07);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.-suggestion-box-hidden {
  display: none;
}
.blog-home__suggestion-box,
.blog-category__suggestion-box,
.blog-subcategory__suggestion-box,
.post-page__suggestion-box {
  position: fixed;
  bottom: 0;
  z-index: 1;
}
.blog-home__header,
.blog-category__header,
.blog-subcategory__header,
.post-page__header {
  background-color: #fff;
  color: #1e1f24;
  height: 50px;
  overflow: hidden;
  width: 100%;
  position: fixed;
  transition: height ease-in-out 0.3s;
  will-change: height;
  z-index: 1;
}
.blog-home__header__wrapper,
.blog-category__header__wrapper,
.blog-subcategory__header__wrapper,
.post-page__header__wrapper {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 50px;
  justify-content: space-between;
  padding: 0 16px;
  width: 100%;
}
.blog-home__header__second-wrapper,
.blog-category__header__second-wrapper,
.blog-subcategory__header__second-wrapper,
.post-page__header__second-wrapper {
  display: flex;
  align-items: center;
}
.blog-home__header__link,
.blog-category__header__link,
.blog-subcategory__header__link,
.post-page__header__link {
  cursor: pointer;
}
.blog-home__header__link__text,
.blog-category__header__link__text,
.blog-subcategory__header__link__text,
.post-page__header__link__text,
.blog-home__header__link__logo,
.blog-category__header__link__logo,
.blog-subcategory__header__link__logo,
.post-page__header__link__logo {
  display: none;
}
.blog-home__header__link__logo-text,
.blog-category__header__link__logo-text,
.blog-subcategory__header__link__logo-text,
.post-page__header__link__logo-text {
  display: inline-block;
  height: 18px;
  width: auto;
}
.blog-home__header__fh-nav,
.blog-category__header__fh-nav,
.blog-subcategory__header__fh-nav,
.post-page__header__fh-nav {
  display: block;
}
.blog-home__header__nav-button,
.blog-category__header__nav-button,
.blog-subcategory__header__nav-button,
.post-page__header__nav-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 20px;
  justify-content: space-between;
  margin: 0;
  width: 28px;
}
.blog-home__header__nav-button__first,
.blog-category__header__nav-button__first,
.blog-subcategory__header__nav-button__first,
.post-page__header__nav-button__first,
.blog-home__header__nav-button__second,
.blog-category__header__nav-button__second,
.blog-subcategory__header__nav-button__second,
.post-page__header__nav-button__second,
.blog-home__header__nav-button__third,
.blog-category__header__nav-button__third,
.blog-subcategory__header__nav-button__third,
.post-page__header__nav-button__third {
  background-color: #979797;
  border-radius: 1.5px;
  display: block;
  height: 2px;
  transform-origin: center;
  transition-delay: 0.2s;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  will-change: transform;
}
.blog-home__header.-is-open,
.blog-category__header.-is-open,
.blog-subcategory__header.-is-open,
.post-page__header.-is-open {
  height: auto;
}
.blog-home__header.-is-open .blog-home__nav,
.blog-category__header.-is-open .blog-home__nav,
.blog-subcategory__header.-is-open .blog-home__nav,
.post-page__header.-is-open .blog-home__nav,
.blog-home__header.-is-open .blog-category__nav,
.blog-category__header.-is-open .blog-category__nav,
.blog-subcategory__header.-is-open .blog-category__nav,
.post-page__header.-is-open .blog-category__nav,
.blog-home__header.-is-open .blog-subcategory__nav,
.blog-category__header.-is-open .blog-subcategory__nav,
.blog-subcategory__header.-is-open .blog-subcategory__nav,
.post-page__header.-is-open .blog-subcategory__nav,
.blog-home__header.-is-open .post-page__nav,
.blog-category__header.-is-open .post-page__nav,
.blog-subcategory__header.-is-open .post-page__nav,
.post-page__header.-is-open .post-page__nav {
  max-height: calc(100vh - 50px);
  overflow-y: scroll;
}
.blog-home__header.-is-open .blog-home__header__nav-button__first,
.blog-category__header.-is-open .blog-home__header__nav-button__first,
.blog-subcategory__header.-is-open .blog-home__header__nav-button__first,
.post-page__header.-is-open .blog-home__header__nav-button__first,
.blog-home__header.-is-open .blog-category__header__nav-button__first,
.blog-category__header.-is-open .blog-category__header__nav-button__first,
.blog-subcategory__header.-is-open .blog-category__header__nav-button__first,
.post-page__header.-is-open .blog-category__header__nav-button__first,
.blog-home__header.-is-open .blog-subcategory__header__nav-button__first,
.blog-category__header.-is-open .blog-subcategory__header__nav-button__first,
.blog-subcategory__header.-is-open .blog-subcategory__header__nav-button__first,
.post-page__header.-is-open .blog-subcategory__header__nav-button__first,
.blog-home__header.-is-open .post-page__header__nav-button__first,
.blog-category__header.-is-open .post-page__header__nav-button__first,
.blog-subcategory__header.-is-open .post-page__header__nav-button__first,
.post-page__header.-is-open .post-page__header__nav-button__first {
  transform: rotate(45deg) translate(7px, 6px);
}
.blog-home__header.-is-open .blog-home__header__nav-button__second,
.blog-category__header.-is-open .blog-home__header__nav-button__second,
.blog-subcategory__header.-is-open .blog-home__header__nav-button__second,
.post-page__header.-is-open .blog-home__header__nav-button__second,
.blog-home__header.-is-open .blog-category__header__nav-button__second,
.blog-category__header.-is-open .blog-category__header__nav-button__second,
.blog-subcategory__header.-is-open .blog-category__header__nav-button__second,
.post-page__header.-is-open .blog-category__header__nav-button__second,
.blog-home__header.-is-open .blog-subcategory__header__nav-button__second,
.blog-category__header.-is-open .blog-subcategory__header__nav-button__second,
.blog-subcategory__header.-is-open .blog-subcategory__header__nav-button__second,
.post-page__header.-is-open .blog-subcategory__header__nav-button__second,
.blog-home__header.-is-open .post-page__header__nav-button__second,
.blog-category__header.-is-open .post-page__header__nav-button__second,
.blog-subcategory__header.-is-open .post-page__header__nav-button__second,
.post-page__header.-is-open .post-page__header__nav-button__second {
  transform: scaleX(0);
}
.blog-home__header.-is-open .blog-home__header__nav-button__third,
.blog-category__header.-is-open .blog-home__header__nav-button__third,
.blog-subcategory__header.-is-open .blog-home__header__nav-button__third,
.post-page__header.-is-open .blog-home__header__nav-button__third,
.blog-home__header.-is-open .blog-category__header__nav-button__third,
.blog-category__header.-is-open .blog-category__header__nav-button__third,
.blog-subcategory__header.-is-open .blog-category__header__nav-button__third,
.post-page__header.-is-open .blog-category__header__nav-button__third,
.blog-home__header.-is-open .blog-subcategory__header__nav-button__third,
.blog-category__header.-is-open .blog-subcategory__header__nav-button__third,
.blog-subcategory__header.-is-open .blog-subcategory__header__nav-button__third,
.post-page__header.-is-open .blog-subcategory__header__nav-button__third,
.blog-home__header.-is-open .post-page__header__nav-button__third,
.blog-category__header.-is-open .post-page__header__nav-button__third,
.blog-subcategory__header.-is-open .post-page__header__nav-button__third,
.post-page__header.-is-open .post-page__header__nav-button__third {
  transform: rotate(-45deg) translate(6px, -6px);
}
.blog-home__nav,
.blog-category__nav,
.blog-subcategory__nav,
.post-page__nav {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 21px;
}
.blog-home__nav.-on-footer,
.blog-category__nav.-on-footer,
.blog-subcategory__nav.-on-footer,
.post-page__nav.-on-footer {
  background-color: #f5f5f5;
}
.blog-home__nav__title,
.blog-category__nav__title,
.blog-subcategory__nav__title,
.post-page__nav__title {
  display: none;
}
.blog-home__nav__subcategories,
.blog-category__nav__subcategories,
.blog-subcategory__nav__subcategories,
.post-page__nav__subcategories,
.blog-home__nav__posts,
.blog-category__nav__posts,
.blog-subcategory__nav__posts,
.post-page__nav__posts {
  padding-top: 21px;
}
.blog-home__nav__categories,
.blog-category__nav__categories,
.blog-subcategory__nav__categories,
.post-page__nav__categories,
.blog-home__nav__subcategories,
.blog-category__nav__subcategories,
.blog-subcategory__nav__subcategories,
.post-page__nav__subcategories,
.blog-home__nav__posts,
.blog-category__nav__posts,
.blog-subcategory__nav__posts,
.post-page__nav__posts {
  width: 100%;
}
.blog-home__nav__categories__title,
.blog-category__nav__categories__title,
.blog-subcategory__nav__categories__title,
.post-page__nav__categories__title,
.blog-home__nav__subcategories__title,
.blog-category__nav__subcategories__title,
.blog-subcategory__nav__subcategories__title,
.post-page__nav__subcategories__title,
.blog-home__nav__posts__title,
.blog-category__nav__posts__title,
.blog-subcategory__nav__posts__title,
.post-page__nav__posts__title {
  color: #1e1f24;
  display: block;
  font-size: 18px;
  letter-spacing: -0.25px;
  font-weight: bold;
  line-height: 30px;
}
.blog-home__nav__categories__list,
.blog-category__nav__categories__list,
.blog-subcategory__nav__categories__list,
.post-page__nav__categories__list,
.blog-home__nav__subcategories__list,
.blog-category__nav__subcategories__list,
.blog-subcategory__nav__subcategories__list,
.post-page__nav__subcategories__list,
.blog-home__nav__posts__list,
.blog-category__nav__posts__list,
.blog-subcategory__nav__posts__list,
.post-page__nav__posts__list {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  list-style: none;
}
.blog-home__nav__categories__list__item,
.blog-category__nav__categories__list__item,
.blog-subcategory__nav__categories__list__item,
.post-page__nav__categories__list__item,
.blog-home__nav__subcategories__list__item,
.blog-category__nav__subcategories__list__item,
.blog-subcategory__nav__subcategories__list__item,
.post-page__nav__subcategories__list__item,
.blog-home__nav__posts__list__item,
.blog-category__nav__posts__list__item,
.blog-subcategory__nav__posts__list__item,
.post-page__nav__posts__list__item {
  width: 100%;
  display: block;
}
.blog-home__nav__categories__list__item__link,
.blog-category__nav__categories__list__item__link,
.blog-subcategory__nav__categories__list__item__link,
.post-page__nav__categories__list__item__link,
.blog-home__nav__subcategories__list__item__link,
.blog-category__nav__subcategories__list__item__link,
.blog-subcategory__nav__subcategories__list__item__link,
.post-page__nav__subcategories__list__item__link,
.blog-home__nav__posts__list__item__link,
.blog-category__nav__posts__list__item__link,
.blog-subcategory__nav__posts__list__item__link,
.post-page__nav__posts__list__item__link {
  color: #1e1f24;
  font-size: 18px;
  letter-spacing: -0.25px;
  line-height: 40px;
  text-align: left;
}
.blog-home__tops-posts,
.blog-category__tops-posts,
.blog-subcategory__tops-posts,
.post-page__tops-posts {
  align-items: flex-start;
  display: flex;
  height: 270px;
  padding-top: 50px;
}
.blog-home__tops-posts__left,
.blog-category__tops-posts__left,
.blog-subcategory__tops-posts__left,
.post-page__tops-posts__left,
.blog-home__tops-posts__right,
.blog-category__tops-posts__right,
.blog-subcategory__tops-posts__right,
.post-page__tops-posts__right {
  display: block;
  height: 270px;
  width: 100%;
}
.blog-home__tops-posts__left__wrapper,
.blog-category__tops-posts__left__wrapper,
.blog-subcategory__tops-posts__left__wrapper,
.post-page__tops-posts__left__wrapper,
.blog-home__tops-posts__right__wrapper,
.blog-category__tops-posts__right__wrapper,
.blog-subcategory__tops-posts__right__wrapper,
.post-page__tops-posts__right__wrapper {
  align-items: center;
  background-origin: border-box;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 270px;
  justify-content: flex-end;
  padding: 30px;
  width: 100%;
}
.blog-home__tops-posts__left__claim,
.blog-category__tops-posts__left__claim,
.blog-subcategory__tops-posts__left__claim,
.post-page__tops-posts__left__claim,
.blog-home__tops-posts__right__claim,
.blog-category__tops-posts__right__claim,
.blog-subcategory__tops-posts__right__claim,
.post-page__tops-posts__right__claim {
  align-items: flex-start;
  background-color: #fff;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 300px;
  min-height: 70px;
  padding: 13px 20px 13px 13px;
}
.blog-home__tops-posts__left__claim__title,
.blog-category__tops-posts__left__claim__title,
.blog-subcategory__tops-posts__left__claim__title,
.post-page__tops-posts__left__claim__title,
.blog-home__tops-posts__right__claim__title,
.blog-category__tops-posts__right__claim__title,
.blog-subcategory__tops-posts__right__claim__title,
.post-page__tops-posts__right__claim__title {
  display: block;
  font-size: 18px;
  letter-spacing: -0.25px;
  font-weight: bold;
  line-height: 22px;
  text-align: left;
  padding-bottom: 10px;
}
.blog-home__tops-posts__left__claim__link,
.blog-category__tops-posts__left__claim__link,
.blog-subcategory__tops-posts__left__claim__link,
.post-page__tops-posts__left__claim__link,
.blog-home__tops-posts__right__claim__link,
.blog-category__tops-posts__right__claim__link,
.blog-subcategory__tops-posts__right__claim__link,
.post-page__tops-posts__right__claim__link {
  color: #005fc9;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}
.blog-home__tops-posts__right,
.blog-category__tops-posts__right,
.blog-subcategory__tops-posts__right,
.post-page__tops-posts__right {
  display: none;
}
.blog-home__searchbar,
.blog-category__searchbar,
.blog-subcategory__searchbar,
.post-page__searchbar {
  background-color: #f5f5f5;
}
.blog-home__searchbar .search-bar-molecule__desktop__container,
.blog-category__searchbar .search-bar-molecule__desktop__container,
.blog-subcategory__searchbar .search-bar-molecule__desktop__container,
.post-page__searchbar .search-bar-molecule__desktop__container {
  display: none;
}
.blog-home__searchbar .search-form .search-bar-molecule__desktop__container,
.blog-category__searchbar .search-form .search-bar-molecule__desktop__container,
.blog-subcategory__searchbar .search-form .search-bar-molecule__desktop__container,
.post-page__searchbar .search-form .search-bar-molecule__desktop__container {
  display: block;
}
.blog-home__searchbar .search-bar-molecule__bottom__checkboxes.-is-empty,
.blog-category__searchbar .search-bar-molecule__bottom__checkboxes.-is-empty,
.blog-subcategory__searchbar .search-bar-molecule__bottom__checkboxes.-is-empty,
.post-page__searchbar .search-bar-molecule__bottom__checkboxes.-is-empty {
  display: none;
}
.blog-home__breadcrumb,
.blog-category__breadcrumb,
.blog-subcategory__breadcrumb,
.post-page__breadcrumb {
  display: block;
  text-align: left;
  margin: 0 auto;
  align-items: center;
  box-sizing: border-box;
  padding: 21px;
  width: 100%;
}
.blog-home__breadcrumb__item,
.blog-category__breadcrumb__item,
.blog-subcategory__breadcrumb__item,
.post-page__breadcrumb__item {
  display: inline;
}
.blog-home__breadcrumb__item__link,
.blog-category__breadcrumb__item__link,
.blog-subcategory__breadcrumb__item__link,
.post-page__breadcrumb__item__link {
  cursor: pointer;
  font-size: 11px;
  letter-spacing: 0px;
  font-weight: 500;
  line-height: 13px;
}
.blog-home__breadcrumb__item__last,
.blog-category__breadcrumb__item__last,
.blog-subcategory__breadcrumb__item__last,
.post-page__breadcrumb__item__last {
  font-size: 11px;
  letter-spacing: 0px;
  font-weight: 500;
  line-height: 13px;
}
.blog-home__breadcrumb__item__separator,
.blog-category__breadcrumb__item__separator,
.blog-subcategory__breadcrumb__item__separator,
.post-page__breadcrumb__item__separator {
  padding: 0 5px;
  font-size: 15px;
  letter-spacing: -0.25px;
  font-weight: 500;
}
.blog-home__claim-home,
.blog-category__claim-home,
.blog-subcategory__claim-home,
.post-page__claim-home,
.blog-home__claim,
.blog-category__claim,
.blog-subcategory__claim,
.post-page__claim {
  width: 100%;
  padding: 21px;
  color: #1e1f24;
}
.blog-home__claim-home__title,
.blog-category__claim-home__title,
.blog-subcategory__claim-home__title,
.post-page__claim-home__title,
.blog-home__claim__title,
.blog-category__claim__title,
.blog-subcategory__claim__title,
.post-page__claim__title {
  font-size: 32px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
}
.blog-home__claim__title,
.blog-category__claim__title,
.blog-subcategory__claim__title,
.post-page__claim__title {
  text-align: left;
  font-size: 22px;
}
.blog-home__claim__description,
.blog-category__claim__description,
.blog-subcategory__claim__description,
.post-page__claim__description {
  text-align: left;
  font-size: 16px;
  letter-spacing: -0.25px;
  line-height: 26px;
}
.blog-home__post-section,
.blog-category__post-section,
.blog-subcategory__post-section,
.post-page__post-section,
.blog-home__claim-home,
.blog-category__claim-home,
.blog-subcategory__claim-home,
.post-page__claim-home {
  padding: 13px 21px 25px;
  box-sizing: border-box;
}
.blog-home__post-section__title,
.blog-category__post-section__title,
.blog-subcategory__post-section__title,
.post-page__post-section__title,
.blog-home__claim-home__title,
.blog-category__claim-home__title,
.blog-subcategory__claim-home__title,
.post-page__claim-home__title {
  font-size: 28px;
  line-height: 32px;
  font-weight: bold;
  text-align: left;
}
.blog-home__post-section__category,
.blog-category__post-section__category,
.blog-subcategory__post-section__category,
.post-page__post-section__category,
.blog-home__claim-home__category,
.blog-category__claim-home__category,
.blog-subcategory__claim-home__category,
.post-page__claim-home__category {
  padding: 21px 0;
}
.blog-home__post-section__category__title,
.blog-category__post-section__category__title,
.blog-subcategory__post-section__category__title,
.post-page__post-section__category__title,
.blog-home__claim-home__category__title,
.blog-category__claim-home__category__title,
.blog-subcategory__claim-home__category__title,
.post-page__claim-home__category__title {
  color: #1e1f24;
  display: block;
  font-size: 18px;
  letter-spacing: -0.25px;
  font-weight: bold;
  line-height: 20px;
  padding-bottom: 16px;
  text-align: left;
}
.blog-home__post-section__category__link,
.blog-category__post-section__category__link,
.blog-subcategory__post-section__category__link,
.post-page__post-section__category__link,
.blog-home__claim-home__category__link,
.blog-category__claim-home__category__link,
.blog-subcategory__claim-home__category__link,
.post-page__claim-home__category__link {
  color: #005fc9;
  cursor: pointer;
  display: block;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 500;
  line-height: 16px;
  padding-bottom: 16px;
}
.blog-home__post-section__category__posts,
.blog-category__post-section__category__posts,
.blog-subcategory__post-section__category__posts,
.post-page__post-section__category__posts,
.blog-home__claim-home__category__posts,
.blog-category__claim-home__category__posts,
.blog-subcategory__claim-home__category__posts,
.post-page__claim-home__category__posts {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
.blog-home__post-section__category__posts__post,
.blog-category__post-section__category__posts__post,
.blog-subcategory__post-section__category__posts__post,
.post-page__post-section__category__posts__post,
.blog-home__claim-home__category__posts__post,
.blog-category__claim-home__category__posts__post,
.blog-subcategory__claim-home__category__posts__post,
.post-page__claim-home__category__posts__post {
  display: block;
  overflow: hidden;
  padding: 15px 0;
  width: 100%;
}
.blog-home__post-section__category__posts__post__wrapper,
.blog-category__post-section__category__posts__post__wrapper,
.blog-subcategory__post-section__category__posts__post__wrapper,
.post-page__post-section__category__posts__post__wrapper,
.blog-home__claim-home__category__posts__post__wrapper,
.blog-category__claim-home__category__posts__post__wrapper,
.blog-subcategory__claim-home__category__posts__post__wrapper,
.post-page__claim-home__category__posts__post__wrapper {
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  width: 100%;
}
.blog-home__post-section__category__posts__post__image,
.blog-category__post-section__category__posts__post__image,
.blog-subcategory__post-section__category__posts__post__image,
.post-page__post-section__category__posts__post__image,
.blog-home__claim-home__category__posts__post__image,
.blog-category__claim-home__category__posts__post__image,
.blog-subcategory__claim-home__category__posts__post__image,
.post-page__claim-home__category__posts__post__image {
  display: block;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  width: 100%;
}
.blog-home__post-section__category__posts__post__title,
.blog-category__post-section__category__posts__post__title,
.blog-subcategory__post-section__category__posts__post__title,
.post-page__post-section__category__posts__post__title,
.blog-home__claim-home__category__posts__post__title,
.blog-category__claim-home__category__posts__post__title,
.blog-subcategory__claim-home__category__posts__post__title,
.post-page__claim-home__category__posts__post__title {
  color: #1e1f24;
  font-size: 18px;
  letter-spacing: -0.25px;
  font-weight: bold;
  line-height: 26px;
  padding: 16px 0;
  text-align: left;
}
.blog-home__post-section__category__posts__post__text,
.blog-category__post-section__category__posts__post__text,
.blog-subcategory__post-section__category__posts__post__text,
.post-page__post-section__category__posts__post__text,
.blog-home__claim-home__category__posts__post__text,
.blog-category__claim-home__category__posts__post__text,
.blog-subcategory__claim-home__category__posts__post__text,
.post-page__claim-home__category__posts__post__text {
  color: #1e1f24;
  font-size: 16px;
  letter-spacing: -0.25px;
  line-height: 26px;
  text-align: left;
}
.blog-home .footer-molecule,
.blog-category .footer-molecule,
.blog-subcategory .footer-molecule,
.post-page .footer-molecule {
  border-top: 0px solid transparent;
}
.blog-home .footer-molecule__second-footer,
.blog-category .footer-molecule__second-footer,
.blog-subcategory .footer-molecule__second-footer,
.post-page .footer-molecule__second-footer {
  border-top: 0px solid transparent;
}
@media (min-width: 768px) {
  .blog-home__suggestion-box,
  .blog-category__suggestion-box,
  .blog-subcategory__suggestion-box,
  .post-page__suggestion-box {
    bottom: 20px;
    right: 20px;
  }
  .blog-home .search-bar-molecule__desktop__container,
  .blog-category .search-bar-molecule__desktop__container,
  .blog-subcategory .search-bar-molecule__desktop__container,
  .post-page .search-bar-molecule__desktop__container {
    display: block;
  }
  .blog-home .search-bar-molecule__mobile,
  .blog-category .search-bar-molecule__mobile,
  .blog-subcategory .search-bar-molecule__mobile,
  .post-page .search-bar-molecule__mobile {
    display: none;
  }
  .blog-home__header,
  .blog-category__header,
  .blog-subcategory__header,
  .post-page__header {
    height: 50px;
  }
  .blog-home__header__wrapper,
  .blog-category__header__wrapper,
  .blog-subcategory__header__wrapper,
  .post-page__header__wrapper {
    height: 50px;
    margin: auto;
    padding: 0 40px;
  }
  .blog-home__header__link__logo,
  .blog-category__header__link__logo,
  .blog-subcategory__header__link__logo,
  .post-page__header__link__logo {
    display: inline-block;
    height: 20px;
    margin-right: 5px;
    width: auto;
  }
  .blog-home__header.-is-open,
  .blog-category__header.-is-open,
  .blog-subcategory__header.-is-open,
  .post-page__header.-is-open {
    height: auto;
  }
  .blog-home__header.-is-open .blog-home__nav,
  .blog-category__header.-is-open .blog-home__nav,
  .blog-subcategory__header.-is-open .blog-home__nav,
  .post-page__header.-is-open .blog-home__nav,
  .blog-home__header.-is-open .blog-category__nav,
  .blog-category__header.-is-open .blog-category__nav,
  .blog-subcategory__header.-is-open .blog-category__nav,
  .post-page__header.-is-open .blog-category__nav,
  .blog-home__header.-is-open .blog-subcategory__nav,
  .blog-category__header.-is-open .blog-subcategory__nav,
  .blog-subcategory__header.-is-open .blog-subcategory__nav,
  .post-page__header.-is-open .blog-subcategory__nav,
  .blog-home__header.-is-open .post-page__nav,
  .blog-category__header.-is-open .post-page__nav,
  .blog-subcategory__header.-is-open .post-page__nav,
  .post-page__header.-is-open .post-page__nav {
    overflow-y: hidden;
  }
  .blog-home__nav,
  .blog-category__nav,
  .blog-subcategory__nav,
  .post-page__nav {
    align-items: center;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    margin: auto;
    max-width: 1200px;
    padding: 28px;
  }
  .blog-home__nav.-on-footer,
  .blog-category__nav.-on-footer,
  .blog-subcategory__nav.-on-footer,
  .post-page__nav.-on-footer {
    margin-top: 50px;
    padding-top: 50px;
    background: transparent;
    border-top: 1px solid #ebebeb;
  }
  .blog-home__nav__title,
  .blog-category__nav__title,
  .blog-subcategory__nav__title,
  .post-page__nav__title {
    display: block;
    width: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: -5px;
  }
  .blog-home__nav__title__text,
  .blog-category__nav__title__text,
  .blog-subcategory__nav__title__text,
  .post-page__nav__title__text {
    color: #1e1f24;
    font-size: 28px;
    font-weight: 800;
    line-height: 38px;
    max-width: 250px;
    text-align: left;
  }
  .blog-home__nav__categories,
  .blog-category__nav__categories,
  .blog-subcategory__nav__categories,
  .post-page__nav__categories,
  .blog-home__nav__subcategories,
  .blog-category__nav__subcategories,
  .blog-subcategory__nav__subcategories,
  .post-page__nav__subcategories,
  .blog-home__nav__posts,
  .blog-category__nav__posts,
  .blog-subcategory__nav__posts,
  .post-page__nav__posts {
    padding: 0 20px;
    width: 20%;
  }
  .blog-home__nav__categories__title,
  .blog-category__nav__categories__title,
  .blog-subcategory__nav__categories__title,
  .post-page__nav__categories__title,
  .blog-home__nav__subcategories__title,
  .blog-category__nav__subcategories__title,
  .blog-subcategory__nav__subcategories__title,
  .post-page__nav__subcategories__title,
  .blog-home__nav__posts__title,
  .blog-category__nav__posts__title,
  .blog-subcategory__nav__posts__title,
  .post-page__nav__posts__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    letter-spacing: -0.25px;
    line-height: 16px;
    padding-bottom: 7px;
  }
  .blog-home__nav__categories__list__item__link,
  .blog-category__nav__categories__list__item__link,
  .blog-subcategory__nav__categories__list__item__link,
  .post-page__nav__categories__list__item__link,
  .blog-home__nav__subcategories__list__item__link,
  .blog-category__nav__subcategories__list__item__link,
  .blog-subcategory__nav__subcategories__list__item__link,
  .post-page__nav__subcategories__list__item__link,
  .blog-home__nav__posts__list__item__link,
  .blog-category__nav__posts__list__item__link,
  .blog-subcategory__nav__posts__list__item__link,
  .post-page__nav__posts__list__item__link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    letter-spacing: -0.25px;
    line-height: 20px;
    display: block;
  }
  .blog-home__nav__posts__list__item__link,
  .blog-category__nav__posts__list__item__link,
  .blog-subcategory__nav__posts__list__item__link,
  .post-page__nav__posts__list__item__link {
    width: 170%;
  }
  .blog-home__tops-posts,
  .blog-category__tops-posts,
  .blog-subcategory__tops-posts,
  .post-page__tops-posts {
    height: 35vw;
    height: 35vw;
    max-height: 450px;
  }
  .blog-home__tops-posts__left,
  .blog-category__tops-posts__left,
  .blog-subcategory__tops-posts__left,
  .post-page__tops-posts__left,
  .blog-home__tops-posts__right,
  .blog-category__tops-posts__right,
  .blog-subcategory__tops-posts__right,
  .post-page__tops-posts__right {
    align-items: flex-start;
    display: flex;
    height: 35vw;
    max-height: 450px;
  }
  .blog-home__tops-posts__left__wrapper,
  .blog-category__tops-posts__left__wrapper,
  .blog-subcategory__tops-posts__left__wrapper,
  .post-page__tops-posts__left__wrapper,
  .blog-home__tops-posts__right__wrapper,
  .blog-category__tops-posts__right__wrapper,
  .blog-subcategory__tops-posts__right__wrapper,
  .post-page__tops-posts__right__wrapper {
    align-items: flex-start;
    height: 35vw;
    max-height: 450px;
    padding: 24px;
  }
  .blog-home__tops-posts__left__claim,
  .blog-category__tops-posts__left__claim,
  .blog-subcategory__tops-posts__left__claim,
  .post-page__tops-posts__left__claim,
  .blog-home__tops-posts__right__claim,
  .blog-category__tops-posts__right__claim,
  .blog-subcategory__tops-posts__right__claim,
  .post-page__tops-posts__right__claim {
    max-width: 400px;
    padding: 16px 25px 16px 16px;
  }
  .blog-home__tops-posts__left__claim__title,
  .blog-category__tops-posts__left__claim__title,
  .blog-subcategory__tops-posts__left__claim__title,
  .post-page__tops-posts__left__claim__title,
  .blog-home__tops-posts__right__claim__title,
  .blog-category__tops-posts__right__claim__title,
  .blog-subcategory__tops-posts__right__claim__title,
  .post-page__tops-posts__right__claim__title {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 16px;
  }
  .blog-home__tops-posts__left__claim__link,
  .blog-category__tops-posts__left__claim__link,
  .blog-subcategory__tops-posts__left__claim__link,
  .post-page__tops-posts__left__claim__link,
  .blog-home__tops-posts__right__claim__link,
  .blog-category__tops-posts__right__claim__link,
  .blog-subcategory__tops-posts__right__claim__link,
  .post-page__tops-posts__right__claim__link {
    font-size: 18px;
    letter-spacing: -0.25px;
    line-height: 20px;
  }
  .blog-home__tops-posts__right,
  .blog-category__tops-posts__right,
  .blog-subcategory__tops-posts__right,
  .post-page__tops-posts__right {
    box-sizing: border-box;
    border-left: solid #fff 4px;
  }
  .blog-home__breadcrumb,
  .blog-category__breadcrumb,
  .blog-subcategory__breadcrumb,
  .post-page__breadcrumb {
    max-width: 1200px;
    margin: auto;
    padding: 28px;
  }
  .blog-home__breadcrumb__item,
  .blog-category__breadcrumb__item,
  .blog-subcategory__breadcrumb__item,
  .post-page__breadcrumb__item {
    display: inline;
  }
  .blog-home__breadcrumb__item__link,
  .blog-category__breadcrumb__item__link,
  .blog-subcategory__breadcrumb__item__link,
  .post-page__breadcrumb__item__link {
    font-size: 12px;
    letter-spacing: -0.29px;
    line-height: 15px;
  }
  .blog-home__breadcrumb__item__link:hover,
  .blog-category__breadcrumb__item__link:hover,
  .blog-subcategory__breadcrumb__item__link:hover,
  .post-page__breadcrumb__item__link:hover {
    color: #005fc9;
    text-decoration: underline;
  }
  .blog-home__claim,
  .blog-category__claim,
  .blog-subcategory__claim,
  .post-page__claim {
    max-width: 1200px;
    padding: 28px;
    margin: auto;
  }
  .blog-home__claim__title,
  .blog-category__claim__title,
  .blog-subcategory__claim__title,
  .post-page__claim__title {
    font-size: 36px;
    line-height: 40px;
    text-align: center;
  }
  .blog-home__claim__description,
  .blog-category__claim__description,
  .blog-subcategory__claim__description,
  .post-page__claim__description {
    font-size: 16px;
    letter-spacing: -0.25px;
    line-height: 26px;
    margin: auto;
    max-width: 800px;
    text-align: center;
  }
  .blog-home__post-section,
  .blog-category__post-section,
  .blog-subcategory__post-section,
  .post-page__post-section,
  .blog-home__claim-home,
  .blog-category__claim-home,
  .blog-subcategory__claim-home,
  .post-page__claim-home {
    max-width: 1200px;
    margin: auto;
    padding: 13px 28px 25px;
  }
  .blog-home__post-section__title,
  .blog-category__post-section__title,
  .blog-subcategory__post-section__title,
  .post-page__post-section__title,
  .blog-home__claim-home__title,
  .blog-category__claim-home__title,
  .blog-subcategory__claim-home__title,
  .post-page__claim-home__title {
    font-size: 36px;
    line-height: 40px;
  }
  .blog-home__post-section__category,
  .blog-category__post-section__category,
  .blog-subcategory__post-section__category,
  .post-page__post-section__category,
  .blog-home__claim-home__category,
  .blog-category__claim-home__category,
  .blog-subcategory__claim-home__category,
  .post-page__claim-home__category {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 28px;
  }
  .blog-home__post-section__category__title,
  .blog-category__post-section__category__title,
  .blog-subcategory__post-section__category__title,
  .post-page__post-section__category__title,
  .blog-home__claim-home__category__title,
  .blog-category__claim-home__category__title,
  .blog-subcategory__claim-home__category__title,
  .post-page__claim-home__category__title {
    display: inline-block;
    font-size: 28px;
    line-height: 34px;
    width: 80%;
  }
  .blog-home__post-section__category__link,
  .blog-category__post-section__category__link,
  .blog-subcategory__post-section__category__link,
  .post-page__post-section__category__link,
  .blog-home__claim-home__category__link,
  .blog-category__claim-home__category__link,
  .blog-subcategory__claim-home__category__link,
  .post-page__claim-home__category__link {
    display: inline-block;
    font-size: 18px;
    letter-spacing: -0.25px;
    line-height: 21px;
    text-align: right;
    width: 18%;
  }
  .blog-home__post-section__category__posts,
  .blog-category__post-section__category__posts,
  .blog-subcategory__post-section__category__posts,
  .post-page__post-section__category__posts,
  .blog-home__claim-home__category__posts,
  .blog-category__claim-home__category__posts,
  .blog-subcategory__claim-home__category__posts,
  .post-page__claim-home__category__posts {
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .blog-home__post-section__category__posts__post,
  .blog-category__post-section__category__posts__post,
  .blog-subcategory__post-section__category__posts__post,
  .post-page__post-section__category__posts__post,
  .blog-home__claim-home__category__posts__post,
  .blog-category__claim-home__category__posts__post,
  .blog-subcategory__claim-home__category__posts__post,
  .post-page__claim-home__category__posts__post {
    display: inline-block;
    min-width: 250px;
    padding-right: 20px;
    width: 50%;
  }
  .blog-home__post-section__category__posts__post__image,
  .blog-category__post-section__category__posts__post__image,
  .blog-subcategory__post-section__category__posts__post__image,
  .post-page__post-section__category__posts__post__image,
  .blog-home__claim-home__category__posts__post__image,
  .blog-category__claim-home__category__posts__post__image,
  .blog-subcategory__claim-home__category__posts__post__image,
  .post-page__claim-home__category__posts__post__image {
    height: 20vw;
  }
  .blog-home .footer-molecule,
  .blog-category .footer-molecule,
  .blog-subcategory .footer-molecule,
  .post-page .footer-molecule {
    border-top: 1px solid #e6e6e6;
  }
}
@media (min-width: 1200px) {
  .blog-home__post-section__category__posts,
  .blog-category__post-section__category__posts,
  .blog-subcategory__post-section__category__posts,
  .post-page__post-section__category__posts {
    justify-content: flex-start;
  }
  .blog-home__post-section__category__posts__post,
  .blog-category__post-section__category__posts__post,
  .blog-subcategory__post-section__category__posts__post,
  .post-page__post-section__category__posts__post {
    padding-right: 20px;
    width: 286px;
  }
  .blog-home__post-section__category__posts__post:nth-child(1),
  .blog-category__post-section__category__posts__post:nth-child(1),
  .blog-subcategory__post-section__category__posts__post:nth-child(1),
  .post-page__post-section__category__posts__post:nth-child(1) {
    padding-left: 0;
  }
  .blog-home__post-section__category__posts__post:nth-child(4),
  .blog-category__post-section__category__posts__post:nth-child(4),
  .blog-subcategory__post-section__category__posts__post:nth-child(4),
  .post-page__post-section__category__posts__post:nth-child(4) {
    padding-right: 0;
  }
  .blog-home__post-section__category__posts__post__image,
  .blog-category__post-section__category__posts__post__image,
  .blog-subcategory__post-section__category__posts__post__image,
  .post-page__post-section__category__posts__post__image {
    height: 170px;
  }
}
.page-error {
  min-height: 400px;
  height: calc(100vh - 400px);
  display: flex;
  flex-direction: column;
  max-width: 800px;
  padding: 20px;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.page-error__title {
  display: block;
  color: #4a4a4a;
  font-size: 60px;
  padding: 0 0 30px;
  text-align: center;
  font-weight: 500;
}
.page-error__subtitle {
  display: block;
  font-size: 26px;
  color: #ccdff4;
  line-height: 30px;
  padding: 0 0 20px;
  text-align: center;
  font-weight: 500;
}
.page-error__text {
  display: block;
  color: #4a4a4a;
  font-size: 15px;
  letter-spacing: -0.25px;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 24px;
  text-align: center;
  padding: 0 0 50px;
  max-width: 520px;
}
.page-error__button {
  display: block;
  margin: 0;
  line-height: 50px;
  border-radius: 3px;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 500;
  color: #fff;
  background-color: #005fc9;
  text-align: center;
  padding: 0 20px;
  height: 50px;
  cursor: pointer;
}
.page-error__button:hover,
.page-error__button:focus {
  background-color: #267dc0;
}
.page-error__button__icon {
  height: 16px;
  margin-right: 10px;
  vertical-align: middle;
  line-height: 50px;
}
@media (min-width: 768px) {
  .page-error {
    height: calc(100vh - 200px);
    min-height: 400px;
  }
  .page-error__title {
    font-size: 72px;
  }
  .page-error__subtitle {
    font-size: 22px;
  }
}
.establishment #hideWhenSearch {
  display: none;
}
.establishment #hideWhenSearch .carrousel__innocuos-container {
  max-height: 500px;
  height: 500px;
}
.establishment #hideWhenSearch .carrousel__container {
  max-height: 500px;
  height: 500px;
}
.establishment #hideWhenSearch .carrousel__container__element {
  height: 500px;
  max-height: 500px;
}
.establishment #hideWhenSearch .carrousel__arrow-right,
.establishment #hideWhenSearch .carrousel__arrow-left {
  height: 500px;
  max-height: 500px;
}
.establishment #hideWhenSearch .carrousel__arrow-right {
  margin-left: 800px;
}
@media (min-width: 768px) {
  .establishment #hideWhenSearch__box-best-partner {
    margin: -70px auto 0 auto;
  }
}
.home {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #fff;
  color: #4a4a4a;
  width: 100%;
  box-sizing: border-box;
  display: block;
}
.home__body {
  width: 100%;
  padding-top: 50px;
}
.home__body-container {
  width: 100%;
}
.home__body__partners {
  max-width: 100vw;
  overflow: hidden;
}
.home__body__partners__scroll {
  display: none;
  animation: moveSlideshow 750s linear infinite;
  background-image: url("https://static.100r.systems/img/pages/home/home-sprite.jpg");
  background-repeat: repeat-x;
  background-position: center 40px;
  background-size: 100vw;
  height: 150px;
  width: 10000vw;
}
.home__body__partners-container {
  display: none;
}
.home__body__steps-mobile {
  padding: 30px 7.03125%;
}
.home__body__steps {
  padding: 30px 7.03125%;
  border-top: 1px solid #ccdff4;
  border-bottom: 1px solid #ccdff4;
}
.home__body__destinations {
  padding: 0;
}
.home__body__destinations__title__line {
  display: none;
}
.home__body__destinations__title__text {
  width: 100%;
  font-size: 21px;
  line-height: 26px;
  text-align: left;
  padding: 0 7.03125% 38px;
  letter-spacing: -0.25px;
}
.home__body__destinations__list {
  width: 100vw;
  overflow: hidden;
}
.home__body__destinations__list__container {
  padding-left: 7.03125%;
  width: 920px;
}
.home__body__destinations__list__innocuos-container {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
.home__body__destinations__list__innocuos-container::-webkit-scrollbar {
  display: none;
}
.home__body__destinations__list__item {
  display: inline-block;
  height: 300px;
  width: 200px;
  opacity: 1;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 3px;
  overflow: hidden;
  vertical-align: top;
}
.home__body__destinations__list__item:first-child {
  padding-left: 0;
}
.home__body__destinations__list__item__image {
  background-position: 50% 50%;
  background-repeat: none;
  background-size: auto 100%;
  display: block;
  width: 100%;
  height: 100%;
}
.home__body__destinations__list__item__wrapper {
  background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.1) 60%, rgba(0,0,0,0.7) 100%);
  background: -moz-linear-gradient(180deg, rgba(0,0,0,0.1) 60%, rgba(0,0,0,0.7) 100%);
  background: -ms-linear-gradient(180deg, rgba(0,0,0,0.1) 60%, rgba(0,0,0,0.7) 100%);
  background: -o-linear-gradient(180deg, rgba(0,0,0,0.1) 60%, rgba(0,0,0,0.7) 100%);
  background: linear-gradient(180deg, rgba(0,0,0,0.1) 60%, rgba(0,0,0,0.7) 100%);
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 10px;
}
.home__body__destinations__list__item__wrapper__text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
.home__body__destinations__list__item__wrapper__text-wrapper__name {
  font-size: 18px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  line-height: 22px;
  text-align: center;
  margin: 0;
}
.home__body__destinations__list__item__wrapper__text-wrapper__number {
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 19px;
  letter-spacing: -0.25px;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
}
.home__body__destinations-mini {
  width: 100%;
  padding: 30px 7.03125%;
}
.home__body__destinations-mini__header__title {
  font-size: 16px;
  letter-spacing: -0.25px;
  line-height: 26px;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: -0.25px;
}
.home__body__destinations-mini__header__link {
  display: none;
}
.home__body__destinations-mini__list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.home__body__destinations-mini__list__item {
  height: 100px;
  border-top: 1px solid #ccdff4;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}
.home__body__destinations-mini__list__item:last-child {
  border-bottom: 1px solid #ccdff4;
}
.home__body__destinations-mini__list__item-wrapper {
  display: flex;
  padding: 10px 0;
}
.home__body__destinations-mini__list__item__image {
  width: 100px;
  height: 80px;
  flex-shrink: 0;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: all ease 0.3s;
}
.home__body__destinations-mini__list__item__text {
  flex-shrink: 1;
  width: 100%;
  padding-left: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home__body__destinations-mini__list__item__text__name {
  display: block;
  width: 90%;
  font-size: 18px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  line-height: 20px;
  margin: 0;
  text-align: left;
  font-weight: 700;
}
.home__body__destinations-mini__list__item__text__number {
  display: block;
  width: 90%;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 19px;
  color: #9b9b9b;
  margin: 0;
  text-transform: uppercase;
}
.home__body__destinations-mini__footer {
  text-align: right;
  width: 100%;
  height: 50px;
  line-height: 50px;
}
.home__body__destinations-mini__footer__link {
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 23px;
  color: #005fc9;
  text-transform: uppercase;
  vertical-align: middle;
}
.home__body__destinations-mini__footer__link__icon {
  font-size: 10px;
  letter-spacing: 0px;
  padding-left: 5px;
  line-height: 11px;
}
.home__body__compare {
  width: 100%;
  border-top: 1px solid #ccdff4;
  border-bottom: 1px solid #ccdff4;
  padding: 45px 0;
}
.home__body__compare__text {
  padding: 0 7.03125%;
}
.home__body__compare__text__title {
  font-size: 21px;
  letter-spacing: -0.25px;
  line-height: 26px;
  padding-bottom: 30px;
  text-align: left;
  font-weight: 700;
}
.home__body__compare__text__paragraf {
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 24px;
  color: #9b9b9b;
  font-weight: 400;
  margin: 0;
  text-align: left;
  padding-bottom: 20px;
}
.home__body__compare__image {
  width: 80%;
  max-width: 400px;
  text-align: center;
  margin: 20px auto;
}
.home__body__compare__image__img {
  background-image: url("https://static.100r.systems/img/pages/home/home-sprite.jpg");
  width: 80%;
  margin: auto;
  padding: 50%;
  background-position: 50% 100%;
  background-size: 100% auto;
  display: inline-block;
  background-repeat: no-repeat;
}
.home__body__app {
  width: 100%;
  border-top: 1px solid #ccdff4;
  border-bottom: 1px solid #ccdff4;
  padding: 45px 0 0;
  display: flex;
  flex-direction: column;
}
.home__body__app__image {
  text-align: center;
  order: 2;
  display: none;
}
.home__body__app__image__img {
  width: 70%;
  max-width: 300px;
}
.home__body__app__text {
  order: 1;
  padding: 0 7.03125%;
}
.home__body__app__text__title {
  font-size: 21px;
  line-height: 26px;
  padding-bottom: 30px;
  letter-spacing: -0.25px;
  text-align: left;
  font-weight: 700;
}
.home__body__app__text__paragraf {
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 24px;
  color: #9b9b9b;
  font-weight: 400;
  margin: 0;
  text-align: left;
  padding-bottom: 20px;
}
.home__body__app__text__appstores {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0;
}
.home__body__app__text__appstores__apple,
.home__body__app__text__appstores__android {
  padding: 0 20px;
}
.home__body__app__text__appstores__apple__img,
.home__body__app__text__appstores__android__img {
  width: 100%;
  max-width: 100px;
}
@media (min-width: 600px) {
  .home__body__partners__scroll {
    background-size: 70vw;
  }
}
@media (min-width: 768px) {
  .home__header .header-molecule-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .home__header .header-molecule__logo {
    min-width: 200px;
  }
  .home__header .header-molecule__nav__item {
    display: inline-flex;
    align-items: center;
  }
  .home__body {
    width: 100%;
    padding-top: 0px;
  }
  .home__body__search-bar .search-bar-molecule__desktop__container .search-bar-molecule__desktop__box__dates .DateRangePicker .transition-container--horizontal {
    height: 310px !important;
  }
  .home__body__search-bar .search-bar-molecule__desktop__container .search-bar-molecule__desktop__box__dates .DateRangePicker .-below-margin,
  .home__body__search-bar .search-bar-molecule__desktop__container .search-bar-molecule__desktop__box__dates .DateRangePicker .-above-margin {
    height: 360px !important;
  }
  .home__body__search-bar .search-bar-molecule__desktop__container .search-bar-molecule__desktop__box__dates .DateRangePicker .-below-margin:before,
  .home__body__search-bar .search-bar-molecule__desktop__container .search-bar-molecule__desktop__box__dates .DateRangePicker .-above-margin:before {
    bottom: 45px;
  }
  .home__body__search-bar .search-bar-molecule__desktop__container .search-bar-molecule__desktop__box__dates .DateRangePicker .-without-checkboxes {
    bottom: 20px;
  }
  .home__body-container {
    max-width: 1200px;
    margin: auto;
  }
  .home__body__claim-desktop {
    height: 30vh;
    min-height: 200px;
  }
  .home__body__claim-desktop .claim__title,
  .home__body__claim-desktop .claim__subtitle {
    font-weight: 700;
  }
  .home__body__partners {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vh;
    min-height: 60px;
  }
  .home__body__partners__scroll {
    display: none;
  }
  .home__body__partners-container {
    display: flex;
    width: 100%;
  }
  .home__body__partners__line {
    flex-shrink: 1;
    width: 50%;
    height: 27.5px;
    border-bottom: 1px solid #ccdff4;
    vertical-align: middle;
    border-radius: 2px;
    display: block;
  }
  .home__body__partners__image {
    display: inline-block;
    width: 550px;
    flex-shrink: 0;
    margin: 0 10px;
    height: 55px;
    background-image: url("https://static.100r.systems/img/pages/home/home-sprite.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
  }
  .home__body__steps {
    border: none;
    padding-top: 0;
  }
  .home__body__destinations {
    max-width: 1200px;
    margin: auto;
    padding: 50px 40px;
  }
  .home__body__destinations__title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 70px;
  }
  .home__body__destinations__title__line {
    flex-shrink: 1;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #ccdff4;
    vertical-align: middle;
    border-radius: 2px;
    display: block;
  }
  .home__body__destinations__title__text {
    flex-shrink: 0;
    max-width: 480px;
    text-align: center;
    padding: 0 30px;
    width: auto;
    line-height: 34px;
    letter-spacing: -0.25px;
    font-size: 28px;
    height: inherit;
  }
  .home__body__destinations__list {
    width: 100%;
    overflow: visible;
    padding-bottom: 40px;
  }
  .home__body__destinations__list__container {
    padding: 0;
    margin: auto;
    width: 100%;
    display: flex;
    overflow: visible;
  }
  .home__body__destinations__list__innocuos-container {
    overflow: visible;
  }
  .home__body__destinations__list__item {
    width: 100vw;
    height: 400px;
    margin-right: 20px;
    border-radius: 3px;
    transition: all ease 0.3s;
    overflow: hidden;
  }
  .home__body__destinations__list__item:last-child {
    margin-right: 0;
  }
  .home__body__destinations__list__item:hover {
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.3);
    transform: translateY(-10px);
  }
  .home__body__destinations__list__item-image {
    transition: all ease 0.3s;
  }
  .home__body__destinations__list__item-image:hover {
    transform: scale(1.05);
  }
  .home__body__destinations__list__item__wrapper {
    border-radius: 3px;
  }
  .home__body__destinations__list__item__wrapper__text-wrapper {
    height: 80vw;
  }
  .home__body__destinations__list__item__wrapper__text-wrapper__name {
    font-size: 24px;
    width: auto;
    line-height: 30px;
  }
  .home__body__destinations__list__item__wrapper__text-wrapper__number {
    font-size: 14px;
    letter-spacing: -0.25px;
  }
  .home__body__destinations-mini {
    max-width: 1200px;
    margin: auto;
    padding: 30px 40px;
  }
  .home__body__destinations-mini__header {
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
  }
  .home__body__destinations-mini__header__title {
    display: block;
  }
  .home__body__destinations-mini__header__link {
    display: block;
    font-size: 14px;
    letter-spacing: -0.25px;
    line-height: 23px;
    color: #005fc9;
    text-transform: uppercase;
    vertical-align: middle;
  }
  .home__body__destinations-mini__header__link__icon {
    font-size: 10px;
    letter-spacing: 0px;
    padding-left: 5px;
    line-height: 11px;
  }
  .home__body__destinations-mini__list {
    padding: 0 0;
  }
  .home__body__destinations-mini__list-container {
    width: 100%;
    flex-direction: row;
  }
  .home__body__destinations-mini__list__item {
    margin-right: 20px;
    border-bottom: 1px solid #ccdff4;
    display: inline-block;
    width: 25%;
    flex-shrink: 1;
    transition: all ease 0.3s;
  }
  .home__body__destinations-mini__list__item:last-child {
    margin-right: 0;
  }
  .home__body__destinations-mini__list__item:hover {
    opacity: 0.8;
  }
  .home__body__destinations-mini__list__item__text__number {
    max-width: 85%;
    text-align: left;
  }
  .home__body__destinations-mini__footer {
    display: none;
  }
  .home__body__compare {
    border: none;
    margin: auto;
    text-align: center;
    padding: 7.03125% 7.03125%;
    max-width: 1200px;
    width: 80%;
  }
  .home__body__compare__text {
    width: 50%;
    display: inline-block;
    padding: 0;
    vertical-align: middle;
  }
  .home__body__compare__text__paragraf {
    width: 100%;
    max-width: 470px;
  }
  .home__body__compare__text__paragraf:first-child {
    padding-bottom: 20px;
  }
  .home__body__compare__image {
    display: inline-block;
    width: 50%;
    padding-top: 0;
    vertical-align: middle;
    padding-left: 7.03125%;
  }
  .home__body__compare__image__img {
    max-width: 365px;
    width: 100%;
  }
  .home__body__app {
    flex-direction: row;
    justify-content: center;
    border: none;
    padding: 50px 7.03125% 0px;
  }
  .home__body__app__image {
    order: 1;
    align-self: flex-end;
    padding: 0 7.03125% 0 0;
    display: block;
  }
  .home__body__app__image__img {
    display: block;
    background-image: url("https://static.100r.systems/img/pages/home/home-sprite.jpg");
    height: 450px;
    padding: 50%;
    width: 300px;
    background-size: 300px;
    background-position: 50% 24%;
    background-size: 550px;
  }
  .home__body__app__text {
    order: 2;
    max-width: 450px;
    flex-shrink: 1;
    padding: 0;
    align-self: flex-end;
  }
  .home__body__app__text__appstores {
    justify-content: flex-start;
  }
  .home__body__app__text__appstores__apple {
    padding-left: 0;
  }
  .home .home-version-new {
    padding-top: 90px;
    margin-top: -230px;
    padding-top: 280px;
    min-height: 505px;
  }
  .home .home-version-new .claim {
    align-items: flex-start;
    padding: 0 30px;
    margin: 0 auto;
    max-width: 1040px;
    width: calc(100% - 32px);
    height: auto;
    min-height: auto;
  }
  .home .home-version-new .claim__title {
    margin: 0;
    text-align: left;
    font-size: 36px;
    color: #4a4a4a;
    font-weight: 300;
    -webkit-text-fill-color: inherit;
    max-width: 100%;
    letter-spacing: -0.25px;
  }
  .home .home-version-new .claim__subtitle {
    color: #2e3085;
    font-size: 32px;
    margin: 0;
  }
  .home .home-version-new .search-bar-molecule {
    padding-top: 50px;
  }
  .home .home-version-new .search-bar-molecule__desktop__box {
    border: 1px solid #669fdf;
  }
  .home .home-version-new .search-bar-molecule__desktop__box__destination,
  .home .home-version-new .search-bar-molecule__desktop__box__dates {
    border-right: 1px solid #669fdf;
    height: 81px;
  }
  .home .home-version-new .search-bar-molecule__desktop__box__destination__title,
  .home .home-version-new .search-bar-molecule__desktop__box__dates__title {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.25px;
  }
  .home .home-version-new .search-bar-molecule__desktop__box__guests__title {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.25px;
  }
  .home .home-version-new .home__body__partners,
  .home .home-version-new .home__body__steps-desktop {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1120px) {
  .home__body__destinations__list__item {
    height: 34vw;
  }
  .home__body__destinations__list__item__name {
    font-size: 18px;
    letter-spacing: -0.25px;
    line-height: 22px;
    transition: all ease 0.3s;
  }
  .home__body__destinations__list__item__number {
    font-size: 12px;
    letter-spacing: -0.29px;
    line-height: 19px;
    transition: all ease 0.3s;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .home__body__destinations-mini__list {
    padding: 0 0;
  }
  .home__body__destinations-mini__list-container {
    width: 100%;
    display: inline-block;
  }
  .home__body__destinations-mini__list__item {
    margin-right: 5%;
    display: inline-block;
    width: 47.5%;
  }
  .home__body__destinations-mini__list__item:nth-child(4),
  .home__body__destinations-mini__list__item:nth-child(2) {
    margin-right: 0;
  }
  .home__body__destinations-mini__list__item:nth-child(3),
  .home__body__destinations-mini__list__item:nth-child(4) {
    border-top: 0;
  }
  .home__body__destinations-mini__list__item:hover .home__body__destinations-mini__list__item__image {
    transition: all ease 0.3s;
    opacity: 0.8;
  }
  .home__body__destinations-mini__footer {
    display: none;
  }
}
@-moz-keyframes moveSlideshow {
  100% {
    transform: translate3D(-10000vw, 0, 0);
  }
}
@-webkit-keyframes moveSlideshow {
  100% {
    transform: translate3D(-10000vw, 0, 0);
  }
}
@-o-keyframes moveSlideshow {
  100% {
    transform: translate3D(-10000vw, 0, 0);
  }
}
@keyframes moveSlideshow {
  100% {
    transform: translate3D(-10000vw, 0, 0);
  }
}
.page-landing .landing-page {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  width: 100vw;
  background: rgba(236,239,241,0.4);
  overflow: hidden;
  padding-bottom: 50px;
}
.page-landing .landing-page__suggestion-box {
  position: fixed;
  z-index: 9;
  bottom: 0;
}
.page-landing .landing-page a:not([href]) {
  cursor: default;
}
.page-landing .landing-page .search-bar-molecule__desktop__container {
  display: none;
}
.page-landing .landing-page .search-form .search-bar-molecule__desktop__container {
  display: block;
}
.page-landing .landing-page__header {
  background-color: #fff;
}
.page-landing .landing-page__header__wrapper {
  padding: 12px 0px 12px 16px;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: 1200px;
  box-shadow: none;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.page-landing .landing-page__header__link {
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.page-landing .landing-page__header__link__logo {
  height: 24px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}
.page-landing .landing-page__header__link__text {
  display: none;
}
.page-landing .landing-page__header__nav {
  height: 50px;
  background-color: #fff;
  box-shadow: none;
}
.page-landing .landing-page__header__nav__links {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: flex-end;
  justify-content: flex-end;
}
.page-landing .landing-page__header__nav__links__link {
  border-left: 1px solid rgba(0,0,0,0.1);
  border-right: 1px solid rgba(0,0,0,0.1);
  flex: 0 0 auto;
  width: 158px;
  transition: all ease 0.3s;
  cursor: pointer;
}
.page-landing .landing-page__header__nav__links__link__text {
  line-height: 50px;
  color: #4a4a4a;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: -0.29px;
  padding: 0 20px 0 20px;
  margin: 0;
  text-align: center;
}
.page-landing .landing-page__header__nav__links__link:hover {
  background-color: rgba(0,0,0,0.1);
}
.page-landing .landing-page__header__links {
  position: absolute;
  width: 100%;
  height: 0;
  text-align: left;
  background: #f6f7f8;
  padding: 0 7.03125%;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-top: 1px solid rgba(0,0,0,0.1);
  overflow: hidden;
  z-index: 9995;
  top: 50px;
  left: 0;
}
.page-landing .landing-page__header__links__container {
  max-width: 1200px;
  margin: auto;
}
.page-landing .landing-page__header__links__list {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  padding-top: 20px;
}
.page-landing .landing-page__header__links__list__item {
  color: #4a4a4a;
  width: 100%;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 1.5;
  display: inline-block;
}
.page-landing .landing-page__header__links__list__item:first-child {
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 500;
  padding-bottom: 5px;
}
.page-landing .landing-page__header__links__list__item__link {
  font-weight: 300;
  color: #4a4a4a;
  font-size: 14px;
  letter-spacing: -0.25px;
  padding: 0;
}
.page-landing .landing-page__header__links__list__item__link:hover {
  text-decoration: underline;
  color: #005fc9;
}
.page-landing .landing-page__header__links.-is-showed {
  display: block;
  position: absolute;
  height: auto;
  padding: 3.515625% 7.03125%;
}
.page-landing .landing-page__breadcrumb {
  background-color: #1e1f24;
  min-height: 40px;
  padding: 0 7.03125% 0 7.03125%;
}
.page-landing .landing-page__breadcrumb__list {
  padding: 0;
  text-align: left;
  line-height: 40px;
  width: 100%;
}
.page-landing .landing-page__breadcrumb__list__item {
  color: rgba(255,255,255,0.9);
  display: inline-block;
  font-size: 14px;
  letter-spacing: -0.25px;
  text-transform: capitalize;
}
.page-landing .landing-page__breadcrumb__list__item__link {
  cursor: pointer;
}
.page-landing .landing-page__breadcrumb__list__item__link:hover {
  text-decoration: underline;
}
.page-landing .landing-page__breadcrumb__list__item__link__actual {
  font-weight: 400;
  text-decoration: none;
}
.page-landing .landing-page__breadcrumb__list__item:last-child .landing-page__breadcrumb__item__link {
  cursor: default;
  text-decoration: underline;
}
.page-landing .landing-page__breadcrumb__list__item__arrow {
  margin: 0 5px;
  color: rgba(255,255,255,0.7);
  font-weight: 100;
  font-size: 9px;
}
.page-landing .landing-page__breadcrumb__menu {
  display: none;
  color: rgba(255,255,255,0.9);
  font-size: 12px;
  letter-spacing: -0.29px;
  font-weight: 300;
}
.page-landing .landing-page__breadcrumb__menu:hover {
  text-decoration: underline;
}
.page-landing .landing-page__cover-section {
  width: 100%;
  min-height: 240px;
  height: 40%;
  box-sizing: border-box;
  background-position: center;
  background-size: cover;
}
.page-landing .landing-page__cover-section__overlay {
  width: 100%;
  min-height: 240px;
  height: 40%;
  background: rgba(0,0,0,0.2);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-landing .landing-page__cover-section__claim {
  width: 100%;
  padding: 20px 10px;
  box-sizing: border-box;
  flex: 0 1 auto;
  width: 100%;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  max-width: 80%;
}
.page-landing .landing-page__cover-section__claim__bold {
  font-weight: 300;
}
.page-landing .landing-page__search {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}
.page-landing .landing-page__search.haveCheckboxes {
  height: 155px;
}
.page-landing .landing-page__search .search-bar-molecule {
  width: 100%;
  min-height: inherit;
  height: inherit;
  box-sizing: border-box;
}
.page-landing .landing-page__search.-is-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  background: #fafafa;
  z-index: 9995;
  text-align: center;
  outline: 1px solid #efecec;
}
.page-landing .landing-page__search.-is-fixed .searchbox-desktop {
  margin-top: 12px;
  max-width: inherit;
  text-align: left;
  position: relative;
}
.page-landing .landing-page__search .searchbox-desktop {
  flex: 0 1 auto;
  margin: auto;
  left: inherit;
  top: inherit;
  position: inherit;
  height: 51px;
  max-width: 90%;
}
.page-landing .landing-page__search .searchbox-desktop__form-desktop {
  margin: auto;
}
.page-landing .landing-page__search .search-bar-mobile {
  width: 90%;
}
.page-landing .landing-page__search .search-bar-mobile.-is-fixed {
  left: 50%;
  transform: translateX(-50%);
  align-self: center;
  position: fixed;
  top: -8px;
  margin: 18px auto auto auto;
}
.page-landing .landing-page__sub-menu {
  height: 50px;
  text-align: left;
  background-color: #fff;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  border-bottom: 1px solid rgba(179,179,179,0.5);
}
.page-landing .landing-page__sub-menu::-webkit-scrollbar {
  display: none;
}
.page-landing .landing-page__sub-menu__item {
  line-height: 46px;
  font-size: 14px;
  letter-spacing: -0.25px;
  text-transform: uppercase;
  display: inline-block;
  padding: 0 10px 0 10px;
  text-align: center;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  cursor: pointer;
  color: rgba(105,105,105,0.5);
}
.page-landing .landing-page__sub-menu__item__link {
  margin: 0;
  cursor: pointer;
}
.page-landing .landing-page__sub-menu__item.-is-selected {
  border-bottom-color: #005fc9;
  color: #005fc9;
  cursor: default;
}
.page-landing .landing-page__text-section {
  width: 100%;
  padding: 5.2734375% 7.03125% 3.515625% 7.03125%;
  box-sizing: border-box;
}
.page-landing .landing-page__text-section.-is-white {
  background-color: #fff;
}
.page-landing .landing-page__text-section.-not-description {
  padding: 5.2734375% 7.03125% 0 7.03125%;
}
.page-landing .landing-page__text-section__container {
  width: 100%;
  max-width: 800px;
  margin: auto;
  font-size: 16px;
  letter-spacing: -0.25px;
  line-height: 1.75;
  font-weight: 300;
  color: #4a4a4a;
}
.page-landing .landing-page__text-section__container ul {
  list-style: initial;
}
.page-landing .landing-page__text-section__container ol {
  list-style: decimal;
}
.page-landing .landing-page__text-section__title-section {
  margin-bottom: 20px;
}
.page-landing .landing-page__text-section__title-section__title {
  font-size: 22px;
  line-height: 1.5;
  font-weight: 500;
  color: #4a4a4a;
  text-align: left;
  padding-right: 10px;
}
.page-landing .landing-page__text-section__text {
  font-size: 16px;
  letter-spacing: -0.25px;
  line-height: 1.75;
  font-weight: 300;
  color: #4a4a4a;
  text-align: left;
  margin: 0;
}
.page-landing .landing-page__text-section li,
.page-landing .landing-page__text-section ul {
  font-size: 16px;
  letter-spacing: -0.25px;
  line-height: 1.75;
  font-weight: 300;
  color: #4a4a4a;
  text-align: left;
  margin: 0;
}
.page-landing .landing-page__text-section ul {
  list-style: initial;
}
.page-landing .landing-page__text-section ul p {
  font-size: 16px;
  letter-spacing: -0.25px;
  line-height: 1.75;
  font-weight: 300;
  color: #4a4a4a;
  text-align: left;
  margin: 0;
}
.page-landing .landing-page__text-section strong {
  font-weight: 500;
  color: #4a4a4a;
}
.page-landing .landing-page__text-section a {
  color: #005fc9;
  text-decoration: underline;
}
.page-landing .landing-page__text-section__mini-link {
  margin: 30px auto 0 auto;
}
.page-landing .landing-page__carrousel {
  overflow-x: visible;
  overflow-y: hidden;
  text-align: left;
  height: 215px;
  background-color: #fff;
  -webkit-overflow-scrolling: touch;
}
.page-landing .landing-page__carrousel::-webkit-scrollbar {
  display: none;
}
.page-landing .landing-page__carrousel__container {
  padding: 0px;
  margin: auto;
}
.page-landing .landing-page__carrousel__item {
  height: 210px;
  display: inline-block;
  cursor: pointer;
}
.page-landing .landing-page__carrousel__item__link {
  display: block;
  height: 100%;
  width: 280px;
  margin-right: 20px;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}
.page-landing .landing-page__mini-cards {
  text-align: center;
  padding: 30px 0px 30px 0px;
  overflow: scroll;
}
.page-landing .landing-page__mini-cards__card {
  display: inline-block;
  vertical-align: top;
}
.page-landing .landing-page__mini-cards__card:first-child {
  margin-right: 10px;
}
.page-landing .landing-page__service-cards {
  text-align: center;
  padding: 7.03125% 0;
}
.page-landing .landing-page__service-cards__title {
  font-size: 20px;
  font-weight: 500;
  color: #4a4a4a;
  text-align: left;
  max-width: 800px;
  padding: 0 7.03125% 7.03125% 7.03125%;
  margin: auto;
}
.page-landing .landing-page__service-cards__content {
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.page-landing .landing-page__service-cards__content::-webkit-scrollbar {
  display: none;
}
.page-landing .landing-page__service-cards__content__container {
  max-width: 800px;
  padding: 0px 7.03125%;
  margin: auto;
  text-align: left;
}
.page-landing .landing-page__service-cards__content__item {
  display: inline-block;
  margin: 0 5px 0 0;
}
.page-landing .landing-page__apartments {
  overflow-x: visible;
  overflow-y: hidden;
  text-align: left;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 3.515625%;
}
.page-landing .landing-page__apartments::-webkit-scrollbar {
  display: none;
}
.page-landing .landing-page__apartments__container {
  padding: 0px 7.03125%;
  margin: auto;
  text-align: left;
}
.page-landing .landing-page__apartments__item {
  display: inline-block;
  vertical-align: baseline;
  margin: 0;
  padding: 10px 10px 10px 0;
  width: 280px;
  height: 380px;
  box-sizing: border-box;
}
.page-landing .landing-page__apartments__item__card {
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 10px 10px 10px 0;
  display: block;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card {
  background-color: #fff;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,0.07);
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__header {
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  align-content: center;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__header__logo {
  flex: 0 1 auto;
  box-sizing: border-box;
  margin: 0;
  min-width: 130px;
  padding-left: 10px;
  text-align: left;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__header__logo__image {
  height: 30px;
  vertical-align: middle;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__header__rating {
  flex: 0 1 auto;
  min-width: 80px;
  box-sizing: border-box;
  margin: 0;
  text-align: center;
  padding-right: 10px;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__header__rating__number,
.page-landing .landing-page__apartments__item__card .simple-apartment-card__header__rating__text {
  color: #97c93c;
  text-align: center;
  margin: 0;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__header__rating__number {
  font-size: 18px;
  letter-spacing: -0.25px;
  line-height: 18px;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__header__rating__text {
  font-size: 11px;
  letter-spacing: 0px;
  line-height: 14px;
  text-transform: uppercase;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__carrousel {
  height: 161px;
  background-position: center;
  background-size: cover;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__information {
  justify-content: space-between;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__information__title-box {
  width: 68%;
  height: 86px;
  display: inline-block;
  margin: 0;
  padding: 15px 10px 0 10px;
  box-sizing: border-box;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__information__title-box__title {
  color: #4a4a4a;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 1.5;
  margin: 0;
  letter-spacing: -0.2px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__information__title-box__place {
  color: rgba(0,0,0,0.4);
  font-size: 11px;
  letter-spacing: 0px;
  line-height: 1.5;
  margin: 0;
  letter-spacing: -0.2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__information__price-box {
  width: 32%;
  height: 60px;
  display: inline-block;
  vertical-align: top;
  margin: 0;
  background-color: #005fc9;
  padding: 12px 0;
  box-sizing: border-box;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__information__price-box__title {
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 14px;
  color: rgba(255,255,255,0.7);
  text-align: center;
  margin: 0;
  text-transform: uppercase;
  font-weight: 300;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__information__price-box__price {
  font-size: 22px;
  line-height: 22px;
  color: #fff;
  text-align: center;
  margin: 0;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__information__button {
  width: 32%;
  height: 48px;
  display: inline-block;
  vertical-align: top;
  margin: 0;
  background-color: #005fc9;
  box-sizing: border-box;
  color: #fff;
  line-height: 48px;
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__information__amenities {
  width: 100%;
  text-align: left;
  padding: 5px 10px;
  box-sizing: border-box;
  border-top: 1px solid rgba(0,0,0,0.05);
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__information__amenities__guests,
.page-landing .landing-page__apartments__item__card .simple-apartment-card__information__amenities__rooms {
  color: rgba(0,0,0,0.4);
  margin-right: 21px;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 14px;
  font-weight: 500;
}
.page-landing .landing-page__apartments__item__card .simple-apartment-card__information__amenities__guests:before,
.page-landing .landing-page__apartments__item__card .simple-apartment-card__information__amenities__rooms:before {
  padding-right: 8px;
  vertical-align: sub;
  font-size: 15px;
  letter-spacing: -0.25px;
}
.page-landing .landing-page__apartments__content {
  overflow-x: visible;
  overflow-y: hidden;
  padding-top: 20px;
  -webkit-overflow-scrolling: touch;
}
.page-landing .landing-page__apartments__content::-webkit-scrollbar {
  display: none;
}
.page-landing .landing-page__apartments__container {
  margin: auto;
  padding: 0;
  text-align: left;
}
.page-landing .landing-page__places-container {
  background-color: #fff;
  overflow-x: visible;
  overflow-y: hidden;
  text-align: left;
  -webkit-overflow-scrolling: touch;
  padding: 7.03125% 0 7.03125% 7.03125%;
}
.page-landing .landing-page__places-container::-webkit-scrollbar {
  display: none;
}
.page-landing .landing-page__places-container__container {
  margin: auto;
  padding: 0;
}
.page-landing .landing-page__places-container__item {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  margin-right: 10px;
  width: 150px;
}
.page-landing .landing-page__links-section {
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 15px;
}
.page-landing .landing-page__links-section__list-pages {
  height: 300px;
}
.page-landing .landing-page__links-section__list-pages__page {
  text-align: center;
  display: none;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  height: 300px;
  overflow-y: hidden;
  padding: 20px 20px;
  background-color: #fff;
}
.page-landing .landing-page__links-section__list-pages__page.-is-selected {
  display: block;
}
.page-landing .landing-page__links-section__list-pages__page__content {
  text-align: left;
  height: 190px;
  flex-direction: column;
}
.page-landing .landing-page__links-section__list-pages__page__content__item {
  display: inline-block;
  width: 320px;
  box-sizing: border-box;
  padding: 0 5px 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-landing .landing-page__links-section__list-pages__page__content__item__link {
  color: #4a4a4a;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 1.7;
  font-weight: 300;
}
.page-landing .landing-page__links-section__list-pages__page__content__item__link:hover {
  color: #005fc9;
  text-decoration: underline;
}
.page-landing .landing-page__establishments {
  font-size: 90%;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
.page-landing .landing-page__establishments__list {
  list-style: none;
}
.page-landing .landing-page__establishments .establishment-card {
  max-width: 48%;
  min-width: 46%;
  width: auto;
}
.page-landing .landing-page__establishments__list {
  width: 414px;
  margin: 1% 0;
}
.page-landing .landing-page__establishments__item {
  background-color: transparent;
  width: auto;
}
.page-landing .landing-page__establishments .snippet-similar__body {
  height: 35px;
}
@media (max-width: 340px) {
  .page-landing .landing-page__mini-cards__card {
    width: 45%;
  }
}
@media (max-width: 552px) {
  .page-landing .landing-page__list {
    padding-bottom: 5%;
  }
}
@media (min-width: 765px) {
  .page-landing .landing-page__establishments__list {
    margin: 2vh 0vw;
    width: 280px;
  }
}
@media (min-width: 768px) {
  .page-landing .landing-page__suggestion-box {
    bottom: 20px;
    right: 20px;
  }
  .page-landing .landing-page .search-bar-molecule__desktop__container {
    display: block;
  }
  .page-landing .landing-page .search-bar-molecule__mobile {
    display: none;
  }
  .page-landing .landing-page__header__link {
    width: 73%;
  }
  .page-landing .landing-page__header__link__logo {
    height: 20.25px;
  }
  .page-landing .landing-page__header__link__text {
    display: inline-block;
    font-size: 18px;
    letter-spacing: -0.25px;
    height: 18px;
    letter-spacing: -1px;
    line-height: 26px;
    margin-left: 8px;
    vertical-align: middle;
  }
  .page-landing .landing-page__search {
    height: 122px;
  }
  .page-landing .landing-page__establishments {
    display: flex;
    justify-content: center;
    max-width: 95%;
    font-size: 100%;
    margin: 1% 5%;
  }
  .page-landing .landing-page__establishments__item {
    max-width: inherit;
    min-width: inherit;
    width: inherit;
    height: inherit;
    background-color: transparent;
  }
  .page-landing .landing-page__establishments__item .snippet-similar__title,
  .page-landing .landing-page__establishments__item img {
    width: 260px;
    height: 200px;
  }
}
@media (min-width: 1024px) {
  .page-landing .landing-page__establishments .establishment-card {
    max-width: 23%;
    min-width: 22%;
    width: auto;
  }
  .page-landing .landing-page__header__links__list {
    width: 33%;
    padding-top: 0;
  }
  .page-landing .landing-page__header__links__item {
    font-size: 14px;
    letter-spacing: -0.25px;
  }
  .page-landing .landing-page__header__links:first-child {
    font-size: 16px;
    letter-spacing: -0.25px;
  }
  .page-landing .landing-page__header__nav__links__link {
    width: 180px;
  }
  .page-landing .landing-page__header__nav__links__link__text {
    font-size: 14px;
    letter-spacing: -0.25px;
  }
  .page-landing .landing-page__cover-section {
    margin-bottom: 25px;
    height: 50vh;
  }
  .page-landing .landing-page__cover-section__overlay {
    height: 50vh;
  }
  .page-landing .landing-page__cover-section__claim {
    font-size: 40px;
    line-height: 50px;
    font-size: 74px;
    line-height: 1.3;
    font-weight: 700;
    max-width: 75%;
  }
  .page-landing .landing-page__cover-section__claim.-is-black {
    color: #4a4a4a;
    background-color: #fff;
    margin: auto;
    font-size: 50px;
    line-height: 50px;
    line-height: 1.3;
    font-weight: 700;
    max-width: 100%;
    padding-top: 40px;
  }
  .page-landing .landing-page__breadcrumb {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
  }
  .page-landing .landing-page__breadcrumb__list {
    flex: 0 1 auto;
    width: auto;
    margin-left: 0;
  }
  .page-landing .landing-page__breadcrumb__menu {
    display: block;
    flex: 0 1 auto;
  }
  .page-landing .landing-page__sub-menu {
    padding: 0 7.03125%;
    height: 64px;
  }
  .page-landing .landing-page__sub-menu__inocuos-container {
    margin: auto;
    box-sizing: border-box;
  }
  .page-landing .landing-page__sub-menu__item {
    line-height: 59px;
  }
  .page-landing .landing-page__text-section__title-section {
    justify-content: center;
    margin-bottom: 30px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .page-landing .landing-page__text-section__title-section__title {
    text-align: center;
    font-size: 32px;
    font-weight: 300;
  }
  .page-landing .landing-page__text-section__title-section__title:after {
    content: '';
    display: block;
    width: 90px;
    border-bottom: 2px solid #005fc9;
    margin: 30px auto 0 auto;
  }
  .page-landing .landing-page__text-section__container {
    font-size: 18px;
    letter-spacing: -0.25px;
    line-height: 2;
  }
  .page-landing .landing-page__text-section__container ul,
  .page-landing .landing-page__text-section__container li {
    font-size: 18px;
    letter-spacing: -0.25px;
    line-height: 2;
  }
  .page-landing .landing-page__text-section__text {
    font-size: 18px;
    letter-spacing: -0.25px;
    line-height: 2;
  }
  .page-landing .landing-page__carrousel {
    height: auto;
    text-align: center;
  }
  .page-landing .landing-page__carrousel__container {
    height: inherit;
    padding: 0;
  }
  .page-landing .landing-page__carrousel__inocuos-container {
    width: 80% !important;
    margin: auto;
    box-sizing: border-box;
  }
  .page-landing .landing-page__carrousel__item {
    width: 50%;
    height: 300px;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    padding: 10px;
  }
  .page-landing .landing-page__carrousel__item__link {
    display: block;
    width: 100%;
  }
  .page-landing .landing-page__mini-cards {
    background: #fff;
    padding: 0;
  }
  .page-landing .landing-page__mini-cards__card:first-child {
    margin-right: 20px;
  }
  .page-landing .landing-page__apartments__container {
    padding-left: 20px;
  }
  .page-landing .landing-page__places-container__container {
    padding-left: 20px;
  }
  .page-landing .landing-page__service-cards {
    text-align: center;
    padding: 3.515625% 0;
  }
  .page-landing .landing-page__service-cards__title {
    padding: 3.515625% 0 3.515625% 0;
    text-align: center;
    font-size: 32px;
    font-weight: 300;
    line-height: 1.5;
  }
  .page-landing .landing-page__service-cards__title:after {
    content: '';
    display: block;
    width: 90px;
    border-bottom: 2px solid #005fc9;
    margin: 30px auto 0 auto;
  }
  .page-landing .landing-page__service-cards__content__inocuos-container {
    width: 100% !important;
    box-sizing: border-box;
  }
  .page-landing .landing-page__service-cards__content__container {
    max-width: 95% !important;
    padding: 0px;
    margin: auto;
    text-align: center;
  }
  .page-landing .landing-page__service-cards__content__item {
    display: inline-block;
    width: 20%;
    margin: 20px 0 0 0;
    padding: 0 10px 0 10px;
  }
  .page-landing .landing-page__service-cards__content__item__card {
    width: 100%;
  }
  .page-landing .landing-page__apartments {
    overflow: inherit;
    text-align: center;
  }
  .page-landing .landing-page__apartments__container {
    padding: 0;
    margin: auto;
    text-align: left;
  }
  .page-landing .landing-page__apartments__inocuos-container {
    width: 95% !important;
    margin: auto;
    text-align: center;
    box-sizing: border-box;
  }
  .page-landing .landing-page__apartments__item {
    width: 25%;
    max-width: 300px;
    height: 380px;
    display: inline-block;
  }
  .page-landing .landing-page__apartments__item__card {
    border-radius: 3px;
    cursor: pointer;
  }
  .page-landing .landing-page__places-container {
    overflow: inherit;
  }
  .page-landing .landing-page__places-container__container {
    margin: auto;
    padding: 0 7.03125%;
  }
  .page-landing .landing-page__places-container__inocuos-container {
    width: 100% !important;
    margin: auto;
    text-align: center;
    box-sizing: border-box;
  }
  .page-landing .landing-page__places-container__item {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin: 0;
    width: 25%;
    padding: 20px;
  }
  .page-landing .landing-page__places-container__item__element {
    border-radius: 3px;
    height: 350px;
    width: 100%;
    padding: 0 10px 0 10px;
  }
}
@media (max-width: 600px) {
  .page-landing .landing-page__establishments .establishment-card {
    max-width: 98%;
    min-width: 96%;
    width: auto;
  }
}
@media (min-width: 1400px) {
  .page-landing .landing-page__establishments__list {
    margin: 2vh;
  }
  .page-landing .landing-page__cover-section__claim {
    max-width: 1400px;
  }
  .page-landing .landing-page__breadcrumb__list {
    width: 1200px;
    margin: auto;
  }
  .page-landing .landing-page__carrousel__item {
    height: 400px;
  }
  .page-landing .landing-page__carrousel__inocuos-container {
    width: 1300px !important;
  }
  .page-landing .landing-page__service-cards__content__inocuos-container,
  .page-landing .landing-page__service-cards__content__container {
    width: 1400px;
  }
  .page-landing .landing-page__apartments__inocuos-container {
    width: 1400px !important;
  }
  .page-landing .landing-page__places-container {
    padding-left: 0;
    padding-right: 0;
  }
  .page-landing .landing-page__container {
    padding: 0;
  }
  .page-landing .landing-page__inocuos-container {
    width: 1300px !important;
  }
}
.header-molecule__logo__image__img-text {
  margin-left: 10px;
}
.pagination.landing-seo {
  margin: auto;
  text-align: center;
}
.establishment .establishment-page {
  overflow-x: hidden;
  margin: 0 auto;
  max-width: 1920px;
}
.establishment .establishment-page__sticky-mobile {
  animation-fill-mode: both;
  animation: 0.75s linear 0.75s 1 slideInFromBottom;
  background-color: #fff;
  bottom: 0;
  box-shadow: 0px 0px 12px 5px rgba(0,0,0,0.12);
  height: 67px;
  left: 0;
  position: fixed;
  transform: translateZ(1px);
  width: 100%;
  z-index: 7;
}
.establishment .establishment-page__sticky-mobile__container {
  display: flex;
  justify-content: space-around;
  transform: translateZ(1px);
  width: 100%;
}
.establishment .establishment-page__sticky-mobile__container__left,
.establishment .establishment-page__sticky-mobile__container__right {
  box-sizing: border-box;
  display: flex;
  float: left;
  padding: 10px;
  text-align: center;
  justify-content: center;
  width: 50%;
}
.establishment .establishment-page__sticky-mobile__container__left {
  flex-flow: column;
  margin-top: -5px;
}
.establishment .establishment-page__sticky-mobile__container__left__instant,
.establishment .establishment-page__sticky-mobile__container__left__icon,
.establishment .establishment-page__sticky-mobile__container__left__price,
.establishment .establishment-page__sticky-mobile__container__left__night,
.establishment .establishment-page__sticky-mobile__container__left__partner-name {
  display: inline-block;
}
.establishment .establishment-page__sticky-mobile__container__left__price {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  margin-right: 3px;
}
.establishment .establishment-page__sticky-mobile__container__left__night {
  clear: both;
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  line-height: 21px;
}
.establishment .establishment-page__sticky-mobile__container__left__icon {
  color: #fdd835;
  margin-right: 5px;
  font-size: 15px;
  letter-spacing: -0.25px;
}
.establishment .establishment-page__sticky-mobile__container__left__partner-name {
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
}
.establishment .establishment-page__sticky-mobile__container__right__button {
  width: 100%;
  max-height: 45px;
  background-color: #005fc9;
  color: #fff;
  font-size: 15px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  font-weight: 500;
  line-height: 22px;
  font-family: inherit;
  padding-bottom: 4px;
}
.establishment .establishment-page__sticky-mobile__container__right__button:hover,
.establishment .establishment-page__sticky-mobile__container__right__button:focus {
  background-color: #005fc9;
  color: #fff;
}
.establishment .establishment-page__sticky-desktop {
  display: none;
}
.establishment .establishment-page__header {
  display: flex;
  flex-direction: column;
}
.establishment .establishment-page__header__favourite {
  position: absolute;
  z-index: 2;
  right: 12px;
  top: 65px;
}
.establishment .establishment-page__header__favourite .hundredicons-hr-icon-favorito-filled {
  animation: heartIn 0.6s ease-in-out;
}
.establishment .establishment-page__header__carrousel {
  display: block;
  margin-top: 50px;
  position: relative;
}
.establishment .establishment-page__header__carrousel .carrousel {
  height: 280px;
}
.establishment .establishment-page__header__carrousel .carrousel__innocuos-container {
  max-height: 280px;
  height: 100%;
  min-height: 280px;
}
.establishment .establishment-page__header__carrousel .carrousel__container {
  height: 100%;
}
.establishment .establishment-page__header__carrousel .carrousel__container__element {
  max-height: 280px;
  height: 100%;
  min-height: 280px;
}
.establishment .establishment-page__header__carrousel .carrousel__image {
  margin-top: -50px;
  background-size: cover;
  background-position: 50% 50%;
  height: 100%;
  width: 100%;
}
.establishment .establishment-page__header__carrousel .carrousel__number {
  color: #fff;
  background-color: rgba(8,8,8,0.66);
  top: 222px;
  right: 20px;
  padding: 11px;
  display: block;
  position: absolute;
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
}
.establishment .establishment-page__header__carrousel .carrousel__arrow-left,
.establishment .establishment-page__header__carrousel .carrousel__arrow-right {
  color: #fff;
  position: absolute;
  font-size: 25px;
  height: 280px;
  width: 20%;
  max-height: 280px;
  min-height: 280px;
  display: flex;
}
.establishment .establishment-page__header__carrousel .carrousel__arrow-left__arrow-left,
.establishment .establishment-page__header__carrousel .carrousel__arrow-right__arrow-left {
  left: 0;
}
.establishment .establishment-page__header__carrousel .carrousel__arrow-left__arrow-right,
.establishment .establishment-page__header__carrousel .carrousel__arrow-right__arrow-right {
  right: 0;
}
.establishment .establishment-page__header__grid {
  display: none;
}
.establishment .establishment-page__sharing-favourites .sharing-favourites {
  display: block;
}
.establishment .establishment-page__sharing-favourites .sharing-favourites__container__sharing {
  border-bottom: 0px solid #b0bec5;
}
.establishment .establishment-page__sharing-favourites .sharing-favourites__container__favourites {
  display: none;
}
.establishment .establishment-page__content {
  display: flex;
  padding-top: 30px;
  margin: 0 auto;
  padding-bottom: 120px;
}
.establishment .establishment-page__content__left-side {
  display: none;
  width: 20%;
  min-width: 170px;
  height: 100%;
  box-sizing: border-box;
}
.establishment .establishment-page__content__left-side__menu {
  color: #808080;
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  font-weight: 500;
  line-height: 21px;
  margin: 0;
  cursor: pointer;
  max-width: 150px;
}
.establishment .establishment-page__content__left-side__menu__item {
  padding-bottom: 15px;
}
.establishment .establishment-page__content__left-side__menu__item:empty {
  padding-bottom: 0;
}
.establishment .establishment-page__content__left-side__menu__item:hover {
  color: #005fc9;
}
.establishment .establishment-page__content__left-side .selected {
  color: #005fc9;
}
.establishment .establishment-page__content__right-side {
  width: 90%;
  margin: 0 auto;
}
.establishment .establishment-page__content__right-side__establishment-description {
  color: #4a4a4a;
  text-align: left;
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
}
.establishment .establishment-page__content__right-side__establishment-description__title {
  font-weight: 600;
  padding-bottom: 30px;
  text-align: left;
  box-sizing: border-box;
}
.establishment .establishment-page__content__right-side__establishment-description__info {
  line-height: 1.75;
  margin: 0;
  font-weight: 400;
  padding-bottom: 10px;
}
.establishment .establishment-page__content__right-side__establishment-description__read-more {
  display: block;
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
  cursor: pointer;
  text-decoration: underline;
}
.establishment .establishment-page__content__right-side__map .establishment-page__map {
  width: 100%;
  height: auto;
  display: inline-block;
}
.establishment .establishment-page__content__right-side__map .establishment-page__map__title {
  padding-bottom: 40px;
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
  cursor: default;
  font-weight: 600;
  text-align: left;
  max-width: 400px;
}
.establishment .establishment-page__content__right-side__map .establishment-page__map__map {
  height: 60vh;
  width: 100%;
}
.establishment .establishment-page__content__right-side__map .establishment-page__map__map .gm-style-pbt {
  text-align: center;
}
.establishment .establishment-page__content__right-side__services__component .services__title {
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
  cursor: default;
  font-weight: 600;
  padding-bottom: 0;
  text-align: left;
  box-sizing: border-box;
}
.establishment .establishment-page__content__right-side__services__component .services__list__last-item {
  margin-bottom: 0;
}
.establishment .establishment-page__content__right-side__services__component .services__list__item {
  margin-bottom: 0;
}
.establishment .establishment-page__content__right-side__services__component .services__list__item__container {
  margin: 15px 0;
}
.establishment .establishment-page__content__right-side__separator {
  border-bottom: 1px solid #e8e8e8;
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding-top: 30px;
  margin-bottom: 30px;
}
.establishment .establishment-page__content__right-side__cta {
  margin-bottom: 40px;
}
.establishment .establishment-page__content__right-side__comments {
  border: none;
  margin-bottom: 0;
  padding: 0;
  overflow: initial;
}
.establishment .establishment-page__content__right-side__comments__title {
  margin-bottom: 30px;
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  font-weight: 600;
  text-align: left;
  box-sizing: border-box;
  color: #4a4a4a;
}
.establishment .establishment-page__content__right-side__comments .comments-box {
  display: flex;
  flex-direction: column;
  border: none;
  padding: 20px 0;
  overflow: initial;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 3px;
}
.establishment .establishment-page__content__right-side__comments .comments-box__partners,
.establishment .establishment-page__content__right-side__comments .comments-box__partner {
  width: 210px;
  float: left;
  clear: both;
}
.establishment .establishment-page__content__right-side__comments .comments-box__partners__line-separator,
.establishment .establishment-page__content__right-side__comments .comments-box__partner__line-separator {
  display: none;
}
.establishment .establishment-page__content__right-side__comments .comments-box__num-comments {
  margin-right: 0;
  color: #4a4a4a;
  margin: 0px 0 20px 89px;
  font-weight: 600;
}
.establishment .establishment-page__content__right-side__comments .comments-box__num-comments.-no-positive {
  margin-left: 0;
  margin-bottom: 20px;
}
.establishment .establishment-page__content__right-side__comments .comments-box__explain {
  width: auto;
  margin: 7px 0 26px 89px;
  color: #4a4a4a;
  display: inline-block;
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  line-height: 1.7;
  text-align: left;
}
.establishment .establishment-page__content__right-side__comments .comments-box__partners {
  margin-left: 100px;
  display: inline-block;
  text-align: left;
  width: 90%;
}
.establishment .establishment-page__content__right-side__comments .comments-box__partners.-no-positive {
  margin: 0;
}
.establishment .establishment-page__content__right-side__comments .comments-box__partners .clickable {
  cursor: pointer;
}
.establishment .establishment-page__content__right-side__comments .comments-box__partners__partner {
  width: 210px;
  float: left;
  clear: both;
  display: inline-flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 15px;
  height: 35px;
  vertical-align: middle;
}
.establishment .establishment-page__content__right-side__comments .comments-box__partners__partner__link {
  width: 99%;
  justify-content: space-between;
  align-items: center;
}
.establishment .establishment-page__content__right-side__comments .comments-box__partners__partner__arrow {
  display: inline-block;
  width: 10%;
  height: 26px;
  color: #005fc9;
  margin-left: 7px;
  margin-right: 10px;
  vertical-align: bottom;
}
.establishment .establishment-page__content__right-side__comments .comments-box__partners__partner__arrow:before {
  display: flex;
  justify-content: center;
  font-size: 18px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
}
.establishment .establishment-page__content__right-side__comments .comments-box__partners__partner__info {
  display: inline-flex;
  flex-direction: column;
  width: 75%;
}
.establishment .establishment-page__content__right-side__comments .comments-box__partners__partner__info__name {
  margin-top: -3px;
  color: #4a4a4a;
}
.establishment .establishment-page__content__right-side__comments .comments-box__partners__partner__info__comment {
  color: #b3b3b3;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.establishment .establishment-page__content__right-side__comments .comments-box__partners__partner__line-separator {
  border-right: 1px solid #e8e8e8;
  display: none;
  width: 1px;
  height: 85%;
  vertical-align: middle;
  margin-right: 15px;
}
.establishment .establishment-page__content__right-side__cancellation__title {
  margin-bottom: 30px;
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  font-weight: 600;
  text-align: left;
  box-sizing: border-box;
  color: #4a4a4a;
}
.establishment .establishment-page__content__right-side__grid-matching__title {
  color: #4a4a4a;
  font-size: 16px;
  letter-spacing: -0.25px;
  font-weight: 600;
  margin-bottom: 40px;
}
.establishment .establishment-page__content__right-side__similars__title {
  font-size: 16px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}
.establishment .establishment-page__content__right-side__similars__snippet {
  display: flex;
  min-width: 274px;
  justify-content: space-between;
  flex-flow: row wrap;
}
.establishment .establishment-page__content__right-side__similars__snippet__item {
  width: 48%;
  max-width: 190px;
  padding: 8px 0;
  height: 160px;
  margin-bottom: 15px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.establishment .establishment-page__content__right-side__similars__snippet__item:nth-child(odd) {
  margin-right: 1px;
}
.establishment .establishment-page__content__right-side__similars__snippet__item:nth-child(even) {
  margin-left: 1px;
}
.establishment .establishment-page__content__right-side__similars__snippet__item .snippet-similar__recomendation {
  width: 18px;
  height: 18px;
}
.establishment .establishment-page__content__right-side__similars__snippet__item .snippet-similar__recomendation__container {
  line-height: 19px;
}
.establishment .establishment-page__content__right-side__similars__snippet__item .snippet-similar__recomendation__background {
  font-size: 12px;
  letter-spacing: -0.29px;
  letter-spacing: -0.25px;
}
.establishment .establishment-page__content__right-side__similars__snippet__item .snippet-similar__body {
  display: none;
}
.establishment .establishment-page .breadcrumb {
  min-height: 40px;
  padding: 0;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.29px;
  letter-spacing: -0.25px;
  text-transform: capitalize;
  text-align: left;
  width: 100%;
  margin: -20px 0 12px;
}
.establishment .establishment-page .breadcrumb__item {
  display: inline-block;
  height: 26px;
  margin-left: 0;
}
.establishment .establishment-page .breadcrumb__item:last-child .breadcrumb__item__link {
  cursor: default;
}
.establishment .establishment-page .breadcrumb__item:last-child .breadcrumb__item__link:hover {
  color: inherit;
}
.establishment .establishment-page .breadcrumb__item__link {
  cursor: pointer;
}
.establishment .establishment-page .breadcrumb__item__link:hover {
  color: #005fc9;
}
.establishment .establishment-page .breadcrumb__item__arrow {
  margin: 0 5px;
  font-weight: 300;
}
.establishment .footer-molecule {
  display: none;
  height: 100px;
}
.establishment .footer-molecule__second-footer {
  border-top: none;
  display: flex;
}
.establishment .tooltip-top,
.establishment .tooltip-right,
.establishment .tooltip-left,
.establishment .tooltip-bottom {
  animation: tooltip-appear 0.4s linear;
}
@media (min-width: 768px) {
  .establishment .establishment-page__sticky-desktop {
    display: none;
    position: fixed;
    z-index: 7;
    top: 0;
    left: 0;
    height: 64px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 12px 5px rgba(0,0,0,0.12);
    animation: 0.33s linear 0.1s 1 slideInFromTop;
    animation-fill-mode: both;
    transform: translateZ(1px);
  }
  .establishment .establishment-page__sticky-desktop.visible {
    display: block;
  }
  .establishment .establishment-page__sticky-desktop__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .establishment .establishment-page__sticky-desktop__container__left,
  .establishment .establishment-page__sticky-desktop__container__right {
    height: 100%;
    float: left;
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
  }
  .establishment .establishment-page__sticky-desktop__container__left {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: auto;
    height: 67px;
    margin-top: -1px;
    padding-left: 50px;
  }
  .establishment .establishment-page__sticky-desktop__container__left__partner-name {
    display: inline-block;
    text-align: left;
  }
  .establishment .establishment-page__sticky-desktop__container__left__partner-name__text {
    font-size: 16px;
    letter-spacing: -0.25px;
    letter-spacing: -0.25px;
    line-height: 23px;
    font-weight: bold;
    color: #4a4a4a;
  }
  .establishment .establishment-page__sticky-desktop__container__left__partner-name__text:hover {
    cursor: pointer;
    color: #005fc9;
  }
  .establishment .establishment-page__sticky-desktop__container__right {
    width: 380px;
  }
  .establishment .establishment-page__sticky-desktop__container__right__icon {
    color: #fdd835;
    margin-right: 5px;
    font-size: 15px;
    letter-spacing: -0.25px;
    letter-spacing: -0.25px;
  }
  .establishment .establishment-page__sticky-desktop__container__right__price {
    font-size: 18px;
    letter-spacing: -0.25px;
    letter-spacing: -0.25px;
    font-weight: 600;
    line-height: 32px;
    margin-right: 25px;
  }
  .establishment .establishment-page__sticky-desktop__container__right__button {
    width: 260px;
    max-width: 260px;
    max-height: 45px;
    background-color: #005fc9;
    color: #fff;
    font-size: 15px;
    letter-spacing: -0.25px;
    letter-spacing: -0.25px;
    font-weight: 500;
    line-height: 22px;
    font-family: inherit;
    padding-bottom: 4px;
    display: inline-block;
  }
  .establishment .establishment-page__sticky-desktop__container__right__button:hover,
  .establishment .establishment-page__sticky-desktop__container__right__button:focus {
    background-color: #005fc9;
    color: #fff;
  }
  .establishment .establishment-page__sticky-desktop__container__right__icons {
    width: 90px;
    display: inline-block;
    transform: translateY(5px);
  }
  .establishment .establishment-page__sticky-desktop__container__right__icons__favourite,
  .establishment .establishment-page__sticky-desktop__container__right__icons__sharing {
    width: 45px;
    height: 60px;
    display: inline-block;
    font-size: 18px;
    letter-spacing: -0.25px;
    letter-spacing: -0.25px;
    text-align: center;
  }
  .establishment .establishment-page__sticky-desktop__container__right__icons__favourite__icon,
  .establishment .establishment-page__sticky-desktop__container__right__icons__sharing__icon {
    width: 60px;
    display: inline-block;
    color: #4a4a4a;
  }
  .establishment .establishment-page__sticky-desktop__container__right__icons__favourite__icon:hover,
  .establishment .establishment-page__sticky-desktop__container__right__icons__sharing__icon:hover {
    cursor: pointer;
  }
  .establishment .establishment-page__sticky-desktop__container__right__icons__favourite .hundredicons-share:hover,
  .establishment .establishment-page__sticky-desktop__container__right__icons__sharing .hundredicons-share:hover {
    color: #005fc9;
  }
  .establishment .establishment-page__sticky-desktop__container__right__icons__favourite .hundredicons-hr-icon-favorito:hover,
  .establishment .establishment-page__sticky-desktop__container__right__icons__sharing .hundredicons-hr-icon-favorito:hover {
    color: #ed4b82;
  }
  .establishment .establishment-page__sticky-desktop__container__right__icons__favourite .hundredicons-hr-icon-favorito-filled,
  .establishment .establishment-page__sticky-desktop__container__right__icons__sharing .hundredicons-hr-icon-favorito-filled {
    animation: heartIn 0.6s ease-in-out;
    animation-fill-mode: both;
    color: #ed4b82;
  }
  .establishment .establishment-page__sticky-mobile {
    display: none;
  }
  .establishment .establishment-page__header {
    flex-direction: row;
  }
  .establishment .establishment-page__header__grid {
    display: block;
  }
  .establishment .establishment-page__header__carrousel {
    display: none;
  }
  .establishment .establishment-page__header__favourite {
    display: none;
  }
  .establishment .establishment-page__content {
    max-width: 85%;
    padding-top: 60px;
    padding-bottom: 70px;
  }
  .establishment .establishment-page__content__left-side {
    display: block;
  }
  .establishment .establishment-page__content__left-side .fixed {
    animation-fill-mode: forwards;
    animation-name: scrollMenu;
    animation-duration: 0.5s;
    transform: translateZ(1px);
    position: fixed;
  }
  .establishment .establishment-page__content__right-side {
    width: 75%;
  }
  .establishment .establishment-page__content__right-side__separator {
    padding-top: 60px;
    margin-bottom: 60px;
  }
  .establishment .establishment-page__content__right-side__comments {
    padding-top: 0;
  }
  .establishment .establishment-page__content__right-side__comments .comments-box {
    overflow: hidden;
    border: 1px solid #e8e8e8;
    padding: 30px 50px;
    border-radius: 3px;
    box-sizing: border-box;
    font-size: 16px;
    letter-spacing: -0.25px;
    letter-spacing: -0.25px;
  }
  .establishment .establishment-page__content__right-side__comments .comments-box__explain {
    margin: 25px 0 25px 111px;
    font-size: 16px;
    letter-spacing: -0.25px;
    letter-spacing: -0.25px;
  }
  .establishment .establishment-page__content__right-side__comments .comments-box__explain.-no-positive {
    display: none;
  }
  .establishment .establishment-page__content__right-side__comments .comments-box__partners {
    margin-left: 111px;
    display: flex;
  }
  .establishment .establishment-page__content__right-side__comments .comments-box__partners__partner {
    width: 33%;
  }
  .establishment .establishment-page__content__right-side__comments .comments-box__line-separator {
    display: block;
  }
  .establishment .establishment-page__content__right-side__comments .comments-box__line-separator__num-comments {
    margin: 0px 111px 20px;
  }
  .establishment .establishment-page__content__right-side__comments .comments-box__line-separator__num-comments.-no-positive {
    margin-left: 0;
    margin-top: 20px;
  }
  .establishment .establishment-page__content__right-side__comments .comments-box.-no-positive {
    border: none;
    margin: 0;
    padding: 0;
  }
  .establishment .establishment-page__content__right-side__similars {
    margin-bottom: 80px;
  }
  .establishment .establishment-page__content__right-side__similars__title {
    margin-bottom: 40px;
  }
  .establishment .establishment-page__content__right-side__similars__snippet__item {
    margin-bottom: 15px;
    height: 205px;
    width: 240px;
    max-width: none;
    padding: 0;
  }
  .establishment .establishment-page__content__right-side__similars__snippet__item:hover {
    box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.75);
  }
  .establishment .establishment-page__content__right-side__similars__snippet__item .snippet-similar__image-container {
    height: 100%;
  }
  .establishment .establishment-page__content__right-side__similars__snippet__item .snippet-similar__image-container .snippet-similar__type {
    padding: 0 7px;
  }
  .establishment .establishment-page__content__right-side__similars__snippet__item .snippet-similar__image {
    height: 140px;
  }
  .establishment .establishment-page__content__right-side__similars__snippet__item .snippet-similar__footer {
    height: calc(100% - 140px);
  }
  .establishment .establishment-page__content__right-side__similars__snippet__item .snippet-similar__footer__city-partner__city,
  .establishment .establishment-page__content__right-side__similars__snippet__item .snippet-similar__footer__city-partner__partner {
    font-size: 14px;
    letter-spacing: -0.25px;
    letter-spacing: -0.25px;
  }
  .establishment .footer-molecule {
    display: block;
  }
}
@media (min-width: 1600px) {
  .establishment .establishment-page__content__right-side__similars__snippet {
    max-width: 80%;
  }
}
@-moz-keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@-o-keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@-moz-keyframes scrollMenu {
  0% {
    top: 60px;
  }
  100% {
    top: 90px;
  }
}
@-webkit-keyframes scrollMenu {
  0% {
    top: 60px;
  }
  100% {
    top: 90px;
  }
}
@-o-keyframes scrollMenu {
  0% {
    top: 60px;
  }
  100% {
    top: 90px;
  }
}
@keyframes scrollMenu {
  0% {
    top: 60px;
  }
  100% {
    top: 90px;
  }
}
@-moz-keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@-o-keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.popunders {
  position: fixed;
  width: 100%;
  min-width: 769px;
  height: 100%;
  background-color: #eee;
  top: 0;
  left: 0;
  z-index: 10000;
}
.popunders__header {
  height: 80px;
  padding-top: 10px;
}
.popunders__header__logo {
  position: absolute;
  right: 20px;
  top: 15px;
  width: 200px;
  height: 34px;
  display: inline-block;
  background-image: url("https://static.100r.systems/img/general/logo-hr.png");
  background-size: cover;
}
.popunders__header__resum {
  margin: 10px 0 0 10px;
  display: inline-block;
}
.popunders__header__resum__info {
  display: inline-block;
}
.popunders__header__resum__info__destination {
  color: #4a4a4a;
  text-align: left;
  font-size: 2em;
  font-weight: 300;
  max-width: 365px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.popunders__header__resum__info__dates {
  padding: 5px;
  color: #4a4a4a;
  font-size: 1.3em;
  font-weight: 300;
}
.popunders__header__resum__options {
  color: #4a4a4a;
  display: inline-block;
  margin-left: 20px;
  vertical-align: top;
}
.popunders__header__resum__options__option {
  display: inline-block;
  margin-left: 5px;
}
.popunders__header__resum__options__option__icon {
  color: #4a4a4a;
  display: inline-block;
  vertical-align: bottom;
  font-size: 2.3em;
  margin-left: 10px;
}
.popunders__header__resum__options__option__icon.hundredicons-moon {
  font-size: 1.9em;
  width: 18px;
}
.popunders__header__resum__options__option__text {
  color: #4a4a4a;
  display: inline-block;
  vertical-align: baseline;
  margin: 0;
  font-size: 2em;
  font-weight: 300;
  padding-left: 2px;
}
.popunders__tabs {
  padding-top: 20px;
}
.popunders__tabs__headers {
  width: 100%;
}
.popunders__tabs__headers .columns {
  display: inline-block;
  padding: 0 10px 0 10px;
  transition: all ease 0.3s;
}
.popunders__tabs__headers .columns.-number-1 {
  width: 100%;
}
.popunders__tabs__headers .columns.-number-2 {
  width: 50%;
}
.popunders__tabs__headers .columns.-number-3 {
  width: 33.333%;
}
.popunders__tabs__headers .columns.-number-4 {
  width: 25%;
}
.popunders__tabs__headers__tab {
  background-color: rgba(255,255,255,0.9);
  padding: 7px;
  max-width: 70%;
  border-radius: 10px 20px 0 0;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all ease 0.3s;
  cursor: pointer;
}
.popunders__tabs__headers__tab__title {
  color: #61625e;
  margin: 0;
  width: 80%;
  font-weight: 300;
  font-size: 1.3em;
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}
.popunders__tabs__headers__tab__icon {
  right: 0px;
  font-size: 25px;
  float: right;
  padding-top: 4px;
}
.popunders__tabs__body {
  width: 100%;
}
.popunders__tabs__body .columns {
  display: inline-block;
  padding: 0 10px 0 10px;
  transition: all ease 0.3s;
}
.popunders__tabs__body .columns.-number-1 {
  width: 100%;
}
.popunders__tabs__body .columns.-number-2 {
  width: 50%;
}
.popunders__tabs__body .columns.-number-3 {
  width: 33.333%;
}
.popunders__tabs__body .columns.-number-4 {
  width: 25%;
}
.popunders__tabs__body__content {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 0 5px 5px 5px;
  width: 100%;
  height: 70vh;
}
.popunders__tabs.-some-tab-selected .popunders__tabs__headers .columns {
  transition: all ease 1s;
  width: auto;
  padding-right: 0px;
}
.popunders__tabs.-some-tab-selected .popunders__tabs__headers .columns .popunders__tabs__headers__tab {
  max-width: none;
}
.popunders__tabs.-some-tab-selected .popunders__tabs__headers .columns.-selected .popunders__tabs__headers__tab {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  padding: 7px 15px 7px 15px;
  cursor: default;
}
.popunders__tabs.-some-tab-selected .popunders__tabs__headers .columns.-selected .popunders__tabs__headers__tab__title {
  color: #4a4a4a;
  font-weight: 500;
  max-width: none;
  overflow-x: visible;
  width: 95%;
  display: inline-block;
  vertical-align: middle;
}
.popunders__tabs.-some-tab-selected .popunders__tabs__headers .columns.-selected .popunders__tabs__headers__tab__icon {
  display: none;
}
.popunders__tabs.-some-tab-selected .popunders__tabs__headers .columns.-unselected .popunders__tabs__headers__tab {
  box-shadow: none;
}
.popunders__tabs.-some-tab-selected .popunders__tabs__headers .columns.-unselected .popunders__tabs__headers__tab__title {
  max-width: none;
  overflow-x: visible;
  width: 100%;
}
.popunders__tabs.-some-tab-selected .popunders__tabs__headers .columns.-unselected .popunders__tabs__headers__tab__icon {
  display: none;
}
.popunders__tabs.-some-tab-selected .popunders__tabs__body .-selected {
  width: 100%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  overflow: hidden;
}
.popunders__tabs.-some-tab-selected .popunders__tabs__body .-selected .popunders__tabs__body__content {
  border-radius: 0 5px 5px 5px;
}
.popunders__tabs.-some-tab-selected .popunders__tabs__body .-unselected {
  display: none;
}
.post-page .post-page {
  width: 100vw;
  background: rgba(236,239,241,0.4);
  overflow: hidden;
  padding-bottom: 50px;
}
.post-page .post-page__ad {
  opacity: 0;
  background-color: transparent;
  position: fixed;
  right: 0;
  height: 600px;
  width: 300px;
  margin: 0 2vw;
}
.post-page .post-page a:not([href]) {
  cursor: default;
}
.post-page .post-page__breadcrumb {
  max-width: 930px;
  padding: 0;
  margin: 0 auto;
  width: 100%;
}
.post-page .post-page__header {
  background-color: #fff;
  margin-top: 0px;
  top: 0;
}
.post-page .post-page__header__wrapper {
  padding: 0px 40px;
  height: 50px;
  border-bottom: 1px solid #eef2f3;
}
.post-page .post-page__header__links {
  position: absolute;
  width: 100%;
  height: 0;
  text-align: left;
  background: #f6f7f8;
  padding: 0 7.03125%;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-top: 1px solid rgba(0,0,0,0.1);
  overflow: hidden;
  z-index: 9995;
  top: 50px;
  left: 0;
}
.post-page .post-page__header__links__container {
  max-width: 1200px;
  margin: auto;
}
.post-page .post-page__header__links__list {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  padding-top: 20px;
}
.post-page .post-page__header__links__list__item {
  color: #4a4a4a;
  width: 100%;
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 1.5;
  display: inline-block;
}
.post-page .post-page__header__links__list__item:first-child {
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 500;
  padding-bottom: 5px;
}
.post-page .post-page__header__links__list__item__link {
  font-weight: 300;
  color: #4a4a4a;
  font-size: 14px;
  letter-spacing: -0.25px;
  padding: 0;
}
.post-page .post-page__header__links__list__item__link:hover {
  text-decoration: underline;
  color: #005fc9;
}
.post-page .post-page__header__links.-is-showed {
  display: block;
  position: absolute;
  height: auto;
  padding: 3.515625% 7.03125%;
}
.post-page .post-page__intro {
  max-width: 920px;
  margin: 80px auto 40px;
  width: 70%;
}
.post-page .post-page__intro__title {
  color: #232323;
  font-size: 36px;
  letter-spacing: -2px;
  margin: auto;
  font-weight: bold;
}
.post-page .post-page__intro__updated,
.post-page .post-page__intro__subtitle {
  color: #232323;
  line-height: 24px;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 300;
}
.post-page .post-page__intro__subtitle {
  margin-top: 2em;
}
.post-page .post-page__image {
  min-height: 300px;
  width: auto;
  background-color: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.post-page .post-page__searchbar {
  background-color: #f7f9f9;
  margin-bottom: 5vh;
}
.post-page .post-page__content {
  padding: 0 7.03125%;
  max-width: 980px;
  margin: 0 auto;
  line-height: 24px;
}
.post-page .post-page__content .twitter-tweet,
.post-page .post-page__content .instagram-media {
  margin: 0 auto !important;
}
.post-page .post-page__content iframe[src*="facebook"] {
  min-height: 605px;
  width: 500px;
  display: flex;
  margin: 0 auto;
}
.post-page .post-page__content__img-container {
  margin: 35px 0;
  max-width: 1040px;
  max-height: 700px;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
  max-width: 930px;
}
.post-page .post-page__content__endline {
  max-width: 920px;
  margin: 0 auto;
  margin: 5vh auto;
  display: block;
  opacity: 0.5;
}
.post-page .post-page__content__endline:before {
  background-color: #e5e5e5;
  content: '';
  display: block;
  height: 2px;
  margin: auto;
  width: 100%;
}
.post-page .post-page__content .google-maps-iframe,
.post-page .post-page__content .youtube-iframe {
  max-width: 700px;
  background-color: inherit;
  margin: 30px auto;
  max-width: 1040px;
  height: 700px;
}
.post-page .post-page__content .google-maps-iframe iframe,
.post-page .post-page__content .youtube-iframe iframe {
  margin: 0;
  max-width: 930px;
  display: block;
  height: 100%;
  width: 100%;
}
.post-page .post-page__content h1,
.post-page .post-page__content h2,
.post-page .post-page__content h3,
.post-page .post-page__content p {
  background-color: inherit;
}
.post-page .post-page__content h1,
.post-page .post-page__content h2,
.post-page .post-page__content h3,
.post-page .post-page__content h4 {
  max-width: 920px;
  margin: 0;
  margin-top: 5vh;
  width: 70%;
  color: #232323;
  font-weight: bold;
  margin-bottom: 0px;
}
.post-page .post-page__content h1 {
  font-size: 32px;
}
.post-page .post-page__content h2 {
  font-size: 28px;
}
.post-page .post-page__content h3 {
  font-size: 22px;
}
.post-page .post-page__content p {
  max-width: 930px;
  margin: 0;
  width: 100%;
  color: #232323;
  font-size: 16px;
  letter-spacing: -0.25px;
  font-weight: 300;
}
.post-page .post-page__content p:empty {
  display: none;
}
.post-page .post-page__content img {
  display: block;
  margin: auto;
  width: 100%;
  height: auto;
  transition: 0.2s ease-in-out;
}
.post-page .post-page__content img:hover {
  transform: scale(1.03, 1.03);
}
.post-page .post-page__content ol,
.post-page .post-page__content ul {
  max-width: 930px;
  margin: 0;
  width: 90%;
  color: #232323;
  margin-left: 10px;
}
.post-page .post-page__content ol {
  list-style-type: decimal;
}
.post-page .post-page__content ul {
  list-style-type: disc;
}
.post-page .post-page__content a {
  color: #005fc9;
}
.post-page .post-page__content a:hover {
  text-decoration: underline;
}
.post-page .post-page__content strong {
  font-weight: 500;
}
.post-page .post-page__content.-post-centered {
  padding-right: 0;
}
.post-page .post-page__author {
  max-width: 920px;
  margin: 0 auto;
  margin-bottom: 5vh;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.post-page .post-page__author__image {
  border-radius: 50%;
  display: inline-block;
  width: 125px;
  height: auto;
  margin-right: 2em;
  width: 100%;
  max-width: 125px;
}
.post-page .post-page__author__info {
  font-size: 14px;
  letter-spacing: -0.25px;
  display: flex;
  flex-direction: column;
  line-height: 24px;
  width: 90%;
}
.post-page .post-page__author__info__name {
  font-weight: 600;
}
.post-page .post-page__author__info__description {
  font-weight: 200;
  background-color: inherit;
}
@media (max-width: 767px) {
  .post-page .post-page__breadcrumb {
    width: 90%;
    line-height: 20px;
  }
  .post-page .post-page__intro {
    width: 90%;
  }
  .post-page .post-page__content__img-container {
    margin: 20px auto;
  }
  .post-page .post-page__content .google-maps-iframe,
  .post-page .post-page__content .youtube-iframe {
    max-width: 350px;
    height: 350px;
  }
  .post-page .post-page__content p {
    width: 90%;
  }
  .post-page .post-page__content h1,
  .post-page .post-page__content h2,
  .post-page .post-page__content h3,
  .post-page .post-page__content h4 {
    width: 90%;
  }
  .post-page .post-page__content ol,
  .post-page .post-page__content ul {
    width: 80%;
  }
  .post-page .post-page__content img {
    margin-top: 30px;
  }
  .post-page .post-page__content img:hover {
    transform: none;
  }
  .post-page .post-page__content iframe[src*="facebook"] {
    display: none;
  }
  .post-page .post-page__author {
    width: 90%;
  }
}
@media (min-width: 1024px) {
  .post-page .post-page__author__info__description {
    width: 50%;
  }
  .post-page .post-page__content {
    padding-right: 270px;
  }
  .post-page .post-page__ad-content {
    position: relative;
  }
  .post-page .post-page__ad {
    margin: 0 1.5vw;
  }
  .post-page .post-page__content-container {
    width: 94%;
  }
}
@media (max-width: 1023px) {
  .post-page .post-page__ad {
    display: none;
  }
}
@media (min-width: 1024px) and (min-width: 1476px) {
  .post-page .post-page__content {
    padding: 0 10.5%;
  }
}
@media (min-width: 1024px) and (min-width: 1600px) {
  .post-page .post-page__content {
    padding: 0 7.03125%;
  }
  .post-page .post-page__content-container {
    width: 100%;
  }
  .post-page .post-page__ad {
    margin: 0 3vw;
  }
}
.privacy {
  width: 95%;
  max-width: 800px;
  margin: 75px auto;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.privacy__container {
  border-bottom: 1px solid rgba(0,0,0,0.1);
  padding: 20px 0 10px 0;
  line-height: 1.7;
}
.privacy__container__title {
  color: #4a4a4a;
  font-size: 1.5em;
  font-weight: 800;
  margin: 15px 0 15px 0;
}
.privacy__container__subtitle {
  color: #4a4a4a;
  font-size: 1.2em;
  font-weight: 600;
  margin: 10px 0 10px 0;
}
.privacy__container__subtitle__2 {
  font-size: 1em;
  font-weight: 400;
  margin-top: 10px;
}
.privacy__container__subtitle__3 {
  font-weight: bolder;
}
.privacy__container__download {
  color: #4a4a4a;
  text-decoration: underline;
  line-height: 50px;
  font-size: 15px;
  letter-spacing: -0.25px;
  font-weight: bold;
}
.privacy__container p {
  color: #4a4a4a;
  margin: 0;
  text-align: left;
}
@media (max-width: 1200px) {
  .privacy {
    margin: 115px auto;
  }
}
@media (max-width: 601px) {
  .privacy {
    margin: 300px auto;
  }
}
.compatibility-modal {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  text-align: center;
  padding: 30px 10px 0 10px;
  transition: 1s ease all;
}
.compatibility-modal__title {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: #4a4a4a;
}
.compatibility-modal__text {
  text-align: center;
  font-size: 16px;
  letter-spacing: -0.25px;
  font-weight: 300;
  color: #4a4a4a;
  padding-top: 10px;
}
.compatibility-modal__browsers {
  width: 100%;
  text-align: center;
  padding-top: 30px;
}
.compatibility-modal__browsers__item {
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
  padding: 10px 5px 10px 5px;
  width: 40%;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 5px;
}
.compatibility-modal__browsers__item__image {
  width: 70%;
}
.compatibility-modal__browsers__item__text {
  text-transform: capitalize;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0;
}
.hundredmodal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  z-index: 9996;
}
.hundredmodal.menu_filters-container-modal {
  text-align: left;
}
.hundredmodal.menu_filters-container-modal.-animation-enter .hundredmodal__content {
  animation-name: slideInUp;
}
.hundredmodal.menu_filters-container-modal.-animation-leave .hundredmodal__content {
  animation-name: slideOutUp;
}
.hundredmodal.menu_user-container-modal {
  text-align: right;
}
.hundredmodal.menu_user-container-modal .hundredmodal__content {
  width: auto;
}
.hundredmodal.menu_user-container-modal.-animation-enter .hundredmodal__content {
  animation-name: slideInRight;
}
.hundredmodal.menu_user-container-modal.-animation-leave .hundredmodal__content {
  animation-name: slideOutRight;
}
.hundredmodal.menu_user-container-modal.-animation-leave .hundredmodal__veil {
  display: none;
}
.hundredmodal.establishment-container-modal .hundredmodal__veil {
  display: none;
}
.hundredmodal.establishment-container-modal.-animation-enter .hundredmodal__content {
  animation-name: slideInUp;
}
.hundredmodal.establishment-container-modal.-animation-enter .hundredmodal__veil {
  display: none;
}
.hundredmodal.establishment-container-modal.-animation-leave .hundredmodal__content {
  animation-name: slideOutUp;
}
.hundredmodal.establishment-container-modal.-animation-leave .hundredmodal__veil {
  display: none;
}
.hundredmodal.login-container-modal {
  transform: translateZ(1px);
}
.hundredmodal.share-container-modal .hundredmodal__content__close,
.hundredmodal.share-container-modal .hundredmodal__veil {
  display: none;
}
.hundredmodal.establishment-share-container-modal .hundredmodal__content__close,
.hundredmodal.establishment-share-container-modal .hundredmodal__veil {
  display: none;
}
.hundredmodal.alert-popup-focus-lost-container-modal {
  transform: translateZ(1px);
  text-align: center;
}
.hundredmodal.alert-popup-focus-lost-container-modal .hundredmodal__content__close,
.hundredmodal.alert-popup-focus-lost-container-modal .hundredmodal__veil {
  display: flex;
}
.hundredmodal.leads-form-container-modal {
  text-align: center;
}
.hundredmodal.search-mobile-container-modal {
  transform: translateZ(1px);
}
.hundredmodal.search-mobile-container-modal.-animation-enter .hundredmodal__content {
  animation-name: slideInUp;
}
.hundredmodal.search-mobile-container-modal.-animation-leave .hundredmodal__content {
  animation-name: slideOutUp;
}
.hundredmodal.notification-container-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  top: initial;
  right: initial;
  height: auto;
  padding: 0;
  width: 100%;
  overflow: initial;
}
.hundredmodal.notification-container-modal.-animation-enter .hundredmodal__content {
  animation-name: slideInUp;
}
.hundredmodal.notification-container-modal.-animation-leave .hundredmodal__content {
  animation-name: slideOutUp;
}
.hundredmodal__content {
  position: relative;
  margin: auto;
  width: auto;
  animation-duration: 0.25s;
  animation-timing-function: linear;
  display: inline-block;
  backface-visibility: hidden;
  animation-fill-mode: forwards;
}
.hundredmodal__content__close {
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 18px;
  letter-spacing: -0.25px;
  cursor: pointer;
  position: absolute;
  color: #fff;
  right: 0;
  left: 100%;
  transform: translate(9px, -30px);
  z-index: 9997;
  top: 0;
}
.hundredmodal__veil {
  background-color: rgba(0,0,0,0.6);
  height: 200vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 200vw;
}
.hundredmodal.language-suggest-container-modal .hundredicons-cross:before {
  display: none;
}
@media (max-width: 767px) {
  .hundredmodal {
    display: block;
  }
  .hundredmodal__content {
    height: 100vh;
    width: 100%;
  }
  .hundredmodal__content__close {
    color: #4a4a4a;
    transform: translate(-10px, 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: auto;
    font-size: 16px;
    letter-spacing: -0.25px;
    border-radius: 50%;
    background-color: #fff;
    padding-left: 8vw;
    top: 0;
  }
}
@media (min-width: 768px) {
  .hundredmodal.menu_filters-container-modal {
    text-align: left;
    transform: none;
  }
  .hundredmodal.menu_filters-container-modal .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.menu_filters-container-modal.-animation-enter .hundredmodal__content {
    animation-name: slideInLeft;
  }
  .hundredmodal.menu_filters-container-modal.-animation-enter .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.menu_filters-container-modal.-animation-leave .hundredmodal__content {
    animation-name: slideOutLeft;
  }
  .hundredmodal.menu_filters-container-modal.-animation-leave .hundredmodal__veil {
    display: none;
  }
  .hundredmodal.menu_user-container-modal {
    text-align: right;
  }
  .hundredmodal.establishment-container-modal {
    text-align: center;
    padding: 40px 0;
  }
  .hundredmodal.establishment-container-modal .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.establishment-container-modal.-animation-enter .hundredmodal__content {
    animation-name: slideInUp;
  }
  .hundredmodal.establishment-container-modal.-animation-enter .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.share-container-modal {
    text-align: center;
    padding: 22vh 0;
  }
  .hundredmodal.share-container-modal .hundredmodal__content__close {
    display: block;
  }
  .hundredmodal.share-container-modal .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.login-container-modal {
    text-align: center;
    padding: 20vh 0;
  }
  .hundredmodal.login-container-modal .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.establishment-share-container-modal {
    text-align: center;
    padding: 20vh 0;
  }
  .hundredmodal.establishment-share-container-modal .hundredmodal__content__close {
    display: block;
  }
  .hundredmodal.establishment-share-container-modal .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.alert-popup-focus-lost-container-modal {
    text-align: center;
    padding: 12vh 0 2vh;
  }
  .hundredmodal.alert-popup-focus-lost-container-modal .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.alert-popup-focus-lost-container-modal.-animation-leave .hundredmodal__content {
    display: none;
  }
  .hundredmodal.alert-popup-focus-lost-container-modal.-animation-leave .hundredmodal__veil {
    display: none;
  }
  .hundredmodal.date-picker-container-modal {
    text-align: center;
    padding: 20vh 0;
  }
  .hundredmodal.date-picker-container-modal .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.language-suggest-container-modal {
    text-align: center;
    padding: 30vh 0;
  }
  .hundredmodal.language-suggest-container-modal .hundredmodal__veil {
    display: inherit;
  }
  .hundredmodal.language-suggest-container-modal .hundredicons-cross:before {
    display: none;
  }
  .hundredmodal.leads-form-container-modal {
    text-align: center;
    padding: 40px 0;
  }
  .hundredmodal.leads-form-container-modal .hundredmodal__content__close {
    display: block;
  }
  .hundredmodal.leads-form-container-modal .hundredmodal__veil {
    display: inherit;
  }
}
.hundredmodal#user_social_layer {
  z-index: 9999;
}
.hundredmodal#menus_layer {
  z-index: 9997;
}
.hundredmodal#especial_layer {
  z-index: 9996;
}
.shape__modal {
  z-index: 9999;
}
.shape__menu {
  z-index: 9997;
}
.map-notification.notification {
  position: fixed;
  right: 10px;
  top: calc(100vh - 80vh);
  width: auto;
  min-height: auto;
  max-width: 220px;
  border-radius: 0.5em;
  animation-name: slideInRight;
  animation-duration: 500ms;
  animation-delay: 0ms;
}
@media (max-width: 767px) {
  .map-notification.notification {
    top: 20vh;
  }
}
.map-notification.notification .notification__content__title {
  font-size: 14px;
  letter-spacing: -0.25px;
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  letter-spacing: 0;
  white-space: normal;
  text-align: right;
}
.-animation-leave .map-notification.notification,
.-animation-enter .map-notification.notification {
  animation-name: none;
  animation-delay: 10s;
}
.search-modal {
  letter-spacing: -0.25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  position: fixed;
  overflow: scroll;
  z-index: 9996;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: #fff;
  animation: slideInUp;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.78, 0.6, 0.4, 0.99);
  transition: all ease 0.5s;
  display: block;
}
.search-modal__header {
  text-align: right;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding-left: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.search-modal__header__icon {
  font-size: 18px;
  letter-spacing: -0.25px;
  color: #4a4a4a;
}
.search-modal__header__text {
  position: relative;
  margin-right: 3%;
}
.search-modal__header__text:before {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 30px;
  left: -15px;
  top: 9px;
  background-color: #17d280;
}
.search-modal__body {
  display: block;
  padding: 50px 0 150px;
}
.search-modal__body .search-bar-molecule {
  background-color: #fff;
  padding: 0px;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
}
.search-modal__body .search-bar-molecule__desktop__box {
  box-shadow: none;
  border: none;
  border-top: 1px solid #ccdff4;
}
.search-modal__body .search-bar-molecule .search-bar-molecule__bottom__checkboxes {
  padding-left: 12px;
}
.search-modal__body .search-bar-molecule .search-bar-molecule__bottom__checkboxes__title {
  color: #4a4a4a;
}
.search-modal__body .search-bar-molecule .search-bar-molecule__bottom__checkboxes .hundred-ui-checkbox__label:after {
  box-shadow: inset 0 0 0 1px #ccdff4;
}
.search-modal__body .search-bar-molecule .search-bar-molecule__bottom__checkboxes .hundred-ui-checkbox__label__text {
  color: #9e9e9e;
}
.search-modal.-closed {
  transform: translateY(110%);
  top: 110%;
}
.search-modal.-opened {
  transform: translateY(0);
  top: 0;
}
@media (min-width: 500px) {
  .search-modal__body .search-bar-molecule {
    max-width: 500px;
    margin: 0 auto;
  }
  .search-modal__body .search-bar-molecule__desktop__box {
    border: 1px solid #ccdff4;
  }
}
@media (min-width: 414px) {
  .search-modal__body {
    display: flex;
    align-items: center;
    height: 100vh;
  }
  .search-modal__body .search-bar-molecule {
    margin: 0 auto;
    max-width: inherit;
  }
}
.veil {
  position: fixed;
  transition: all ease 0.5s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.veil.-show {
  z-index: 9995;
  background: rgba(0,0,0,0.4);
}
.veil.-hide {
  z-index: 0;
  background: transparent;
  display: none;
}
.sprite-flags__arg,
.sprite-flags__aus,
.sprite-flags__chi,
.sprite-flags__col,
.sprite-flags__fra,
.sprite-flags__ger,
.sprite-flags__ita,
.sprite-flags__jpn,
.sprite-flags__mex,
.sprite-flags__per,
.sprite-flags__spa,
.sprite-flags__uki,
.sprite-flags__uru,
.sprite-flags__usa {
  width: 100%;
  position: absolute;
}
.sprite-flags__arg {
  top: 4px;
}
.sprite-flags__aus {
  top: -60px;
}
.sprite-flags__chi {
  top: -124px;
}
.sprite-flags__col {
  top: -190px;
}
.sprite-flags__fra {
  top: -255px;
}
.sprite-flags__ger {
  top: -319px;
}
.sprite-flags__ita {
  top: -384px;
}
.sprite-flags__jpn {
  top: -448px;
}
.sprite-flags__mex {
  top: -514px;
}
.sprite-flags__per {
  top: -577px;
}
.sprite-flags__spa {
  top: -643px;
}
.sprite-flags__uki {
  top: -707px;
}
.sprite-flags__uru {
  top: -772px;
}
.sprite-flags__usa {
  top: -836px;
}
